:root, [data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: .4rem;
  --bs-border-radius-xxl: .5rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #0d6efd40;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #dee2e6bf;
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: #dee2e680;
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: #ffffff26;
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.75rem;
  }

  h4, .h4 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

mark, .mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--bs-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6, .gx-6 {
  --bs-gutter-x: 5rem;
}

.g-6, .gy-6 {
  --bs-gutter-y: 5rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-6, .gx-sm-6 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --bs-gutter-y: 5rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }

  .g-md-6, .gx-md-6 {
    --bs-gutter-x: 5rem;
  }

  .g-md-6, .gy-md-6 {
    --bs-gutter-y: 5rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-6, .gx-lg-6 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --bs-gutter-y: 5rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-6, .gx-xl-6 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --bs-gutter-y: 5rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --bs-gutter-y: 5rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), .05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), .1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a5b5cc;
  --bs-table-striped-bg: #c4d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b4b6b7;
  --bs-table-striped-bg: #d6d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbcdce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a5c4ca;
  --bs-table-striped-bg: #c4e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e6c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dab9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc1c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7c9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--bs-border-width));
  padding-bottom: calc(.375rem + var(--bs-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--bs-border-width));
  padding-bottom: calc(.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--bs-border-width));
  padding-bottom: calc(.25rem + var(--bs-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--bs-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-clip: padding-box;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  margin: -.375rem -.75rem;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  width: 100%;
  color: var(--bs-body-color);
  border: solid #0000;
  border-width: var(--bs-border-width) 0;
  background-color: #0000;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  width: 100%;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  border-radius: var(--bs-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--bs-border-radius-lg);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--bs-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb%28134, 182.5, 254%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: var(--bs-form-valid-color);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: var(--bs-form-invalid-color);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius)  - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: .125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 700;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), .8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), .3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), .15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  color: var(--bs-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--cnvs-contrast-300);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%285.2, 44, 101.2%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%28109.8, 168, 253.8%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb%28109.8, 168, 253.8%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--bs-alert-link-color);
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress, .progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: .5;
  --bs-btn-close-hover-opacity: .75;
  --bs-btn-close-focus-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: .25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--bs-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  opacity: var(--bs-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg)  - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg)  - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform .3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  transition: var(--bs-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  line-height: var(--bs-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
  outline: 0;
}

.icon-link {
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--bs-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.mh-100 {
  max-height: 100% !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-6 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.mx-n6 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-6 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --bs-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --bs-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --bs-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --bs-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.bg-opacity-20 {
  --bs-bg-opacity: .2;
}

.bg-opacity-40 {
  --bs-bg-opacity: .4;
}

.bg-opacity-60 {
  --bs-bg-opacity: .6;
}

.bg-opacity-90 {
  --bs-bg-opacity: .9;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-6 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .m-sm-n6 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-sm-n6 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .mt-sm-n6 {
    margin-top: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .me-sm-n6 {
    margin-right: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .ms-sm-n6 {
    margin-left: -5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 5rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-6 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .m-md-n6 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-md-n6 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .mt-md-n6 {
    margin-top: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .me-md-n6 {
    margin-right: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .ms-md-n6 {
    margin-left: -5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .row-gap-md-6 {
    row-gap: 5rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .column-gap-md-6 {
    column-gap: 5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-6 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .m-lg-n6 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-lg-n6 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .mt-lg-n6 {
    margin-top: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .me-lg-n6 {
    margin-right: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .ms-lg-n6 {
    margin-left: -5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 5rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-6 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .m-xl-n6 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xl-n6 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xl-n6 {
    margin-top: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .me-xl-n6 {
    margin-right: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xl-n6 {
    margin-left: -5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 5rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-6 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .m-xxl-n6 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .me-xxl-n6 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 5rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root {
  --cnvs-themecolor: #1abc9c;
  --cnvs-themecolor-rgb: 26, 188, 156;
  --bs-link-color: var(--cnvs-themecolor);
  --bs-link-color-rgb: var(--cnvs-themecolor-rgb);
  --bs-link-hover-color: var(--cnvs-heading-color);
  --cnvs-white: #fff;
  --cnvs-black: #000;
  --cnvs-headings-font-weight: 600;
  --cnvs-body-font: "Inter", sans-serif;
  --cnvs-primary-font: "Inter", sans-serif;
  --cnvs-secondary-font: "Playfair Display", serif;
  --cnvs-line-height-base: 1.5;
  --cnvs-line-height-content: 1.65;
  --cnvs-margin-base: 3rem;
  --cnvs-margin-sm: 2rem;
  --cnvs-margin-lg: 5rem;
  --cnvs-content-padding: 5rem;
  --cnvs-section-margin: 4rem;
  --cnvs-section-padding: var(--cnvs-section-margin);
  --cnvs-col-padding: var(--cnvs-section-padding);
  --cnvs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --cnvs-box-shadow: 0 .5rem 1rem #00000026;
  --cnvs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --cnvs-copyrights-font-size: .875rem;
  --bs-bg-opacity: 1;
  --cnvs-header-height: 100px;
  --cnvs-header-height-sm: 60px;
  --cnvs-header-height-md: 80px;
  --cnvs-header-height-lg: 120px;
  --cnvs-header-height-shrink: 60px;
  --cnvs-side-header-width: 260px;
  --cnvs-side-header-padding-gutters: 30px;
  --cnvs-side-panel-size: 300px;
  --cnvs-side-panel-padding: 50px 70px 50px 40px;
  --cnvs-side-panel-widget-padding: 40px;
  --cnvs-transitions: .5s ease;
  --cnvs-color-facebook: #3b5998;
  --cnvs-color-facebook-rgb: 59, 89, 152;
  --cnvs-color-whatsapp: #25d366;
  --cnvs-color-whatsapp-rgb: 37, 211, 102;
  --cnvs-color-delicious: #205cc0;
  --cnvs-color-delicious-rgb: 32, 92, 192;
  --cnvs-color-paypal: #00588b;
  --cnvs-color-paypal-rgb: 0, 88, 139;
  --cnvs-color-waze: #05c8f7;
  --cnvs-color-waze-rgb: 5, 200, 247;
  --cnvs-color-android: #a4c639;
  --cnvs-color-android-rgb: 164, 198, 57;
  --cnvs-color-googledrive: #1fa463;
  --cnvs-color-googledrive-rgb: 31, 164, 99;
  --cnvs-color-wikipedia: #333;
  --cnvs-color-wikipedia-rgb: 51, 51, 51;
  --cnvs-color-stumbleupon: #f74425;
  --cnvs-color-stumbleupon-rgb: 247, 68, 37;
  --cnvs-color-foursquare: #25a0ca;
  --cnvs-color-foursquare-rgb: 37, 160, 202;
  --cnvs-color-call: #444;
  --cnvs-color-call-rgb: 68, 68, 68;
  --cnvs-color-ninetyninedesigns: #f26739;
  --cnvs-color-ninetyninedesigns-rgb: 242, 103, 57;
  --cnvs-color-forrst: #5b9a68;
  --cnvs-color-forrst-rgb: 91, 154, 104;
  --cnvs-color-digg: #191919;
  --cnvs-color-digg-rgb: 25, 25, 25;
  --cnvs-color-spotify: #1db954;
  --cnvs-color-spotify-rgb: 29, 185, 84;
  --cnvs-color-reddit: #c6c6c6;
  --cnvs-color-reddit-rgb: 198, 198, 198;
  --cnvs-color-blogger: #fc4f08;
  --cnvs-color-blogger-rgb: 252, 79, 8;
  --cnvs-color-cc: #688527;
  --cnvs-color-cc-rgb: 104, 133, 39;
  --cnvs-color-dribbble: #ea4c89;
  --cnvs-color-dribbble-rgb: 234, 76, 137;
  --cnvs-color-evernote: #5ba525;
  --cnvs-color-evernote-rgb: 91, 165, 37;
  --cnvs-color-flickr: #ff0084;
  --cnvs-color-flickr-rgb: 255, 0, 132;
  --cnvs-color-google: #4885ed;
  --cnvs-color-google-rgb: 72, 133, 237;
  --cnvs-color-salesforce: #1798c1;
  --cnvs-color-salesforce-rgb: 23, 152, 193;
  --cnvs-color-cloudflare: #f38020;
  --cnvs-color-cloudflare-rgb: 243, 128, 32;
  --cnvs-color-linkedin: #0e76a8;
  --cnvs-color-linkedin-rgb: 14, 118, 168;
  --cnvs-color-vk: #2b587a;
  --cnvs-color-vk-rgb: 43, 88, 122;
  --cnvs-color-rss: #ee802f;
  --cnvs-color-rss-rgb: 238, 128, 47;
  --cnvs-color-skype: #00aff0;
  --cnvs-color-skype-rgb: 0, 175, 240;
  --cnvs-color-twitter: #00acee;
  --cnvs-color-twitter-rgb: 0, 172, 238;
  --cnvs-color-x-twitter: #010101;
  --cnvs-color-x-twitter-rgb: 1, 1, 1;
  --cnvs-color-youtube: #c4302b;
  --cnvs-color-youtube-rgb: 196, 48, 43;
  --cnvs-color-vimeo: #86c9ef;
  --cnvs-color-vimeo-rgb: 134, 201, 239;
  --cnvs-color-slack: #ecb22e;
  --cnvs-color-slack-rgb: 236, 178, 46;
  --cnvs-color-yahoo: #720e9e;
  --cnvs-color-yahoo-rgb: 114, 14, 158;
  --cnvs-color-email3: #6567a5;
  --cnvs-color-email3-rgb: 101, 103, 165;
  --cnvs-color-macstore: #333;
  --cnvs-color-macstore-rgb: 51, 51, 51;
  --cnvs-color-figma: #ab7c63;
  --cnvs-color-figma-rgb: 171, 124, 99;
  --cnvs-color-podcast: #e4b21b;
  --cnvs-color-podcast-rgb: 228, 178, 27;
  --cnvs-color-meetup: #e51937;
  --cnvs-color-meetup-rgb: 229, 25, 55;
  --cnvs-color-dropbox: #3d9ae8;
  --cnvs-color-dropbox-rgb: 61, 154, 232;
  --cnvs-color-ebay: #89c507;
  --cnvs-color-ebay-rgb: 137, 197, 7;
  --cnvs-color-github: #171515;
  --cnvs-color-github-rgb: 23, 21, 21;
  --cnvs-color-googleplay: #3bccff;
  --cnvs-color-googleplay-rgb: 59, 204, 255;
  --cnvs-color-itunes: #222;
  --cnvs-color-itunes-rgb: 34, 34, 34;
  --cnvs-color-periscope: #52677b;
  --cnvs-color-periscope-rgb: 82, 103, 123;
  --cnvs-color-meta: #0668e1;
  --cnvs-color-meta-rgb: 6, 104, 225;
  --cnvs-color-soundcloud: #f70;
  --cnvs-color-soundcloud-rgb: 255, 119, 0;
  --cnvs-color-tumblr: #34526f;
  --cnvs-color-tumblr-rgb: 52, 82, 111;
  --cnvs-color-wordpress: #1e8cbe;
  --cnvs-color-wordpress-rgb: 30, 140, 190;
  --cnvs-color-yelp: #c41200;
  --cnvs-color-yelp-rgb: 196, 18, 0;
  --cnvs-color-microsoft: #f65314;
  --cnvs-color-microsoft-rgb: 246, 83, 20;
  --cnvs-color-trello: #0079bf;
  --cnvs-color-trello-rgb: 0, 121, 191;
  --cnvs-color-scribd: #666;
  --cnvs-color-scribd-rgb: 102, 102, 102;
  --cnvs-color-stripe: #008cdd;
  --cnvs-color-stripe-rgb: 0, 140, 221;
  --cnvs-color-print: #111;
  --cnvs-color-print-rgb: 17, 17, 17;
  --cnvs-color-btc: #f2a900;
  --cnvs-color-btc-rgb: 242, 169, 0;
  --cnvs-color-weibo: #df2029;
  --cnvs-color-weibo-rgb: 223, 32, 41;
  --cnvs-color-acrobat: #d3222a;
  --cnvs-color-acrobat-rgb: 211, 34, 42;
  --cnvs-color-drupal: #27537a;
  --cnvs-color-drupal-rgb: 39, 83, 122;
  --cnvs-color-buffer: #333;
  --cnvs-color-buffer-rgb: 51, 51, 51;
  --cnvs-color-pocket: #ee4056;
  --cnvs-color-pocket-rgb: 238, 64, 86;
  --cnvs-color-bitbucket: #0e4984;
  --cnvs-color-bitbucket-rgb: 14, 73, 132;
  --cnvs-color-stackoverflow: #ef8236;
  --cnvs-color-stackoverflow-rgb: 239, 130, 54;
  --cnvs-color-hackernews: #f60;
  --cnvs-color-hackernews-rgb: 255, 102, 0;
  --cnvs-color-xing: #126567;
  --cnvs-color-xing-rgb: 18, 101, 103;
  --cnvs-color-instagram: #8a3ab9;
  --cnvs-color-instagram-rgb: 138, 58, 185;
  --cnvs-color-quora: #a82400;
  --cnvs-color-quora-rgb: 168, 36, 0;
  --cnvs-color-openid: #e16309;
  --cnvs-color-openid-rgb: 225, 99, 9;
  --cnvs-color-steam: #111;
  --cnvs-color-steam-rgb: 17, 17, 17;
  --cnvs-color-amazon: #e47911;
  --cnvs-color-amazon-rgb: 228, 121, 17;
  --cnvs-color-line: #00b900;
  --cnvs-color-line-rgb: 0, 185, 0;
  --cnvs-color-kickstarter: #87c442;
  --cnvs-color-kickstarter-rgb: 135, 196, 66;
  --cnvs-color-appstore: #000;
  --cnvs-color-appstore-rgb: 0, 0, 0;
  --cnvs-color-pinterest: #c8232c;
  --cnvs-color-pinterest-rgb: 200, 35, 44;
  --cnvs-color-fivehundredpx: #111;
  --cnvs-color-fivehundredpx-rgb: 17, 17, 17;
  --cnvs-color-tiktok: #000;
  --cnvs-color-tiktok-rgb: 0, 0, 0;
  --cnvs-color-airbnb: #ff5a5f;
  --cnvs-color-airbnb-rgb: 255, 90, 95;
  --cnvs-color-gpay: #6877df;
  --cnvs-color-gpay-rgb: 104, 119, 223;
  --cnvs-color-unsplash: #111;
  --cnvs-color-unsplash-rgb: 17, 17, 17;
  --cnvs-color-snapchat: #fffc00;
  --cnvs-color-snapchat-rgb: 255, 252, 0;
  --cnvs-color-discord: #7289da;
  --cnvs-color-discord-rgb: 114, 137, 218;
  --cnvs-color-twitch: #6441a5;
  --cnvs-color-twitch-rgb: 100, 65, 165;
  --cnvs-font-size-body: var(--bs-body-font-size);
  --cnvs-font-size-h1: 2.25rem;
  --cnvs-font-size-h2: 1.875rem;
  --cnvs-font-size-h3: 1.625rem;
  --cnvs-font-size-h4: 1.375rem;
  --cnvs-font-size-h5: 1.125rem;
  --cnvs-font-size-h6: .875rem;
  --cnvs-font-size-small: calc(var(--cnvs-font-size-body) * .875);
}

:root, .not-dark {
  --cnvs-contrast-0: #fff;
  --cnvs-contrast-100: #f8f9fa;
  --cnvs-contrast-200: #e9ecef;
  --cnvs-contrast-300: #dee2e6;
  --cnvs-contrast-400: #ced4da;
  --cnvs-contrast-500: #adb5bd;
  --cnvs-contrast-600: #6c757d;
  --cnvs-contrast-700: #495057;
  --cnvs-contrast-800: #343a40;
  --cnvs-contrast-900: #212529;
  --cnvs-contrast-1000: #000;
  --cnvs-invert-contrast-0: #000;
  --cnvs-invert-contrast-100: #212529;
  --cnvs-invert-contrast-200: #343a40;
  --cnvs-invert-contrast-300: #495057;
  --cnvs-invert-contrast-400: #6c757d;
  --cnvs-invert-contrast-500: #adb5bd;
  --cnvs-invert-contrast-600: #ced4da;
  --cnvs-invert-contrast-700: #dee2e6;
  --cnvs-invert-contrast-800: #e9ecef;
  --cnvs-invert-contrast-900: #f8f9fa;
  --cnvs-invert-contrast-1000: #fff;
  --cnvs-body-bg: var(--bs-body-bg);
  --cnvs-body-bg-boxed: var(--cnvs-body-bg);
  --cnvs-link-color: var(--cnvs-themecolor);
  --cnvs-link-hover-color: var(--cnvs---cnvs-heading-color);
  --cnvs-contrast-rgb: 0, 0, 0;
  --cnvs-invert-contrast-rgb: 255, 255, 255;
  --cnvs-contrast-bg: #fff;
  --cnvs-contrast-bg-offset: #f8f9fa;
  --cnvs-topbar-bg: var(--cnvs-contrast-0);
  --cnvs-topbar-color: var(--cnvs-contrast-700);
  --cnvs-topbar-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-topbar-social-icon-color: var(--cnvs-contrast-600);
  --cnvs-header-bg: var(--cnvs-contrast-0);
  --cnvs-header-sticky-bg: var(--cnvs-header-bg);
  --cnvs-side-header-bg: var(--cnvs-contrast-0);
  --cnvs-section-bg: var(--cnvs-contrast-bg-offset);
  --cnvs-heading-color: var(--cnvs-contrast-900);
  --cnvs-line-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-footer-bg: var(--cnvs-contrast-bg-offset);
  --cnvs-bg-overlay-bg-opacity: .75;
  --cnvs-bg-overlay-bg: rgba(var(--cnvs-invert-contrast-rgb), var(--cnvs-bg-overlay-bg-opacity));
  color: var(--cnvs-contrast-800);
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

[class*="col-"]:not([class*="sticky-"]):not([class*="position-"]) {
  position: relative;
}

@media (width <= 575.98px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns.grid-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .card-columns > * {
    width: 100%;
    display: inline-block;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.col-1-5 {
  flex: none;
  width: 20%;
}

@media (width >= 576px) {
  .col-sm-1-5 {
    flex: none;
    width: 20%;
  }
}

@media (width >= 768px) {
  .col-md-1-5 {
    flex: none;
    width: 20%;
  }
}

@media (width >= 992px) {
  .col-lg-1-5 {
    flex: none;
    width: 20%;
  }
}

@media (width >= 1200px) {
  .col-xl-1-5 {
    flex: none;
    width: 20%;
  }
}

@media (width >= 1400px) {
  .col-xxl-1-5 {
    flex: none;
    width: 20%;
  }
}

#wrapper {
  float: none;
  background-color: var(--cnvs-body-bg);
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 10px #0000001a;
}

@media (width >= 1200px) {
  body:not(.stretched) #wrapper {
    max-width: 1200px;
  }
}

@media (width >= 1400px) {
  body:not(.stretched) #wrapper {
    max-width: 1400px;
  }
}

.stretched #wrapper {
  width: 100%;
  box-shadow: none;
  margin: 0;
}

.section {
  width: 100%;
  margin: var(--cnvs-section-margin) 0;
  padding: var(--cnvs-section-padding) 0;
  background-color: var(--cnvs-section-bg);
  position: relative;
  overflow: hidden;
}

.section .container {
  z-index: 2;
}

.section .container + .video-wrap {
  z-index: 1;
}

.parallax {
  --cnvs-parallax-ratio: 2;
  --cnvs-parallax-width: 100%;
  --cnvs-parallax-height: calc(1px * (var(--cnvs-scroll-height)  + ((var(--cnvs-parallax-ratio) * 100))));
  --cnvs-parallax-factor: -1px;
  --cnvs-parallax-value: calc(var(--cnvs-parallax-factor) * var(--cnvs-parallax-ratio) * var(--cnvs-scroll-percent));
  will-change: transform;
  position: relative;
  overflow: hidden;
}

.parallax-bg {
  z-index: -1;
  object-fit: cover;
  transform: translate3d(0px, var(--cnvs-parallax-value), 0px);
  will-change: transform;
  transition: transform .1s linear;
  top: 0;
  left: 0;
  max-width: none !important;
  width: var(--cnvs-parallax-width) !important;
  height: var(--cnvs-parallax-height) !important;
  position: absolute !important;
}

.parallax[data-parallax-to="bottom"] {
  --cnvs-parallax-factor: 1px;
}

.parallax[data-parallax-to="bottom"] .parallax-bg {
  top: auto;
  bottom: 0;
}

.parallax[data-parallax-direction="horizontal"] {
  --cnvs-parallax-width: calc(1px * (var(--cnvs-scroll-width)  + ((var(--cnvs-parallax-ratio) * 100))));
  --cnvs-parallax-height: 100%;
}

.parallax[data-parallax-direction="horizontal"] .parallax-bg {
  transform: translate3d(var(--cnvs-parallax-value), 0px, 0px);
}

.parallax[data-parallax-direction="horizontal"][data-parallax-to="right"] {
  --cnvs-parallax-factor: 1px;
}

.parallax[data-parallax-direction="horizontal"][data-parallax-to="right"] .parallax-bg {
  left: auto;
  right: 0;
}

.mobile-parallax, .video-placeholder {
  background-position: center !important;
  background-size: cover !important;
  background-attachment: scroll !important;
}

.revealer-image {
  transition: bottom .3s ease-in-out;
  position: relative;
  bottom: -100px;
}

@media (prefers-reduced-motion: reduce) {
  .revealer-image {
    transition: none;
  }
}

.section:hover .revealer-image {
  bottom: -50px;
}

.postcontent, .sidebar {
  position: relative;
}

dl, dt, dd, ol, ul, li {
  margin: 0;
  padding: 0;
}

em {
  font-family: var(--cnvs-secondary-font);
}

.clear {
  clear: both;
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  overflow: hidden;
}

::selection {
  background: var(--cnvs-themecolor);
  color: var(--cnvs-body-bg);
  text-shadow: none;
  background: var(--cnvs-themecolor);
}

::-webkit-selection {
  background: var(--cnvs-themecolor);
  color: var(--cnvs-body-bg);
  text-shadow: none;
}

:active, :focus {
  outline: none !important;
}

body {
  line-height: 1.5;
  font-size: var(--cnvs-font-size-body);
  font-family: var(--cnvs-body-font);
  background: var(--cnvs-body-bg);
}

body:not(.stretched) {
  background: var(--cnvs-body-bg-boxed);
}

a {
  color: var(--cnvs-link-color);
}

a:not(.btn-link):not(.text-decoration-underline):not(.more-link) {
  text-decoration: none !important;
}

a:hover {
  color: var(--cnvs-link-hover-color);
}

a img {
  border: none;
}

img {
  max-width: 100%;
}

iframe {
  width: 100%;
  border: 0 !important;
  overflow: hidden !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--cnvs-heading-color);
  font-weight: var(--cnvs-headings-font-weight);
  line-height: var(--cnvs-line-height-base);
  font-family: var(--cnvs-primary-font);
  margin: 0 0 30px;
}

h5, .h5, h6, .h6 {
  margin-bottom: 20px;
  font-weight: bold;
}

h1, .h1 {
  font-size: var(--cnvs-font-size-h1);
}

h2, .h2 {
  font-size: var(--cnvs-font-size-h2);
}

h3, .h3 {
  font-size: var(--cnvs-font-size-h3);
}

h4, .h4 {
  font-size: var(--cnvs-font-size-h4);
}

h5, .h5 {
  font-size: var(--cnvs-font-size-h5);
}

h6, .h6 {
  font-size: var(--cnvs-font-size-h6);
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

h4, .h4 {
  font-weight: 600;
}

h5, .h5, h6, .h6 {
  font-weight: bold;
}

@media (width <= 767.98px) {
  h1, .h1 {
    font-size: 1.75rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.25rem;
  }

  h4, .h4 {
    font-size: 1rem;
  }
}

h1 > span:not(.nocolor):not(.badge), .h1 > span:not(.nocolor):not(.badge), h2 > span:not(.nocolor):not(.badge), .h2 > span:not(.nocolor):not(.badge), h3 > span:not(.nocolor):not(.badge), .h3 > span:not(.nocolor):not(.badge), h4 > span:not(.nocolor):not(.badge), .h4 > span:not(.nocolor):not(.badge), h5 > span:not(.nocolor):not(.badge), .h5 > span:not(.nocolor):not(.badge), h6 > span:not(.nocolor):not(.badge), .h6 > span:not(.nocolor):not(.badge) {
  color: var(--cnvs-themecolor);
}

p, pre, ul, ol, dl, dd, blockquote, address, table, fieldset, form {
  margin-bottom: 30px;
}

blockquote p:last-child {
  margin-bottom: 0;
}

small, .small {
  font-size: var(--cnvs-font-size-small);
}

.line, .double-line {
  clear: both;
  width: 100%;
  margin: var(--cnvs-section-margin) 0;
  border-top: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  position: relative;
}

.line.line-sm {
  margin: calc(var(--cnvs-section-margin) * .5) 0;
}

span.middot {
  margin: 0 5px;
  display: inline-block;
}

.double-line {
  border-top: 3px double rgba(var(--cnvs-contrast-rgb), .1);
}

.header-stick {
  margin-top: -var(--cnvs-margin-base) !important;
}

.content-wrap .header-stick {
  margin-top: calc(-1 * var(--cnvs-margin-lg)) !important;
}

.footer-stick {
  margin-bottom: -var(--cnvs-margin-base) !important;
}

.content-wrap .footer-stick {
  margin-bottom: calc(-1 * var(--cnvs-margin-lg)) !important;
}

.border-f5 {
  border-color: #f5f5f5 !important;
}

.col-padding {
  padding: 4rem;
}

.text-shadow-none {
  text-shadow: none !important;
}

.inline-block {
  float: none !important;
  display: inline-block !important;
}

.center {
  text-align: center !important;
}

.mx-auto {
  float: none !important;
  position: relative !important;
}

.text-contrast-0, .h-text-contrast-0:hover {
  color: var(--cnvs-contrast-0) !important;
}

.text-contrast-100, .h-text-contrast-100:hover {
  color: var(--cnvs-contrast-100) !important;
}

.text-contrast-200, .h-text-contrast-200:hover {
  color: var(--cnvs-contrast-200) !important;
}

.text-contrast-300, .h-text-contrast-300:hover {
  color: var(--cnvs-contrast-300) !important;
}

.text-contrast-400, .h-text-contrast-400:hover {
  color: var(--cnvs-contrast-400) !important;
}

.text-contrast-500, .h-text-contrast-500:hover {
  color: var(--cnvs-contrast-500) !important;
}

.text-contrast-600, .h-text-contrast-600:hover {
  color: var(--cnvs-contrast-600) !important;
}

.text-contrast-700, .h-text-contrast-700:hover {
  color: var(--cnvs-contrast-700) !important;
}

.text-contrast-800, .h-text-contrast-800:hover {
  color: var(--cnvs-contrast-800) !important;
}

.text-contrast-900, .h-text-contrast-900:hover {
  color: var(--cnvs-contrast-900) !important;
}

.text-contrast-1000, .h-text-contrast-1000:hover {
  color: var(--cnvs-contrast-1000) !important;
}

.bg-contrast-0, .h-bg-contrast-0:hover {
  background-color: var(--cnvs-contrast-0) !important;
}

.bg-contrast-100, .h-bg-contrast-100:hover {
  background-color: var(--cnvs-contrast-100) !important;
}

.bg-contrast-200, .h-bg-contrast-200:hover {
  background-color: var(--cnvs-contrast-200) !important;
}

.bg-contrast-300, .h-bg-contrast-300:hover {
  background-color: var(--cnvs-contrast-300) !important;
}

.bg-contrast-400, .h-bg-contrast-400:hover {
  background-color: var(--cnvs-contrast-400) !important;
}

.bg-contrast-500, .h-bg-contrast-500:hover {
  background-color: var(--cnvs-contrast-500) !important;
}

.bg-contrast-600, .h-bg-contrast-600:hover {
  background-color: var(--cnvs-contrast-600) !important;
}

.bg-contrast-700, .h-bg-contrast-700:hover {
  background-color: var(--cnvs-contrast-700) !important;
}

.bg-contrast-800, .h-bg-contrast-800:hover {
  background-color: var(--cnvs-contrast-800) !important;
}

.bg-contrast-900, .h-bg-contrast-900:hover {
  background-color: var(--cnvs-contrast-900) !important;
}

.bg-contrast-1000, .h-bg-contrast-1000:hover {
  background-color: var(--cnvs-contrast-1000) !important;
}

.border-contrast-0, .h-border-contrast-0:hover {
  border-color: var(--cnvs-contrast-0) !important;
}

.border-contrast-100, .h-border-contrast-100:hover {
  border-color: var(--cnvs-contrast-100) !important;
}

.border-contrast-200, .h-border-contrast-200:hover {
  border-color: var(--cnvs-contrast-200) !important;
}

.border-contrast-300, .h-border-contrast-300:hover {
  border-color: var(--cnvs-contrast-300) !important;
}

.border-contrast-400, .h-border-contrast-400:hover {
  border-color: var(--cnvs-contrast-400) !important;
}

.border-contrast-500, .h-border-contrast-500:hover {
  border-color: var(--cnvs-contrast-500) !important;
}

.border-contrast-600, .h-border-contrast-600:hover {
  border-color: var(--cnvs-contrast-600) !important;
}

.border-contrast-700, .h-border-contrast-700:hover {
  border-color: var(--cnvs-contrast-700) !important;
}

.border-contrast-800, .h-border-contrast-800:hover {
  border-color: var(--cnvs-contrast-800) !important;
}

.border-contrast-900, .h-border-contrast-900:hover {
  border-color: var(--cnvs-contrast-900) !important;
}

.border-contrast-1000, .h-border-contrast-1000:hover {
  border-color: var(--cnvs-contrast-1000) !important;
}

.bg-color, .bg-color #header-wrap, .bg-color[class*="bg-opacity-"] {
  background-color: rgba(var(--cnvs-themecolor-rgb), var(--bs-bg-opacity, 1)) !important;
}

.color {
  color: rgba(var(--cnvs-themecolor-rgb), var(--bs-text-opacity, 1)) !important;
}

.border-color {
  border-color: var(--cnvs-themecolor) !important;
}

.text-transform-none {
  text-transform: none !important;
}

.overflow-visible {
  overflow: visible !important;
}

.fw-extralight {
  font-weight: 100 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fst-normal {
  font-style: normal !important;
}

.ls-0, .ls-n0 {
  letter-spacing: 0 !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-n1 {
  letter-spacing: -1px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-n2 {
  letter-spacing: -2px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-n3 {
  letter-spacing: -3px !important;
}

.ls-4 {
  letter-spacing: 4px !important;
}

.ls-n4 {
  letter-spacing: -4px !important;
}

.ls-5 {
  letter-spacing: 5px !important;
}

.ls-n5 {
  letter-spacing: -5px !important;
}

@supports (text-stroke: var(--cnvs-text-stroke-size) var(--cnvs-text-stroke-color)) or (-webkit-text-stroke: var(--cnvs-text-stroke-size) var(--cnvs-text-stroke-color)) {
  .text-stroke, .h-text-stroke:hover {
    --cnvs-text-stroke-size: 2px;
    --cnvs-text-stroke-color: var(--cnvs-contrast-900);
    color: #0000;
    text-stroke: var(--cnvs-text-stroke-size) var(--cnvs-text-stroke-color);
    -webkit-text-stroke: var(--cnvs-text-stroke-size) var(--cnvs-text-stroke-color);
  }

  .text-stroke-none, .h-text-stroke-none:hover {
    text-stroke: 0 transparent;
    -webkit-text-stroke: 0 transparent;
    color: inherit;
  }
}

.font-body {
  font-family: var(--cnvs-body-font) !important;
}

.font-primary {
  font-family: var(--cnvs-primary-font) !important;
}

.font-secondary {
  font-family: var(--cnvs-secondary-font) !important;
}

.bg-icon {
  color: rgba(var(--cnvs-contrast-rgb), .1);
  font-size: 210px;
  display: block;
  position: absolute;
  bottom: -60px;
  right: -50px;
}

.text-underline-offset-0 {
  text-underline-offset: 0px !important;
}

.text-underline-offset-1 {
  text-underline-offset: 1px !important;
}

.text-underline-offset-2 {
  text-underline-offset: 2px !important;
}

.text-underline-offset-3 {
  text-underline-offset: 3px !important;
}

.text-underline-offset-4 {
  text-underline-offset: 4px !important;
}

.text-underline-offset-5 {
  text-underline-offset: 5px !important;
}

.text-underline-offset-6 {
  text-underline-offset: 6px !important;
}

.text-underline-offset-7 {
  text-underline-offset: 7px !important;
}

.text-underline-offset-8 {
  text-underline-offset: 8px !important;
}

.text-underline-offset-auto {
  text-underline-offset: auto !important;
}

.object-fill {
  object-fit: fill !important;
}

.object-contain {
  object-fit: contain !important;
}

.object-cover {
  object-fit: cover !important;
}

.object-none {
  object-fit: none !important;
}

.object-scale-down {
  object-fit: scale-down !important;
}

.object-bottom {
  object-position: bottom !important;
}

.bg-bottom {
  background-position: bottom !important;
}

@media (width >= 576px) {
  .bg-sm-bottom {
    background-position: bottom !important;
  }
}

@media (width >= 768px) {
  .bg-md-bottom {
    background-position: bottom !important;
  }
}

@media (width >= 992px) {
  .bg-lg-bottom {
    background-position: bottom !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-bottom {
    background-position: bottom !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-bottom {
    background-position: bottom !important;
  }
}

.object-center {
  object-position: center !important;
}

.bg-center {
  background-position: center !important;
}

@media (width >= 576px) {
  .bg-sm-center {
    background-position: center !important;
  }
}

@media (width >= 768px) {
  .bg-md-center {
    background-position: center !important;
  }
}

@media (width >= 992px) {
  .bg-lg-center {
    background-position: center !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-center {
    background-position: center !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-center {
    background-position: center !important;
  }
}

.object-left {
  object-position: left !important;
}

.bg-left {
  background-position: 0 !important;
}

@media (width >= 576px) {
  .bg-sm-left {
    background-position: 0 !important;
  }
}

@media (width >= 768px) {
  .bg-md-left {
    background-position: 0 !important;
  }
}

@media (width >= 992px) {
  .bg-lg-left {
    background-position: 0 !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-left {
    background-position: 0 !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-left {
    background-position: 0 !important;
  }
}

.object-left-bottom {
  object-position: left bottom !important;
}

.bg-left-bottom {
  background-position: 0 100% !important;
}

@media (width >= 576px) {
  .bg-sm-left-bottom {
    background-position: 0 100% !important;
  }
}

@media (width >= 768px) {
  .bg-md-left-bottom {
    background-position: 0 100% !important;
  }
}

@media (width >= 992px) {
  .bg-lg-left-bottom {
    background-position: 0 100% !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-left-bottom {
    background-position: 0 100% !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-left-bottom {
    background-position: 0 100% !important;
  }
}

.object-left-top {
  object-position: left top !important;
}

.bg-left-top {
  background-position: 0 0 !important;
}

@media (width >= 576px) {
  .bg-sm-left-top {
    background-position: 0 0 !important;
  }
}

@media (width >= 768px) {
  .bg-md-left-top {
    background-position: 0 0 !important;
  }
}

@media (width >= 992px) {
  .bg-lg-left-top {
    background-position: 0 0 !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-left-top {
    background-position: 0 0 !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-left-top {
    background-position: 0 0 !important;
  }
}

.object-right {
  object-position: right !important;
}

.bg-right {
  background-position: 100% !important;
}

@media (width >= 576px) {
  .bg-sm-right {
    background-position: 100% !important;
  }
}

@media (width >= 768px) {
  .bg-md-right {
    background-position: 100% !important;
  }
}

@media (width >= 992px) {
  .bg-lg-right {
    background-position: 100% !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-right {
    background-position: 100% !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-right {
    background-position: 100% !important;
  }
}

.object-right-bottom {
  object-position: right bottom !important;
}

.bg-right-bottom {
  background-position: 100% 100% !important;
}

@media (width >= 576px) {
  .bg-sm-right-bottom {
    background-position: 100% 100% !important;
  }
}

@media (width >= 768px) {
  .bg-md-right-bottom {
    background-position: 100% 100% !important;
  }
}

@media (width >= 992px) {
  .bg-lg-right-bottom {
    background-position: 100% 100% !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-right-bottom {
    background-position: 100% 100% !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-right-bottom {
    background-position: 100% 100% !important;
  }
}

.object-right-top {
  object-position: right top !important;
}

.bg-right-top {
  background-position: 100% 0 !important;
}

@media (width >= 576px) {
  .bg-sm-right-top {
    background-position: 100% 0 !important;
  }
}

@media (width >= 768px) {
  .bg-md-right-top {
    background-position: 100% 0 !important;
  }
}

@media (width >= 992px) {
  .bg-lg-right-top {
    background-position: 100% 0 !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-right-top {
    background-position: 100% 0 !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-right-top {
    background-position: 100% 0 !important;
  }
}

.object-top {
  object-position: top !important;
}

.bg-top {
  background-position: top !important;
}

@media (width >= 576px) {
  .bg-sm-top {
    background-position: top !important;
  }
}

@media (width >= 768px) {
  .bg-md-top {
    background-position: top !important;
  }
}

@media (width >= 992px) {
  .bg-lg-top {
    background-position: top !important;
  }
}

@media (width >= 1200px) {
  .bg-xl-top {
    background-position: top !important;
  }
}

@media (width >= 1400px) {
  .bg-xxl-top {
    background-position: top !important;
  }
}

.bg-auto {
  background-size: auto !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-cover {
  background-size: cover !important;
}

.imagescale, .imagescalein {
  --cnvs-img-scale-default: 100%;
  --cnvs-img-scale-ratio: 110%;
  --cnvs-img-scale-position: center center;
  display: block;
  overflow: hidden;
}

.imagescale img, .imagescalein img {
  -webkit-transform: scale(var(--cnvs-img-scale-ratio));
  transform: scale(var(--cnvs-img-scale-ratio));
  transform-origin: var(--cnvs-img-scale-position);
  transition: all 1s;
}

@media (prefers-reduced-motion: reduce) {
  .imagescale img, .imagescalein img {
    transition: none;
  }
}

.imagescale:hover, .imagescalein:hover, .imagescalein {
  --cnvs-img-scale-ratio: var(--cnvs-img-scale-default);
}

.imagescalein:hover {
  --cnvs-img-scale-ratio: var(--cnvs-img-scale-ratio);
}

.grayscale, .grayscale-out:hover {
  --cnvs-gray-filter: brightness(80%) grayscale(1);
  -webkit-filter: var(--cnvs-gray-filter);
  -moz-filter: var(--cnvs-gray-filter);
  -o-filter: var(--cnvs-gray-filter);
  -ms-filter: var(--cnvs-gray-filter);
  transition: filter 1s;
}

@media (prefers-reduced-motion: reduce) {
  .grayscale, .grayscale-out:hover {
    transition: none;
  }
}

.grayscale-out, .grayscale:hover {
  --cnvs-gray-filter: brightness(100%) grayscale(0);
  transition: filter 1s;
}

@media (prefers-reduced-motion: reduce) {
  .grayscale-out, .grayscale:hover {
    transition: none;
  }
}

.bganimate {
  -webkit-animation: BgAnimated 30s infinite linear 30s infinite linear;
  animation: BgAnimated 30s infinite linear 30s infinite linear;
}

@keyframes BgAnimated {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 400px;
  }
}

.input-block-level {
  box-sizing: border-box;
  min-height: 30px;
  display: block;
  width: 100% !important;
}

.vertical-middle {
  z-index: 3;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  display: flex;
  position: relative;
}

.vertical-middle-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.vertical-middle + .video-wrap {
  z-index: 2 !important;
}

.magnific-max-width .mfp-content {
  max-width: 800px;
}

.op-ts {
  transition: opacity var(--cnvs-transitions);
}

@media (prefers-reduced-motion: reduce) {
  .op-ts {
    transition: none;
  }
}

.op-0, .h-op-0:hover {
  opacity: 0 !important;
}

.op-1, .h-op-1:hover {
  opacity: 1 !important;
}

.op-01, .h-op-01:hover {
  opacity: .1 !important;
}

.op-02, .h-op-02:hover {
  opacity: .2 !important;
}

.op-03, .h-op-03:hover {
  opacity: .3 !important;
}

.op-04, .h-op-04:hover {
  opacity: .4 !important;
}

.op-05, .h-op-05:hover {
  opacity: .5 !important;
}

.op-06, .h-op-06:hover {
  opacity: .6 !important;
}

.op-07, .h-op-07:hover {
  opacity: .7 !important;
}

.op-08, .h-op-08:hover {
  opacity: .8 !important;
}

.op-09, .h-op-09:hover {
  opacity: .9 !important;
}

.text-smaller {
  font-size: 80% !important;
}

.text-smaller small, .text-smaller .small {
  font-size: 90% !important;
}

.text-medium {
  font-size: 112.5% !important;
}

.text-larger {
  font-size: 120% !important;
}

.text-ts {
  transition: color var(--cnvs-transitions);
}

@media (prefers-reduced-motion: reduce) {
  .text-ts {
    transition: none;
  }
}

.h-text-color:hover, a.h-text-color:hover {
  color: var(--cnvs-themecolor) !important;
}

.bg-ts {
  transition: background-color var(--cnvs-transitions);
}

@media (prefers-reduced-motion: reduce) {
  .bg-ts {
    transition: none;
  }
}

.h-bg-color:hover {
  background-color: var(--cnvs-themecolor) !important;
}

.h-nobg:hover {
  background-color: #0000 !important;
}

.h-text-primary:hover, a.h-text-primary:hover {
  color: var(--bs-primary) !important;
}

.h-bg-primary:hover {
  background-color: var(--bs-primary) !important;
}

.h-text-secondary:hover, a.h-text-secondary:hover {
  color: var(--bs-secondary) !important;
}

.h-bg-secondary:hover {
  background-color: var(--bs-secondary) !important;
}

.h-text-success:hover, a.h-text-success:hover {
  color: var(--bs-success) !important;
}

.h-bg-success:hover {
  background-color: var(--bs-success) !important;
}

.h-text-info:hover, a.h-text-info:hover {
  color: var(--bs-info) !important;
}

.h-bg-info:hover {
  background-color: var(--bs-info) !important;
}

.h-text-warning:hover, a.h-text-warning:hover {
  color: var(--bs-warning) !important;
}

.h-bg-warning:hover {
  background-color: var(--bs-warning) !important;
}

.h-text-danger:hover, a.h-text-danger:hover {
  color: var(--bs-danger) !important;
}

.h-bg-danger:hover {
  background-color: var(--bs-danger) !important;
}

.h-text-light:hover, a.h-text-light:hover {
  color: var(--bs-light) !important;
}

.h-bg-light:hover {
  background-color: var(--bs-light) !important;
}

.h-text-dark:hover, a.h-text-dark:hover {
  color: var(--bs-dark) !important;
}

.h-bg-dark:hover {
  background-color: var(--bs-dark) !important;
}

.bg-clip {
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  border: 1rem solid #0000;
  transform: translate3d(0, 0, 0);
}

.bg-clip-sm {
  border-width: .5rem;
}

.bg-clip-lg {
  border-width: 2rem;
}

.gradient-light-grey, .h-gradient-light-grey:hover {
  background-image: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7) !important;
  background-image: linear-gradient(to right, #2c3e50, #bdc3c7) !important;
}

.gradient-green-dark, .h-gradient-green-dark:hover {
  background-image: -webkit-linear-gradient(to right, #99f2c8, #1f4037) !important;
  background-image: linear-gradient(to right, #99f2c8, #1f4037) !important;
}

.gradient-grey-brown, .h-gradient-grey-brown:hover {
  background-image: -webkit-linear-gradient(to right, #c06c84, #6c5b7b, #355c7d) !important;
  background-image: linear-gradient(to right, #c06c84, #6c5b7b, #355c7d) !important;
}

.gradient-light-pink, .h-gradient-light-pink:hover {
  background-image: -webkit-linear-gradient(to right, #ffdde1, #ee9ca7) !important;
  background-image: linear-gradient(to right, #ffdde1, #ee9ca7) !important;
}

.gradient-blue-purple, .h-gradient-blue-purple:hover {
  background-image: -webkit-linear-gradient(to right, #4a00e0, #8e2de2) !important;
  background-image: linear-gradient(to right, #4a00e0, #8e2de2) !important;
}

.gradient-multiples, .h-gradient-multiples:hover {
  background-image: -webkit-linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%) !important;
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%) !important;
}

.gradient-blue-green, .h-gradient-blue-green:hover {
  background-image: -webkit-linear-gradient(to right, #2af598 0%, #009efd 100%) !important;
  background-image: linear-gradient(to right, #2af598 0%, #009efd 100%) !important;
}

.gradient-light, .h-gradient-light:hover {
  background-image: -webkit-linear-gradient(to top, #ddd 0%, #fff 100%) !important;
  background-image: linear-gradient(to top, #ddd 0%, #fff 100%) !important;
}

.gradient-grey-orange, .h-gradient-grey-orange:hover {
  background-image: -webkit-linear-gradient(to right, #3b4371, #f3904f) !important;
  background-image: linear-gradient(to right, #3b4371, #f3904f) !important;
}

.gradient-sunset, .h-gradient-sunset:hover {
  background-image: -webkit-linear-gradient(to top, #a8edea 0%, #fed6e3 100%) !important;
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%) !important;
}

.gradient-dark, .h-gradient-dark:hover {
  background-image: -webkit-linear-gradient(to right, #535353, #000) !important;
  background-image: linear-gradient(to right, #535353, #000) !important;
}

.gradient-lemon, .h-gradient-lemon:hover {
  background-image: -webkit-linear-gradient(to right, #cac531, #f3f9a7) !important;
  background-image: linear-gradient(to right, #cac531, #f3f9a7) !important;
}

.gradient-earth, .h-gradient-earth:hover {
  background-image: -webkit-linear-gradient(to right, #649173, #dbd5a4) !important;
  background-image: linear-gradient(to right, #649173, #dbd5a4) !important;
}

.gradient-sky, .h-gradient-sky:hover {
  background-image: -webkit-linear-gradient(to right, #2980b9, #6dd5fa, #f5f5f5) !important;
  background-image: linear-gradient(to right, #2980b9, #6dd5fa, #f5f5f5) !important;
}

.gradient-ocean, .h-gradient-ocean:hover {
  background-image: -webkit-linear-gradient(to right, #000046, #1cb5e0) !important;
  background-image: linear-gradient(to right, #000046, #1cb5e0) !important;
}

.gradient-horizon, .h-gradient-horizon:hover {
  background-image: linear-gradient(90deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%) !important;
}

.gradient-green-blue, .h-gradient-green-blue:hover {
  background-image: -webkit-linear-gradient(to right, #43cea2, #185a9d) !important;
  background-image: linear-gradient(to right, #43cea2, #185a9d) !important;
}

.gradient-purple-blue, .h-gradient-purple-blue:hover {
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%) !important;
}

.gradient-ocean-hue, .h-gradient-ocean-hue:hover {
  background-image: -webkit-linear-gradient(to right, #97edab69 0%, #a0c4f385 100%) !important;
  background-image: linear-gradient(to right, #97edab69 0%, #a0c4f385 100%) !important;
}

.gradient-sky-hue, .h-gradient-sky-hue:hover {
  background-image: linear-gradient(45deg, #ffdee9 0%, #b5fffc 100%) !important;
}

.text-fill-effect, .gradient-text {
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  text-shadow: none !important;
}

.gradient-underline {
  background-position: 0 88%;
  background-repeat: no-repeat;
  background-size: 100% 8px;
}

[class*="gutter-"] {
  --cnvs-custom-gutter: var(--bs-gutter-x);
  margin-right: calc(var(--cnvs-custom-gutter) * -.5);
  margin-left: calc(var(--cnvs-custom-gutter) * -.5);
  margin-top: calc(var(--cnvs-custom-gutter) * -1);
}

[class*="gutter-"] > .col, [class*="gutter-"] > [class*="col-"] {
  padding-right: calc(var(--cnvs-custom-gutter) * .5);
  padding-left: calc(var(--cnvs-custom-gutter) * .5);
  margin-top: var(--cnvs-custom-gutter);
}

.gutter-10 {
  --cnvs-custom-gutter: 10px;
}

.gutter-20 {
  --cnvs-custom-gutter: 20px;
}

.gutter-30 {
  --cnvs-custom-gutter: 30px;
}

.gutter-40 {
  --cnvs-custom-gutter: 40px;
}

.gutter-50 {
  --cnvs-custom-gutter: 50px;
}

@media (width >= 576px) {
  .gutter-sm-10 {
    --cnvs-custom-gutter: 10px;
  }

  .gutter-sm-20 {
    --cnvs-custom-gutter: 20px;
  }

  .gutter-sm-30 {
    --cnvs-custom-gutter: 30px;
  }

  .gutter-sm-40 {
    --cnvs-custom-gutter: 40px;
  }

  .gutter-sm-50 {
    --cnvs-custom-gutter: 50px;
  }
}

@media (width >= 768px) {
  .gutter-md-10 {
    --cnvs-custom-gutter: 10px;
  }

  .gutter-md-20 {
    --cnvs-custom-gutter: 20px;
  }

  .gutter-md-30 {
    --cnvs-custom-gutter: 30px;
  }

  .gutter-md-40 {
    --cnvs-custom-gutter: 40px;
  }

  .gutter-md-50 {
    --cnvs-custom-gutter: 50px;
  }
}

@media (width >= 992px) {
  .gutter-lg-10 {
    --cnvs-custom-gutter: 10px;
  }

  .gutter-lg-20 {
    --cnvs-custom-gutter: 20px;
  }

  .gutter-lg-30 {
    --cnvs-custom-gutter: 30px;
  }

  .gutter-lg-40 {
    --cnvs-custom-gutter: 40px;
  }

  .gutter-lg-50 {
    --cnvs-custom-gutter: 50px;
  }
}

@media (width >= 1200px) {
  .gutter-xl-10 {
    --cnvs-custom-gutter: 10px;
  }

  .gutter-xl-20 {
    --cnvs-custom-gutter: 20px;
  }

  .gutter-xl-30 {
    --cnvs-custom-gutter: 30px;
  }

  .gutter-xl-40 {
    --cnvs-custom-gutter: 40px;
  }

  .gutter-xl-50 {
    --cnvs-custom-gutter: 50px;
  }
}

@media (width >= 1400px) {
  .gutter-xxl-10 {
    --cnvs-custom-gutter: 10px;
  }

  .gutter-xxl-20 {
    --cnvs-custom-gutter: 20px;
  }

  .gutter-xxl-30 {
    --cnvs-custom-gutter: 30px;
  }

  .gutter-xxl-40 {
    --cnvs-custom-gutter: 40px;
  }

  .gutter-xxl-50 {
    --cnvs-custom-gutter: 50px;
  }
}

[class*="col-mb-"] {
  margin-bottom: calc(var(--cnvs-col-mb) * -1);
}

[class*="col-mb-"] > .col, [class*="col-mb-"] > [class*="col-"] {
  padding-bottom: var(--cnvs-col-mb);
}

.col-mb-30 {
  --cnvs-col-mb: 30px;
}

.col-mb-50 {
  --cnvs-col-mb: 50px;
}

.col-mb-80 {
  --cnvs-col-mb: 80px;
}

@media (width >= 576px) {
  .col-mb-sm-30 {
    --cnvs-col-mb: 30px;
  }

  .col-mb-sm-50 {
    --cnvs-col-mb: 50px;
  }

  .col-mb-sm-80 {
    --cnvs-col-mb: 80px;
  }
}

@media (width >= 768px) {
  .col-mb-md-30 {
    --cnvs-col-mb: 30px;
  }

  .col-mb-md-50 {
    --cnvs-col-mb: 50px;
  }

  .col-mb-md-80 {
    --cnvs-col-mb: 80px;
  }
}

@media (width >= 992px) {
  .col-mb-lg-30 {
    --cnvs-col-mb: 30px;
  }

  .col-mb-lg-50 {
    --cnvs-col-mb: 50px;
  }

  .col-mb-lg-80 {
    --cnvs-col-mb: 80px;
  }
}

@media (width >= 1200px) {
  .col-mb-xl-30 {
    --cnvs-col-mb: 30px;
  }

  .col-mb-xl-50 {
    --cnvs-col-mb: 50px;
  }

  .col-mb-xl-80 {
    --cnvs-col-mb: 80px;
  }
}

@media (width >= 1400px) {
  .col-mb-xxl-30 {
    --cnvs-col-mb: 30px;
  }

  .col-mb-xxl-50 {
    --cnvs-col-mb: 50px;
  }

  .col-mb-xxl-80 {
    --cnvs-col-mb: 80px;
  }
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}

.h-100 {
  height: 100% !important;
}

.min-vh-90 {
  min-height: 90vh !important;
}

.vh-90 {
  height: 90vh !important;
}

.max-vh-90 {
  max-height: 90vh !important;
}

.h-90 {
  height: 90% !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.h-75 {
  height: 75% !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.max-vh-60 {
  max-height: 60vh !important;
}

.h-60 {
  height: 60% !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.h-50 {
  height: 50% !important;
}

.min-vh-40 {
  min-height: 40vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.max-vh-40 {
  max-height: 40vh !important;
}

.h-40 {
  height: 40% !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.h-25 {
  height: 25% !important;
}

.min-vh-0 {
  min-height: 0 !important;
}

.vh-0 {
  height: 0 !important;
}

.max-vh-0 {
  max-height: 0 !important;
}

.h-0 {
  height: 0% !important;
}

.max-vh-none {
  max-height: none !important;
}

.h-auto {
  height: auto !important;
}

@media (width >= 576px) {
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .max-vh-sm-100 {
    max-height: 100vh !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .min-vh-sm-90 {
    min-height: 90vh !important;
  }

  .vh-sm-90 {
    height: 90vh !important;
  }

  .max-vh-sm-90 {
    max-height: 90vh !important;
  }

  .h-sm-90 {
    height: 90% !important;
  }

  .min-vh-sm-75 {
    min-height: 75vh !important;
  }

  .vh-sm-75 {
    height: 75vh !important;
  }

  .max-vh-sm-75 {
    max-height: 75vh !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .min-vh-sm-60 {
    min-height: 60vh !important;
  }

  .vh-sm-60 {
    height: 60vh !important;
  }

  .max-vh-sm-60 {
    max-height: 60vh !important;
  }

  .h-sm-60 {
    height: 60% !important;
  }

  .min-vh-sm-50 {
    min-height: 50vh !important;
  }

  .vh-sm-50 {
    height: 50vh !important;
  }

  .max-vh-sm-50 {
    max-height: 50vh !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .min-vh-sm-40 {
    min-height: 40vh !important;
  }

  .vh-sm-40 {
    height: 40vh !important;
  }

  .max-vh-sm-40 {
    max-height: 40vh !important;
  }

  .h-sm-40 {
    height: 40% !important;
  }

  .min-vh-sm-25 {
    min-height: 25vh !important;
  }

  .vh-sm-25 {
    height: 25vh !important;
  }

  .max-vh-sm-25 {
    max-height: 25vh !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .min-vh-sm-0 {
    min-height: 0 !important;
  }

  .vh-sm-0 {
    height: 0 !important;
  }

  .max-vh-sm-0 {
    max-height: 0 !important;
  }

  .h-sm-0 {
    height: 0% !important;
  }

  .max-vh-sm-none {
    max-height: none !important;
  }

  .h-sm-auto {
    height: auto !important;
  }
}

@media (width >= 768px) {
  .min-vh-md-100 {
    min-height: 100vh !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .max-vh-md-100 {
    max-height: 100vh !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .min-vh-md-90 {
    min-height: 90vh !important;
  }

  .vh-md-90 {
    height: 90vh !important;
  }

  .max-vh-md-90 {
    max-height: 90vh !important;
  }

  .h-md-90 {
    height: 90% !important;
  }

  .min-vh-md-75 {
    min-height: 75vh !important;
  }

  .vh-md-75 {
    height: 75vh !important;
  }

  .max-vh-md-75 {
    max-height: 75vh !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .min-vh-md-60 {
    min-height: 60vh !important;
  }

  .vh-md-60 {
    height: 60vh !important;
  }

  .max-vh-md-60 {
    max-height: 60vh !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .min-vh-md-50 {
    min-height: 50vh !important;
  }

  .vh-md-50 {
    height: 50vh !important;
  }

  .max-vh-md-50 {
    max-height: 50vh !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .min-vh-md-40 {
    min-height: 40vh !important;
  }

  .vh-md-40 {
    height: 40vh !important;
  }

  .max-vh-md-40 {
    max-height: 40vh !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .min-vh-md-25 {
    min-height: 25vh !important;
  }

  .vh-md-25 {
    height: 25vh !important;
  }

  .max-vh-md-25 {
    max-height: 25vh !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .min-vh-md-0 {
    min-height: 0 !important;
  }

  .vh-md-0 {
    height: 0 !important;
  }

  .max-vh-md-0 {
    max-height: 0 !important;
  }

  .h-md-0 {
    height: 0% !important;
  }

  .max-vh-md-none {
    max-height: none !important;
  }

  .h-md-auto {
    height: auto !important;
  }
}

@media (width >= 992px) {
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .max-vh-lg-100 {
    max-height: 100vh !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .min-vh-lg-90 {
    min-height: 90vh !important;
  }

  .vh-lg-90 {
    height: 90vh !important;
  }

  .max-vh-lg-90 {
    max-height: 90vh !important;
  }

  .h-lg-90 {
    height: 90% !important;
  }

  .min-vh-lg-75 {
    min-height: 75vh !important;
  }

  .vh-lg-75 {
    height: 75vh !important;
  }

  .max-vh-lg-75 {
    max-height: 75vh !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .min-vh-lg-60 {
    min-height: 60vh !important;
  }

  .vh-lg-60 {
    height: 60vh !important;
  }

  .max-vh-lg-60 {
    max-height: 60vh !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .min-vh-lg-50 {
    min-height: 50vh !important;
  }

  .vh-lg-50 {
    height: 50vh !important;
  }

  .max-vh-lg-50 {
    max-height: 50vh !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .min-vh-lg-40 {
    min-height: 40vh !important;
  }

  .vh-lg-40 {
    height: 40vh !important;
  }

  .max-vh-lg-40 {
    max-height: 40vh !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .min-vh-lg-25 {
    min-height: 25vh !important;
  }

  .vh-lg-25 {
    height: 25vh !important;
  }

  .max-vh-lg-25 {
    max-height: 25vh !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .min-vh-lg-0 {
    min-height: 0 !important;
  }

  .vh-lg-0 {
    height: 0 !important;
  }

  .max-vh-lg-0 {
    max-height: 0 !important;
  }

  .h-lg-0 {
    height: 0% !important;
  }

  .max-vh-lg-none {
    max-height: none !important;
  }

  .h-lg-auto {
    height: auto !important;
  }
}

@media (width >= 1200px) {
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .max-vh-xl-100 {
    max-height: 100vh !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .min-vh-xl-90 {
    min-height: 90vh !important;
  }

  .vh-xl-90 {
    height: 90vh !important;
  }

  .max-vh-xl-90 {
    max-height: 90vh !important;
  }

  .h-xl-90 {
    height: 90% !important;
  }

  .min-vh-xl-75 {
    min-height: 75vh !important;
  }

  .vh-xl-75 {
    height: 75vh !important;
  }

  .max-vh-xl-75 {
    max-height: 75vh !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .min-vh-xl-60 {
    min-height: 60vh !important;
  }

  .vh-xl-60 {
    height: 60vh !important;
  }

  .max-vh-xl-60 {
    max-height: 60vh !important;
  }

  .h-xl-60 {
    height: 60% !important;
  }

  .min-vh-xl-50 {
    min-height: 50vh !important;
  }

  .vh-xl-50 {
    height: 50vh !important;
  }

  .max-vh-xl-50 {
    max-height: 50vh !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .min-vh-xl-40 {
    min-height: 40vh !important;
  }

  .vh-xl-40 {
    height: 40vh !important;
  }

  .max-vh-xl-40 {
    max-height: 40vh !important;
  }

  .h-xl-40 {
    height: 40% !important;
  }

  .min-vh-xl-25 {
    min-height: 25vh !important;
  }

  .vh-xl-25 {
    height: 25vh !important;
  }

  .max-vh-xl-25 {
    max-height: 25vh !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .min-vh-xl-0 {
    min-height: 0 !important;
  }

  .vh-xl-0 {
    height: 0 !important;
  }

  .max-vh-xl-0 {
    max-height: 0 !important;
  }

  .h-xl-0 {
    height: 0% !important;
  }

  .max-vh-xl-none {
    max-height: none !important;
  }

  .h-xl-auto {
    height: auto !important;
  }
}

@media (width >= 1400px) {
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }

  .vh-xxl-100 {
    height: 100vh !important;
  }

  .max-vh-xxl-100 {
    max-height: 100vh !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .min-vh-xxl-90 {
    min-height: 90vh !important;
  }

  .vh-xxl-90 {
    height: 90vh !important;
  }

  .max-vh-xxl-90 {
    max-height: 90vh !important;
  }

  .h-xxl-90 {
    height: 90% !important;
  }

  .min-vh-xxl-75 {
    min-height: 75vh !important;
  }

  .vh-xxl-75 {
    height: 75vh !important;
  }

  .max-vh-xxl-75 {
    max-height: 75vh !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .min-vh-xxl-60 {
    min-height: 60vh !important;
  }

  .vh-xxl-60 {
    height: 60vh !important;
  }

  .max-vh-xxl-60 {
    max-height: 60vh !important;
  }

  .h-xxl-60 {
    height: 60% !important;
  }

  .min-vh-xxl-50 {
    min-height: 50vh !important;
  }

  .vh-xxl-50 {
    height: 50vh !important;
  }

  .max-vh-xxl-50 {
    max-height: 50vh !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .min-vh-xxl-40 {
    min-height: 40vh !important;
  }

  .vh-xxl-40 {
    height: 40vh !important;
  }

  .max-vh-xxl-40 {
    max-height: 40vh !important;
  }

  .h-xxl-40 {
    height: 40% !important;
  }

  .min-vh-xxl-25 {
    min-height: 25vh !important;
  }

  .vh-xxl-25 {
    height: 25vh !important;
  }

  .max-vh-xxl-25 {
    max-height: 25vh !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .min-vh-xxl-0 {
    min-height: 0 !important;
  }

  .vh-xxl-0 {
    height: 0 !important;
  }

  .max-vh-xxl-0 {
    max-height: 0 !important;
  }

  .h-xxl-0 {
    height: 0% !important;
  }

  .max-vh-xxl-none {
    max-height: none !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

@media (width >= 576px) {
  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (width >= 768px) {
  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }
}

@media (width >= 992px) {
  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (width >= 1200px) {
  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }
}

@media (width >= 1400px) {
  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: sticky !important;
  }
}

.lazy {
  opacity: 0;
}

.lazy:not(.initial) {
  transition: opacity 1s;
}

@media (prefers-reduced-motion: reduce) {
  .lazy:not(.initial) {
    transition: none;
  }
}

.lazy.initial, .lazy-loaded, .lazy-error {
  opacity: 1;
}

img.lazy {
  max-width: 100%;
  height: auto;
}

img.lazy:not([src]) {
  visibility: hidden;
}

.border-width-0, .h-border-width-0:hover {
  border-width: 0 !important;
}

.border-width-1, .h-border-width-1:hover {
  border-width: 1px !important;
}

.border-width-2, .h-border-width-2:hover {
  border-width: 2px !important;
}

.border-width-3, .h-border-width-3:hover {
  border-width: 3px !important;
}

.border-width-4, .h-border-width-4:hover {
  border-width: 4px !important;
}

.border-width-5, .h-border-width-5:hover {
  border-width: 5px !important;
}

.border-width-6, .h-border-width-6:hover {
  border-width: 6px !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

.h-border-primary:hover {
  border-color: var(--bs-primary) !important;
}

.h-border-secondary:hover {
  border-color: var(--bs-secondary) !important;
}

.h-border-success:hover {
  border-color: var(--bs-success) !important;
}

.h-border-info:hover {
  border-color: var(--bs-info) !important;
}

.h-border-warning:hover {
  border-color: var(--bs-warning) !important;
}

.h-border-danger:hover {
  border-color: var(--bs-danger) !important;
}

.h-border-light:hover {
  border-color: var(--bs-light) !important;
}

.h-border-dark:hover {
  border-color: var(--bs-dark) !important;
}

.border-transparent, .h-border-transparent:hover {
  border-color: #0000 !important;
}

.border-default, .h-border-default:hover {
  border-color: rgba(var(--cnvs-contrast-rgb), .1) !important;
}

.border-ts {
  transition: border var(--cnvs-transitions);
}

@media (prefers-reduced-motion: reduce) {
  .border-ts {
    transition: none;
  }
}

.rounded-4 {
  border-radius: .4rem !important;
}

.rounded-5 {
  border-radius: .5rem !important;
}

.rounded-6 {
  border-radius: .6rem !important;
}

.rounded-top-left-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-right-0 {
  border-top-right-radius: 0 !important;
}

.rounded-bottom-right-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}

.mw-xs {
  max-width: 38rem !important;
}

.mw-sm {
  max-width: 48rem !important;
}

.mw-md {
  max-width: 64rem !important;
}

.mw-lg {
  max-width: 80rem !important;
}

.mw-xl {
  max-width: 90rem !important;
}

.mw-xxl {
  max-width: 100rem !important;
}

.text-size-xs {
  font-size: .875rem !important;
}

.text-size-sm {
  font-size: 1rem !important;
}

.text-size-md {
  font-size: 1.5rem !important;
}

.text-size-lg {
  font-size: 2rem !important;
}

.text-size-xl {
  font-size: calc(1rem + 2.25vw) !important;
}

.text-size-xxl {
  font-size: calc(1rem + 3.5vw) !important;
}

.pos-x-start {
  left: 0 !important;
  right: auto !important;
}

.pos-x-end {
  left: auto !important;
  right: 0 !important;
}

.pos-x-center {
  left: 50% !important;
  right: auto !important;
  transform: translateX(-50%) !important;
}

.pos-y-start {
  top: 0 !important;
  bottom: auto !important;
}

.pos-y-end {
  top: auto !important;
  bottom: 0 !important;
}

.pos-y-center {
  top: 50% !important;
  bottom: auto !important;
  transform: translateY(-50%) !important;
}

.pos-x-center.pos-y-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.square {
  --cnvs-square-size: 3rem;
  width: var(--cnvs-square-size) !important;
  height: var(--cnvs-square-size) !important;
}

.square-xs {
  --cnvs-square-size: 1rem;
}

.square-sm {
  --cnvs-square-size: 2rem;
}

.square-md {
  --cnvs-square-size: 4rem;
}

.square-lg {
  --cnvs-square-size: 6rem;
}

.square-xl {
  --cnvs-square-size: 8rem;
}

.rotate-0, .h-rotate-0:hover {
  transform: rotate(0) !important;
}

.rotate-15, .h-rotate-15:hover {
  transform: rotate(15deg) !important;
}

.rotate-n15, .h-rotate-n15:hover {
  transform: rotate(-15deg) !important;
}

.rotate-30, .h-rotate-30:hover {
  transform: rotate(30deg) !important;
}

.rotate-n30, .h-rotate-n30:hover {
  transform: rotate(-30deg) !important;
}

.rotate-45, .h-rotate-45:hover {
  transform: rotate(45deg) !important;
}

.rotate-n45, .h-rotate-n45:hover {
  transform: rotate(-45deg) !important;
}

.rotate-60, .h-rotate-60:hover {
  transform: rotate(60deg) !important;
}

.rotate-n60, .h-rotate-n60:hover {
  transform: rotate(-60deg) !important;
}

.rotate-75, .h-rotate-75:hover {
  transform: rotate(75deg) !important;
}

.rotate-n75, .h-rotate-n75:hover {
  transform: rotate(-75deg) !important;
}

.rotate-90, .h-rotate-90:hover {
  transform: rotate(90deg) !important;
}

.rotate-n90, .h-rotate-n90:hover {
  transform: rotate(-90deg) !important;
}

.rotate-105, .h-rotate-105:hover {
  transform: rotate(105deg) !important;
}

.rotate-n105, .h-rotate-n105:hover {
  transform: rotate(-105deg) !important;
}

.rotate-120, .h-rotate-120:hover {
  transform: rotate(120deg) !important;
}

.rotate-n120, .h-rotate-n120:hover {
  transform: rotate(-120deg) !important;
}

.rotate-135, .h-rotate-135:hover {
  transform: rotate(135deg) !important;
}

.rotate-n135, .h-rotate-n135:hover {
  transform: rotate(-135deg) !important;
}

.rotate-150, .h-rotate-150:hover {
  transform: rotate(150deg) !important;
}

.rotate-n150, .h-rotate-n150:hover {
  transform: rotate(-150deg) !important;
}

.rotate-165, .h-rotate-165:hover {
  transform: rotate(165deg) !important;
}

.rotate-n165, .h-rotate-n165:hover {
  transform: rotate(-165deg) !important;
}

.rotate-180, .h-rotate-180:hover {
  transform: rotate(180deg) !important;
}

.rotate-n180, .h-rotate-n180:hover {
  transform: rotate(-180deg) !important;
}

.rotate-195, .h-rotate-195:hover {
  transform: rotate(195deg) !important;
}

.rotate-n195, .h-rotate-n195:hover {
  transform: rotate(-195deg) !important;
}

.rotate-210, .h-rotate-210:hover {
  transform: rotate(210deg) !important;
}

.rotate-n210, .h-rotate-n210:hover {
  transform: rotate(-210deg) !important;
}

.rotate-225, .h-rotate-225:hover {
  transform: rotate(225deg) !important;
}

.rotate-n225, .h-rotate-n225:hover {
  transform: rotate(-225deg) !important;
}

.rotate-240, .h-rotate-240:hover {
  transform: rotate(240deg) !important;
}

.rotate-n240, .h-rotate-n240:hover {
  transform: rotate(-240deg) !important;
}

.rotate-255, .h-rotate-255:hover {
  transform: rotate(255deg) !important;
}

.rotate-n255, .h-rotate-n255:hover {
  transform: rotate(-255deg) !important;
}

.rotate-270, .h-rotate-270:hover {
  transform: rotate(270deg) !important;
}

.rotate-n270, .h-rotate-n270:hover {
  transform: rotate(-270deg) !important;
}

.rotate-285, .h-rotate-285:hover {
  transform: rotate(285deg) !important;
}

.rotate-n285, .h-rotate-n285:hover {
  transform: rotate(-285deg) !important;
}

.rotate-300, .h-rotate-300:hover {
  transform: rotate(300deg) !important;
}

.rotate-n300, .h-rotate-n300:hover {
  transform: rotate(-300deg) !important;
}

.rotate-315, .h-rotate-315:hover {
  transform: rotate(315deg) !important;
}

.rotate-n315, .h-rotate-n315:hover {
  transform: rotate(-315deg) !important;
}

.rotate-330, .h-rotate-330:hover {
  transform: rotate(330deg) !important;
}

.rotate-n330, .h-rotate-n330:hover {
  transform: rotate(-330deg) !important;
}

.rotate-345, .h-rotate-345:hover {
  transform: rotate(345deg) !important;
}

.rotate-n345, .h-rotate-n345:hover {
  transform: rotate(-345deg) !important;
}

.rotate-360, .h-rotate-360:hover {
  transform: rotate(360deg) !important;
}

.rotate-n360, .h-rotate-n360:hover {
  transform: rotate(-360deg) !important;
}

.align-wide-lg {
  width: 960px !important;
  max-width: 960px !important;
  margin-left: calc(50% - 480px) !important;
  margin-right: calc(50% - 480px) !important;
}

.align-wide-xl {
  width: 1140px !important;
  max-width: 1140px !important;
  margin-left: calc(50% - 570px) !important;
  margin-right: calc(50% - 570px) !important;
}

.align-wide-xxl {
  width: 1320px !important;
  max-width: 1320px !important;
  margin-left: calc(50% - 660px) !important;
  margin-right: calc(50% - 660px) !important;
}

@media (width <= 575.98px) {
  .align-wide-lg, .align-wide-xl, .align-wide-xxl {
    width: 100vw !important;
    max-width: 100vw !important;
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
  }
}

.align-full {
  width: 100vw !important;
  max-width: 100vw !important;
  margin-left: calc(50% - 50vw) !important;
  margin-right: calc(50% - 50vw) !important;
}

@media (width <= 575.98px) {
  .align-wide-lg, .align-wide-xl, .align-wide-xxl {
    width: 100vw !important;
    max-width: 100vw !important;
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
  }
}

.shadow-sm, .h-shadow-sm:hover {
  box-shadow: var(--cnvs-box-shadow-sm) !important;
}

.shadow, .h-shadow:hover {
  box-shadow: var(--cnvs-box-shadow) !important;
}

.shadow-lg, .h-shadow-lg:hover {
  box-shadow: var(--cnvs-box-shadow-lg) !important;
}

.h-shadow-none:hover {
  box-shadow: none !important;
}

.shadow-ts {
  transition: box-shadow var(--cnvs-transitions);
}

@media (prefers-reduced-motion: reduce) {
  .shadow-ts {
    transition: none;
  }
}

.all-ts {
  transition: all var(--cnvs-transitions);
}

@media (prefers-reduced-motion: reduce) {
  .all-ts {
    transition: none;
  }
}

.translate-y-sm, .h-translate-y-sm:hover {
  transform: translateY(-3px) !important;
}

.translate-y, .h-translate-y:hover {
  transform: translateY(-6px) !important;
}

.translate-y-lg, .h-translate-y-lg:hover {
  transform: translateY(-10px) !important;
}

.translate-x-sm, .h-translate-x-sm:hover {
  transform: translateX(-3px) !important;
}

.translate-x, .h-translate-x:hover {
  transform: translateX(-6px) !important;
}

.translate-x-lg, .h-translate-x-lg:hover {
  transform: translateX(-10px) !important;
}

.translate-x-n-sm, .h-translate-x-n-sm:hover {
  transform: translateX(3px) !important;
}

.translate-x-n, .h-translate-x-n:hover {
  transform: translateX(6px) !important;
}

.translate-x-n-lg, .h-translate-x-n-lg:hover {
  transform: translateX(10px) !important;
}

.scale-sm, .h-scale-sm:hover {
  transform: scale(1.025) !important;
}

.scale, .h-scale:hover {
  transform: scale(1.05) !important;
}

.scale-lg, .h-scale-lg:hover {
  transform: scale(1.1) !important;
}

.scale-n-sm, .h-scale-n-sm:hover {
  transform: scale(.975) !important;
}

.scale-n, .h-scale-n:hover {
  transform: scale(.95) !important;
}

.scale-n-lg, .h-scale-n-lg:hover {
  transform: scale(.9) !important;
}

.transform-ts {
  transition: transform .5s cubic-bezier(.215, .61, .355, 1);
}

@media (prefers-reduced-motion: reduce) {
  .transform-ts {
    transition: none;
  }
}

.element-float {
  --cnvs-float-speed: 4s;
  --cnvs-float-easing: ease-in-out;
  --cnvs-float-x-from: 0%;
  --cnvs-float-x-to: 0%;
  --cnvs-float-y-from: 0%;
  --cnvs-float-y-to: 15%;
  animation: cnvsFloat var(--cnvs-float-speed) var(--cnvs-float-easing) infinite;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.element-float.disable-on-hover:hover {
  animation: none;
}

@keyframes cnvsFloat {
  0% {
    transform: translate3d(var(--cnvs-float-x-from), var(--cnvs-float-y-from), 0);
  }

  50% {
    transform: translate3d(var(--cnvs-float-x-to), var(--cnvs-float-y-to), 0);
  }

  100% {
    transform: translate3d(var(--cnvs-float-x-from), var(--cnvs-float-y-from), 0);
  }
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.z-6 {
  z-index: 6 !important;
}

.z-7 {
  z-index: 7 !important;
}

.z-8 {
  z-index: 8 !important;
}

.z-9 {
  z-index: 9 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-auto {
  z-index: auto !important;
}

#top-bar {
  --cnvs-topbar-height: 45px;
  --cnvs-topbar-font-size: .875rem;
  --cnvs-topbar-font-weight: 500;
  --cnvs-topbar-font-transform: none;
  --cnvs-topbar-color: var(--cnvs-contrast-700);
  --cnvs-topbar-border-size: 1px;
  --cnvs-topbar-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-topbar-social-icon-color: var(--cnvs-contrast-600);
  --cnvs-topbar-submenu-padding: .625rem;
  --cnvs-topbar-submenu-font-size: .75rem;
  border-bottom: var(--cnvs-topbar-border-size) solid var(--cnvs-topbar-border-color);
  font-size: var(--cnvs-topbar-font-size);
  background-color: var(--cnvs-topbar-bg);
  position: relative;
}

.top-links {
  border-bottom: var(--cnvs-topbar-border-size) solid var(--cnvs-topbar-border-color);
  flex: none;
  position: relative;
}

@media (width >= 768px) {
  .top-links {
    border-bottom: 0;
  }
}

.top-links-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
  display: flex;
}

.top-links-item {
  border-left: var(--cnvs-topbar-border-size) solid var(--cnvs-topbar-border-color);
  position: relative;
}

.top-links-item:first-child, .top-links-sub-menu .top-links-item {
  border-left: 0 !important;
}

.top-links-item > a {
  line-height: calc(var(--cnvs-topbar-height)  - 24px);
  font-weight: var(--cnvs-topbar-font-weight);
  text-transform: var(--cnvs-topbar-font-transform);
  color: var(--cnvs-topbar-color);
  padding: 12px;
  display: block;
}

.top-links-item > a > i {
  vertical-align: top;
}

.top-links-item > a > i.sub-menu-indicator {
  font-size: .75rem;
  margin: 0 0 0 .375rem !important;
}

.top-links-item > a > i:first-child {
  margin-right: 3px;
}

.top-links-item.full-icon > a > i {
  margin: 0;
  font-size: .875rem;
  top: 2px;
}

.top-links-item:hover {
  background-color: var(--cnvs-contrast-200);
}

.top-links-sub-menu, .top-links-section {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
  background: var(--cnvs-topbar-bg);
  border: 0;
  border: var(--cnvs-topbar-border-size) solid var(--cnvs-topbar-border-color);
  border-top: 1px solid var(--cnvs-themecolor);
  width: 140px;
  margin-top: 10px;
  line-height: 1.5;
  list-style: none;
  transition: opacity .25s, margin .2s;
  position: absolute;
  top: 100%;
  left: -1px;
  box-shadow: 0 13px 42px 11px #0000000d;
}

@media (prefers-reduced-motion: reduce) {
  .top-links-sub-menu, .top-links-section {
    transition: none;
  }
}

.top-links:not(.on-click) .top-links-item:hover > .top-links-sub-menu, .top-links:not(.on-click) .top-links-item:hover > .top-links-section, .top-links.on-click .top-links-sub-menu, .top-links.on-click .top-links-section {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  z-index: 499;
  margin-top: 0;
}

.top-links.on-click .top-links-sub-menu, .top-links.on-click .top-links-section {
  display: none;
}

.top-links-sub-menu .top-links-sub-menu {
  top: calc(-1 * var(--cnvs-topbar-border-size));
  left: 100%;
}

.top-links-sub-menu .top-links-item:not(:first-child) {
  border-top: var(--cnvs-topbar-border-size) solid rgba(var(--cnvs-contrast-rgb), .1);
}

.top-links-sub-menu .top-links-item:hover {
  background-color: var(--cnvs-contrast-100);
}

.top-links-sub-menu .top-links-item > a {
  padding-top: var(--cnvs-topbar-submenu-padding);
  padding-bottom: var(--cnvs-topbar-submenu-padding);
  font-size: var(--cnvs-topbar-submenu-font-size);
  align-items: center;
  line-height: 20px;
  display: flex;
}

.top-links-sub-menu .top-links-item > a > img {
  width: 16px;
  height: 16px;
  margin-right: .5rem;
  position: relative;
}

.top-links-sub-menu .top-links-item > a i.sub-menu-indicator {
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
  transform: translateY(-50%)rotate(-90deg);
  margin: 0 !important;
}

.top-links-sub-menu.top-demo-lang .top-links-item > a > img {
  width: 16px;
  height: 16px;
  top: 4px;
}

.top-links-section {
  width: 280px;
  padding: 25px;
  left: 0;
}

.top-links-section.menu-pos-invert {
  left: auto;
  right: 0;
}

#top-social {
  justify-content: center;
  margin: 0;
  display: flex;
}

#top-social li {
  border-left: var(--cnvs-topbar-border-size) solid var(--cnvs-topbar-border-color);
  list-style: none;
  position: relative;
}

#top-social li:first-child {
  border-left: 0 !important;
}

#top-social li .ts-icon, #top-social li .ts-text {
  height: var(--cnvs-topbar-height);
  line-height: calc(var(--cnvs-topbar-height)  - 1px);
  flex-grow: 1;
  flex-basis: 0;
  display: block;
}

#top-social li .ts-icon {
  text-align: center;
  width: 40px;
  font-size: .875rem;
}

#top-social li .ts-text {
  white-space: nowrap;
  max-width: 0;
  transition: all .2s;
}

@media (prefers-reduced-motion: reduce) {
  #top-social li .ts-text {
    transition: none;
  }
}

#top-social li a:hover .ts-text {
  max-width: 200px;
  padding-right: 12px;
  transition: all .4s;
}

@media (prefers-reduced-motion: reduce) {
  #top-social li a:hover .ts-text {
    transition: none;
  }
}

#top-social a {
  width: auto;
  color: var(--cnvs-topbar-social-icon-color);
  font-weight: bold;
  display: flex;
  overflow: hidden;
}

#top-social a:hover {
  color: var(--bs-gray-100);
}

#top-login {
  margin-bottom: 0;
}

:root, .not-dark {
  --cnvs-header-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-header-transparent-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-header-floating-bg: var(--cnvs-contrast-0);
  --cnvs-primary-menu-color: var(--cnvs-contrast-900);
  --cnvs-primary-menu-submenu-bg: var(--cnvs-contrast-0);
  --cnvs-primary-menu-submenu-color: var(--cnvs-contrast-900);
  --cnvs-primary-menu-submenu-border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-primary-menu-submenu-hover-bg: rgba(var(--cnvs-contrast-rgb), .025);
  --cnvs-primary-menu-submenu-subtitle-color: var(--cnvs-contrast-600);
  --cnvs-header-misc-icons-color: var(--cnvs-primary-menu-color);
  --cnvs-side-panel-bg: var(--cnvs-contrast-100);
}

#header {
  --cnvs-sticky-header-height: var(--cnvs-header-height);
  --cnvs-header-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-header-transparent-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-primary-menu-padding-x: 15px;
  --cnvs-header-floating-top-offset: 60px;
  --cnvs-header-floating-padding: 30px;
  --cnvs-side-header-padding-gutters: 30px;
  --cnvs-primary-menu-color: var(--cnvs-contrast-900);
  --cnvs-primary-menu-hover-color: var(--cnvs-themecolor);
  --cnvs-primary-menu-active-color: var(--cnvs-primary-menu-hover-color);
  --cnvs-primary-menu-font: var(--cnvs-primary-font);
  --cnvs-primary-menu-font-weight: 700;
  --cnvs-primary-menu-font-size: .8125rem;
  --cnvs-primary-menu-tt: uppercase;
  --cnvs-primary-menu-ls: 1px;
  --cnvs-primary-menu-icon-size: 1rem;
  --cnvs-primary-menu-icon-gap: .625rem;
  --cnvs-primary-menu-hover-text-decoration: none;
  --cnvs-primary-menu-hover-text-decoration-offset: 3px;
  --cnvs-primary-menu-submenu-padding-x: 15px;
  --cnvs-primary-menu-submenu-padding-y: 10px;
  --cnvs-primary-menu-submenu-top-border: 2px solid var(--cnvs-themecolor);
  --cnvs-primary-menu-submenu-width: 240px;
  --cnvs-primary-menu-submenu-display-speed: .3s;
  --cnvs-primary-menu-submenu-display-offset: .5rem;
  --cnvs-primary-menu-submenu-font: var(--cnvs-body-font);
  --cnvs-primary-menu-submenu-font-size: .75rem;
  --cnvs-primary-menu-submenu-font-weight: 600;
  --cnvs-primary-menu-submenu-hover-font-weight: 700;
  --cnvs-primary-menu-submenu-tt: uppercase;
  --cnvs-primary-menu-submenu-color: var(--cnvs-contrast-900);
  --cnvs-primary-menu-submenu-spacing: 0;
  --cnvs-primary-menu-submenu-border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-primary-menu-submenu-hover-bg: rgba(var(--cnvs-contrast-rgb), .025);
  --cnvs-primary-menu-submenu-hover-color: var(--cnvs-primary-menu-active-color);
  --cnvs-primary-menu-submenu-hover-padding-offset: .25rem;
  --cnvs-primary-menu-submenu-subtitle-display: none;
  --cnvs-primary-menu-submenu-subtitle-font-size: var(--cnvs-primary-menu-submenu-font-size);
  --cnvs-primary-menu-submenu-subtitle-font-weight: 400;
  --cnvs-primary-menu-submenu-subtitle-tt: none;
  --cnvs-header-misc-icons-size: 1.125rem;
  --cnvs-header-misc-icons-color: var(--cnvs-primary-menu-color);
  --cnvs-mega-menu-width-sm: 400px;
  --cnvs-mobile-menu-off-canvas-width: 280px;
  --cnvs-top-search-font-size: 2rem;
  --cnvs-top-search-placeholder: var(--cnvs-contrast-600);
  position: relative;
}

#header-wrap {
  z-index: 299;
  background-color: var(--cnvs-header-bg);
  border-bottom: 1px solid var(--cnvs-header-border-color);
  backface-visibility: hidden;
  width: 100%;
  height: auto;
  transition: height .2s, background-color .3s;
  position: fixed;
}

@media (prefers-reduced-motion: reduce) {
  #header-wrap {
    transition: none;
  }
}

.is-expanded-menu #top-bar + #header:not(.sticky-header) #header-wrap, .is-expanded-menu #slider + #header:not(.sticky-header) #header-wrap, .is-expanded-menu .floating-header:not(.sticky-header) #header-wrap, .is-expanded-menu div + #header-wrap, .is-expanded-menu .no-sticky #header-wrap, .is-expanded-menu #header[data-sticky-offset]:not([data-sticky-offset="full"]):not(.sticky-header) #header-wrap, body.is-expanded-menu:not(.stretched) #header:not(.sticky-header) #header-wrap {
  position: absolute;
}

body:not(.is-expanded-menu) #header:not([data-mobile-sticky="true"]) #header-wrap {
  position: relative;
}

body:not(.is-expanded-menu) #header:not([data-mobile-sticky="true"]) .header-wrap-clone {
  display: none;
}

.header-wrap-clone {
  height: calc(var(--cnvs-header-height)  + 1px);
}

.header-row {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.is-expanded-menu .header-row {
  position: initial;
}

.is-expanded-menu .header-size-sm {
  --cnvs-header-height: var(--cnvs-header-height-sm);
}

.is-expanded-menu .header-size-md {
  --cnvs-header-height: var(--cnvs-header-height-md);
}

.is-expanded-menu .header-size-lg {
  --cnvs-header-height: var(--cnvs-header-height-lg);
}

.is-expanded-menu #header.sticky-header-shrink {
  --cnvs-sticky-header-height: var(--cnvs-header-height-shrink);
}

.is-expanded-menu #header + .include-header {
  margin-top: calc(-1 * var(--cnvs-header-height)  - 1px);
  vertical-align: bottom;
  width: 100%;
  display: inline-block;
}

.is-expanded-menu #header.transparent-header {
  --cnvs-header-bg: transparent;
  border-bottom: 0;
}

.is-expanded-menu #header.transparent-header:not(.sticky-header) #header-wrap {
  z-index: 299;
  border-bottom: 1px solid #0000;
}

.is-expanded-menu #header.transparent-header.floating-header {
  margin-top: var(--cnvs-header-floating-top-offset);
}

.is-expanded-menu #header.transparent-header.floating-header .container {
  padding: 0 var(--cnvs-header-floating-padding);
  background-color: var(--cnvs-header-floating-bg);
}

.is-expanded-menu #header.transparent-header.floating-header:not(.sticky-header) .container {
  position: relative;
}

#header.floating-header + .include-header {
  margin-top: calc(var(--cnvs-header-height)  + var(--cnvs-header-floating-top-offset)  - 2px);
}

.is-expanded-menu #header.transparent-header + .page-title-parallax.include-header .container {
  z-index: 5;
  padding-top: var(--cnvs-header-height);
}

.is-expanded-menu #header.transparent-header.semi-transparent {
  --cnvs-header-bg: rgba(var(--cnvs-invert-contrast-rgb), .7);
}

.is-expanded-menu #header.full-header:not(.transparent-header) {
  border-bottom-color: var(--cnvs-header-border-color);
}

.is-expanded-menu #header.full-header.transparent-header #header-wrap {
  border-bottom: 1px solid var(--cnvs-header-transparent-border-color);
}

.is-expanded-menu #slider + #header.transparent-header.full-header #header-wrap {
  border-top: 1px solid var(--cnvs-header-transparent-border-color);
  border-bottom: none;
}

.is-expanded-menu.stretched .full-header #header-wrap > .container {
  width: 100%;
  max-width: none;
  padding: 0 30px;
}

.is-expanded-menu .sub-menu-container, .is-expanded-menu .mega-menu-content {
  transition: top .4s;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .sub-menu-container, .is-expanded-menu .mega-menu-content {
    transition: none;
  }
}

.is-expanded-menu .sticky-header #header-wrap {
  background-color: var(--cnvs-header-sticky-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.is-expanded-menu .sticky-header.semi-transparent {
  --cnvs-header-sticky-bg: rgba(var(--cnvs-invert-contrast-rgb), .7);
}

.is-expanded-menu .sticky-header.full-header #header-wrap {
  border-bottom: 1px solid var(--cnvs-header-border-color);
}

.is-expanded-menu .sticky-header-shrink .style-3 .menu-container > .menu-item > .menu-link {
  --cnvs-primary-menu-padding-y: 19px;
  border-radius: 0;
  margin: 0;
}

.is-expanded-menu .sticky-header-shrink .style-4 .menu-container > .menu-item > .menu-link {
  --cnvs-primary-menu-padding-y: 8px;
  margin: 10px 0;
}

.is-expanded-menu .sticky-header-shrink .style-5 .menu-container > .menu-item:not(:first-child) {
  margin-left: 0;
}

.is-expanded-menu .sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link {
  line-height: 22px;
}

.is-expanded-menu .sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link > div {
  padding: 0;
}

.is-expanded-menu .sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link i:not(.sub-menu-indicator) {
  width: 1rem;
  margin: 0 .5rem 0 0;
  font-size: .875rem;
  line-height: 22px;
  display: inline-block;
}

.is-expanded-menu .sticky-header-shrink .sub-title .menu-container > .menu-item > .menu-link {
  --cnvs-primary-menu-padding-y: 18px;
}

body:not(.is-expanded-menu) [data-mobile-sticky="true"] .menu-container {
  max-height: 60vh;
  margin: 0 0 1rem;
  overflow: hidden scroll;
}

body:not(.is-expanded-menu) .sticky-header #header-wrap {
  z-index: 299;
  background-color: var(--cnvs-header-sticky-bg);
  border-bottom: var(--cnvs-primary-menu-submenu-border);
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateZ(0);
}

body:not(.is-expanded-menu) .sticky-header .header-wrap-clone {
  display: block;
}

body:not(.is-expanded-menu):not(.primary-menu-open) .dark.transparent-header-responsive, body:not(.is-expanded-menu):not(.primary-menu-open) .transparent-header-responsive {
  background-color: #0000;
  border: none;
}

body:not(.is-expanded-menu):not(.primary-menu-open) .semi-transparent.transparent-header-responsive {
  z-index: 1;
  background-color: #fffc;
}

#logo {
  align-items: center;
  max-height: 100%;
  margin-right: auto;
  display: flex;
  position: relative;
}

#logo a {
  color: #000;
  font-family: var(--cnvs-primary-font);
  width: 100%;
  font-size: 36px;
}

#logo a [class^="logo-"] {
  display: none;
}

#logo a .logo-default {
  display: flex;
}

#logo img {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: height .4s, padding .4s, margin .4s, opacity .3s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  #logo img {
    transition: none;
  }
}

#header-wrap #logo img {
  height: var(--cnvs-header-height);
}

.is-expanded-menu .full-header #logo {
  border-right: 1px solid var(--cnvs-header-border-color);
  padding-right: 30px;
}

.is-expanded-menu #header.transparent-header.full-header #logo {
  border-right-color: var(--cnvs-header-transparent-border-color);
}

.is-expanded-menu #header.sticky-header-shrink #header-wrap #logo img {
  height: var(--cnvs-sticky-header-height);
}

.header-extras {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.header-extras li {
  align-items: center;
  margin-left: 1.25rem;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.header-extras li:first-child {
  margin-left: 0;
}

.header-extras li .he-text {
  padding-left: 10px;
  font-size: .875rem;
  font-weight: bold;
}

.header-extras li .he-text span {
  color: var(--cnvs-themecolor);
  font-weight: 400;
  display: block;
}

.primary-menu {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
  display: flex;
}

.is-expanded-menu .primary-menu {
  flex: none;
}

.primary-menu + .primary-menu {
  border-top: var(--cnvs-primary-menu-submenu-border);
}

.is-expanded-menu .primary-menu + .primary-menu {
  border-top: 0;
}

.primary-menu-trigger, #page-menu-trigger {
  --cnvs-menu-trigger-gap: .75rem;
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  padding: var(--cnvs-menu-trigger-gap);
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
  display: flex;
}

@media (prefers-reduced-motion: reduce) {
  .primary-menu-trigger, #page-menu-trigger {
    transition: none;
  }
}

.is-expanded-menu .primary-menu-trigger, .is-expanded-menu #page-menu-trigger {
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  display: none;
}

body:not(.top-search-open) .top-search-parent .primary-menu-trigger {
  opacity: 1;
  transition: opacity .2s .2s, top .4s;
}

@media (prefers-reduced-motion: reduce) {
  body:not(.top-search-open) .top-search-parent .primary-menu-trigger {
    transition: none;
  }
}

.top-search-open .top-search-parent .primary-menu-trigger {
  opacity: 0;
}

.primary-menu-trigger .primary-menu-trigger-text {
  margin-left: .25rem;
}

.cnvs-hamburger {
  --cnvs-hamburger-size: 1rem;
  --cnvs-hamburger-width: 2px;
  --cnvs-hamburger-offset: calc(-1 * calc(calc(var(--cnvs-hamburger-size)  - calc(var(--cnvs-hamburger-width) * 2)) * .5));
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: block;
  position: relative;
  overflow: visible;
}

.cnvs-hamburger .cnvs-hamburger-box {
  width: var(--cnvs-hamburger-size);
  height: var(--cnvs-hamburger-size);
  display: block;
  position: relative;
}

.cnvs-hamburger .cnvs-hamburger-inner {
  margin-top: calc(-1 * var(--cnvs-hamburger-width) * .5);
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  display: block;
  top: 50%;
}

.cnvs-hamburger .cnvs-hamburger-inner, .cnvs-hamburger .cnvs-hamburger-inner:before, .cnvs-hamburger .cnvs-hamburger-inner:after {
  width: var(--cnvs-hamburger-size);
  height: var(--cnvs-hamburger-width);
  background-color: var(--cnvs-contrast-900);
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.cnvs-hamburger .cnvs-hamburger-inner:before, .cnvs-hamburger .cnvs-hamburger-inner:after {
  content: "";
  display: block;
}

.cnvs-hamburger .cnvs-hamburger-inner:before {
  top: var(--cnvs-hamburger-offset);
  transition: top 75ms .12s, opacity 75ms;
}

.cnvs-hamburger .cnvs-hamburger-inner:after {
  bottom: var(--cnvs-hamburger-offset);
  transition: bottom 75ms .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.primary-menu-trigger-active .cnvs-hamburger .cnvs-hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(45deg);
}

.primary-menu-trigger-active .cnvs-hamburger .cnvs-hamburger-inner:before {
  opacity: 0;
  transition: top 75ms, opacity 75ms .12s;
  top: 0;
}

.primary-menu-trigger-active .cnvs-hamburger .cnvs-hamburger-inner:after {
  transition: bottom 75ms, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.menu-container {
  width: 100%;
  margin: 0;
  list-style: none;
  display: none;
}

.menu-item {
  position: relative;
}

.menu-item:not(:first-child) {
  border-top: var(--cnvs-primary-menu-submenu-border);
}

.is-expanded-menu .menu-item:not(:first-child) {
  border-top: 0;
}

.menu-item:hover > .menu-link {
  color: var(--cnvs-primary-menu-hover-color);
}

.menu-item.current > .menu-link {
  color: var(--cnvs-primary-menu-active-color);
}

.menu-item .sub-menu-trigger {
  cursor: pointer;
  text-align: center;
  width: 32px;
  height: 32px;
  color: var(--cnvs-primary-menu-color);
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 12px;
  line-height: 32px;
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 9px;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .menu-item .sub-menu-trigger {
    transition: none;
  }
}

.is-expanded-menu .menu-item.menu-item-important > .menu-link > div:after {
  content: "";
  background-color: var(--bs-danger);
  border-radius: 50%;
  width: .5rem;
  height: .5rem;
  display: inline-block;
  position: relative;
  top: -.25rem;
  left: .25rem;
}

.menu-link {
  --cnvs-primary-menu-padding-y: 14px;
  --cnvs-primary-menu-padding-x: 5px;
  padding: var(--cnvs-primary-menu-padding-y) var(--cnvs-primary-menu-padding-x);
  color: var(--cnvs-primary-menu-color);
  line-height: 22px;
  font-weight: var(--cnvs-primary-menu-font-weight);
  font-size: var(--cnvs-primary-menu-font-size);
  font-family: var(--cnvs-primary-menu-font);
  text-transform: var(--cnvs-primary-menu-tt);
  letter-spacing: var(--cnvs-primary-menu-ls);
  display: block;
}

.is-expanded-menu .menu-link {
  --cnvs-primary-menu-padding-y: calc(calc(var(--cnvs-sticky-header-height)  - 22px) * .5);
  --cnvs-primary-menu-padding-x: 15px;
  transition: margin .4s, padding .4s;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .menu-link {
    transition: none;
  }
}

.menu-link span {
  display: var(--cnvs-primary-menu-submenu-subtitle-display);
}

.menu-link i {
  font-size: var(--cnvs-primary-menu-icon-size);
  width: var(--cnvs-primary-menu-icon-size);
  text-align: center;
  margin-right: var(--cnvs-primary-menu-icon-gap);
  vertical-align: top;
  position: relative;
}

.menu-link div > i.sub-menu-indicator {
  display: none;
}

.is-expanded-menu .menu-container > .menu-item-divider {
  border-left: var(--cnvs-primary-menu-submenu-border);
  height: 2.5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.is-expanded-menu .sub-menu-container > .menu-item-divider {
  border-top: var(--cnvs-primary-menu-submenu-border);
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.sub-menu-container, .mega-menu-content {
  border-top: 1px solid var(--cnvs-header-border-color);
  margin-bottom: 0;
  padding-left: 15px;
  list-style: none;
  position: relative;
}

.is-expanded-menu .sub-menu-container, .is-expanded-menu .mega-menu-content {
  visibility: hidden;
  background-color: var(--cnvs-primary-menu-submenu-bg);
  border: var(--cnvs-primary-menu-submenu-border);
  border-top: var(--cnvs-primary-menu-submenu-top-border);
  z-index: 399;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 13px 42px 11px #0000000d;
}

.is-expanded-menu .menu-container > .menu-item:not(.mega-menu) .sub-menu-container:not([class*="col-"]):not([class="col"]) {
  width: var(--cnvs-primary-menu-submenu-width);
}

.sub-menu-container .menu-item:not(:first-child) {
  border-top: var(--cnvs-primary-menu-submenu-border);
}

.sub-menu-container .menu-item > .menu-link {
  font-size: var(--cnvs-primary-menu-submenu-font-size);
  font-weight: var(--cnvs-primary-menu-submenu-font-weight);
  color: var(--cnvs-contrast-900);
  letter-spacing: var(--cnvs-primary-menu-submenu-spacing);
  font-family: var(--cnvs-primary-menu-submenu-font);
  padding: 10px 5px;
  position: relative;
}

.sub-menu-container .menu-item > .menu-link div {
  position: relative;
}

.sub-menu-container .menu-item:hover > .menu-link {
  --cnvs-primary-menu-submenu-font-weight: var(--cnvs-primary-menu-submenu-hover-font-weight);
}

.sub-menu-container .menu-item .sub-menu-trigger {
  top: 6px;
}

.sub-menu-container .sub-menu-trigger {
  font-size: 10px;
}

.mega-menu:not(.mega-menu-full) .mega-menu-content .container {
  max-width: none;
}

.is-expanded-menu .mega-menu {
  position: inherit;
}

.mega-menu-content {
  padding-left: 0;
}

.is-expanded-menu .primary-menu:not(.on-click) .mega-menu-content {
  display: flex;
}

.is-expanded-menu .primary-menu:not(.on-click) .menu-item:not(.mega-menu) .sub-menu-container, .is-expanded-menu .primary-menu:not(.on-click) .mega-menu-content {
  opacity: 0;
  margin-top: var(--cnvs-primary-menu-submenu-display-offset);
  z-index: -9999;
  pointer-events: none;
  transition: all calc(var(--cnvs-primary-menu-submenu-display-speed) * .75) ease;
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .primary-menu:not(.on-click) .menu-item:not(.mega-menu) .sub-menu-container, .is-expanded-menu .primary-menu:not(.on-click) .mega-menu-content {
    transition: none;
  }
}

.is-expanded-menu .primary-menu:not(.on-click) .menu-item.mega-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
  z-index: auto;
  margin-top: 0;
}

.is-expanded-menu .primary-menu:not(.on-click) .menu-item.mega-menu:hover > .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
  pointer-events: auto;
}

.is-expanded-menu .primary-menu:not(.on-click) .menu-item:hover > .sub-menu-container, .is-expanded-menu .primary-menu:not(.on-click) .menu-item:hover > .mega-menu-content {
  opacity: 1;
  visibility: visible;
  z-index: 399;
  pointer-events: auto;
  transition: opacity var(--cnvs-primary-menu-submenu-display-speed) ease, margin var(--cnvs-primary-menu-submenu-display-speed) ease;
  margin-top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .primary-menu:not(.on-click) .menu-item:hover > .sub-menu-container, .is-expanded-menu .primary-menu:not(.on-click) .menu-item:hover > .mega-menu-content {
    transition: none;
  }
}

.is-expanded-menu .primary-menu:not(.on-click) .menu-item:not(.menu-item-hover) > .sub-menu-container > *, .is-expanded-menu .primary-menu:not(.on-click) .menu-item:not(.menu-item-hover) > .mega-menu-content > * {
  display: none;
}

.is-expanded-menu .primary-menu:not(.on-click) .menu-item.mega-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) > *, .is-expanded-menu .primary-menu:not(.on-click).primary-menu-init .menu-item:not(.mega-menu) .sub-menu-container, .is-expanded-menu .primary-menu:not(.on-click).primary-menu-init .mega-menu-content {
  display: block;
}

.is-expanded-menu .with-arrows .menu-container > .menu-item > .menu-link div > i.sub-menu-indicator {
  display: inline-block;
}

body:not(.is-expanded-menu) .primary-menu {
  max-width: 100%;
}

body:not(.is-expanded-menu) .primary-menu > .container {
  max-width: none;
  padding: 0;
}

body:not(.is-expanded-menu) .mega-menu-content {
  border-top: none;
}

body:not(.is-expanded-menu) .mega-menu-content .sub-menu-container {
  border-top: var(--cnvs-primary-menu-submenu-border);
}

body:not(.is-expanded-menu) .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
  display: block !important;
}

body:not(.is-expanded-menu) .mega-menu-content .mega-menu-column {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0;
}

body:not(.is-expanded-menu) .mega-menu-column .widget {
  padding: 30px 0;
}

.mega-menu-content .mega-menu-column:first-child {
  border-top: 0;
}

body:not(.is-expanded-menu) #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
  width: var(--cnvs-mobile-menu-off-canvas-width);
  background-color: var(--cnvs-header-bg);
  height: 100vh;
  transform: translate3d(calc(-1 * var(--cnvs-mobile-menu-off-canvas-width)), 0, 0);
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: -1;
  display: block;
  position: fixed;
  overflow-y: scroll;
  border-right: 1px solid var(--cnvs-header-border-color) !important;
  max-height: none !important;
  margin: 0 !important;
  padding: 35px 25px 60px !important;
  transition: all .3s ease-in-out, opacity .2s !important;
  top: 0 !important;
  left: 0 !important;
}

@media (prefers-reduced-motion: reduce) {
  body:not(.is-expanded-menu) #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
    transition: none;
  }
}

body:not(.is-expanded-menu) #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container.d-block {
  z-index: 499;
  transform: translate3d(0, 0, 0);
}

body:not(.is-expanded-menu) #header:not(.sticky-header) .mobile-menu-off-canvas.from-right .menu-container {
  transform: translate3d(var(--cnvs-mobile-menu-off-canvas-width), 0);
  right: 0;
  border-right: 0 !important;
  border-left: 1px solid var(--cnvs-header-border-color) !important;
  left: auto !important;
}

body:not(.is-expanded-menu) .mobile-menu-overlay .menu-container {
  z-index: 99;
  border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  background-color: var(--cnvs-header-bg);
  -webkit-backface-visibility: hidden;
  border-radius: .5rem;
  width: 100%;
  height: auto;
  min-height: 25vh;
  transition: opacity .3s;
  position: absolute;
  overflow-y: auto;
  max-height: 60vh !important;
  left: 0 !important;
  top: calc(var(--cnvs-header-height)  - 15%) !important;
  margin: 0 !important;
  padding: 1rem 1.5rem !important;
}

.is-expanded-menu .menu-container:not(.mobile-primary-menu) {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.is-expanded-menu .full-header .primary-menu .menu-container {
  border-right: 1px solid var(--cnvs-header-border-color);
  margin-right: .5rem;
  padding-right: .5rem;
}

.is-expanded-menu .transparent-header.full-header .primary-menu .menu-container {
  border-right-color: var(--cnvs-header-transparent-border-color);
}

.is-expanded-menu .menu-container > .menu-item:not(:first-child) {
  margin-left: 2px;
}

.is-expanded-menu .sub-menu-container .sub-menu-container {
  list-style: none;
  left: 100%;
  top: -2px !important;
}

.is-expanded-menu .sub-menu-container.menu-pos-invert, .is-expanded-menu .mega-menu-small .mega-menu-content.menu-pos-invert {
  left: auto;
  right: 0;
}

.is-expanded-menu .sub-menu-container .sub-menu-container.menu-pos-invert {
  right: 100%;
}

.is-expanded-menu .sub-menu-container .menu-item:not(:first-child) {
  border-top: 0;
}

.is-expanded-menu .sub-menu-container .menu-item > .menu-link {
  padding: var(--cnvs-primary-menu-submenu-padding-y) var(--cnvs-primary-menu-submenu-padding-x);
  font-weight: var(--cnvs-primary-menu-submenu-font-weight);
  text-transform: var(--cnvs-primary-menu-submenu-tt);
  border: 0;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .sub-menu-container .menu-item > .menu-link {
    transition: none;
  }
}

.is-expanded-menu .sub-menu-container .menu-item > .menu-link span {
  --cnvs-primary-menu-submenu-subtitle-display: block;
  color: var(--cnvs-primary-menu-submenu-subtitle-color);
  font-size: var(--cnvs-primary-menu-submenu-subtitle-font-size);
  font-weight: var(--cnvs-primary-menu-submenu-subtitle-font-weight);
  text-transform: var(--cnvs-primary-menu-submenu-subtitle-tt);
  line-height: 1.5;
}

.is-expanded-menu .sub-menu-container .menu-item > .menu-link span.menu-subtitle-icon-offset {
  margin-left: calc(var(--cnvs-primary-menu-icon-size)  + var(--cnvs-primary-menu-icon-gap));
}

.is-expanded-menu .sub-menu-container .menu-item:hover > .menu-link {
  background-color: var(--cnvs-primary-menu-submenu-hover-bg);
  --cnvs-primary-menu-submenu-font-weight: var(--cnvs-primary-menu-submenu-hover-font-weight);
  padding-left: calc(var(--cnvs-primary-menu-submenu-padding-x)  + var(--cnvs-primary-menu-submenu-hover-padding-offset));
}

.is-expanded-menu .sub-menu-container .menu-item:hover > .menu-link div {
  text-decoration: var(--cnvs-primary-menu-hover-text-decoration);
  text-underline-offset: var(--cnvs-primary-menu-hover-text-decoration-offset);
}

.is-expanded-menu .sub-menu-container .menu-item:hover > .menu-link, .is-expanded-menu .sub-menu-container .menu-item.current > .menu-link {
  color: var(--cnvs-primary-menu-submenu-hover-color);
}

.is-expanded-menu .menu-link div > i.sub-menu-indicator {
  opacity: .9;
  margin: 0 0 0 .1875rem;
  font-size: .75rem;
  top: 0;
}

.is-expanded-menu .sub-menu-container .menu-link div > i.sub-menu-indicator, .is-expanded-menu.side-header .primary-menu:not(.on-click) .menu-link i.sub-menu-indicator {
  margin: 0;
  line-height: 1;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)rotate(-90deg);
}

.is-expanded-menu .menu-item .sub-menu-trigger, .is-expanded-menu.side-header .on-click .sub-menu-container .menu-link i.sub-menu-indicator:last-child {
  display: none;
}

.is-expanded-menu .mega-menu:not(.mega-menu-full) .mega-menu-content .container {
  max-width: none;
  padding: 0 calc(var(--bs-gutter-x) * .5) !important;
  margin: 0 auto !important;
}

.is-expanded-menu .mega-menu-content {
  width: 100%;
  max-width: none;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

.is-expanded-menu .mega-menu-full .mega-menu-content {
  width: 100%;
  left: 0;
  transform: none;
}

.is-expanded-menu .mega-menu-small {
  position: relative;
}

.is-expanded-menu .mega-menu-small .mega-menu-content {
  width: var(--cnvs-mega-menu-width-sm);
  left: 0;
  transform: none;
}

.is-expanded-menu .floating-header:not(.sticky-header) .mega-menu:not(.mega-menu-small) .mega-menu-content {
  max-width: 100%;
}

.is-expanded-menu .sub-menu-container .mega-menu {
  position: relative;
}

.is-expanded-menu .sub-menu-container .mega-menu-small .mega-menu-content {
  top: 0;
  left: 100%;
}

.is-expanded-menu .sub-menu-container .mega-menu-small .mega-menu-content.menu-pos-invert {
  left: auto;
  right: 100%;
}

.is-expanded-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
  min-width: inherit;
  box-shadow: none;
  z-index: auto;
  background-color: #0000;
  border: 0;
  list-style: none;
  position: relative;
  left: 0;
  visibility: visible !important;
  opacity: 1 !important;
  height: auto !important;
  display: block !important;
  top: 0 !important;
}

.is-expanded-menu .mega-menu-content .sub-menu-container.mega-menu-dropdown {
  width: var(--cnvs-primary-menu-submenu-width);
  padding: .75rem 1.25rem;
}

.is-expanded-menu .mega-menu-column {
  padding-left: 0;
  padding-right: 0;
  display: block !important;
}

.is-expanded-menu .mega-menu:not(.mega-menu-small) .mega-menu-column {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.is-expanded-menu .mega-menu-content:not(.border-0) .sub-menu-container.mega-menu-column:not(:first-child) {
  border-left: var(--cnvs-primary-menu-submenu-border);
}

.is-expanded-menu .mega-menu .mega-menu-style-2 .mega-menu-column {
  padding: 1.75rem 1.5rem;
}

.is-expanded-menu .mega-menu-style-2 .mega-menu-title:not(:first-child) {
  margin-top: 1.5rem;
}

.is-expanded-menu .mega-menu-style-2 .mega-menu-title > .menu-link {
  --cnvs-primary-menu-submenu-tt: uppercase;
  --cnvs-primary-menu-submenu-font-weight: 700;
  --cnvs-primary-menu-submenu-hover-font-weight: 700;
  --cnvs-primary-menu-font: var(--cnvs-primary-font);
  font-size: calc(var(--cnvs-primary-menu-submenu-font-size) * 1.1);
  letter-spacing: 1px;
  color: var(--cnvs-primary-menu-submenu-color);
  margin-bottom: .5rem;
  padding: 0 !important;
  line-height: 1.3 !important;
}

.is-expanded-menu .mega-menu-style-2 .mega-menu-title:hover > .menu-link {
  --cnvs-primary-menu-submenu-hover-bg: transparent;
}

.is-expanded-menu .mega-menu-style-2 .mega-menu-title:hover > .menu-link div {
  text-decoration: none;
}

.is-expanded-menu .mega-menu-style-2 .mega-menu-title > .menu-link:hover {
  color: var(--cnvs-primary-menu-hover-color);
}

.is-expanded-menu .mega-menu-style-2 .sub-menu-container:not(.mega-menu-dropdown) .menu-item {
  border: 0;
}

.is-expanded-menu .mega-menu-style-2 .sub-menu-container:not(.mega-menu-dropdown) .menu-link {
  --cnvs-primary-menu-submenu-padding-x: .25rem;
  --cnvs-primary-menu-submenu-padding-y: .5rem;
}

.is-expanded-menu .mega-menu-style-2 .sub-menu-container:not(.mega-menu-dropdown) .menu-item:not(.mega-menu-title):hover > .menu-link {
  --cnvs-primary-menu-submenu-hover-bg: rgba(var(--cnvs-contrast-rgb), .025);
  --cnvs-primary-menu-submenu-hover-padding-offset: .375rem;
  border-radius: .25rem;
  padding-right: .25rem;
}

.is-expanded-menu .on-click .sub-menu-container, .is-expanded-menu .on-click .mega-menu-content {
  visibility: visible;
  display: none;
}

.is-expanded-menu .on-click .menu-item:not(.mega-menu-small) .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
  display: block;
}

.is-expanded-menu .style-2 #logo {
  margin-right: 1.5rem;
}

.is-expanded-menu .style-2 .primary-menu {
  margin-right: auto;
}

.is-expanded-menu .style-2.menu-center .primary-menu {
  margin-left: auto;
  margin-right: auto;
}

.is-expanded-menu .style-3 .menu-container > .menu-item > .menu-link {
  --cnvs-primary-menu-padding-y: 11px;
  border-radius: 2px;
  margin: 28px 0;
}

.is-expanded-menu .style-3 .menu-container > .menu-item:hover > .menu-link {
  background-color: var(--cnvs-primary-menu-submenu-hover-bg);
}

.is-expanded-menu .style-3 .menu-container > .menu-item.current > .menu-link {
  --cnvs-primary-menu-active-color: var(--bs-white);
  background-color: var(--cnvs-themecolor);
}

.is-expanded-menu .style-4 .menu-container > .menu-item > .menu-link {
  --cnvs-primary-menu-padding-y: 28px;
  margin: var(--cnvs-primary-menu-padding-y) 0;
  border: 1px solid #0000;
  border-radius: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.is-expanded-menu .style-4 .menu-container > .menu-item:hover > .menu-link {
  border-color: var(--cnvs-primary-menu-hover-color);
}

.is-expanded-menu .style-4 .menu-container > .menu-item.current > .menu-link {
  border-color: var(--cnvs-primary-menu-active-color);
}

.is-expanded-menu .style-5 .menu-container {
  border-right: 1px solid var(--cnvs-header-border-color);
  margin-right: 5px;
  padding-right: 10px;
}

.is-expanded-menu .style-5 .menu-container > .menu-item:not(:first-child) {
  margin-left: 15px;
}

.is-expanded-menu .style-5 .menu-container > .menu-item > .menu-link {
  --cnvs-primary-menu-padding-y: 27px;
  line-height: 1;
}

.is-expanded-menu .style-5 .menu-container > .menu-item > .menu-link i:not(.sub-menu-indicator) {
  width: auto;
  margin: 0 0 12px;
  font-size: 20px;
  line-height: 1;
  transition: all .3s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .style-5 .menu-container > .menu-item > .menu-link i:not(.sub-menu-indicator) {
    transition: none;
  }
}

.is-expanded-menu .style-6 .menu-container > .menu-item > .menu-link {
  position: relative;
}

.is-expanded-menu .style-6 .menu-container > .menu-item > .menu-link:after, .is-expanded-menu .style-6 .menu-container > .menu-item.current > .menu-link:after {
  content: "";
  border-top: 2px solid var(--cnvs-primary-menu-active-color);
  width: 0;
  height: 2px;
  transition: width .3s;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu .style-6 .menu-container > .menu-item > .menu-link:after, .is-expanded-menu .style-6 .menu-container > .menu-item.current > .menu-link:after {
    transition: none;
  }
}

.is-expanded-menu .style-6 .menu-container > .menu-item.current > .menu-link:after, .is-expanded-menu .style-6 .menu-container > .menu-item:hover > .menu-link:after {
  width: 100%;
}

.is-expanded-menu .sub-title .menu-container > .menu-item {
  margin-left: 0;
}

.is-expanded-menu .sub-title .menu-container > .menu-item > .menu-link {
  --cnvs-header-height: 100px;
  --cnvs-primary-menu-padding-y: 32px;
  --cnvs-primary-menu-padding-x: 20px;
  line-height: 14px;
  position: relative;
}

.is-expanded-menu .sub-title .menu-container > .menu-item:not(:first-child) > .menu-link:before {
  content: "";
  z-index: -2;
  background-color: var(--cnvs-contrast-200);
  width: 1px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.is-expanded-menu .sub-title .menu-container > .menu-item > .menu-link span {
  color: var(--cnvs-contrast-600);
  text-transform: capitalize;
  margin-top: 13px;
  font-size: .825rem;
  font-weight: 400;
  line-height: 12px;
  display: block;
}

.is-expanded-menu .sub-title .menu-container > .menu-item:hover > .menu-link, .is-expanded-menu .sub-title .menu-container > .menu-item.current > .menu-link {
  color: #fff;
}

.is-expanded-menu .sub-title .menu-container > .menu-item:hover > .menu-link span, .is-expanded-menu .sub-title .menu-container > .menu-item.current > .menu-link span {
  color: var(--cnvs-contrast-200);
}

.is-expanded-menu .sub-title .menu-container > .menu-item:hover > .menu-link:after, .is-expanded-menu .sub-title .menu-container > .menu-item.current > .menu-link:after {
  content: "";
  z-index: -1;
  background-color: var(--cnvs-themecolor);
  border-top: 5px solid #0000001a;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.is-expanded-menu.side-header:not(.open-header) #wrapper {
  margin: 0 0 0 var(--cnvs-side-header-width) !important;
  width: auto !important;
}

.is-expanded-menu.side-header .container {
  padding-left: var(--cnvs-side-header-padding-gutters);
  padding-right: var(--cnvs-side-header-padding-gutters);
}

.is-expanded-menu.side-header #header {
  --cnvs-header-bg: var(--cnvs-side-header-bg);
  z-index: 10;
  width: var(--cnvs-side-header-width);
  border: none;
  border-right: 1px solid var(--cnvs-header-border-color);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 15px #0001;
}

.is-expanded-menu.side-header #header-wrap {
  width: calc(var(--cnvs-side-header-width)  + 40px);
  height: 100%;
  padding-right: 40px;
  position: relative;
  overflow: hidden scroll;
}

.is-expanded-menu.side-header #header-wrap > .container {
  width: var(--cnvs-side-header-width);
  max-width: none;
  padding: 0 var(--cnvs-side-header-padding-gutters);
  min-height: 100%;
  display: flex;
  margin: 0 !important;
}

.is-expanded-menu.side-header .header-wrap-clone {
  display: none;
}

.is-expanded-menu.side-header .header-row {
  flex-direction: column;
  flex-grow: 1 !important;
}

.is-expanded-menu.side-header #logo {
  width: 100%;
  margin: 3rem 0;
}

.is-expanded-menu.side-header #logo img {
  max-width: 100%;
}

.is-expanded-menu.side-header .header-misc {
  width: 100%;
  margin: 0;
}

.is-expanded-menu.side-header .primary-menu {
  width: 100%;
  margin-bottom: 30px;
  flex-grow: 0 !important;
}

.is-expanded-menu.side-header .primary-menu:not(.border-bottom-0):after {
  margin-top: 40px;
}

.is-expanded-menu.side-header .menu-container {
  width: 100%;
  height: auto;
  display: block;
}

.is-expanded-menu .no-triggers .menu-container .sub-menu-trigger {
  display: none;
}

.is-expanded-menu.side-header .on-click .menu-item .sub-menu-trigger {
  font-size: 10px;
  display: block;
  top: 0;
  right: -5px;
}

.is-expanded-menu.side-header .primary-menu:not(.on-click) .text-center i.sub-menu-indicator {
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 2px;
  transform: none;
}

.is-expanded-menu.side-header .primary-menu:not(.on-click) .text-center .sub-menu-container i.sub-menu-indicator {
  top: -1px;
}

.is-expanded-menu.side-header .menu-item {
  width: 100%;
}

.is-expanded-menu.side-header .menu-item:not(:first-child) {
  border-top: 0;
  margin: 10px 0 0;
}

.is-expanded-menu.side-header .menu-link {
  position: relative;
  margin: 0 !important;
  padding: 5px 0 !important;
}

.is-expanded-menu.side-header .sub-menu-container, .is-expanded-menu.side-header .mega-menu-content {
  box-shadow: none;
  z-index: 1;
  background-color: #0000;
  border: none;
  height: auto;
  margin: 5px 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100% !important;
  padding: 0 0 0 12px !important;
}

.is-expanded-menu.side-header .mega-menu-content {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.is-expanded-menu.side-header .mega-menu-column {
  flex: 0 0 100%;
  max-width: 100%;
  border: none !important;
}

.is-expanded-menu.side-header .sub-menu-container .sub-menu-container {
  left: 0;
  top: 0 !important;
}

.is-expanded-menu.side-header .sub-menu-container.menu-pos-invert {
  left: 0;
  right: auto;
}

.is-expanded-menu.side-header .sub-menu-container .sub-menu-container.menu-pos-invert {
  right: auto;
}

.is-expanded-menu.side-header .sub-menu-container .menu-item {
  border: 0;
  margin: 0;
}

.is-expanded-menu.side-header .sub-menu-container .menu-item:first-child {
  border-top: 0;
}

.is-expanded-menu.side-header .menu-item:hover > .menu-link {
  background-color: #0000 !important;
}

.is-expanded-menu.side-header .menu-container > .menu-item.sub-menu > .menu-link, .is-expanded-menu.side-header .menu-container > .menu-item.sub-menu:hover > .menu-link {
  background-image: none !important;
}

.is-expanded-menu.side-header.open-header.push-wrapper {
  overflow-x: hidden;
}

.is-expanded-menu.side-header.open-header #header {
  left: calc(-1 * var(--cnvs-side-header-width));
}

.is-expanded-menu.side-header.open-header.side-header-open #header, .is-expanded-menu.side-header.open-header #wrapper {
  left: 0;
}

.is-expanded-menu.side-header.open-header.push-wrapper.side-header-open #wrapper {
  left: var(--cnvs-side-header-width);
}

.is-expanded-menu #header-trigger {
  display: none;
}

.is-expanded-menu.side-header.open-header #header-trigger {
  --cnvs-header-trigger-size: 2rem;
  --cnvs-header-trigger-offset: 1.5rem;
  cursor: pointer;
  top: var(--cnvs-header-trigger-offset);
  left: var(--cnvs-header-trigger-offset);
  z-index: 11;
  font-size: calc(var(--cnvs-header-trigger-size) * .5);
  width: var(--cnvs-header-trigger-size);
  height: var(--cnvs-header-trigger-size);
  line-height: calc(var(--cnvs-header-trigger-size)  - 2px);
  background-color: var(--cnvs-header-bg);
  border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  text-align: center;
  border-radius: 2px;
  display: block;
  position: fixed;
}

.is-expanded-menu.side-header.open-header.side-header-open #header-trigger {
  left: calc(var(--cnvs-side-header-width)  + var(--cnvs-header-trigger-offset));
}

.is-expanded-menu.side-header.open-header #header-trigger i:first-child, .is-expanded-menu.side-header.open-header.side-header-open #header-trigger i:nth-child(2) {
  display: block;
}

.is-expanded-menu.side-header.open-header.side-header-open #header-trigger i:first-child, .is-expanded-menu.side-header.open-header #header-trigger i:nth-child(2) {
  display: none;
}

.is-expanded-menu.side-header.open-header #header, .is-expanded-menu.side-header.open-header #header-trigger, .is-expanded-menu.side-header.open-header.push-wrapper #wrapper {
  transition: left .4s;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu.side-header.open-header #header, .is-expanded-menu.side-header.open-header #header-trigger, .is-expanded-menu.side-header.open-header.push-wrapper #wrapper {
    transition: none;
  }
}

.is-expanded-menu.side-header.side-header-right #header {
  border: none;
  border-left: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  left: auto;
  right: 0;
}

.is-expanded-menu.side-header.side-header-right:not(.open-header) #wrapper {
  margin: 0 var(--cnvs-side-header-width) 0 0 !important;
}

.is-expanded-menu.side-header.side-header-right.open-header #header {
  left: auto;
  right: calc(-1 * var(--cnvs-side-header-width));
}

.is-expanded-menu.side-header.side-header-right.open-header.side-header-open #header {
  left: auto;
  right: 0;
}

.is-expanded-menu.side-header.side-header-right.open-header.push-wrapper.side-header-open #wrapper {
  left: calc(-1 * var(--cnvs-side-header-width));
}

.is-expanded-menu.side-header.side-header-right.open-header #header-trigger {
  left: auto;
  right: var(--cnvs-header-trigger-offset);
}

.is-expanded-menu.side-header.side-header-right.open-header.side-header-open #header-trigger {
  left: auto;
  right: calc(var(--cnvs-side-header-width)  + var(--cnvs-header-trigger-offset));
}

.is-expanded-menu.side-header.side-header-right.open-header #header, .is-expanded-menu.side-header.side-header-right.open-header #header-trigger {
  transition: right .4s;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu.side-header.side-header-right.open-header #header, .is-expanded-menu.side-header.side-header-right.open-header #header-trigger {
    transition: none;
  }
}

.is-expanded-menu.overlay-menu:not(.top-search-open) .primary-menu-trigger {
  opacity: 1;
  pointer-events: auto;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: flex;
}

.is-expanded-menu.overlay-menu .primary-menu-trigger {
  z-index: 599;
}

.is-expanded-menu.overlay-menu .primary-menu {
  --cnvs-primary-menu-font-size: .9375rem;
  background-color: var(--cnvs-header-bg);
  background: rgba(var(--cnvs-invert-contrast-rgb), .95);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.is-expanded-menu.overlay-menu .primary-menu > #overlay-menu-close {
  text-align: center;
  width: 48px;
  height: 48px;
  color: var(--cnvs-contrast-900);
  font-size: 1.5rem;
  line-height: 48px;
  position: fixed;
  top: 25px;
  left: auto;
  right: 25px;
}

.is-expanded-menu.overlay-menu .primary-menu, .is-expanded-menu.overlay-menu .primary-menu > #overlay-menu-close {
  pointer-events: none;
  z-index: -2;
  transform: translateY(-80px);
  opacity: 0 !important;
  transition: opacity .4s, transform .45s !important;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu.overlay-menu .primary-menu, .is-expanded-menu.overlay-menu .primary-menu > #overlay-menu-close {
    transition: none;
  }
}

.is-expanded-menu.overlay-menu.primary-menu-open .primary-menu, .is-expanded-menu.overlay-menu.primary-menu-open #overlay-menu-close {
  pointer-events: auto;
  z-index: 499;
  transform: translateY(0);
  opacity: 1 !important;
  transition: opacity .3s, transform .45s !important;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu.overlay-menu.primary-menu-open .primary-menu, .is-expanded-menu.overlay-menu.primary-menu-open #overlay-menu-close {
    transition: none;
  }
}

.is-expanded-menu.overlay-menu .primary-menu, .is-expanded-menu.overlay-menu.primary-menu-open .primary-menu {
  transform: translateY(0);
  transition: opacity .3s !important;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-menu.overlay-menu .primary-menu, .is-expanded-menu.overlay-menu.primary-menu-open .primary-menu {
    transition: none;
  }
}

.is-expanded-menu.overlay-menu .primary-menu > .container {
  max-height: 90%;
  overflow-y: scroll;
}

.is-expanded-menu.overlay-menu .menu-container {
  flex-direction: column;
  max-width: 250px;
  border: 0 !important;
  margin: 0 auto !important;
  padding: 0 !important;
  display: flex !important;
}

.is-expanded-menu.overlay-menu .menu-item {
  width: 100%;
  position: relative;
}

.is-expanded-menu.overlay-menu .menu-link {
  --cnvs-primary-menu-padding-y: 10px;
  --cnvs-primary-menu-padding-x: 0;
}

.is-expanded-menu.overlay-menu .sub-menu-container, .is-expanded-menu.overlay-menu .mega-menu-content {
  max-width: 100%;
  box-shadow: none;
  background: none;
  border: 0;
  display: none;
  position: relative;
  right: auto;
  transform: translateX(0);
  width: 100% !important;
  padding: 5px 0 !important;
  top: 0 !important;
  left: 0 !important;
}

.is-expanded-menu.overlay-menu .mega-menu-content .sub-menu-container {
  padding: 5px 0 !important;
}

.is-expanded-menu.overlay-menu .mega-menu-title .sub-menu-container {
  padding-top: 0 !important;
}

.is-expanded-menu.overlay-menu .mega-menu-content .mega-menu-column {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  border: 0 !important;
}

.is-expanded-menu.overlay-menu .sub-menu-container .menu-link {
  padding: 5px 0;
}

.is-expanded-menu.overlay-menu .sub-menu-container .menu-link i.sub-menu-indicator {
  margin-left: 5px;
  display: none;
  position: relative;
  top: -1px;
  left: 0;
  transform: rotate(0);
}

.is-expanded-menu.overlay-menu .sub-menu-container .menu-item:hover > .menu-link {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

.is-expanded-menu.overlay-menu .sub-menu-trigger {
  display: block;
  top: 5px;
}

.is-expanded-menu.overlay-menu .sub-menu-container .sub-menu-trigger {
  top: 0;
}

@media (width <= 767.98px) {
  .side-push-panel #side-panel-trigger-close a {
    z-index: 12;
    width: 40px;
    height: 40px;
    color: var(--cnvs-contrast-900);
    text-align: center;
    background-color: #0000001a;
    border-radius: 0 0 0 2px;
    font-size: 18px;
    line-height: 40px;
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
  }
}

.header-misc {
  position: initial;
  align-items: center;
  margin-left: 15px;
  display: flex;
}

.is-expanded-menu .header-misc {
  order: 6;
}

.header-misc-element, .header-misc-icon {
  margin: 0 8px;
}

.header-misc-icon > a {
  width: calc(var(--cnvs-header-misc-icons-size)  + .125rem);
  height: calc(var(--cnvs-header-misc-icons-size)  + .125rem);
  font-size: calc(var(--cnvs-header-misc-icons-size));
  line-height: calc(var(--cnvs-header-misc-icons-size)  + .125rem);
  text-align: center;
  color: var(--cnvs-header-misc-icons-color);
  justify-content: center;
  align-items: center;
  transition: color .3s ease-in-out;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .header-misc-icon > a {
    transition: none;
  }
}

.is-expanded-menu .header-misc:not([class*="order-"]) {
  order: 6;
}

#top-search {
  transition: opacity .3s;
}

@media (prefers-reduced-motion: reduce) {
  #top-search {
    transition: none;
  }
}

#top-search a {
  z-index: 11;
}

#top-search a i {
  transition: opacity .3s;
}

@media (prefers-reduced-motion: reduce) {
  #top-search a i {
    transition: none;
  }
}

.top-search-open #top-search a i:first-child, #top-search a i:nth-child(2) {
  display: none;
}

.top-search-open #top-search a i:nth-child(2) {
  z-index: 11;
  font-size: 1.125rem;
  display: block;
}

.top-search-form {
  opacity: 0;
  z-index: -2;
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .top-search-form {
    transition: none;
  }
}

.top-search-open .top-search-form {
  opacity: 1;
  z-index: 10;
  pointer-events: auto;
}

.top-search-form input {
  height: 100%;
  font-size: 1.175rem;
  font-weight: 500;
  font-family: var(--cnvs-primary-font);
  border: 0;
  border-radius: 0;
  padding: 10px 80px 10px 0;
  box-shadow: none !important;
  color: var(--cnvs-primary-menu-color) !important;
  background-color: #0000 !important;
  outline: 0 !important;
  margin-top: 0 !important;
}

@media (width >= 992px) {
  .top-search-form input {
    font-size: var(--cnvs-top-search-font-size);
  }

  .full-header .top-search-form input, .container-fluid .top-search-form input, .container-sm .top-search-form input, .container-md .top-search-form input, .container-lg .top-search-form input, .container-xl .top-search-form input, .container-xxl .top-search-form input {
    padding-left: 30px;
  }

  .transparent-header:not(.sticky-header):not(.full-header):not(.floating-header) .top-search-form input {
    border-bottom: 2px solid var(--cnvs-header-transparent-border-color);
  }
}

.search-overlay .top-search-form {
  background-color: var(--cnvs-contrast-0);
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
}

.search-overlay .top-search-form input {
  text-align: center;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 40px;
}

.top-search-form input::-moz-placeholder {
  color: var(--cnvs-top-search-placeholder);
  opacity: 1;
}

.top-search-form input:-ms-input-placeholder {
  color: var(--cnvs-top-search-placeholder);
  opacity: 1;
}

.top-search-form input::-webkit-input-placeholder {
  color: var(--cnvs-top-search-placeholder);
  opacity: 1;
}

.primary-menu .container .top-search-form input, .sticky-header .top-search-form input {
  border: none !important;
}

body:not(.top-search-open) .top-search-parent #logo, body:not(.top-search-open) .top-search-parent .primary-menu, body:not(.top-search-open) .top-search-parent .header-misc > :not(#top-search) {
  opacity: 1;
  transition: padding .4s, margin .4s, opacity .2s .2s;
}

@media (prefers-reduced-motion: reduce) {
  body:not(.top-search-open) .top-search-parent #logo, body:not(.top-search-open) .top-search-parent .primary-menu, body:not(.top-search-open) .top-search-parent .header-misc > :not(#top-search) {
    transition: none;
  }
}

.top-search-open .top-search-parent #logo, .top-search-open .top-search-parent .primary-menu, .top-search-open .top-search-parent .header-misc > :not(#top-search) {
  opacity: 0;
}

.top-cart-number {
  --cnvs-top-cart-number-size: 1.125rem;
  color: #fff;
  width: var(--cnvs-top-cart-number-size);
  height: var(--cnvs-top-cart-number-size);
  font-size: .625rem;
  line-height: var(--cnvs-top-cart-number-size);
  text-align: center;
  background-color: var(--cnvs-themecolor);
  opacity: .8;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -7px;
  left: auto;
  right: -8px;
}

@media (width >= 992px) {
  #top-cart {
    position: relative;
  }
}

.top-cart-content {
  background-color: var(--cnvs-primary-menu-submenu-bg);
  border: 0;
  border-radius: .25rem;
  width: 300px;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: auto;
  box-shadow: 0 20px 50px 10px #0000000d;
}

@media (prefers-reduced-motion: reduce) {
  .top-cart-content {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .top-cart-content {
    z-index: -1;
    height: 100vh;
    transform: translate3d(300px, 0, 0);
    right: 0 !important;
  }
}

@media (width >= 992px) {
  .top-cart-content {
    opacity: 0;
    border: 1px solid var(--cnvs-header-border-color);
    border-top: 2px solid var(--cnvs-themecolor);
    width: 280px;
    top: calc((var(--cnvs-sticky-header-height)  + 20px) * .5);
    z-index: auto;
    margin: -10000px 0 0;
    transition: opacity .5s, top .4s;
    position: absolute;
    right: 0;
  }
}

@media (width >= 992px) and (prefers-reduced-motion: reduce) {
  .top-cart-content {
    transition: none;
  }
}

.top-cart-title {
  border-bottom: 1px solid var(--cnvs-header-border-color);
  padding: .75rem 1rem;
}

.top-cart-title h4, .top-cart-title .h4 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 600;
}

.top-cart-items {
  padding: 1rem;
}

@media (width >= 992px) {
  .top-cart-items {
    padding: 1rem;
  }
}

.top-cart-item, .top-cart-action, .top-cart-item-desc {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.top-cart-item {
  justify-content: flex-start;
}

.top-cart-item:not(:first-child) {
  border-top: 1px solid var(--cnvs-header-border-color);
  margin-top: 1rem;
  padding-top: 1rem;
}

.top-cart-item-image a, .top-cart-item-image img {
  width: 60px;
  height: 60px;
  display: block;
}

.top-cart-item-image:hover {
  border-color: var(--cnvs-themecolor);
}

.top-cart-item-desc, .top-cart-item-desc-title {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding-left: 1rem;
}

.top-cart-item-desc {
  align-items: flex-start;
}

.top-cart-item-desc-title {
  padding-left: 0;
}

.top-cart-item-desc a {
  color: var(--cnvs-contrast-800);
  margin-bottom: 5px;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: block;
}

.top-cart-item-desc a:hover {
  color: var(--cnvs-themecolor);
}

.top-cart-item-price {
  color: var(--cnvs-primary-menu-color);
  font-size: .75rem;
}

.top-cart-item-quantity {
  color: var(--cnvs-primary-menu-color);
  padding-top: 5px;
  font-size: .75rem;
  line-height: 1.25rem;
}

.top-cart-action {
  border-top: 1px solid var(--cnvs-header-border-color);
  align-items: center;
  padding: 1rem;
}

.top-cart-action .top-checkout-price {
  color: var(--cnvs-themecolor);
  font-size: 1.25rem;
  font-weight: 500;
}

@media (width <= 991.98px) {
  .top-cart-open .top-cart-content {
    z-index: 499;
    transform: translate3d(0, 0, 0);
  }
}

@media (width >= 992px) {
  .top-cart-open .top-cart-content {
    opacity: 1;
    z-index: 399;
    margin-top: 0;
  }
}

#page-menu {
  --cnvs-page-submenu-sticky-offset: var(--cnvs-header-height-shrink);
  --cnvs-page-submenu-link-size: .875rem;
  --cnvs-page-submenu-link-marging-x: 4px;
  --cnvs-page-submenu-link-marging-y: 10px;
  --cnvs-page-submenu-link-rounded: 14px;
  --cnvs-page-submenu-dropdown-width: 150px;
  --cnvs-page-submenu-trigger: 44px;
  --cnvs-page-dotsmenu-size: 10px;
  --cnvs-page-dotsmenu-gutters: 10px;
  --cnvs-page-dotsmenu-rounded: 50%;
  position: relative;
}

#page-menu-wrap {
  z-index: 199;
  background-color: var(--cnvs-themecolor);
  -webkit-backface-visibility: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

#page-menu.sticky-page-menu #page-menu-wrap {
  position: fixed;
  box-shadow: 0 0 15px #0000004d;
}

body:not(.is-expanded-pagemenu) #page-menu, #header.no-sticky ~ #page-menu {
  --cnvs-page-submenu-sticky-offset: 0;
}

body:not(.is-expanded-pagemenu) #header[data-mobile-sticky="true"] ~ #page-menu {
  --cnvs-page-submenu-sticky-offset: var(--cnvs-header-height);
}

#page-menu.sticky-page-menu:not(.dots-menu) #page-menu-wrap {
  top: var(--cnvs-page-submenu-sticky-offset);
}

#page-menu-trigger {
  opacity: 1;
  pointer-events: auto;
  width: var(--cnvs-page-submenu-trigger);
  height: var(--cnvs-page-submenu-trigger);
  line-height: var(--cnvs-page-submenu-trigger);
  color: #fff;
  margin-top: 0;
  top: 0;
  left: auto;
  right: 15px;
}

.page-menu-open #page-menu-trigger {
  background-color: #0003;
}

.page-menu-row {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.page-menu-title {
  color: #fff;
  margin-right: auto;
  font-size: 1.25rem;
  font-weight: 300;
}

.page-menu-title span {
  font-weight: 700;
}

.page-menu-nav {
  background-color: var(--cnvs-themecolor);
  width: 200px;
  height: auto;
  display: none;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
}

.is-expanded-pagemenu .page-menu-nav {
  display: -ms-flex;
  flex: none;
  width: auto;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  background-color: #0000 !important;
}

#page-menu.page-menu-open .page-menu-nav {
  display: block;
}

.page-menu-nav .page-menu-container {
  background-color: #0003;
  margin: 0;
  list-style: none;
}

.is-expanded-pagemenu .page-menu-nav .page-menu-container {
  background-color: #0000;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  display: flex;
}

.page-menu-item {
  position: relative;
}

.page-menu-item > a {
  color: var(--cnvs-white);
  line-height: 22px;
  font-size: var(--cnvs-page-submenu-link-size);
  padding: 11px 14px;
  display: block;
  position: relative;
}

.is-expanded-pagemenu .page-menu-item > a {
  margin: var(--cnvs-page-submenu-link-marging-y) var(--cnvs-page-submenu-link-marging-x);
  border-radius: var(--cnvs-page-submenu-link-rounded);
  padding-top: 3px;
  padding-bottom: 3px;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-pagemenu .page-menu-item > a {
    transition: none;
  }
}

.page-menu-item > a .sub-menu-indicator {
  position: absolute;
  top: 50%;
  left: auto;
  right: 14px;
  transform: translateY(-50%);
}

.is-expanded-pagemenu .page-menu-item > a .sub-menu-indicator {
  margin-left: .5rem;
  position: relative;
  left: 0;
  right: 0;
  transform: none;
}

.page-menu-item:hover > a, .page-menu-item.current > a {
  background-color: #00000026;
}

.page-menu-sub-menu {
  background-color: #00000026;
  padding: 1px 10px 5px;
  list-style: none;
  display: none;
}

.is-expanded-pagemenu .page-menu-sub-menu {
  width: var(--cnvs-page-submenu-dropdown-width);
  background-color: var(--cnvs-themecolor);
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.page-menu-item:hover > .page-menu-sub-menu {
  display: block;
}

.page-menu-sub-menu .page-menu-item > a {
  border-radius: 14px;
  margin: 5px 0;
  padding-top: 3px;
  padding-bottom: 3px;
}

.is-expanded-pagemenu .page-menu-sub-menu .page-menu-item > a {
  background-color: #00000026;
  border-radius: 0;
  height: auto;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.is-expanded-pagemenu .page-menu-sub-menu li:hover a, .is-expanded-pagemenu .page-menu-sub-menu li.current a {
  background-color: #0000004d;
}

.is-expanded-pagemenu #page-menu.dots-menu {
  z-index: 299;
  width: 24px;
  line-height: 1.5;
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.is-expanded-pagemenu #page-menu.dots-menu #page-menu-wrap {
  box-shadow: none;
  position: relative;
  background-color: #0000 !important;
  top: 0 !important;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-wrap-clone {
  display: none;
}

.is-expanded-pagemenu #page-menu.dots-menu .container {
  padding: 0;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-title {
  display: none;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-nav {
  width: 100%;
  display: block;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-container {
  flex-direction: column;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item {
  width: var(--cnvs-page-dotsmenu-size);
  height: var(--cnvs-page-dotsmenu-size);
  margin: var(--cnvs-page-dotsmenu-gutters) 0;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item > a {
  border-radius: var(--cnvs-page-dotsmenu-rounded);
  width: 100%;
  height: 100%;
  color: var(--cnvs-contrast-600);
  background-color: #0006;
  margin: 0;
  padding: 0;
  position: absolute;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item > a:hover {
  background-color: #000000b3;
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item.current > a {
  background-color: var(--cnvs-themecolor);
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item div {
  background-color: var(--cnvs-themecolor);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  border-radius: .25rem;
  width: auto;
  padding: 6px 14px;
  font-size: .75rem;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

@media (prefers-reduced-motion: reduce) {
  .is-expanded-pagemenu #page-menu.dots-menu .page-menu-item div {
    transition: none;
  }
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item div:after {
  content: "";
  border-top: 7px solid #0000;
  border-bottom: 7px solid #0000;
  border-left: 6px solid var(--cnvs-themecolor);
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: auto;
  right: -5px;
  transform: translateY(-50%);
}

.is-expanded-pagemenu #page-menu.dots-menu .page-menu-item > a:hover div {
  opacity: 1;
  right: 30px;
}

.is-expanded-pagemenu #page-menu.dots-menu .dots-menu-border .page-menu-item {
  width: 12px;
  height: 12px;
}

.is-expanded-pagemenu #page-menu.dots-menu .dots-menu-border .page-menu-item > a {
  border: 3px solid #0006;
  background-color: #0000 !important;
}

.is-expanded-pagemenu #page-menu.dots-menu .dots-menu-border .page-menu-item > a:hover {
  border-color: #000000b3;
}

.is-expanded-pagemenu #page-menu.dots-menu .dots-menu-border .page-menu-item.current > a {
  border-color: var(--cnvs-themecolor);
}

.one-page-arrow {
  z-index: 3;
  text-align: center;
  color: #222;
  width: 32px;
  height: 32px;
  margin-left: -1rem;
  font-size: 2rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
}

@media (width <= 575.98px) {
  .one-page-arrow {
    display: none !important;
  }
}

.side-push-panel {
  overflow-x: hidden;
}

#side-panel {
  --cnvs-side-panel-bg: var(--cnvs-contrast-100);
  top: 0;
  right: calc(-1 * var(--cnvs-side-panel-size));
  z-index: 699;
  width: var(--cnvs-side-panel-size);
  background-color: var(--cnvs-side-panel-bg);
  -webkit-backface-visibility: hidden;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.side-panel-left #side-panel {
  left: calc(-1 * var(--cnvs-side-panel-size));
  right: auto;
}

.side-push-panel.stretched #wrapper, .side-push-panel.stretched .sticky-header .container {
  position: relative;
  right: 0;
}

.side-panel-left.side-push-panel.stretched #wrapper, .side-panel-left.side-push-panel.stretched .sticky-header .container {
  left: 0;
  right: auto;
}

#side-panel .side-panel-wrap {
  width: calc(var(--cnvs-side-panel-size)  + 30px);
  height: 100%;
  padding: var(--cnvs-side-panel-padding);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll;
}

#side-panel .widget {
  margin-top: var(--cnvs-side-panel-widget-padding);
  padding-top: var(--cnvs-side-panel-widget-padding);
  width: calc(var(--cnvs-side-panel-size)  - 80px);
  border-top: 1px solid var(--cnvs-contrast-200);
}

#side-panel .widget:first-child {
  border-top: 0;
  margin: 0;
  padding-top: 0;
}

.side-panel-open #side-panel {
  right: 0;
}

.side-panel-left.side-panel-open #side-panel {
  left: 0;
  right: auto;
}

.side-push-panel.side-panel-open.stretched #wrapper, .side-push-panel.side-panel-open.stretched .sticky-header .container {
  right: var(--cnvs-side-panel-size);
}

.side-push-panel.side-panel-open.stretched.device-xxl .slider-parallax .slider-inner, .side-push-panel.side-panel-open.stretched.device-xl .slider-parallax .slider-inner, .side-push-panel.side-panel-open.stretched.device-lg .slider-parallax .slider-inner {
  left: calc(-1 * var(--cnvs-side-panel-size));
}

.side-panel-left.side-push-panel.side-panel-open.stretched #wrapper, .side-panel-left.side-push-panel.side-panel-open.stretched .sticky-header .container, .side-panel-left.side-push-panel.side-panel-open.stretched .slider-inner {
  left: var(--cnvs-side-panel-size);
  right: auto;
}

#side-panel-trigger {
  cursor: pointer;
  z-index: 11;
  margin-right: 5px;
  display: block;
}

#side-panel-trigger-close a {
  display: none;
}

#side-panel, .side-push-panel.stretched #wrapper, .side-push-panel.stretched #header .container {
  transition: right .4s;
}

@media (prefers-reduced-motion: reduce) {
  #side-panel, .side-push-panel.stretched #wrapper, .side-push-panel.stretched #header .container {
    transition: none;
  }
}

.side-panel-left #side-panel, .side-panel-left.side-push-panel.stretched #wrapper, .side-panel-left.side-push-panel.stretched #header .container, .side-push-panel.stretched .slider-inner {
  transition: left .4s;
}

@media (prefers-reduced-motion: reduce) {
  .side-panel-left #side-panel, .side-panel-left.side-push-panel.stretched #wrapper, .side-panel-left.side-push-panel.stretched #header .container, .side-push-panel.stretched .slider-inner {
    transition: none;
  }
}

.body-overlay {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  background-color: #0009;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  position: fixed;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .body-overlay {
    transition: none;
  }
}

.side-panel-open .body-overlay {
  opacity: 1;
  z-index: 599;
  pointer-events: auto;
}

.side-panel-open:not(.device-xs):not(.device-sm):not(.device-md) .body-overlay:hover {
  cursor: url("close.c14de864.png") 15 15, default;
}

:root, .not-dark {
  --cnvs-slider-caption-font-color: var(--cnvs-contrast-800);
  --cnvs-slider-arrows-bg-color: #0000004d;
  --cnvs-slider-arrows-bg-hover-color: #0009;
  --cnvs-slider-arrow-color: var(--bs-gray-100);
  --cnvs-owl-dots-slider-color: #fff;
}

:root {
  --cnvs-fslider-dots-position-top: 14px;
  --cnvs-fslider-dots-position-right: 10px;
  --cnvs-fslider-dots-size: .625rem;
  --cnvs-fslider-dots-border-color: #fff;
  --cnvs-fslider-dots-border-radius: 50%;
  --cnvs-fslider-thumbs-gutters: 2px;
  --cnvs-fslider-thumbs-width: 100px;
  --cnvs-canvas-slider-dots-size: .625rem;
  --cnvs-slider-pagination-gutters: 4px;
  --cnvs-slider-pagination-rounded: 50%;
  --cnvs-swiper-dots-position-bottom: 20px;
  --cnvs-slider-caption-width: 550px;
  --cnvs-slider-caption-font-size: 1.375rem;
  --cnvs-slider-caption-font-weight: 300;
  --cnvs-slider-caption-transition: top .3s ease;
  --cnvs-slider-caption-p-size: 1.5rem;
  --cnvs-slider-caption-offset: 50px;
  --cnvs-slider-caption-bg-light: #fffc;
  --cnvs-slider-caption-bg-dark: #0009;
  --cnvs-slider-caption-bg-radius: 2px;
  --cnvs-slider-caption-bg-padding: 5px 12px;
  --cnvs-slider-arrows-size: 52px;
  --cnvs-slider-arrows-sm-size: calc(var(--cnvs-slider-arrows-size)  - 20px);
  --cnvs-slider-arrows-border-radius: .25rem;
  --cnvs-slider-arrow-sm-offset: 20px;
  --cnvs-owl-dots-slider-position: top auto bottom 15px;
}

.fslider, .fslider .flexslider, .fslider .slider-wrap, .fslider .slide, .fslider .slide > a, .fslider .slide > img, .fslider .slide > a > img {
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  overflow: hidden;
}

.fslider.h-100 .flexslider, .fslider.h-100 .flex-viewport, .fslider.h-100 .slider-wrap, .fslider.h-100 .slide, .fslider.h-100 .slide > a, .fslider.h-100 .slide > a > img {
  height: 100% !important;
}

.fslider.h-100 .slide > a > img {
  object-fit: cover;
}

.fslider {
  min-height: 32px;
}

.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus {
  border: none;
  outline: none;
}

.slider-wrap, .flex-control-nav, .flex-direction-nav {
  border: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.flexslider {
  margin: 0;
  padding: 0;
  position: relative;
}

.flexslider .slider-wrap > .slide {
  -webkit-backface-visibility: hidden;
  display: none;
}

.flexslider .slider-wrap img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.slider-wrap:after {
  content: " ";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

html[xmlns] .slider-wrap {
  display: block;
}

* html .slider-wrap {
  height: 1%;
}

.no-js .slider-wrap > .slide:first-child {
  display: block;
}

.flex-viewport {
  max-height: 2000px;
  transition: all 1s;
}

@media (prefers-reduced-motion: reduce) {
  .flex-viewport {
    transition: none;
  }
}

.slider-element {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.slider-parallax {
  position: relative;
}

.slider-parallax, .slider-parallax .swiper {
  visibility: visible;
  -webkit-backface-visibility: hidden;
}

.slider-inner {
  opacity: 1;
  visibility: visible;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.slider-parallax .slider-inner {
  position: fixed;
}

.h-auto:not(.slider-parallax) .slider-inner, .h-auto .swiper, .min-vh-0:not(.slider-parallax) .slider-inner, .min-vh-0 .swiper {
  height: auto !important;
  position: relative !important;
}

.slider-parallax-invisible .slider-inner {
  opacity: 0;
  visibility: hidden;
}

@media (width >= 992px) {
  .h-auto .swiper-wrapper, .min-vh-0 .swiper-wrapper {
    height: 75vh;
  }
}

.slider-inner.slider-inner-relative {
  height: auto !important;
  position: relative !important;
}

.device-touch .slider-parallax .slider-inner {
  position: relative;
}

body:not(.side-header) .slider-parallax .slider-inner {
  left: 0;
}

body:not(.stretched) .slider-parallax .slider-inner {
  left: auto;
}

.slider-parallax .slider-inner, .slider-parallax .slider-caption {
  will-change: transform;
  backface-visibility: hidden;
  transition: transform cubic-bezier(.14, .15, .13, .99);
}

@media (prefers-reduced-motion: reduce) {
  .slider-parallax .slider-inner, .slider-parallax .slider-caption {
    transition: none;
  }
}

.scroll-detect .slider-inner {
  transform: translate3d(0px, calc(400px * var(--cnvs-scroll-end)), 0px);
  backface-visibility: hidden;
  will-change: transform;
}

.scroll-detect .slider-caption {
  transform: translate3d(0px, calc(100px * var(--cnvs-scroll-end)), 0px);
  backface-visibility: hidden;
  opacity: calc(1 * var(--cnvs-scroll-ratio));
  will-change: transform;
}

@media (width <= 991.98px) {
  #slider {
    flex-direction: column;
    justify-content: center;
    display: flex !important;
  }

  #slider .slider-inner {
    flex-grow: 1;
    align-items: center;
    display: flex;
    position: relative;
  }
}

.boxed-slider {
  padding-top: 40px;
}

.swiper_wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.full-screen .swiper_wrapper {
  height: auto;
}

.swiper_wrapper .swiper {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
}

.swiper_wrapper .swiper-slide {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.swiper_wrapper .swiper-slide [data-caption-animate] {
  -webkit-backface-visibility: hidden;
}

.swiper-slide-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-element .video-wrap, .section .video-wrap, .swiper-slide .yt-bg-player, .section .yt-bg-player, .swiper-slide-bg {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.yt-bg-player .inlinePlayButton, .yt-bg-player .loading {
  display: none !important;
}

.slider-element .container, .slider-element .container-fluid, .slider-element .container-sm, .slider-element .container-md, .slider-element .container-lg, .slider-element .container-xl, .slider-element .container-xxl {
  height: 100%;
  position: relative;
}

.slider-element.canvas-slider .swiper-slide img {
  width: 100%;
}

.slider-element.canvas-slider a {
  height: 100%;
  display: block;
}

.swiper-pagination {
  z-index: 20;
  text-align: center;
  width: 100%;
  margin: 0;
  line-height: 1;
  position: absolute;
  top: auto;
  bottom: var(--cnvs-swiper-dots-position-bottom) !important;
}

.swiper-pagination span {
  cursor: pointer;
  width: var(--cnvs-canvas-slider-dots-size);
  height: var(--cnvs-canvas-slider-dots-size);
  margin: 0 var(--cnvs-slider-pagination-gutters);
  opacity: 1;
  border: 1px solid var(--cnvs-slider-arrow-color);
  border-radius: var(--cnvs-slider-pagination-rounded);
  background-color: #0000;
  transition: all .3s;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .swiper-pagination span {
    transition: none;
  }
}

.swiper-pagination span:hover, .swiper-pagination span.swiper-pagination-bullet-active {
  background-color: var(--cnvs-slider-arrow-color) !important;
}

.video-wrap {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.video-wrap video {
  z-index: 1;
  opacity: 1;
  width: 100%;
  transition: opacity .3s 1s;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .video-wrap video {
    transition: none;
  }
}

body:not(.has-plugin-html5video) .video-wrap video {
  opacity: 0;
}

.video-overlay, .video-placeholder {
  z-index: 5;
  -webkit-backface-visibility: hidden;
  background: url("grid.f85ef1c4.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.video-placeholder {
  z-index: 4;
  background-repeat: no-repeat !important;
}

.slider-caption, .flex-caption {
  --cnvs-slider-caption-font-color: var(--cnvs-contrast-800);
  z-index: 20;
  height: 100%;
  max-width: var(--cnvs-slider-caption-width);
  color: var(--cnvs-slider-caption-font-color);
  font-size: var(--cnvs-slider-caption-font-weight);
  font-weight: var(--cnvs-slider-caption-font-weight);
  flex-flow: column;
  justify-content: center;
  transition: top .3s;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .slider-caption, .flex-caption {
    transition: none;
  }
}

.slider-caption h2, .slider-caption .h2 {
  color: var(--cnvs-slider-caption-font-color);
  text-shadow: none;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 0;
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1;
}

@media (width <= 767.98px) {
  .slider-caption h2, .slider-caption .h2 {
    font-size: 5.5vw;
  }
}

.slider-caption p {
  font-size: var(--cnvs-slider-caption-p-size);
  margin-top: 2rem;
  margin-bottom: .75rem;
}

@media (width <= 767.98px) {
  .slider-caption p {
    font-size: 2.6vw;
  }
}

.slider-caption.slider-caption-right {
  margin-left: auto;
}

.slider-caption.slider-caption-center {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  left: 0;
  right: 0;
}

.slider-caption-bg {
  padding: var(--cnvs-slider-caption-bg-padding);
  border-radius: var(--cnvs-slider-caption-bg-radius);
  background-color: var(--cnvs-slider-caption-bg-dark);
  top: auto;
  bottom: var(--cnvs-slider-caption-offset);
  left: var(--cnvs-slider-caption-offset);
}

.slider-caption-bg.slider-caption-bg-light {
  background-color: var(--cnvs-slider-caption-bg-light);
  color: var(--cnvs-contrast-900);
  text-shadow: none;
  font-weight: 400;
}

.slider-caption-top-left {
  top: var(--cnvs-slider-caption-offset);
  bottom: auto;
}

.slider-caption-top-right {
  top: var(--cnvs-slider-caption-offset);
  bottom: auto;
  left: auto;
  right: var(--cnvs-slider-caption-offset);
}

.slider-caption-bottom-right {
  left: auto;
  right: var(--cnvs-slider-caption-offset);
}

.slider-element .owl-carousel {
  margin: 0;
}

.slider-arrow-left, .slider-arrow-right, .flex-prev, .flex-next, .slider-arrow-top-sm, .slider-arrow-bottom-sm {
  cursor: pointer;
  z-index: 10;
  background-color: var(--cnvs-slider-arrows-bg-color);
  width: var(--cnvs-slider-arrows-size);
  height: var(--cnvs-slider-arrows-size);
  border-radius: 0 var(--cnvs-slider-arrows-border-radius) var(--cnvs-slider-arrows-border-radius) 0;
  border: 0;
  transition: background-color .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (prefers-reduced-motion: reduce) {
  .slider-arrow-left, .slider-arrow-right, .flex-prev, .flex-next, .slider-arrow-top-sm, .slider-arrow-bottom-sm {
    transition: none;
  }
}

.slider-arrow-top-sm, .slider-arrow-bottom-sm {
  top: auto;
  bottom: 57px;
  left: auto;
  right: var(--cnvs-slider-arrow-sm-offset);
  width: var(--cnvs-slider-arrows-sm-size);
  height: var(--cnvs-slider-arrows-sm-size);
  border-radius: var(--cnvs-slider-arrows-border-radius) var(--cnvs-slider-arrows-border-radius) 0 0;
  margin: 0;
}

.slider-arrow-bottom-sm {
  bottom: var(--cnvs-slider-arrow-sm-offset);
  border-radius: 0 0 var(--cnvs-slider-arrows-border-radius) var(--cnvs-slider-arrows-border-radius);
}

.fslider[data-thumbs="true"] .flex-prev, .fslider[data-thumbs="true"] .flex-next {
  margin-top: calc(-1 * var(--cnvs-slider-arrow-sm-offset));
}

.slider-arrow-right, .slider-element .owl-next, .flex-next {
  border-radius: var(--cnvs-slider-arrows-border-radius) 0 0 var(--cnvs-slider-arrows-border-radius);
  left: auto;
  right: 0;
}

.slider-arrow-left i, .slider-arrow-right i, .flex-prev i, .flex-next i, .slider-arrow-top-sm i, .slider-arrow-bottom-sm i {
  line-height: var(--cnvs-slider-arrows-size);
  width: 100%;
  height: 100%;
  color: var(--cnvs-slider-arrow-color);
  font-size: calc(var(--cnvs-slider-arrows-size) / 1.75);
  text-align: center;
  transition: color .3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .slider-arrow-left i, .slider-arrow-right i, .flex-prev i, .flex-next i, .slider-arrow-top-sm i, .slider-arrow-bottom-sm i {
    transition: none;
  }
}

.slider-arrow-top-sm i, .slider-arrow-bottom-sm i {
  width: var(--cnvs-slider-arrows-sm-size);
  height: var(--cnvs-slider-arrows-sm-size);
  line-height: calc(var(--cnvs-slider-arrows-sm-size)  - 2px);
  color: var(--cnvs-owl-dots-slider-color);
  margin: 0;
  font-size: 18px;
}

.slider-element .owl-prev i {
  margin-left: 0;
}

.slider-element .owl-next i {
  margin-right: 4px;
}

.slider-arrow-left:hover, .slider-arrow-right:hover, .flex-prev:hover, .flex-next:hover, .slider-arrow-top-sm:hover, .slider-arrow-bottom-sm:hover {
  background-color: var(--cnvs-slider-arrows-bg-hover-color) !important;
}

.slide-number {
  opacity: 0;
  z-index: 20;
  text-align: center;
  width: 36px;
  height: 40px;
  color: rgba(var(--cnvs-invert-contrast-rgb), .8);
  font-size: 1.125rem;
  font-weight: 300;
  transition: all .3s linear;
  position: absolute;
  top: auto;
  bottom: 20px;
  right: 20px;
}

@media (prefers-reduced-motion: reduce) {
  .slide-number {
    transition: none;
  }
}

.slider-element:hover .slide-number {
  opacity: 1;
}

.slide-number-current, .slide-number-total {
  line-height: 1;
  position: absolute;
  top: 5px;
  left: 0;
}

.slide-number-total {
  inset: auto 0 5px auto;
}

.slide-number span {
  font-size: 32px;
  line-height: 40px;
  display: block;
}

.slider-element .owl-dots {
  z-index: 20;
  width: 100%;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 15px;
}

.slider-element .owl-dots button {
  width: var(--cnvs-canvas-slider-dots-size);
  height: var(--cnvs-canvas-slider-dots-size);
  margin: 0 var(--cnvs-slider-pagination-gutters);
  opacity: 1 !important;
  border: var(--cnvs-owl-nav-border) !important;
  background-color: #0000 !important;
}

.slider-element .owl-dots .owl-dot.active, .slider-element .owl-dots .owl-dot:hover {
  background-color: var(--cnvs-owl-nav-background) !important;
}

.nav-offset .flex-prev, .nav-offset .flex-next {
  border-radius: 3px;
}

.nav-offset .flex-prev {
  left: 20px;
}

.nav-offset .flex-next {
  right: 20px;
}

.nav-circle .flex-prev, .nav-circle .flex-next {
  border-radius: 50%;
}

.nav-solid .flex-prev, .nav-solid .flex-next {
  opacity: 1;
  background-color: var(--cnvs-slider-arrow-color);
}

.nav-solid .flex-prev:hover, .nav-solid .flex-next:hover {
  background-color: #333 !important;
}

.nav-solid .flex-prev:not(:hover) i, .nav-solid .flex-next:not(:hover) i {
  color: #444;
  text-shadow: none;
}

.nav-size-sm .flex-prev, .nav-size-sm .flex-next {
  width: var(--cnvs-slider-arrows-sm-size);
  height: var(--cnvs-slider-arrows-sm-size);
}

.nav-size-sm .flex-prev i, .nav-size-sm .flex-next i {
  line-height: var(--cnvs-slider-arrows-sm-size);
  font-size: 1.5rem;
}

.fslider[class*="nav-pos-"] .flex-prev, .fslider[class*="nav-pos-"] .flex-next {
  margin: 0 5px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transform: none;
}

.fslider.nav-size-sm[class*="nav-pos-"] .flex-prev, .fslider.nav-size-sm[class*="nav-pos-"] .flex-next {
  margin: 0 3px;
}

.fslider[class*="nav-pos-"] .flex-direction-nav {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
}

.nav-pos-top .flex-direction-nav, .nav-pos-top-left .flex-direction-nav, .nav-pos-top-right .flex-direction-nav {
  top: 20px;
  left: 0;
}

.nav-pos-bottom .flex-direction-nav, .nav-pos-bottom-left .flex-direction-nav, .nav-pos-bottom-right .flex-direction-nav {
  top: auto;
  bottom: 20px;
}

.nav-pos-top-left .flex-direction-nav, .nav-pos-bottom-left .flex-direction-nav {
  left: 16px;
  width: auto !important;
}

.nav-pos-top-right .flex-direction-nav, .nav-pos-bottom-right .flex-direction-nav {
  left: auto;
  right: 16px;
  width: auto !important;
}

.fslider[data-animation="fade"][data-thumbs="true"] .flexslider, .fslider.testimonial[data-animation="fade"] .flexslider {
  height: auto !important;
}

.flex-control-nav {
  z-index: 10;
  text-align: center;
  width: auto;
  top: var(--cnvs-fslider-dots-position-top);
  right: var(--cnvs-fslider-dots-position-right);
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: absolute;
}

.flex-control-nav li {
  margin: 0 var(--cnvs-slider-pagination-gutters);
  width: var(--cnvs-fslider-dots-size);
  height: var(--cnvs-fslider-dots-size);
  display: block;
}

.flex-control-nav li a {
  cursor: pointer;
  text-indent: -9999px;
  border: 1px solid var(--cnvs-fslider-dots-border-color);
  color: #0000;
  border-radius: var(--cnvs-fslider-dots-border-radius);
  transition: all .3s ease-in-out;
  display: block;
  width: 100% !important;
  height: 100% !important;
}

@media (prefers-reduced-motion: reduce) {
  .flex-control-nav li a {
    transition: none;
  }
}

.flex-control-nav li:hover a, .flex-control-nav li a.flex-active {
  background-color: var(--cnvs-fslider-dots-border-color);
}

.control-solid .flex-control-nav li a {
  background-color: var(--cnvs-fslider-dots-border-color);
  border: none;
}

.control-solid .flex-control-nav li:hover a, .control-solid .flex-control-nav li a.flex-active {
  background-color: var(--cnvs-themecolor);
}

.control-square .flex-control-nav li a {
  border-radius: 3px;
}

.control-diamond .flex-control-nav li a {
  border-radius: 3px;
  transform: rotate(45deg);
}

.control-offset-lg .flex-control-nav {
  top: calc(var(--cnvs-fslider-dots-position-top10));
  right: calc(var(--cnvs-fslider-dots-position-right10));
}

.control-pos-top-left .flex-control-nav, .control-pos-bottom-left .flex-control-nav {
  left: var(--cnvs-fslider-dots-position-right);
  right: auto;
}

.control-offset-lg.control-pos-top-left .flex-control-nav, .control-offset-lg.control-pos-bottom-left .flex-control-nav {
  left: calc(var(--cnvs-fslider-dots-position-right10));
}

.control-pos-bottom-right .flex-control-nav, .control-pos-bottom-left .flex-control-nav, .control-pos-bottom .flex-control-nav, .control-pos-bottom .flex-control-nav {
  top: auto;
  bottom: var(--cnvs-fslider-dots-position-top);
}

.control-offset-lg.control-pos-bottom-right .flex-control-nav, .control-offset-lg.control-pos-bottom-left .flex-control-nav, .control-offset-lg.control-pos-bottom .flex-control-nav, .control-offset-lg.control-pos-bottom .flex-control-nav {
  bottom: calc(var(--cnvs-fslider-dots-position-top10));
}

.control-pos-bottom .flex-control-nav, .control-pos-bottom .flex-control-nav, .control-pos-top .flex-control-nav, .control-pos-top .flex-control-nav {
  width: 100%;
  right: 0;
}

.control-size-lg .flex-control-nav li {
  width: .9325rem;
  height: .9325rem;
  margin: 0 5px;
}

.flex-control-nav.flex-control-thumbs {
  margin: var(--cnvs-fslider-thumbs-gutters) calc(-1 * var(--cnvs-fslider-thumbs-gutters)) calc(-1 * var(--cnvs-fslider-thumbs-gutters)) 0;
  justify-content: left;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.flex-control-nav.flex-control-thumbs li {
  margin: 0 var(--cnvs-fslider-thumbs-gutters) var(--cnvs-fslider-thumbs-gutters) 0;
}

.flex-control-nav.flex-control-thumbs li img {
  cursor: pointer;
  text-indent: -9999px;
  opacity: .5;
  border: 0;
  border-radius: 0;
  margin: 0;
  transition: all .3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .flex-control-nav.flex-control-thumbs li img {
    transition: none;
  }
}

.flex-control-nav.flex-control-thumbs li, .flex-control-nav.flex-control-thumbs li img {
  display: block;
  width: var(--cnvs-fslider-thumbs-width) !important;
  height: auto !important;
}

.flex-control-nav.flex-control-thumbs li:hover img, .flex-control-nav.flex-control-thumbs li img.flex-active {
  opacity: 1;
  border-width: 0;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs {
  margin: var(--cnvs-fslider-thumbs-gutters) calc(-1 * var(--cnvs-fslider-thumbs-gutters)) calc(-1 * var(--cnvs-fslider-thumbs-gutters)) 0;
  flex-wrap: wrap;
  height: auto;
  display: flex;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
  max-width: 100%;
  padding: 0 var(--cnvs-fslider-thumbs-gutters) var(--cnvs-fslider-thumbs-gutters) 0;
  flex: none;
  margin: 0;
  width: 25% !important;
  height: auto !important;
}

.fslider.flex-thumb-grid.grid-3 .flex-control-nav.flex-control-thumbs li {
  width: 33.3333% !important;
}

.fslider.flex-thumb-grid.grid-5 .flex-control-nav.flex-control-thumbs li {
  width: 20% !important;
}

.fslider.flex-thumb-grid.grid-6 .flex-control-nav.flex-control-thumbs li {
  width: 16.6667% !important;
}

.fslider.flex-thumb-grid.grid-8 .flex-control-nav.flex-control-thumbs li {
  width: 12.5% !important;
}

.fslider.flex-thumb-grid.grid-10 .flex-control-nav.flex-control-thumbs li {
  width: 10% !important;
}

.fslider.flex-thumb-grid.grid-12 .flex-control-nav.flex-control-thumbs li {
  width: 8.33333% !important;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li img {
  width: 100% !important;
  height: auto !important;
}

:root, .not-dark {
  --cnvs-page-title-bg: var(--cnvs-contrast-bg-offset);
  --cnvs-page-title-color: var(--cnvs-contrast-900);
  --cnvs-page-title-border-color: rgba(var(--cnvs-contrast-rgb), .1);
}

#page-title, .page-title {
  --cnvs-page-title-padding: 4rem;
  --cnvs-page-title-parallax-padding: 6.25rem;
  --cnvs-page-title-mini-padding: 1.5rem;
  --cnvs-page-title-bg: var(--cnvs-contrast-bg-offset);
  --cnvs-page-title-color: var(--cnvs-contrast-900);
  --cnvs-page-title-font-size: 2.25rem;
  --cnvs-page-title-font-weight: 500;
  --cnvs-page-title-spacing: 0;
  --cnvs-page-title-subtitle-size: 1.125rem;
  --cnvs-page-title-parallax-font-size: calc(2rem + 1vw);
  --cnvs-page-title-parallax-subtitle-size: 1.25rem;
  --cnvs-page-title-mini-size: 1.25rem;
  --cnvs-page-title-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-page-title-center-mx-width: 48rem;
  padding: var(--cnvs-page-title-padding) 0;
  background-color: var(--cnvs-page-title-bg);
  border-bottom: 1px solid var(--cnvs-page-title-border-color);
  position: relative;
}

@media (width <= 767.98px) {
  #page-title, .page-title {
    --cnvs-page-title-padding: 3rem;
    text-align: center;
  }
}

.page-title-row {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width >= 768px) {
  .page-title-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
  }
}

.page-title-content {
  text-align: center;
}

@media (width >= 768px) {
  .page-title-content {
    text-align: left;
  }
}

.page-title-content h1, .page-title-content .h1 {
  line-height: 1;
  font-weight: var(--cnvs-page-title-font-weight);
  color: var(--cnvs-page-title-color);
  font-size: var(--cnvs-page-title-font-size);
  letter-spacing: var(--cnvs-page-title-spacing);
  margin: 0;
  padding: 0;
}

@media (width <= 767.98px) {
  .page-title-content h1, .page-title-content .h1 {
    font-size: calc(1.5rem + 1.25vw);
    line-height: 1.5;
  }
}

.page-title-content span {
  color: var(--cnvs-contrast-600);
  font-size: var(--cnvs-page-title-subtitle-size);
  margin-top: 1rem;
  display: block;
}

.breadcrumb {
  justify-content: center;
  margin: 1.5rem 0 0;
  font-size: 90%;
  position: relative;
}

.breadcrumb a {
  color: var(--cnvs-contrast-700);
}

.breadcrumb a:hover {
  color: var(--cnvs-themecolor);
}

.breadcrumb i {
  width: auto !important;
}

@media (width >= 768px) {
  .breadcrumb {
    justify-content: flex-start;
    margin: 0;
  }

  .page-title-right .page-title-row {
    flex-direction: row-reverse;
  }

  .page-title-right .page-title-content {
    text-align: right;
  }
}

.page-title-center .page-title-row {
  flex-direction: column;
  align-items: center;
}

.page-title-center .page-title-content {
  text-align: center;
}

.page-title-center .breadcrumb {
  margin-top: 2rem;
}

.page-title-pattern {
  background-image: url("pattern.2757cea8.png");
  background-repeat: repeat;
  background-attachment: fixed;
}

.page-title-parallax {
  --cnvs-page-title-padding: var(--cnvs-page-title-parallax-padding);
  --cnvs-page-title-font-size: var(--cnvs-page-title-parallax-font-size);
  --cnvs-page-title-font-weight: 500;
}

.page-title-parallax .container {
  z-index: 2;
  position: relative;
}

.page-title-parallax span {
  font-size: var(--cnvs-page-title-parallax-subtitle-size);
}

@media (width <= 767.98px) {
  .page-title-parallax span {
    font-size: 1rem;
  }
}

.page-title-parallax .breadcrumb {
  font-size: .875rem;
}

.page-title-mini {
  --cnvs-page-title-color: var(--cnvs-contrast-900);
  --cnvs-page-title-padding: var(--cnvs-page-title-mini-padding);
  --cnvs-page-title-font-size: var(--cnvs-page-title-mini-size);
  --cnvs-page-title-font-weight: 600;
}

@media (width <= 767.98px) {
  .page-title-mini {
    --cnvs-page-title-padding: 2rem;
  }

  .page-title-mini h1, .page-title-mini .h1 {
    font-size: var(--cnvs-page-title-mini-size);
  }

  .page-title-mini .breadcrumb {
    margin-top: 1rem;
  }
}

.page-title-mini span {
  display: none;
}

.page-title-video {
  background: none;
  position: relative;
  overflow: hidden;
}

.page-title-video .container {
  z-index: 3;
  position: relative;
}

.page-title-video .video-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page-title-video .video-wrap video {
  width: 100%;
}

@media (width <= 767.98px) {
  #page-title #portfolio-navigation, #portfolio-ajax-title #portfolio-navigation {
    margin: 1rem auto 0;
    position: relative;
    top: 0;
    left: 0;
  }
}

#content {
  background-color: var(--cnvs-body-bg);
  position: relative;
}

.content-wrap {
  padding: var(--cnvs-content-padding) 0;
  position: relative;
  overflow: hidden;
}

.content-wrap .container {
  position: relative;
}

.content-wrap p {
  line-height: var(--cnvs-line-height-content);
}

.grid-container, .grid-inner {
  will-change: transform;
  position: relative;
  overflow: hidden;
}

.grid-inner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.grid-container {
  transition: height .4s;
}

@media (prefers-reduced-motion: reduce) {
  .grid-container {
    transition: none;
  }
}

:root, .not-dark {
  --cnvs-grid-filter-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-portfolio-meta-color: var(--cnvs-contrast-600);
  --cnvs-portfolio-meta-row-1-color: var(--cnvs-heading-color);
}

.grid-filter-wrap, .grid-filter {
  --cnvs-grid-filter-mb: 3.5rem;
  --cnvs-grid-filter-border: 1px;
  --cnvs-grid-filter-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-grid-filter-border-radius: .25rem;
  --cnvs-grid-filter-item-padding-x: 1.25rem;
  --cnvs-grid-filter-item-padding-y: .75rem;
  --cnvs-grid-filter-item-font-size: .9375rem;
  --cnvs-grid-filter-col-sm: 3;
  --cnvs-grid-filter-col-xs: 2;
  --cnvs-grid-filter-styles-m: .75rem;
  --cnvs-grid-filter-styles-radius: 1.25rem;
  --cnvs-grid-shuffle-size: 2.5rem;
}

.grid-filter {
  margin-bottom: var(--cnvs-grid-filter-mb);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .grid-filter {
    border: var(--cnvs-grid-filter-border) solid var(--cnvs-grid-filter-border-color);
    border-radius: var(--cnvs-grid-filter-border-radius);
  }
}

@media (width <= 767.98px) {
  .grid-filter {
    width: 100%;
  }
}

.grid-filter-wrap {
  justify-content: space-between;
  display: flex;
}

.grid-filter li {
  width: calc(100% / var(--cnvs-grid-filter-col-xs));
  position: relative;
}

@media (width >= 576px) {
  .grid-filter li {
    width: calc(100% / var(--cnvs-grid-filter-col-sm));
  }
}

@media (width >= 768px) {
  .grid-filter li {
    width: auto;
  }
}

.grid-filter li a {
  padding: var(--cnvs-grid-filter-item-padding-y) var(--cnvs-grid-filter-item-padding-x);
  font-size: var(--cnvs-grid-filter-item-font-size);
  color: var(--cnvs-contrast-600);
  text-align: center;
  border-radius: 2px;
  display: block;
  position: relative;
}

@media (width >= 768px) {
  .grid-filter li a {
    border-left: var(--cnvs-grid-filter-border) solid var(--cnvs-grid-filter-border-color);
    border-radius: 0;
  }

  .bothsidebar .grid-filter li a {
    padding: 12px 14px;
  }
}

.grid-filter li a:hover {
  color: var(--cnvs-themecolor);
}

.grid-filter li.activeFilter a {
  color: #fff;
  background-color: var(--cnvs-themecolor);
  margin: 0;
  font-weight: 600;
}

@media (width >= 768px) {
  .grid-filter li.activeFilter a {
    margin: -1px 0;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .bothsidebar .grid-filter li.activeFilter a {
    padding: 13px 18px;
  }

  .grid-filter li:first-child a {
    border-radius: var(--cnvs-grid-filter-border-radius) 0 0 var(--cnvs-grid-filter-border-radius);
    border-left: none;
  }

  .grid-filter li:last-child a {
    border-radius: 0 var(--cnvs-grid-filter-border-radius) var(--cnvs-grid-filter-border-radius) 0;
  }
}

.grid-filter.style-2, .grid-filter.style-3, .grid-filter.style-4, .grid-filter.style-5, .grid-filter.style-6 {
  border: none;
  border-radius: 0;
  margin-bottom: 2.75rem;
}

.grid-filter.style-2 li, .grid-filter.style-3 li, .grid-filter.style-4 li, .grid-filter.style-5 li, .grid-filter.style-6 li {
  margin-bottom: var(--cnvs-grid-filter-styles-m);
}

.grid-filter.style-2 li a, .grid-filter.style-3 li a, .grid-filter.style-4 li a, .grid-filter.style-5 li a, .grid-filter.style-6 li a {
  border-radius: var(--cnvs-grid-filter-styles-radius);
  background-color: #0000;
  border: none;
  margin: 0;
  padding-top: 9px;
  padding-bottom: 9px;
}

.grid-filter.style-2 li.activeFilter a {
  background-color: var(--cnvs-themecolor);
  color: #fff !important;
}

@media (width >= 768px) {
  .grid-filter.style-2 li:not(:first-child), .grid-filter.style-3 li:not(:first-child) {
    margin-left: var(--cnvs-grid-filter-styles-m);
  }
}

.grid-filter.style-3 li a {
  border: var(--cnvs-grid-filter-border) solid transparent;
}

.grid-filter.style-3 li.activeFilter a {
  border-color: var(--cnvs-themecolor);
  background-color: #0000;
  color: var(--cnvs-themecolor) !important;
}

@media (width >= 768px) {
  .grid-filter.style-4 li:not(:first-child) {
    margin-left: 2.25rem;
  }
}

.grid-filter.style-4 li a {
  border-radius: 0;
  padding: .75rem 0;
}

.grid-filter.style-4 li a:after {
  content: "";
  width: 0;
  height: 2px;
  transition: all .3s;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
}

@media (prefers-reduced-motion: reduce) {
  .grid-filter.style-4 li a:after {
    transition: none;
  }
}

.grid-filter.style-4 li.activeFilter a {
  color: var(--cnvs-contrast-900) !important;
}

.grid-filter.style-4 li.activeFilter a:after {
  background-color: var(--cnvs-themecolor);
  width: 100%;
  left: 0%;
}

@media (width >= 768px) {
  .grid-filter.style-5 li:not(:first-child) {
    margin-left: 2.25rem;
  }
}

.grid-filter.style-5 li a {
  border-radius: 0;
  padding: .75rem 0 1rem;
}

.grid-filter.style-5 li a:after {
  content: "";
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  transition: all .3s;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)scale(0);
}

@media (prefers-reduced-motion: reduce) {
  .grid-filter.style-5 li a:after {
    transition: none;
  }
}

.grid-filter.style-5 li.activeFilter a {
  color: var(--cnvs-contrast-900) !important;
}

.grid-filter.style-5 li.activeFilter a:after {
  background-color: var(--cnvs-themecolor);
  transform: translateX(-50%)scale(1);
}

.grid-shuffle {
  width: var(--cnvs-grid-shuffle-size);
  height: var(--cnvs-grid-shuffle-size);
  border: var(--cnvs-grid-filter-border) solid var(--cnvs-grid-filter-border-color);
  font-size: calc(var(--cnvs-grid-shuffle-size) / 2.625);
  text-align: center;
  line-height: calc(var(--cnvs-grid-shuffle-size)  - var(--cnvs-grid-filter-border)  - var(--cnvs-grid-filter-border));
  color: var(--cnvs-contrast-900);
  cursor: pointer;
  transition: all .2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .grid-shuffle {
    transition: none;
  }
}

.grid-shuffle:hover {
  background-color: var(--cnvs-themecolor);
  color: #fff;
}

.portfolio, [class*="portfolio-"] {
  --cnvs-portfolio-desc-padding-x: .375rem;
  --cnvs-portfolio-desc-padding-y: 1.25rem;
  --cnvs-portfolio-desc-title-size: 1.25rem;
  --cnvs-portfolio-modal-padding: 2.5rem;
  --cnvs-portfolio-ajax-height: 96rem;
  --cnvs-portfolio-meta-font-size: .875rem;
  --cnvs-portfolio-meta-color: var(--cnvs-contrast-600);
  --cnvs-portfolio-meta-row-1: 150px;
  --cnvs-portfolio-meta-row-1-color: var(--cnvs-heading-color);
  --cnvs-portfolio-meta-icon: .875rem;
  --cnvs-portfolio-navigation-size: 1.5rem;
}

.portfolio {
  transition: height .4s linear;
  position: relative;
}

.portfolio-item {
  position: relative;
}

.portfolio-reveal .portfolio-item {
  overflow: hidden;
}

.portfolio-item .portfolio-image {
  position: relative;
  overflow: hidden;
}

.portfolio-item .portfolio-image, .portfolio-item .portfolio-image > a, .portfolio-item .portfolio-image img {
  width: 100%;
  height: auto;
  display: block;
}

.portfolio-rounded .portfolio-image {
  border-radius: calc(var(--cnvs-grid-filter-border-radius)  + 1);
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .portfolio {
    transition: none;
  }
}

@media (width >= 768px) {
  .portfolio-reveal .portfolio-image img, .portfolio-reveal .bg-overlay {
    transition: all .3s ease-in-out;
  }
}

@media (width >= 768px) and (prefers-reduced-motion: reduce) {
  .portfolio-reveal .portfolio-image img, .portfolio-reveal .bg-overlay {
    transition: none;
  }
}

.portfolio-desc {
  z-index: 6;
  padding: var(--cnvs-portfolio-desc-padding-y) var(--cnvs-portfolio-desc-padding-x);
  position: relative;
}

.portfolio-desc h3, .portfolio-desc .h3 {
  font-size: var(--cnvs-portfolio-desc-title-size);
  margin: 0;
  padding: 0;
}

.col-md-9 .portfolio-desc h3, .col-md-9 .portfolio-desc .h3 {
  font-size: calc(var(--cnvs-portfolio-desc-title-size) / 1.1111);
}

.portfolio-desc h3 a, .portfolio-desc .h3 a {
  color: var(--cnvs-contrast-900);
}

.portfolio-desc h3 a:hover, .portfolio-desc .h3 a:hover {
  color: var(--cnvs-themecolor);
}

.portfolio-desc span {
  display: block;
}

.col-md-9 .portfolio-desc span {
  font-size: calc(var(--cnvs-portfolio-desc-title-size) / 1.428);
}

.portfolio-desc span, .portfolio-desc span a {
  color: var(--cnvs-contrast-600);
}

.portfolio-desc span a:hover {
  color: var(--cnvs-contrast-900);
}

.desc-lg .portfolio-desc {
  padding: 20px 8px;
}

.desc-lg .portfolio-desc h3, .desc-lg .portfolio-desc .h3 {
  font-size: calc(var(--cnvs-portfolio-desc-title-size) / .8333);
}

.desc-lg .portfolio-desc span {
  font-size: 110%;
}

.desc-sm .portfolio-desc {
  padding: var(--cnvs-portfolio-desc-padding-y) var(--cnvs-portfolio-desc-padding-x);
}

.desc-sm .portfolio-desc h3, .desc-sm .portfolio-desc .h3 {
  font-size: calc(var(--cnvs-portfolio-desc-title-size) * .8);
}

.desc-sm .portfolio-desc span {
  font-size: 90%;
}

.portfolio.g-0 .portfolio-desc {
  --cnvs-portfolio-desc-padding-x: 15px;
}

.desc-sm.g-0 .portfolio-desc {
  --cnvs-portfolio-desc-padding-x: calc(var(--cnvs-portfolio-desc-padding-x)  - 5px);
}

.portfolio-reveal .portfolio-desc {
  background-color: var(--cnvs-body-bg);
  width: 100%;
  position: absolute;
  top: auto;
  bottom: 0;
  transform: translateY(100%);
}

@media (width >= 768px) {
  .portfolio-reveal .portfolio-desc {
    transition: all .3s ease-in-out;
  }
}

@media (width >= 768px) and (prefers-reduced-motion: reduce) {
  .portfolio-reveal .portfolio-desc {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .portfolio-reveal .portfolio-desc {
    display: none !important;
  }
}

.portfolio-reveal .portfolio-item:hover .portfolio-desc {
  display: none;
  transform: translateY(0);
}

@media (width >= 768px) {
  .portfolio-reveal .portfolio-item:hover .portfolio-desc {
    display: block;
  }
}

.portfolio-reveal .portfolio-item:hover .portfolio-image img, .portfolio-reveal .portfolio-item:hover .bg-overlay {
  transform: translateY(0);
}

@media (width >= 768px) {
  .portfolio-reveal .portfolio-item:hover .portfolio-image img, .portfolio-reveal .portfolio-item:hover .bg-overlay {
    transform: translateY(-15%);
  }
}

.portfolio-parallax .portfolio-image {
  background-attachment: fixed;
  height: 60vh;
}

@media (width >= 992px) {
  .portfolio-parallax .portfolio-image {
    height: 500px;
  }
}

.portfolio-single-image > a, .portfolio-single-image .slide a, .portfolio-single-image img, .portfolio-single-image iframe, .portfolio-single-image video {
  width: 100%;
  display: block;
}

.portfolio-single-image-full {
  height: 600px;
  margin: calc(-1 * var(--cnvs-content-padding)) 0 var(--cnvs-content-padding);
  position: relative;
  overflow: hidden;
}

.portfolio-single-video {
  height: auto !important;
}

.masonry-thumbs {
  --bs-gutter-x: calc(1px * 2);
  --bs-gutter-y: calc(1px * 2);
  position: relative;
}

.masonry-thumbs > * {
  overflow: hidden;
}

.masonry-gap-lg {
  --bs-gutter-x: calc(1px * 3);
  --bs-gutter-y: calc(1px * 3);
}

.masonry-gap-xl {
  --bs-gutter-x: calc(1px * 5);
  --bs-gutter-y: calc(1px * 5);
}

.masonry-thumbs > * > img, .masonry-thumbs .grid-inner > img {
  border-radius: 0;
  width: 100%;
  height: auto;
  display: block;
}

.portfolio-single-content h2, .portfolio-single-content .h2 {
  font-size: var(--cnvs-portfolio-desc-title-size);
  margin: 0 0 20px;
  padding: 0;
  font-weight: 600 !important;
}

.modal-padding {
  --cnvs-portfolio-modal-padding: 2.5rem;
  padding: var(--cnvs-portfolio-modal-padding);
}

.ajax-modal-title {
  background-color: var(--cnvs-contrast-100);
  border-bottom: 1px solid var(--cnvs-contrast-200);
  padding: 25px 40px;
}

.ajax-modal-title h2, .ajax-modal-title .h2 {
  font-size: calc(var(--cnvs-portfolio-desc-title-size) / .714);
  margin-bottom: 0;
}

#portfolio-ajax-wrap {
  max-height: 0;
  transition: max-height .4s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  #portfolio-ajax-wrap {
    transition: none;
  }
}

#portfolio-ajax-wrap.portfolio-ajax-opened {
  max-height: var(--cnvs-portfolio-ajax-height);
}

#portfolio-ajax-container {
  padding-bottom: 60px;
  display: none;
}

#portfolio-ajax-single {
  opacity: 0;
  pointer-events: none;
  transition: opacity .6s;
}

.portfolio-ajax-opened #portfolio-ajax-single {
  opacity: 1;
  pointer-events: auto;
}

#portfolio-ajax-title h2, #portfolio-ajax-title .h2 {
  font-size: calc(var(--cnvs-portfolio-desc-title-size) / .714);
}

.portfolio-ajax-opened ~ .portfolio.grid-container .portfolio-item:not(:hover):not(.portfolio-active) {
  opacity: .25;
}

.portfolio-meta, .portfolio-share {
  font-size: var(--cnvs-portfolio-meta-font-size);
  list-style: none;
}

.portfolio-meta li {
  color: var(--cnvs-portfolio-meta-color);
  margin: 10px 0;
}

.portfolio-meta li:first-child {
  margin-top: 0;
}

.portfolio-meta li span {
  width: var(--cnvs-portfolio-meta-row-1);
  color: var(--cnvs-portfolio-meta-row-1-color);
  font-weight: bold;
  display: inline-block;
}

.portfolio-meta li span i {
  width: var(--cnvs-portfolio-meta-icon);
  text-align: center;
  margin-right: calc(var(--cnvs-portfolio-meta-icon) * .5);
  position: relative;
  top: 1px;
}

#portfolio-navigation {
  max-width: calc(var(--cnvs-portfolio-navigation-size) * 4);
  height: var(--cnvs-portfolio-navigation-size);
  margin-top: calc(var(--cnvs-portfolio-navigation-size) * -.5);
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
}

.page-title-right #portfolio-navigation {
  left: 10px;
  right: auto;
}

.page-title-center #portfolio-navigation {
  margin: 20px auto 0;
  position: relative;
  top: 0;
  left: 0;
}

#portfolio-navigation a {
  float: left;
  margin-left: calc(var(--cnvs-portfolio-navigation-size) * .5);
  width: var(--cnvs-portfolio-navigation-size);
  height: var(--cnvs-portfolio-navigation-size);
  text-align: center;
  color: var(--cnvs-contrast-900);
  transition: color .3s linear;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  #portfolio-navigation a {
    transition: none;
  }
}

#portfolio-navigation a:first-child {
  margin-left: 0;
}

#portfolio-navigation a:hover {
  color: var(--cnvs-themecolor);
}

#portfolio-ajax-show #portfolio-navigation {
  margin-top: 0;
  top: 0;
}

:root, .not-dark {
  --cnvs-post-title-font-color: var(--cnvs-heading-color);
  --cnvs-post-meta-color: var(--cnvs-contrast-600);
  --cnvs-post-entry-link-bg: var(--cnvs-contrast-100);
  --cnvs-post-entry-link-color: var(--cnvs-heading-color);
  --cnvs-post-timeline-border-color: var(--cnvs-contrast-400);
  --cnvs-post-timeline-date-border-color: var(--cnvs-contrast-400);
}

#posts, .posts, .entry, [class*="entry-"] {
  --cnvs-post-item-margin: 50px;
  --cnvs-post-image-size: 100%;
  --cnvs-post-image-rounded: .25rem;
  --cnvs-post-image-mb: 1.5rem;
  --cnvs-post-sm-image: 64px;
  --cnvs-post-title-font-size: 1.75rem;
  --cnvs-post-title-font-weight: 600;
  --cnvs-post-title-font-spacing: 0;
  --cnvs-post-title-text-transform: none;
  --cnvs-post-title-font-color: var(--cnvs-heading-color);
  --cnvs-post-meta-seperator: ⁞;
  --cnvs-post-meta-seperator-width: .25rem;
  --cnvs-post-meta-margin: 1rem;
  --cnvs-post-meta-color: var(--cnvs-contrast-600);
  --cnvs-post-meta-fontsize: 90%;
  --cnvs-post-meta-iconsize: 1rem;
  --cnvs-post-content-margin: 2rem;
  --cnvs-post-entry-link-bg: var(--cnvs-contrast-100);
  --cnvs-post-entry-link-padding: 2rem 0;
  --cnvs-post-entry-link-color: var(--cnvs-heading-color);
  --cnvs-post-entry-link-font-family: var(--cnvs-primary-font);
  --cnvs-post-entry-link-text-transform: none;
  --cnvs-post-entry-link-letter-spacing: 0;
  --cnvs-post-entry-link-font-size: 1.75rem;
  --cnvs-post-entry-link-font-weight: 600;
  --cnvs-post-entry-link-border-radius: .25rem;
  position: relative;
}

.entry {
  margin-bottom: var(--cnvs-post-item-margin);
}

.posts-md .entry, .posts-sm .entry {
  margin-bottom: 0;
}

.entry:after {
  content: "";
  height: 2px;
  margin-top: var(--cnvs-post-item-margin);
  background-color: var(--cnvs-contrast-100);
  position: relative;
}

.entry-image {
  margin-bottom: var(--cnvs-post-image-mb);
}

.posts-md .entry-image, .posts-sm .entry-image {
  margin-bottom: 0;
}

.entry-image, .entry-image > a, .entry-image .slide a, .entry-image img {
  width: var(--cnvs-post-image-size);
  height: auto;
  display: block;
  position: relative;
}

.posts-sm .entry-image, .posts-sm .entry-image > a, .posts-sm .entry-image .slide a, .posts-sm .entry-image img {
  --cnvs-post-image-size: var(--cnvs-post-sm-image);
}

.entry-image iframe {
  display: block;
}

.entry-image video {
  width: 100%;
  display: block;
}

.entry-image img {
  border-radius: var(--cnvs-post-image-rounded);
}

.posts-md .entry-image + .entry-title {
  margin-top: .875rem;
}

.entry-title h2, .entry-title .h2, .entry-title h3, .entry-title .h3, .entry-title h4, .entry-title .h4 {
  font-size: var(--cnvs-post-title-font-size);
  font-weight: var(--cnvs-post-title-font-weight);
  text-transform: var(--cnvs-post-title-text-transform);
  letter-spacing: var(--cnvs-post-title-font-spacing);
  margin: 0;
}

.entry-title h2 a, .entry-title .h2 a, .entry-title h3 a, .entry-title .h3 a, .entry-title h4 a, .entry-title .h4 a {
  color: var(--cnvs-post-title-font-color);
}

.entry-title h2 a:hover, .entry-title .h2 a:hover, .entry-title h3 a:hover, .entry-title .h3 a:hover, .entry-title h4 a:hover, .entry-title .h4 a:hover {
  color: var(--cnvs-themecolor);
}

.entry-title.title-sm h2, .entry-title.title-sm .h2, .entry-title.title-sm h3, .entry-title.title-sm .h3 {
  --cnvs-post-title-font-size: 1.25rem;
  letter-spacing: 0;
}

.entry-title.title-sm h3, .entry-title.title-sm .h3 {
  --cnvs-post-title-font-size: 1.125rem;
}

.entry-title.title-xs h2, .entry-title.title-xs .h2, .entry-title.title-xs h3, .entry-title.title-xs .h3 {
  --cnvs-post-title-font-size: 1rem;
  letter-spacing: 0;
}

.entry-title h4, .entry-title .h4 {
  --cnvs-post-title-font-size: .875rem;
  letter-spacing: 0;
}

.entry-title.nott h2, .entry-title.nott .h2, .entry-title.nott h3, .entry-title.nott .h3, .entry-title h4, .entry-title .h4 {
  text-transform: none;
}

.entry-meta {
  margin-top: .5rem;
  position: relative;
  overflow: hidden;
}

.posts-sm .entry-meta {
  margin-top: .25rem;
}

.single-post .entry-meta {
  margin-bottom: 1.5rem;
}

.posts-md .entry-meta, .posts-sm .entry-meta {
  margin-bottom: 0;
}

.entry-meta ul {
  margin: 0 0 -.75rem calc(-1 * (var(--cnvs-post-meta-margin)  + var(--cnvs-post-meta-seperator-width)));
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  display: flex;
}

.entry-meta.no-separator ul, .entry-meta ul.flex-column {
  margin-left: 0;
}

.entry-meta ul.flex-column {
  align-items: flex-start;
}

.posts-sm .entry-meta ul {
  margin-bottom: -.25rem;
}

.entry-meta li {
  font-size: var(--cnvs-post-meta-fontsize);
  margin: 0 var(--cnvs-post-meta-margin) .75rem 0;
  color: var(--cnvs-contrast-500);
}

.entry-meta ul.flex-column li {
  margin-left: 0;
}

.posts-sm .entry-meta li {
  margin-bottom: .25rem;
}

.entry-meta:not(.no-separator) li:before {
  content: "⁞";
  width: var(--cnvs-post-meta-seperator-width);
  text-align: center;
  margin-right: var(--cnvs-post-meta-margin);
  opacity: .5;
  display: inline-block;
}

.entry-meta i {
  font-size: var(--cnvs-post-meta-iconsize);
  margin-right: .25rem;
  padding-left: 1px;
  position: relative;
}

.entry-meta a:not(:hover) {
  color: var(--cnvs-post-meta-color);
}

.entry-meta ins {
  font-weight: 700;
  text-decoration: none;
}

.entry-content {
  margin-top: var(--cnvs-post-content-margin);
  position: relative;
}

.single-post .entry-content {
  margin-top: 0;
}

.posts-md .entry-content, .posts-sm .entry-content {
  margin-top: calc(var(--cnvs-post-content-margin) / 1.5);
}

.single-post .entry-content .entry-image {
  max-width: 350px;
}

.entry-link {
  background-color: var(--cnvs-post-entry-link-bg);
  width: 100%;
  padding: var(--cnvs-post-entry-link-padding);
  text-align: center;
  color: var(--cnvs-post-entry-link-color);
  font-family: var(--cnvs-post-entry-link-font-family);
  text-transform: var(--cnvs-post-entry-link-text-transform);
  letter-spacing: var(--cnvs-post-entry-link-letter-spacing);
  font-size: var(--cnvs-post-entry-link-font-size);
  font-weight: var(--cnvs-post-entry-link-font-weight);
  border-radius: var(--cnvs-post-entry-link-border-radius);
  border: 1px solid #0000;
  transition: background-color .3s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .entry-link {
    transition: none;
  }
}

.entry-link:hover {
  border-color: var(--cnvs-themecolor);
  background-color: #0000;
}

.entry-link span {
  font-family: var(--cnvs-secondary-font);
  text-transform: none;
  letter-spacing: 0;
  color: var(--cnvs-contrast-700);
  margin-top: 5px;
  font-size: .875rem;
  font-style: italic;
  font-weight: normal;
  display: block;
}

.entry-link:hover span {
  color: var(--cnvs-contrast-500);
}

.entry blockquote {
  margin-bottom: 2rem;
}

.entry blockquote p {
  font-weight: 400;
  font-family: var(--cnvs-secondary-font);
  font-style: italic;
}

.post-grid {
  --cnvs-post-grid-margin: 1.5rem;
}

.post-grid .entry {
  margin-bottom: var(--cnvs-post-grid-margin);
}

.post-grid .entry-title h2, .post-grid .entry-title .h2 {
  --cnvs-post-title-font-size: 1.125rem;
  letter-spacing: 0;
  font-weight: var(--cnvs-post-title-font-weight);
}

.post-grid .entry-link {
  font-size: var(--cnvs-post-entry-link-font-size);
}

.post-grid .entry-link span {
  font-size: .875rem;
}

.post-timeline {
  --cnvs-post-timeline-border-style: dashed;
  --cnvs-post-timeline-border-color: var(--cnvs-contrast-400);
  --cnvs-post-timeline-date-size: .75rem;
  --cnvs-post-timeline-date-border: 2px;
  --cnvs-post-timeline-date-border-color: var(--cnvs-contrast-400);
}

.entry-timeline {
  display: none;
}

@media (width >= 992px) {
  .timeline-border {
    border-left: 1px dashed var(--cnvs-contrast-400);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .postcontent .post-timeline:before {
    content: "";
    border-left: 1px dashed var(--cnvs-post-timeline-border-color);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
  }

  .post-timeline .entry-timeline {
    border: var(--cnvs-post-timeline-date-border) solid var(--cnvs-post-timeline-border-color);
    background-color: var(--cnvs-body-bg);
    text-indent: -9999px;
    width: var(--cnvs-post-timeline-date-size);
    height: var(--cnvs-post-timeline-date-size);
    color: var(--cnvs-contrast-500);
    text-align: center;
    border-radius: 50%;
    padding-top: 0;
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1;
    transition: all .3s ease-in-out;
    display: block;
    position: absolute;
    top: 40px;
    left: auto;
    right: -6px;
  }
}

@media (width >= 992px) and (prefers-reduced-motion: reduce) {
  .post-timeline .entry-timeline {
    transition: none;
  }
}

@media (width >= 992px) {
  .post-timeline .entry-timeline .timeline-divider {
    border-top: 1px dashed var(--cnvs-post-timeline-border-color);
    width: 70px;
    height: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    top: 50%;
    left: auto;
    right: 15px;
  }
}

@media (width >= 992px) and (prefers-reduced-motion: reduce) {
  .post-timeline .entry-timeline .timeline-divider {
    transition: none;
  }
}

@media (width >= 992px) {
  .post-timeline .entry:not(.entry-date-section) {
    padding-right: 85px;
  }

  .post-timeline .entry.alt:not(.entry-date-section) {
    padding-right: inherit;
    padding-left: 85px;
  }

  .post-timeline .alt .entry-timeline {
    left: -6px;
    right: auto;
  }

  .post-timeline .alt .entry-timeline .timeline-divider {
    left: 15px;
    right: auto;
  }

  .post-timeline .entry-timeline span {
    margin-top: 3px;
    font-size: 13px;
    font-weight: normal;
    display: block;
  }

  .post-timeline .entry:hover .entry-timeline, .post-timeline .entry:hover .timeline-divider {
    border-color: var(--cnvs-themecolor);
    color: var(--cnvs-themecolor);
  }
}

.entry.entry-date-section {
  text-align: center;
  margin: 50px 0 80px;
}

.entry.entry-date-section:first-child {
  margin-top: 0;
}

.entry.entry-date-section span {
  background-color: var(--cnvs-body-bg);
  border: 2px solid var(--cnvs-contrast-100);
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 250px;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  font-family: var(--cnvs-primary-font);
  display: inline-block;
}

@media (width >= 992px) {
  .postcontent .post-timeline {
    --cnvs-post-timeline-date-size: 64px;
    --cnvs-post-timeline-date-border: 3px;
  }

  .postcontent .post-timeline:before {
    left: calc(-1 * var(--cnvs-post-timeline-date-size)  - var(--cnvs-post-timeline-date-border)  - 1px);
    margin-left: 0;
  }

  .postcontent .post-timeline {
    margin-left: calc(var(--cnvs-post-timeline-date-size)  + 36px);
    padding-left: 0;
    overflow: visible;
    margin-right: 0 !important;
  }

  .postcontent .post-timeline .entry {
    width: 100% !important;
    padding: 0 !important;
  }

  .postcontent .post-timeline .entry-timeline {
    text-indent: 0;
    top: 20px;
    left: calc(-1 * (var(--cnvs-post-timeline-date-size)  + 36px));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    display: flex;
    right: auto;
  }

  .postcontent .post-timeline .entry-timeline div.timeline-divider {
    top: 50%;
    left: var(--cnvs-post-timeline-date-size);
    width: calc(var(--cnvs-post-timeline-date-size) / 2);
  }
}

.more-link {
  font-family: var(--cnvs-secondary-font);
  text-underline-offset: .375rem;
  font-style: italic;
  text-decoration-thickness: 1px;
  display: inline-block;
}

.post-navigation + .line {
  margin-top: var(--cnvs-contrast-900);
}

#comments {
  border-top: 1px solid var(--cnvs-contrast-100);
  margin-top: 3rem;
  padding-top: 3rem;
  position: relative;
}

.commentlist {
  border-bottom: 1px solid var(--cnvs-contrast-100);
  margin: 0 0 3rem;
  padding-bottom: 3rem;
  list-style: none;
}

#reviews .commentlist {
  margin: 0 0 1.25rem;
  padding-bottom: 2rem;
}

.commentlist ul {
  list-style: none;
}

.commentlist li, .commentlist li ul, .commentlist li ul li {
  margin: 2rem 0 0;
}

.commentlist ul:first-child {
  margin-top: 0;
}

.commentlist li {
  margin: 2rem 0 0 2rem;
  position: relative;
}

#reviews .commentlist li {
  margin-top: 1.25rem;
}

.comment-wrap {
  border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  border-radius: .25rem;
  padding: 1.25rem 1.25rem 1.25rem 2rem;
  position: relative;
}

.commentlist ul .comment-wrap {
  margin-left: 1.5rem;
  padding-left: 1.25rem;
}

#reviews .comment-wrap {
  border: 0;
  padding: .75rem 0 0 2rem;
}

.commentlist > li:first-child, #reviews .commentlist > li:first-child {
  margin-top: 0;
  padding-top: 0;
}

.commentlist li .children {
  margin-top: 0;
}

.commentlist li li .children {
  margin-left: 2rem;
}

.commentlist li .comment-content, .pingback {
  position: relative;
  overflow: hidden;
}

.commentlist li .comment-content p, .pingback p {
  margin: 1.25rem 0 0;
}

.commentlist li .comment-content {
  padding: 0 0 0 1rem;
}

.commentlist li .comment-meta {
  float: left;
  margin-right: 0;
  line-height: 1;
}

.comment-avatar {
  background: var(--cnvs-body-bg);
  border: 1px solid var(--cnvs-contrast-200);
  border-radius: 50%;
  padding: .25rem;
  position: absolute;
  top: 15px;
  left: -35px;
}

.comment-avatar img {
  border-radius: 50%;
  display: block;
}

.commentlist li .children .comment-avatar {
  left: -25px;
}

.comment-content .comment-author {
  color: var(--cnvs-contrast-700);
  margin-bottom: -.75rem;
  font-size: 1rem;
  font-weight: bold;
}

.comment-content .comment-author a {
  color: var(--cnvs-contrast-900);
  border: none;
}

.comment-content .comment-author a:hover {
  color: var(--cnvs-themecolor);
}

.comment-content .comment-author span {
  display: block;
}

.comment-content .comment-author span, .comment-content .comment-author span a {
  font-size: .75rem;
  font-weight: normal;
  font-family: var(--cnvs-primary-font);
  color: var(--cnvs-contrast-500);
}

.comment-content .comment-author span a:hover {
  color: var(--cnvs-contrast-600);
}

.comment-reply-link, .review-comment-ratings {
  text-align: center;
  width: 1rem;
  height: 1rem;
  color: var(--cnvs-contrast-500);
  font-size: 1rem;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

.review-comment-ratings {
  width: auto;
  color: var(--cnvs-contrast-900);
}

.comment-reply-link:hover {
  color: var(--cnvs-contrast-500);
}

#respond, #respond form {
  margin-bottom: 0;
}

.commentlist li #respond {
  margin: 2rem 0 0;
}

.commentlist li li #respond {
  margin-left: 2rem;
}

#respond p {
  margin: .75rem 0 0;
}

#respond p:first-child {
  margin-top: 0;
}

#respond label small, #respond label .small {
  color: var(--cnvs-contrast-500);
  font-weight: normal;
}

#respond input[type="text"], #respond textarea {
  margin-bottom: 0;
}

.fb-comments, .fb_iframe_widget, .fb-comments > span, .fb_iframe_widget > span, .fb-comments > span > iframe, .fb_iframe_widget > span > iframe {
  margin: 0;
  width: 100% !important;
  display: block !important;
}

img.alignleft, div.alignleft {
  float: left;
  max-width: 100%;
  margin: 5px 20px 13px 0;
}

div.alignleft > img, div.alignnone > img, div.aligncenter > img, div.alignright > img {
  float: none;
  display: block;
}

img.alignnone, img.aligncenter, div.alignnone, div.aligncenter {
  float: none;
  margin: 10px 0;
  display: block;
}

img.aligncenter, div.aligncenter, div.aligncenter img {
  clear: both;
  margin-left: auto;
  margin-right: auto;
}

img.alignright, div.alignright {
  float: right;
  margin: 5px 0 13px 20px;
}

.wp-caption {
  text-align: center;
  font-family: var(--cnvs-body-font), Georgia, "Times New Roman", Times, serif;
  margin: 10px 20px 13px;
  font-style: italic;
}

.wp-caption img, .wp-caption img a {
  margin: 0;
  display: block;
}

p.wp-caption-text {
  background-color: var(--cnvs-contrast-100);
  border-radius: 50px;
  margin: .75rem 0 0;
  padding: .25rem .75rem;
  display: inline-block;
}

.wp-smiley {
  max-height: 13px;
  margin: 0 !important;
}

:root, .not-dark {
  --cnvs-cart-quantity-bg: var(--cnvs-contrast-200);
  --cnvs-cart-quantity-color: var(--cnvs-contrast-800);
  --cnvs-cart-quantity-color-hover: var(--cnvs-cart-quantity-color);
  --cnvs-cart-quantity-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-cart-quantity-bg-hover: var(--cnvs-contrast-300);
}

.shop, .product {
  position: relative;
}

.product .product-image {
  border-radius: .25rem;
  position: relative;
  overflow: hidden;
}

.product .product-image > a, .product .product-image .slide a, .product .product-image img {
  width: 100%;
  display: block;
}

.product .product-image > a {
  transition: all .5s;
}

@media (prefers-reduced-motion: reduce) {
  .product .product-image > a {
    transition: none;
  }
}

.product-image > a:nth-of-type(2) {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.product-image:hover > a:nth-of-type(2) {
  opacity: 1;
  z-index: 2;
}

.product-desc {
  padding: 1rem 0;
}

.product-title {
  margin-bottom: .5rem;
}

.product-title h3, .product-title .h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.product-title h3 a, .product-title .h3 a, .single-product .product-title h2 a, .single-product .product-title .h2 a {
  color: var(--cnvs-heading-color);
}

.product-title h3 a:hover, .product-title .h3 a:hover, .single-product .product-title h2 a:hover, .single-product .product-title .h2 a:hover {
  color: var(--cnvs-themecolor);
}

.product-price {
  color: var(--cnvs-heading-color);
  margin-bottom: .25rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.product-price del {
  color: var(--cnvs-contrast-500);
  font-size: 90%;
  font-weight: 400;
}

.product-price ins {
  color: var(--cnvs-themecolor);
  text-decoration: none;
}

.product-image > .badge, .sale-flash {
  z-index: 5;
  position: absolute;
  top: .75rem;
  left: .75rem;
}

.single-product .product {
  width: 100%;
  margin: 0;
}

.shop-quick-view-ajax, .portfolio-ajax-modal {
  background-color: var(--cnvs-contrast-bg);
  width: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.portfolio-ajax-modal {
  max-width: 1000px;
}

.single-product .product-image, .single-product .product-image img {
  height: auto;
}

.single-product .product-title h2, .single-product .product-title .h2 {
  margin-bottom: 8px;
  font-size: 1.75rem;
}

.single-product .product-price {
  color: var(--cnvs-themecolor);
  margin-bottom: 0;
  font-size: 1.5rem;
}

.single-product .product-desc .line {
  margin: 20px 0;
}

.quantity {
  --cnvs-cart-quantity-width: 50px;
  --cnvs-cart-quantity-height: 44px;
  --cnvs-cart-quantity-font-size: 1rem;
  --cnvs-cart-quantity-border: 1px;
  --cnvs-cart-quantity-bg: var(--cnvs-contrast-200);
  --cnvs-cart-quantity-color: var(--cnvs-contrast-800);
  --cnvs-cart-quantity-color-hover: var(--cnvs-cart-quantity-color);
  --cnvs-cart-quantity-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-cart-quantity-bg-hover: var(--cnvs-contrast-300);
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-right: 30px;
  display: inline-flex;
}

.quantity input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.quantity input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.quantity .qty {
  width: var(--cnvs-cart-quantity-width);
  height: var(--cnvs-cart-quantity-height);
  line-height: var(--cnvs-cart-quantity-height);
  border: 0;
  border-left: var(--cnvs-cart-quantity-border) solid var(--cnvs-cart-quantity-border-color);
  border-right: var(--cnvs-cart-quantity-border) solid var(--cnvs-cart-quantity-border-color);
  background-color: var(--cnvs-cart-quantity-bg);
  text-align: center;
  font-size: var(--cnvs-cart-quantity-font-size);
  color: var(--cnvs-cart-quantity-color);
  margin-bottom: 0;
}

.quantity .plus, .quantity .minus {
  cursor: pointer;
  width: calc(var(--cnvs-cart-quantity-width)  - var(--cnvs-cart-quantity-border) * 4);
  height: var(--cnvs-cart-quantity-height);
  line-height: var(--cnvs-cart-quantity-height);
  text-align: center;
  background-color: var(--cnvs-cart-quantity-bg);
  color: var(--cnvs-cart-quantity-color);
  font-size: var(--cnvs-cart-quantity-font-size);
  border: 0 #0000;
  padding: 0;
  font-weight: bold;
  transition: background-color .2s linear;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .quantity .plus, .quantity .minus {
    transition: none;
  }
}

.quantity .plus:hover, .quantity .minus:hover {
  background-color: var(--cnvs-cart-quantity-bg-hover);
  color: var(--cnvs-cart-quantity-color-hover);
}

.quantity .qty:focus, .quantity .plus:focus, .quantity .minus:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.product-meta {
  color: var(--cnvs-contrast-500);
  font-size: 90%;
}

.product-meta > .card-body > span {
  margin: 0 5px;
  display: inline-block;
}

.single-product .fbox-plain.fbox-sm .fbox-icon {
  width: 2.25rem;
}

.single-product .fbox-plain.fbox-sm .fbox-icon i {
  font-size: 1.125rem;
  line-height: 1.5;
}

.cart-border .quantity {
  --cnvs-cart-quantity-bg: transparent;
  --cnvs-cart-quantity-border-color: rgba(var(--cnvs-contrast-rgb), .15);
  --cnvs-cart-quantity-bg-hover: var(--cnvs-contrast-200);
  border: var(--cnvs-cart-quantity-border) solid var(--cnvs-cart-quantity-border-color);
}

.cart-border-2 .quantity {
  --cnvs-cart-quantity-height: 46px;
  --cnvs-cart-quantity-bg-hover: transparent;
  --cnvs-cart-quantity-color-hover: var(--bs-danger);
}

.cart-border.cart-border-2 .qty {
  --cnvs-cart-quantity-width: 25px;
}

.cart-bg-circle .quantity {
  --cnvs-cart-quantity-border: 0px;
  --cnvs-cart-quantity-width: 43px;
  --cnvs-cart-quantity-height: 43px;
  transition: none;
}

.cart .remove {
  color: red;
  font-size: .875rem;
}

.cart .remove:hover {
  color: #000;
}

.cart th {
  color: var(--cnvs-contrast-700);
  font-size: .875rem;
  padding: .75rem 1rem !important;
}

.cart td {
  vertical-align: middle !important;
  border-color: rgba(var(--cnvs-contrast-rgb), .1) !important;
  padding: .75rem 1rem !important;
}

.cart-product-thumbnail a {
  width: 68px;
  display: block;
}

.cart-product-thumbnail img {
  border: 2px solid rgba(var(--cnvs-contrast-rgb), .2);
  width: 64px;
  height: 64px;
  display: block;
}

.cart-product-thumbnail img:hover {
  border-color: var(--cnvs-themecolor);
}

.cart-product-name a, .product-name a {
  font-weight: 600;
}

.cart-product-name a:not(:hover), .product-name a:not(:hover) {
  color: var(--cnvs-heading-color);
}

.cart-product-price, .cart-product-quantity, .cart-product-subtotal {
  text-align: center !important;
}

.cart-product-quantity .quantity {
  margin: 0;
}

@media (width <= 575.98px) {
  .cart:not(.cart-totals) thead th {
    display: none;
  }

  .cart:not(.cart-totals) tbody td {
    text-align: center;
    border: 0;
    width: 100%;
    display: block;
    padding: 0 !important;
  }

  .cart:not(.cart-totals) .cart_item {
    border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    display: block;
    position: relative;
  }

  .cart:not(.cart-totals) .cart_item .cart-product-remove {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    position: absolute;
    top: 1.25rem;
    left: auto;
    right: 1.25rem;
  }

  .cart:not(.cart-totals) .cart-product-thumbnail, .cart:not(.cart-totals) .cart-product-name, .cart:not(.cart-totals) .cart-product-quantity {
    margin-bottom: 1rem;
  }

  .cart:not(.cart-totals) .cart_item .cart-product-thumbnail a {
    display: inline-block;
  }

  .cart:not(.cart-totals) .cart_item .cart-product-price {
    display: none;
  }

  .cart:not(.cart-totals) .cart_item .cart-product-name a {
    font-size: 1rem;
  }

  .cart:not(.cart-totals) .cart_item .cart-product-subtotal {
    color: var(--cnvs-themecolor);
    font-size: 1.25rem;
  }

  .quantity .qty {
    width: 42px;
  }

  .quantity .qty, .quantity .plus, .quantity .minus {
    font-size: .875rem;
  }
}

td.actions {
  background-color: var(--cnvs-contrast-100);
  vertical-align: top !important;
  padding: 20px 15px !important;
}

.single-event .entry-overlay, .parallax .entry-overlay-meta {
  text-align: center;
  width: 100%;
  color: var(--cnvs-white);
  z-index: 5;
  background-color: #000000d9;
  font-size: 1.25rem;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
}

.parallax .entry-overlay, .parallax .entry-overlay-meta {
  text-align: right;
  background: none;
  width: 90%;
  margin: 0 auto;
  bottom: 30px;
  left: auto;
  right: 30px;
  left: 0 !important;
  right: 0 !important;
}

.parallax .entry-overlay-meta {
  text-align: left;
  width: 368px;
  height: auto;
  font-size: .875rem;
  line-height: inherit;
  background-color: #000000b3;
  border-radius: 3px;
  padding: 20px;
  bottom: 130px;
  right: 38px;
}

.parallax.overlay-left .entry-overlay, .parallax.overlay-left .entry-overlay-meta {
  left: 30px;
  right: auto;
}

.parallax.overlay-left .entry-overlay-meta {
  left: 38px;
}

.parallax.overlay-center .entry-overlay, .parallax.overlay-center .entry-overlay-meta {
  bottom: 100px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.parallax.overlay-center .entry-overlay-meta {
  margin-left: -184px;
  top: 100px;
  bottom: auto;
}

.countdown {
  --cnvs-countdown-size: 1.25rem;
  --cnvs-countdown-section: 11px;
  --cnvs-countdown-md-size: 30px;
  --cnvs-countdown-md-section: .875rem;
  --cnvs-countdown-lg-size: 44px;
  --cnvs-countdown-lg-section: 18px;
  --cnvs-countdown-border: 1px dotted var(--cnvs-contrast-500);
  --cnvs-countdown-inline-space: 7px;
  --cnvs-countdown-large: 140px;
  display: block;
  position: relative;
}

.countdown-row {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.countdown-section {
  max-width: 100%;
  font-size: var(--cnvs-countdown-section);
  text-align: center;
  border-left: var(--cnvs-countdown-border);
  color: var(--cnvs-contrast-600);
  text-transform: capitalize;
  flex-grow: 1;
  flex-basis: 0;
  line-height: 1;
}

.countdown-medium .countdown-section {
  --cnvs-countdown-section: .875rem;
}

.countdown-large .countdown-section {
  --cnvs-countdown-section: 1.125rem;
}

.countdown-section:first-child {
  border-left: 0;
}

.countdown-amount {
  font-size: var(--cnvs-countdown-size);
  color: var(--cnvs-contrast-800);
  margin-bottom: 5px;
  display: block;
}

.countdown-medium .countdown-amount {
  --cnvs-countdown-size: var(--cnvs-countdown-md-size);
  margin-bottom: 7px;
}

.countdown-large .countdown-amount {
  --cnvs-countdown-size: var(--cnvs-countdown-lg-size);
  margin-bottom: 8px;
  font-weight: 700;
}

.countdown-descr {
  width: 100%;
  display: block;
}

.countdown.countdown-inline {
  display: inline-block;
}

.countdown.countdown-inline .countdown-row {
  text-align: center;
  display: inline-block;
}

.countdown.countdown-inline .countdown-section {
  font-size: inherit;
  line-height: inherit;
  width: auto;
  color: inherit;
  margin-left: var(--cnvs-countdown-inline-space);
  text-transform: inherit;
  border: none;
  display: inline-block;
}

.countdown.countdown-inline .countdown-section:first-child {
  margin-left: 0;
}

.countdown.countdown-inline .countdown-amount {
  font-size: inherit;
  color: inherit;
  margin: 0 3px 0 0;
  font-weight: bold;
  display: inline-block;
}

.countdown.countdown-inline .countdown-descr {
  width: auto;
  display: inline-block;
}

@media (width >= 768px) {
  .countdown-large.coming-soon .countdown-section {
    border: none;
    padding: 15px;
  }

  .countdown-large.coming-soon .countdown-amount {
    width: var(--cnvs-countdown-large);
    height: var(--cnvs-countdown-large);
    line-height: var(--cnvs-countdown-large);
    background-color: #0003;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px !important;
  }
}

.button {
  --cnvs-btn-padding-x: 1.375rem;
  --cnvs-btn-padding-y: .5rem;
  --cnvs-btn-fontsize: 1rem;
  --cnvs-btn-color: var(--cnvs-themecolor);
  --cnvs-btn-color-dark: var(--bs-gray-900);
  --cnvs-btn-color-light: #e9ecef;
  --cnvs-btn-color-hover: var(--cnvs-btn-color-dark);
  --cnvs-btn-lineheight: calc(var(--cnvs-btn-fontsize) * 1.6);
  --cnvs-btn-border-width: 1px;
  --cnvs-btn-icon-margin: 10px;
  --cnvs-btn-icon-margin-offset: 0px;
  cursor: pointer;
  white-space: nowrap;
  padding: var(--cnvs-btn-padding-y) var(--cnvs-btn-padding-x);
  font-size: var(--cnvs-btn-fontsize);
  line-height: var(--cnvs-btn-lineheight);
  text-align: center;
  background-color: var(--cnvs-btn-color);
  color: #fff;
  border: var(--cnvs-btn-border-width) solid transparent;
  outline: none;
  margin: 5px;
  font-weight: 500;
  transition: all .2s ease-in-out;
  display: inline-block;
  position: relative;
}

.button[class*="bg-opacity-"] {
  --cnvs-btn-color: rgba(var(--cnvs-themecolor-rgb), var(--bs-bg-opacity, 1));
}

.button i {
  margin-right: calc(var(--cnvs-btn-icon-margin)  + var(--cnvs-btn-icon-margin-offset));
  text-align: center;
  width: 1rem;
  position: relative;
}

.button.text-end i {
  margin: 0 0 0 calc(var(--cnvs-btn-icon-margin)  + var(--cnvs-btn-icon-margin-offset));
}

@media (prefers-reduced-motion: reduce) {
  .button {
    transition: none;
  }
}

.button.button-dark {
  background-color: var(--cnvs-btn-color-dark);
}

.button.button-light {
  --cnvs-btn-color: var(--cnvs-btn-color-light);
  color: var(--cnvs-btn-color-dark);
}

.button:hover {
  background-color: var(--cnvs-btn-color-dark);
  color: #fff;
}

.button.button-dark:hover {
  background-color: var(--cnvs-btn-color);
}

.button.button-mini {
  --cnvs-btn-padding-x: .875rem;
  --cnvs-btn-padding-y: .25rem;
  --cnvs-btn-fontsize: .75rem;
}

.button.button-small {
  --cnvs-btn-padding-x: 1.125rem;
  --cnvs-btn-padding-y: .5rem;
  --cnvs-btn-fontsize: .875rem;
}

.button.button-large {
  --cnvs-btn-padding-x: 1.625rem;
  --cnvs-btn-padding-y: .5rem;
  --cnvs-btn-fontsize: 1.125rem;
}

.button.button-xlarge {
  --cnvs-btn-padding-x: 2.25rem;
  --cnvs-btn-padding-y: .625rem;
  --cnvs-btn-fontsize: 1.25rem;
}

.button-desc {
  --cnvs-btn-padding-x: 2.25rem;
  --cnvs-btn-padding-y: 1.5rem;
  --cnvs-btn-fontsize: 1.25rem;
  --cnvs-btn-lineheight: 1;
  text-align: left;
  font-family: var(--cnvs-body-font);
  font-weight: 500;
}

.button-desc span {
  font-size: .875rem;
  font-weight: 400;
  font-family: var(--cnvs-secondary-font);
  text-transform: none;
  margin-top: .75rem;
  display: block;
}

.button-desc i {
  text-align: center;
  width: 3.42857rem;
  margin-right: 12px;
  font-size: 3.42857rem;
}

.button-desc.text-end i {
  margin: 0 0 0 12px;
}

.button-desc div {
  display: inline-block;
}

.button-rounded {
  border-radius: .25rem;
}

.button.button-border {
  --cnvs-btn-border-color: var(--cnvs-btn-color-dark);
  border: var(--cnvs-btn-border-width) solid var(--cnvs-btn-border-color);
  color: var(--cnvs-btn-border-color);
  background: none;
}

.button.button-border.button-desc {
  line-height: 1;
}

.button.button-border:not(.button-fill):hover {
  background-color: var(--cnvs-btn-color);
  color: #fff;
  border-color: #0000 !important;
}

.button.button-border.button-light {
  --cnvs-btn-border-color: #fff;
}

.button.button-border.button-light:hover {
  color: var(--cnvs-btn-color-dark);
  background-color: #fff;
}

.button.button-3d {
  border-radius: .25rem;
  transition: none;
  box-shadow: inset 0 -3px #00000026;
}

.button.button-3d:hover {
  background-color: var(--cnvs-btn-color);
  opacity: .9;
}

.button.button-reveal {
  padding-left: 28px;
  padding-right: 28px;
  overflow: hidden;
}

.button.button-reveal i {
  background-color: #00000026;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 100%;
  margin: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: -32px;
}

.button.button-reveal.button-border i {
  height: calc(100% + var(--cnvs-btn-border-width) * 2);
  top: -2px;
}

.button.button-reveal.text-end i {
  left: auto;
  right: -32px;
}

.button.button-reveal span {
  display: inline-block;
  position: relative;
  left: 0;
}

.button.button-reveal.button-mini {
  padding-left: 17px;
  padding-right: 17px;
}

.button.button-reveal.button-mini i {
  width: 22px;
  left: -22px;
}

.button.button-reveal.button-mini.text-end i {
  left: auto;
  right: -22px;
}

.button.button-reveal.button-mini:hover span {
  left: 11px;
}

.button.button-reveal.button-mini.text-end:hover span {
  left: -11px;
}

.button.button-reveal.button-small {
  padding-left: 20px;
  padding-right: 20px;
}

.button.button-reveal.button-small i {
  width: 26px;
  left: -26px;
}

.button.button-reveal.button-small.text-end i {
  left: auto;
  right: -26px;
}

.button.button-reveal.button-small:hover span {
  left: 13px;
}

.button.button-reveal.button-small.text-end:hover span {
  left: -13px;
}

.button.button-reveal.button-large {
  padding-left: 32px;
  padding-right: 32px;
}

.button.button-reveal.button-large i {
  width: 38px;
  left: -38px;
}

.button.button-reveal.button-large.text-end i {
  left: auto;
  right: -38px;
}

.button.button-reveal.button-large:hover span {
  left: 19px;
}

.button.button-reveal.button-large.text-end:hover span {
  left: -19px;
}

.button.button-reveal.button-xlarge {
  padding-left: 40px;
  padding-right: 40px;
}

.button.button-reveal.button-xlarge i {
  width: 44px;
  left: -44px;
}

.button.button-reveal.button-xlarge.text-end i {
  left: auto;
  right: -44px;
}

.button.button-reveal.button-xlarge:hover span {
  left: 22px;
}

.button.button-reveal.button-xlarge.text-end:hover span {
  left: -22px;
}

.button.button-reveal:hover i {
  left: 0;
}

.button.button-reveal.text-end:hover i {
  left: auto;
  right: 0;
}

.button.button-reveal:hover span {
  left: 16px;
}

.button.button-reveal.text-end:hover span {
  left: -16px;
}

.button.button-reveal.button-light:hover {
  color: var(--cnvs-btn-color-light);
}

.button-reveal i, .button-reveal span {
  transition: left .3s, right .3s;
}

@media (prefers-reduced-motion: reduce) {
  .button-reveal i, .button-reveal span {
    transition: none;
  }
}

.button.button-full {
  white-space: normal;
  text-transform: none;
  border-radius: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 30px 0;
  font-size: 2.14rem;
  font-weight: 300;
  line-height: 1.6;
  display: block;
}

.button.button-full.button-light {
  border-bottom: 1px solid #00000026;
}

.button.button-full.button-light strong {
  border-bottom-color: var(--cnvs-btn-color-dark);
}

.button.button-full.button-light:hover strong {
  border-bottom-color: rgba(var(--cnvs-contrast-rgb), .2);
}

.button.button-full strong {
  border-bottom: 2px solid rgba(var(--cnvs-contrast-rgb), .2);
  font-weight: 700;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .button.button-full strong {
    transition: none;
  }
}

.button.button-circle {
  border-radius: 50rem;
}

.button-flat {
  --cnvs-btn-color-flat: var(--cnvs-btn-color);
  color: var(--cnvs-btn-color-flat);
  background-color: #fff;
  border: 0;
  position: relative;
  overflow: hidden;
}

.button-flat-border {
  border: var(--cnvs-btn-border-width) solid var(--cnvs-btn-color-flat);
}

.button-flat:before {
  content: "";
  background-color: var(--cnvs-btn-color-flat);
  opacity: .1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.button-flat:hover {
  background-color: var(--cnvs-btn-color);
  color: #fff;
}

.button-action {
  --cnvs-btn-action-padding-x: 1rem;
  --cnvs-btn-action-width: 2.625rem;
  --cnvs-btn-action-radius: 50rem;
  --cnvs-btn-action-border: 3px;
  --cnvs-btn-action-border-color: var(--cnvs-themecolor);
  --cnvs-btn-action-transition-speed: .2s;
  --cnvs-btn-action-icon-move: -4px;
  border-radius: var(--cnvs-btn-action-radius);
  border: var(--cnvs-btn-action-border) solid var(--cnvs-btn-action-border-color);
  --cnvs-btn-padding-x: 0 !important;
}

.button-action span {
  padding-right: var(--cnvs-btn-action-padding-x);
  padding-left: calc(var(--cnvs-btn-action-padding-x)  + .5rem);
  z-index: 1;
  transition: color var(--cnvs-btn-action-transition-speed) ease;
  display: inline-block;
  position: relative;
}

.button-action i {
  color: #000;
  width: var(--cnvs-btn-action-width);
  z-index: 1;
  height: 100%;
  transition: transform var(--cnvs-btn-action-transition-speed) ease;
  margin: 0;
}

.button-action:after {
  content: "";
  color: #000;
  width: var(--cnvs-btn-action-width);
  text-align: center;
  border-radius: var(--cnvs-btn-action-radius);
  height: 100%;
  transition: width var(--cnvs-btn-action-transition-speed) ease, height var(--cnvs-btn-action-transition-speed) ease;
  will-change: width, height;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.button-action:hover {
  background-color: inherit;
}

.button-action:hover:after {
  width: 100%;
}

.button-action:hover i {
  transform: translateX(var(--cnvs-btn-action-icon-move));
}

.button-action:hover span {
  color: #000;
}

.button-action.button-large {
  --cnvs-btn-action-width: 3rem;
}

.button-action.button-xlarge {
  --cnvs-btn-action-border: 4px;
  --cnvs-btn-action-width: 3.5rem;
}

.button-shadow {
  --cnvs-btn-shadow-size: 4px;
  --cnvs-btn-shadow-color: var(--cnvs-btn-color);
  box-shadow: var(--cnvs-btn-shadow-size) var(--cnvs-btn-shadow-size) 0px 0px var(--cnvs-btn-shadow-color);
}

.button-shadow-dark {
  --cnvs-btn-shadow-color: var(--bs-dark);
}

.button-shadow-contrast {
  --cnvs-btn-shadow-color: var(--cnvs-contrast-900);
}

.button-shadow:not(.button-shadow-nohover):hover {
  box-shadow: none;
}

.button-shadow-effect:not(.button-shadow-nohover):hover {
  transform: translate3d(var(--cnvs-btn-shadow-size), var(--cnvs-btn-shadow-size), 0);
}

.button.button-border.button-fill {
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.button.button-border.button-fill span {
  position: relative;
}

.button.button-border.button-fill:hover {
  color: #fff;
  background: none !important;
}

.button.button-border.button-fill.button-light:hover {
  border-color: var(--cnvs-btn-color-light) !important;
  color: var(--cnvs-btn-color-dark) !important;
}

.button.button-border.button-fill.button-light :before {
  background-color: var(--cnvs-btn-color-light);
}

.button.button-border.button-fill:before {
  content: "";
  background-color: var(--cnvs-contrast-900);
  -webkit-backface-visibility: hidden;
  width: 0;
  height: 100%;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .button.button-border.button-fill:before {
    transition: none;
  }
}

.button.button-border.button-fill.fill-from-right:before {
  left: auto;
  right: 0;
}

.button.button-border.button-fill:hover:before {
  width: 100%;
}

.button.button-border.button-fill.fill-from-top:before, .button.button-border.button-fill.fill-from-bottom:before {
  transition: all .3s;
}

@media (prefers-reduced-motion: reduce) {
  .button.button-border.button-fill.fill-from-top:before, .button.button-border.button-fill.fill-from-bottom:before {
    transition: none;
  }
}

.button.button-border.button-fill.fill-from-top:before {
  width: 100%;
  height: 0;
}

.button.button-border.button-fill.fill-from-top:hover:before {
  height: 100%;
}

.button.button-border.button-fill.fill-from-bottom:before {
  width: 100%;
  height: 0;
  top: auto;
  bottom: 0;
}

.button.button-border.button-fill.fill-from-bottom:hover:before {
  height: 100%;
}

.button-icon-effect {
  vertical-align: top;
  overflow: hidden;
}

.button-icon-effect i {
  --cnvs-btn-icon-margin-offset: .25rem;
}

.button-icon-effect.button-icon-flip-x i {
  animation: .3s buttonIconSlideBack;
}

.button-icon-effect.button-icon-flip-x:hover i {
  animation: .5s forwards buttonIconSlideFront;
}

@keyframes buttonIconSlideBack {
  0% {
    transform: translateX(0);
  }

  25% {
    opacity: 0;
  }

  26% {
    transform: translateX(-100%);
  }

  27% {
    transform: translateX(100%);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes buttonIconSlideFront {
  0% {
    transform: translateX(0);
  }

  25% {
    opacity: 0;
  }

  26% {
    transform: translateX(100%);
  }

  27% {
    transform: translateX(-100%);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

.button-icon-effect.button-icon-flip-y i {
  animation: .3s buttonIconSlideUp;
}

.button-icon-effect.button-icon-flip-y:hover i {
  animation: .5s forwards buttonIconSlideDown;
}

@keyframes buttonIconSlideUp {
  0% {
    transform: translateY(0);
  }

  25% {
    opacity: 0;
  }

  26% {
    transform: translateY(-100%);
  }

  27% {
    transform: translateY(100%);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes buttonIconSlideDown {
  0% {
    transform: translateY(0);
  }

  25% {
    opacity: 0;
  }

  26% {
    transform: translateY(100%);
  }

  27% {
    transform: translateY(-100%);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

.button-text-effect {
  vertical-align: top;
  overflow: hidden;
}

.button-text-effect .button-inner {
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.button-text-effect .button-inner span {
  transition: all .3s cubic-bezier(.17, .75, .8, 1);
}

.button-text-effect .button-inner span:first-child {
  opacity: 1;
  display: inline-block;
}

.button-text-effect .button-inner span:nth-child(2) {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.button-text-effect:hover span:first-child {
  opacity: 0;
}

.button-text-effect:hover span:nth-child(2) {
  opacity: 1;
}

.button-text-effect.button-text-flip-x span:first-child {
  transform: translate(0);
}

.button-text-effect.button-text-flip-x span:nth-child(2) {
  transform: translate(100%);
}

.button-text-effect.button-text-flip-x:hover span:first-child {
  transform: translate(-100%);
}

.button-text-effect.button-text-flip-x:hover span:nth-child(2), .button-text-effect.button-text-flip-y span:first-child {
  transform: translate(0);
}

.button-text-effect.button-text-flip-y span:nth-child(2) {
  transform: translate(0, 100%);
}

.button-text-effect.button-text-flip-y:hover span:first-child {
  transform: translate(0, -100%);
}

.button-text-effect.button-text-flip-y:hover span:nth-child(2) {
  transform: translate(0);
}

.button-red {
  --cnvs-btn-color: #c02942;
}

.button-red.button-3d:hover, .button-red.button-reveal:hover, .button-red.button-border:hover {
  --cnvs-btn-color: #c02942 !important;
}

.button-red.button-border.button-fill:before {
  background-color: #c02942;
}

.button-red.button-action {
  --cnvs-btn-action-border-color: #c02942;
}

.button-border.button-red, .button-border.button-light.button-red {
  color: #c02942;
  border-color: #c02942;
}

.button-teal {
  --cnvs-btn-color: #53777a;
}

.button-teal.button-3d:hover, .button-teal.button-reveal:hover, .button-teal.button-border:hover {
  --cnvs-btn-color: #53777a !important;
}

.button-teal.button-border.button-fill:before {
  background-color: #53777a;
}

.button-teal.button-action {
  --cnvs-btn-action-border-color: #53777a;
}

.button-border.button-teal, .button-border.button-light.button-teal {
  color: #53777a;
  border-color: #53777a;
}

.button-yellow {
  --cnvs-btn-color: #ecd078;
}

.button-yellow.button-3d:hover, .button-yellow.button-reveal:hover, .button-yellow.button-border:hover {
  --cnvs-btn-color: #ecd078 !important;
}

.button-yellow.button-border.button-fill:before {
  background-color: #ecd078;
}

.button-yellow.button-action {
  --cnvs-btn-action-border-color: #ecd078;
}

.button-border.button-yellow, .button-border.button-light.button-yellow {
  color: #ecd078;
  border-color: #ecd078;
}

.button-purple {
  --cnvs-btn-color: #5d4157;
}

.button-purple.button-3d:hover, .button-purple.button-reveal:hover, .button-purple.button-border:hover {
  --cnvs-btn-color: #5d4157 !important;
}

.button-purple.button-border.button-fill:before {
  background-color: #5d4157;
}

.button-purple.button-action {
  --cnvs-btn-action-border-color: #5d4157;
}

.button-border.button-purple, .button-border.button-light.button-purple {
  color: #5d4157;
  border-color: #5d4157;
}

.button-pink {
  --cnvs-btn-color: #f89fa1;
}

.button-pink.button-3d:hover, .button-pink.button-reveal:hover, .button-pink.button-border:hover {
  --cnvs-btn-color: #f89fa1 !important;
}

.button-pink.button-border.button-fill:before {
  background-color: #f89fa1;
}

.button-pink.button-action {
  --cnvs-btn-action-border-color: #f89fa1;
}

.button-border.button-pink, .button-border.button-light.button-pink {
  color: #f89fa1;
  border-color: #f89fa1;
}

.button-blue {
  --cnvs-btn-color: #1265a8;
}

.button-blue.button-3d:hover, .button-blue.button-reveal:hover, .button-blue.button-border:hover {
  --cnvs-btn-color: #1265a8 !important;
}

.button-blue.button-border.button-fill:before {
  background-color: #1265a8;
}

.button-blue.button-action {
  --cnvs-btn-action-border-color: #1265a8;
}

.button-border.button-blue, .button-border.button-light.button-blue {
  color: #1265a8;
  border-color: #1265a8;
}

.button-green {
  --cnvs-btn-color: #59ba41;
}

.button-green.button-3d:hover, .button-green.button-reveal:hover, .button-green.button-border:hover {
  --cnvs-btn-color: #59ba41 !important;
}

.button-green.button-border.button-fill:before {
  background-color: #59ba41;
}

.button-green.button-action {
  --cnvs-btn-action-border-color: #59ba41;
}

.button-border.button-green, .button-border.button-light.button-green {
  color: #59ba41;
  border-color: #59ba41;
}

.button-brown {
  --cnvs-btn-color: #774f38;
}

.button-brown.button-3d:hover, .button-brown.button-reveal:hover, .button-brown.button-border:hover {
  --cnvs-btn-color: #774f38 !important;
}

.button-brown.button-border.button-fill:before {
  background-color: #774f38;
}

.button-brown.button-action {
  --cnvs-btn-action-border-color: #774f38;
}

.button-border.button-brown, .button-border.button-light.button-brown {
  color: #774f38;
  border-color: #774f38;
}

.button-aqua {
  --cnvs-btn-color: #40c0cb;
}

.button-aqua.button-3d:hover, .button-aqua.button-reveal:hover, .button-aqua.button-border:hover {
  --cnvs-btn-color: #40c0cb !important;
}

.button-aqua.button-border.button-fill:before {
  background-color: #40c0cb;
}

.button-aqua.button-action {
  --cnvs-btn-action-border-color: #40c0cb;
}

.button-border.button-aqua, .button-border.button-light.button-aqua {
  color: #40c0cb;
  border-color: #40c0cb;
}

.button-lime {
  --cnvs-btn-color: #aee239;
}

.button-lime.button-3d:hover, .button-lime.button-reveal:hover, .button-lime.button-border:hover {
  --cnvs-btn-color: #aee239 !important;
}

.button-lime.button-border.button-fill:before {
  background-color: #aee239;
}

.button-lime.button-action {
  --cnvs-btn-action-border-color: #aee239;
}

.button-border.button-lime, .button-border.button-light.button-lime {
  color: #aee239;
  border-color: #aee239;
}

.button-leaf {
  --cnvs-btn-color: #a8caba;
}

.button-leaf.button-3d:hover, .button-leaf.button-reveal:hover, .button-leaf.button-border:hover {
  --cnvs-btn-color: #a8caba !important;
}

.button-leaf.button-border.button-fill:before {
  background-color: #a8caba;
}

.button-leaf.button-action {
  --cnvs-btn-action-border-color: #a8caba;
}

.button-border.button-leaf, .button-border.button-light.button-leaf {
  color: #a8caba;
  border-color: #a8caba;
}

.button-dirtygreen {
  --cnvs-btn-color: #1693a5;
}

.button-dirtygreen.button-3d:hover, .button-dirtygreen.button-reveal:hover, .button-dirtygreen.button-border:hover {
  --cnvs-btn-color: #1693a5 !important;
}

.button-dirtygreen.button-border.button-fill:before {
  background-color: #1693a5;
}

.button-dirtygreen.button-action {
  --cnvs-btn-action-border-color: #1693a5;
}

.button-border.button-dirtygreen, .button-border.button-light.button-dirtygreen {
  color: #1693a5;
  border-color: #1693a5;
}

.button-amber {
  --cnvs-btn-color: #eb9c4d;
}

.button-amber.button-3d:hover, .button-amber.button-reveal:hover, .button-amber.button-border:hover {
  --cnvs-btn-color: #eb9c4d !important;
}

.button-amber.button-border.button-fill:before {
  background-color: #eb9c4d;
}

.button-amber.button-action {
  --cnvs-btn-action-border-color: #eb9c4d;
}

.button-border.button-amber, .button-border.button-light.button-amber {
  color: #eb9c4d;
  border-color: #eb9c4d;
}

.button-black {
  --cnvs-btn-color: #111;
}

.button-black.button-3d:hover, .button-black.button-reveal:hover, .button-black.button-border:hover {
  --cnvs-btn-color: #111 !important;
}

.button-black.button-border.button-fill:before {
  background-color: #111;
}

.button-black.button-action {
  --cnvs-btn-action-border-color: #111;
}

.button-border.button-black, .button-border.button-light.button-black {
  color: #111;
  border-color: #111;
}

.button-light.button-yellow {
  --cnvs-btn-color: #ecd078;
}

.button-border.button-yellow:hover, .button-border.button-yellow.button-fill.button-light:hover {
  color: #333 !important;
}

.button-white {
  --cnvs-btn-color: var(--bs-gray-100);
}

.button-3d.button-white:hover {
  color: var(--bs-gray-900) !important;
}

.button-reveal.button-white:hover {
  --cnvs-btn-color: var(--bs-gray-100) !important;
}

.button[class*="gradient-"] {
  border: 0;
}

.button.button-nohover:hover {
  opacity: inherit !important;
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important;
}

.button.disabled, .button:disabled {
  opacity: .65 !important;
  pointer-events: none !important;
}

.promo {
  --cnvs-promo-span-font-size: 1.125rem;
  --cnvs-promo-uppercase-font-size: 1.25rem;
  --cnvs-promo-uppercase-span-font-size: 1rem;
  --cnvs-promo-border: 1px solid var(--cnvs-contrast-200);
  --cnvs-promo-light: rgba(var(--cnvs-contrast-rgb), .05);
  --cnvs-promo-light-text-color: var(--cnvs-heading-color);
  --cnvs-promo-dark: #343a40;
  --cnvs-promo-dark-text-color: #fff;
  --cnvs-promo-parallax-padding: 5rem;
  position: relative;
}

.promo h3, .promo .h3 {
  margin-bottom: 0;
  font-weight: bold;
}

.promo h3 + span, .promo .h3 + span {
  color: var(--cnvs-promo-light-text-color);
  font-weight: 300;
  font-size: var(--cnvs-promo-span-font-size);
  margin-top: 6px;
  display: block;
}

.promo-uppercase {
  text-transform: uppercase;
}

.promo-uppercase h3, .promo-uppercase .h3 {
  font-size: var(--cnvs-promo-uppercase-span-font-size);
}

.promo-uppercase h3 + span, .promo-uppercase .h3 + span {
  font-size: var(--cnvs-promo-span-font-size);
}

.promo-border {
  border: var(--cnvs-promo-border);
  border-radius: 3px;
}

.promo-light {
  background-color: var(--cnvs-promo-light);
  border-radius: 3px;
}

.promo-dark {
  background-color: var(--cnvs-promo-dark);
  border-radius: 3px;
  border: 0 !important;
}

.promo-dark h3, .promo-dark .h3 {
  color: var(--cnvs-promo-dark-text-color);
}

.promo-dark h3 + span, .promo-dark .h3 + span {
  color: var(--bs-gray-100);
  font-weight: 400;
}

.promo-full {
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
}

.promo.parallax {
  padding: var(--cnvs-promo-parallax-padding) 0 !important;
}

.promo-mini {
  text-align: left;
}

.promo-mini h3, .promo-mini .h3 {
  font-size: var(--cnvs-promo-uppercase-span-font-size);
}

.promo-mini.promo-uppercase h3, .promo-mini.promo-uppercase .h3 {
  font-size: var(--cnvs-promo-span-font-size);
}

.feature-box {
  --cnvs-featured-box-icon: 4rem;
  --cnvs-featured-box-padding-x: .75rem;
  --cnvs-featured-box-icon-size: 1.75rem;
  --cnvs-featured-box-font-size: 1.125rem;
  --cnvs-featured-box-font-color: var(--cnvs-heading-color);
  --cnvs-featured-box-font-weight: 600;
  --cnvs-featured-box-font-tt: none;
  --cnvs-featured-box-icon-sm: calc(4rem * .5);
  --cnvs-featured-box-padding-x-sm: calc(.75rem / 1.5);
  --cnvs-featured-box-icon-size-sm: calc(1.75rem / 1.75);
  --cnvs-featured-box-icon-lg: calc(4rem * 1.5);
  --cnvs-featured-box-icon-size-lg: calc(1.75rem * 1.42857);
  --cnvs-featured-box-icon-xl: calc(4rem * 2);
  --cnvs-featured-box-icon-size-xl: calc(1.75rem * 2.57143);
  --cnvs-featured-box-outline-border: 1px;
  --cnvs-featured-box-outline-padding: .25rem;
  --cnvs-featured-box-rounded: .25rem;
  --cnvs-featured-box-bg-light: var(--cnvs-contrast-200);
  --cnvs-featured-box-bg-dark: #343a40;
  --cnvs-featured-box-plain-font-size: 3rem;
  --cnvs-featured-box-plain-icon-size: 3rem;
  margin-left: calc(-1 * var(--cnvs-featured-box-padding-x));
  margin-right: calc(-1 * var(--cnvs-featured-box-padding-x));
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.feature-box:first-child {
  margin-top: 0;
}

.fbox-icon {
  width: calc(var(--cnvs-featured-box-icon)  + var(--cnvs-featured-box-padding-x) * 2);
  height: var(--cnvs-featured-box-icon);
  padding: 0 var(--cnvs-featured-box-padding-x);
}

.fbox-icon a, .fbox-icon i, .fbox-icon img {
  color: #fff;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.fbox-icon i, .fbox-icon img {
  background-color: var(--cnvs-themecolor);
  border-radius: 50%;
}

.fbox-icon i {
  font-style: normal;
  font-size: var(--cnvs-featured-box-icon-size);
  line-height: var(--cnvs-featured-box-icon);
  text-align: center;
}

.fbox-content {
  min-width: 0;
  max-width: 100%;
  padding: 0 var(--cnvs-featured-box-padding-x);
  flex-grow: 1;
  flex-basis: 0;
}

.fbox-content h3, .fbox-content .h3 {
  font-size: var(--cnvs-featured-box-font-size);
  font-weight: var(--cnvs-featured-box-font-weight);
  font-family: var(--cnvs-primary-font);
  text-transform: var(--cnvs-featured-box-font-tt);
  color: var(--cnvs-featured-box-font-color);
  margin-bottom: 0;
}

.fbox-content p {
  color: var(--cnvs-contrast-600);
  margin-top: .5rem;
  margin-bottom: 0;
}

.fbox-content .before-heading, .fbox-content-sm h3, .fbox-content-sm .h3, .fbox-content-sm p {
  font-size: calc(var(--cnvs-featured-box-font-size) / 1.142);
}

.fbox-content-sm .before-heading {
  font-size: calc(var(--cnvs-featured-box-font-size) / 1.333);
}

.fbox-content-lg h3, .fbox-content-lg .h3 {
  font-size: calc(var(--cnvs-featured-box-font-size) * 1.25);
}

.fbox-content-lg p {
  font-size: calc(var(--cnvs-featured-box-font-size) * 1.25);
  margin-top: .75rem;
}

.fbox-content-lg .before-heading {
  font-size: var(--cnvs-featured-box-font-size);
}

.fbox-sm {
  --cnvs-featured-box-padding-x: var(--cnvs-featured-box-padding-x-sm);
  --cnvs-featured-box-icon: var(--cnvs-featured-box-icon-sm);
  --cnvs-featured-box-icon-size: var(--cnvs-featured-box-icon-size-sm);
}

.fbox-lg {
  --cnvs-featured-box-icon: var(--cnvs-featured-box-icon-lg);
  --cnvs-featured-box-icon-size: var(--cnvs-featured-box-icon-size-lg);
}

.fbox-xl {
  --cnvs-featured-box-icon: var(--cnvs-featured-box-icon-xl);
  --cnvs-featured-box-icon-size: var(--cnvs-featured-box-icon-size-xl);
}

.fbox-outline .fbox-icon a {
  border: var(--cnvs-featured-box-outline-border) solid var(--cnvs-themecolor);
  padding: var(--cnvs-featured-box-outline-padding);
  border-radius: 50%;
}

.fbox-outline .fbox-icon i {
  line-height: calc(var(--cnvs-featured-box-icon) / 1.1428);
}

.fbox-rounded .fbox-icon, .fbox-rounded .fbox-icon i, .fbox-rounded .fbox-icon img {
  border-radius: var(--cnvs-featured-box-rounded) !important;
}

.fbox-rounded .fbox-lg {
  --cnvs-featured-box-rounded: calc(var(--cnvs-featured-box-rounded) / .75);
}

.fbox-rounded .fbox-xl {
  --cnvs-featured-box-rounded: calc(var(--cnvs-featured-box-rounded) * 2);
}

.fbox-light.fbox-outline .fbox-icon a {
  border-color: var(--cnvs-contrast-200);
}

.fbox-light .fbox-icon i, .fbox-light .fbox-icon img {
  border: var(--cnvs-featured-box-outline-border) solid var(--cnvs-contrast-200);
  background-color: var(--cnvs-featured-box-bg-light);
  color: var(--cnvs-heading-color);
}

.fbox-dark.fbox-outline .fbox-icon a {
  border-color: var(--bs-gray-700);
}

.fbox-dark.fbox-outline .fbox-icon i, .fbox-dark.fbox-outline .fbox-icon img {
  background-color: var(--cnvs-featured-box-bg-dark);
}

.fbox-border .fbox-icon a {
  border: var(--cnvs-featured-box-outline-border) solid var(--cnvs-themecolor);
  border-radius: 50%;
}

.fbox-border .fbox-icon i, .fbox-border .fbox-icon img {
  color: var(--cnvs-themecolor);
  border: none;
  background-color: #0000 !important;
}

.fbox-border.fbox-light .fbox-icon a {
  border-color: var(--cnvs-contrast-200);
}

.fbox-border.fbox-light .fbox-icon i, .fbox-border.fbox-light .fbox-icon img {
  color: var(--cnvs-contrast-600);
}

.fbox-border.fbox-dark .fbox-icon a {
  border-color: var(--cnvs-contrast-200);
}

.fbox-border.fbox-dark .fbox-icon i, .fbox-border.fbox-dark .fbox-icon img {
  color: var(--cnvs-heading-color);
}

.fbox-plain .fbox-icon {
  border: none !important;
  height: auto !important;
}

.fbox-plain .fbox-icon i, .fbox-plain .fbox-icon img {
  color: var(--cnvs-themecolor);
  background-color: #0000;
  border: none;
  border-radius: 0;
}

.fbox-plain .fbox-icon i {
  font-size: var(--cnvs-featured-box-plain-font-size);
  line-height: 1;
}

.fbox-plain .fbox-icon img {
  height: auto;
}

.fbox-plain.fbox-image .fbox-icon {
  width: auto;
}

.fbox-plain.fbox-image .fbox-icon img {
  width: auto;
  display: inline-block;
}

.fbox-plain.fbox-light .fbox-icon i, .fbox-plain.fbox-light .fbox-icon img {
  color: var(--cnvs-contrast-600);
}

.fbox-plain.fbox-dark .fbox-icon i, .fbox-plain.fbox-dark .fbox-icon img {
  color: var(--cnvs-contrast-700);
}

.fbox-plain.fbox-sm .fbox-icon i {
  font-size: calc(var(--cnvs-featured-box-plain-font-size) / 1.5);
}

.fbox-plain.fbox-lg .fbox-icon i {
  font-size: calc(var(--cnvs-featured-box-plain-font-size) * 1.5);
}

.fbox-plain.fbox-xl .fbox-icon i {
  font-size: calc(var(--cnvs-featured-box-plain-font-size) * 2);
}

.fbox-center {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.fbox-center .fbox-icon {
  margin-bottom: 1.5rem;
}

.fbox-content h3 + .fbox-icon, .fbox-content .h3 + .fbox-icon {
  margin-top: 1.5rem;
  margin-bottom: 0 !important;
}

.fbox-content h3 span.subtitle, .fbox-content .h3 span.subtitle {
  color: var(--cnvs-heading-color);
  text-transform: none;
  margin-top: 5px;
  font-weight: 300;
  display: block;
}

.fbox-center.fbox-sep:not(.fbox-bg) h3:after, .fbox-center.fbox-sep:not(.fbox-bg) .h3:after {
  content: "";
  background-color: var(--cnvs-contrast-600);
  width: 2rem;
  height: 2px;
  margin: 1.25rem auto;
  transition: width .3s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .fbox-center.fbox-sep:not(.fbox-bg) h3:after, .fbox-center.fbox-sep:not(.fbox-bg) .h3:after {
    transition: none;
  }
}

.fbox-center.fbox-sep:not(.fbox-bg):hover h3:after, .fbox-center.fbox-sep:not(.fbox-bg):hover .h3:after {
  width: 3rem;
}

.fbox-center.fbox-italic p {
  font-style: italic;
  font-family: var(--cnvs-body-font);
}

.fbox-bg.fbox-center {
  border: 1px solid var(--cnvs-featured-box-bg-light);
  border-radius: 5px;
  margin-top: 2rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0 2rem 2rem;
}

.fbox-bg.fbox-center .fbox-icon {
  background-color: var(--cnvs-body-bg);
  margin-top: -2rem;
}

.fbox-bg.fbox-center .fbox-lg {
  margin-top: 3rem;
}

.fbox-bg.fbox-center .fbox-lg .fbox-icon {
  margin-top: -3rem;
}

.fbox-bg.fbox-center .fbox-xl {
  margin-top: 4rem;
}

.fbox-bg.fbox-center .fbox-xl .fbox-icon {
  margin-top: -4rem;
}

.fbox-effect .fbox-icon i {
  z-index: 1;
}

.fbox-effect .fbox-icon i:after {
  pointer-events: none;
  content: "";
  box-sizing: content-box;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.fbox-rounded.fbox-effect .fbox-icon i:after {
  border-radius: var(--cnvs-featured-box-rounded);
}

.fbox-effect .fbox-icon i {
  transition: background-color .3s, color .3s;
}

@media (prefers-reduced-motion: reduce) {
  .fbox-effect .fbox-icon i {
    transition: none;
  }
}

.fbox-effect .fbox-icon i:after {
  box-shadow: 0 0 0 2px var(--cnvs-featured-box-bg-dark);
  opacity: 0;
  padding: 3px;
  transition: -webkit-transform .3s, opacity .3s;
  top: -3px;
  left: -3px;
  transform: scale(.8);
}

@media (prefers-reduced-motion: reduce) {
  .fbox-effect .fbox-icon i:after {
    transition: none;
  }
}

.fbox-effect .fbox-icon i:hover, .fbox-effect:hover .fbox-icon i {
  background-color: var(--cnvs-featured-box-bg-dark);
  color: #fff;
}

.fbox-effect.fbox-dark .fbox-icon i:after {
  box-shadow: 0 0 0 2px var(--cnvs-themecolor);
}

.fbox-effect.fbox-dark .fbox-icon i:hover, .fbox-effect.fbox-dark:hover .fbox-icon i {
  background-color: var(--cnvs-themecolor);
}

.fbox-effect .fbox-icon i:hover:after, .fbox-effect:hover .fbox-icon i:after {
  opacity: 1;
  transform: scale(1);
}

.fbox-border.fbox-effect .fbox-icon i {
  transition: color .5s, box-shadow .5s, background-color .5s;
}

@media (prefers-reduced-motion: reduce) {
  .fbox-border.fbox-effect .fbox-icon i {
    transition: none;
  }
}

.fbox-border.fbox-effect .fbox-icon i:after {
  z-index: -1;
  box-shadow: none;
  background-image: url("iconalt.45c6e5c6.svg");
  background-position: center;
  background-size: 100% 100%;
  padding: 2px;
  transition: -webkit-transform .5s, opacity .5s, background-color .5s;
  top: -2px;
  left: -2px;
}

@media (prefers-reduced-motion: reduce) {
  .fbox-border.fbox-effect .fbox-icon i:after {
    transition: none;
  }
}

.fbox-border.fbox-rounded.fbox-effect .fbox-icon i:after {
  border-radius: 3px;
}

.fbox-border.fbox-effect .fbox-icon i:hover, .fbox-border.fbox-effect:hover .fbox-icon i {
  color: #fff;
  box-shadow: 0 0 0 1px var(--cnvs-featured-box-bg-dark);
}

.fbox-border.fbox-effect .fbox-icon i:after {
  opacity: 0;
  background-color: var(--cnvs-featured-box-bg-dark);
  transform: scale(1.3);
}

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover, .fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i {
  box-shadow: 0 0 0 1px var(--cnvs-themecolor);
}

.fbox-border.fbox-effect.fbox-dark .fbox-icon i:after {
  background-color: var(--cnvs-themecolor);
}

.fbox-border.fbox-effect .fbox-icon i:hover:after, .fbox-border.fbox-effect:hover .fbox-icon i:after {
  opacity: 1;
  transform: scale(1);
}

.media-box {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.media-box .fbox-media {
  width: 100%;
  height: auto;
  margin: 0 0 1.5rem;
  display: block;
  position: relative;
}

.media-box .fbox-media a, .media-box .fbox-media img {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.media-box .fbox-media iframe {
  display: block;
}

.media-box p {
  margin-top: 1rem;
}

.media-box.fbox-bg .fbox-media {
  margin: 0;
}

.media-box.fbox-bg .fbox-media img {
  border-radius: 5px 5px 0 0;
}

.media-box.fbox-bg .fbox-content {
  background-color: var(--cnvs-body-bg);
  border: 1px solid var(--cnvs-featured-box-bg-light);
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 1.5rem;
}

.media-box .fbox-content {
  -ms-flex-preferred-size: auto;
}

.process-steps {
  margin: 0 0 40px;
  list-style: none;
}

.process-steps li {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.process-steps li .i-style {
  z-index: 1;
  position: relative;
}

.process-steps li .i-circled {
  display: block;
  margin-bottom: 0 !important;
}

.process-steps li .i-bordered {
  z-index: 1;
  display: block;
  --cnvs-i-bg-color: var(--cnvs-body-bg) !important;
}

.process-steps li .i-bordered:hover {
  --cnvs-i-bg-color: var(--cnvs-contrast-900) !important;
}

.process-steps li .i-bordered.active {
  --cnvs-i-color: var(--cnvs-contrast-100);
  --cnvs-i-bg-color: var(--cnvs-themecolor) !important;
  border-color: var(--cnvs-themecolor) !important;
}

.process-steps li .i-bordered.active + h5, .process-steps li .i-bordered.active + .h5 {
  color: var(--cnvs-themecolor);
  font-weight: 600;
}

@media (width >= 992px) {
  .process-steps li:before, .process-steps li:after {
    content: "";
    margin-left: calc(var(--cnvs-i-size) / -2);
    border-top: 1px dashed var(--cnvs-contrast-300);
    width: 50%;
    position: absolute;
    top: 30px;
    left: 0;
  }

  .process-steps li:after {
    margin: 0 calc(var(--cnvs-i-size) / -2) 0 0;
    left: auto;
    right: 0;
  }

  .process-steps li:first-child:before, .process-steps li:last-child:after {
    display: none;
  }

  .process-steps li h5, .process-steps li .h5 {
    margin: 15px 0 0;
    font-size: 1rem;
    font-weight: 300;
  }
}

.style-msg, .style-msg2 {
  color: var(--cnvs-heading-color);
  border-left: 4px solid rgba(var(--cnvs-contrast-rgb), .1);
  margin-bottom: 20px;
  display: block;
  position: relative;
}

.style-msg2 {
  border-left-color: rgba(var(--cnvs-contrast-rgb), .1);
}

.primarymsg {
  color: #084298;
  background: #cfe2ff;
  border-color: #b6d4fe;
}

.secondarymsg {
  color: #41464b;
  background: #e2e3e5;
  border-color: #d3d6d8;
}

.successmsg {
  color: #0f5132;
  background: #d1e7dd;
  border-color: #badbcc;
}

.infomsg {
  color: #087990;
  background: #cff4fc;
  border-color: #b6effb;
}

.warningmsg {
  color: #997404;
  background: #fff3cd;
  border-color: #ffecb5;
}

.dangermsg {
  color: #842029;
  background: #f8d7da;
  border-color: #f5c2c7;
}

.lightmsg {
  color: #959596;
  background: #fefefe;
  border-color: #fdfdfe;
}

.darkmsg {
  color: #141619;
  background: #d3d3d4;
  border-color: #bcbebf;
}

.errormsg {
  color: #842029;
  background: #f8d7da;
  border-color: #f5c2c7;
}

.alertmsg {
  color: #997404;
  background: #fff3cd;
  border-color: #ffecb5;
}

.style-msg .sb-msg, .style-msg2 .msgtitle, .style-msg2 .sb-msg {
  text-shadow: 1px 1px 1px #fff9;
  border-left: 1px solid #ffffff80;
  padding: 1rem;
  font-size: .875rem;
  display: block;
}

.style-msg2 .msgtitle {
  background: #00000026;
  border-top: none;
  border-bottom: none;
  font-size: 1rem;
  font-weight: bold;
}

.style-msg-light .sb-msg, .style-msg-light .msgtitle {
  color: var(--bs-white);
  text-shadow: 1px 1px 1px #0000004d;
}

.style-msg2 .sb-msg {
  font-weight: normal;
}

.style-msg2 ol, .style-msg2 ul {
  margin: 0 0 0 30px;
}

.style-msg2 ol {
  list-style-type: decimal;
}

.style-msg2 ul {
  list-style-type: disc;
}

.alert i, .sb-msg i, .msgtitle i {
  text-align: center;
  width: .875rem;
  margin-right: 5px;
  font-size: .875rem;
  position: relative;
  top: 1px;
}

.style-msg .btn-close {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

[class^="i-"] {
  --cnvs-i-size: 3.25rem;
  --cnvs-i-border: 1px;
  --cnvs-i-fontsize: calc(var(--cnvs-i-size) / 2.66667);
  --cnvs-i-color: var(--cnvs-contrast-900);
  --cnvs-i-bg-color: transparent;
}

.i-style, .i-plain, .i-bordered, .social-icon {
  --cnvs-i-color: var(--cnvs-white);
  font-size: var(--cnvs-i-fontsize);
  color: var(--cnvs-i-color);
  cursor: pointer;
  font-style: normal;
  transition: all .3s;
  text-align: center !important;
}

@media (prefers-reduced-motion: reduce) {
  .i-style, .i-plain, .i-bordered, .social-icon {
    transition: none;
  }
}

.i-style, .i-bordered, .i-plain {
  --cnvs-i-size: 3.25rem;
  --cnvs-i-border: 1px;
  --cnvs-i-fontsize: calc(var(--cnvs-i-size) / 2.66667);
  --cnvs-i-bg-color: var(--bs-gray-800);
  background-color: var(--cnvs-i-bg-color);
  display: inline-block;
  width: var(--cnvs-i-size) !important;
  height: var(--cnvs-i-size) !important;
  line-height: var(--cnvs-i-size) !important;
}

.i-bordered {
  --cnvs-i-color: var(--cnvs-contrast-900);
  border: var(--cnvs-i-border) solid rgba(var(--cnvs-contrast-rgb), .5);
  line-height: calc(var(--cnvs-i-size)  - var(--cnvs-i-border) * 2) !important;
}

.i-plain {
  --cnvs-i-size: 3rem;
  --cnvs-i-fontsize: 1.625rem;
  --cnvs-i-color: var(--bs-contrast-900);
}

.i-bordered, .i-plain {
  --cnvs-i-bg-color: transparent !important;
}

.i-light {
  --cnvs-i-color: var(--bs-gray-900);
  --cnvs-i-bg-color: var(--bs-gray-100) !important;
}

.i-alt {
  background-image: linear-gradient(135deg, #ffffff1a 50%, #fff0 50%);
}

.i-style:hover {
  --cnvs-i-color: var(--bs-gray-100);
  --cnvs-i-bg-color: var(--cnvs-themecolor) !important;
}

.i-bordered:hover {
  border-color: var(--cnvs-i-color);
  --cnvs-i-bg-color: var(--cnvs-contrast-800) !important;
  --cnvs-i-color: var(--cnvs-contrast-0) !important;
}

.i-plain:hover {
  --cnvs-i-color: var(--cnvs-themecolor);
  --cnvs-i-bg-color: transparent !important;
}

.i-small.i-style {
  --cnvs-i-size: 1.75rem;
}

.i-small.i-plain {
  --cnvs-i-size: 1.5rem;
  --cnvs-i-fontsize: 1rem;
}

.i-medium.i-style {
  --cnvs-i-size: 2.25rem;
}

.i-medium.i-plain {
  --cnvs-i-fontsize: 1.25rem;
}

.i-large.i-style {
  --cnvs-i-size: 4rem;
}

.i-large.i-plain {
  --cnvs-i-size: 3.75rem;
  --cnvs-i-fontsize: 2.5rem;
}

.i-xlarge.i-style {
  --cnvs-i-size: 5.25rem;
}

.i-xlarge.i-plain {
  --cnvs-i-size: 4.5rem;
  --cnvs-i-fontsize: 3rem;
}

.i-xxlarge.i-style {
  --cnvs-i-size: 6.5rem;
}

.i-xxlarge.i-plain {
  --cnvs-i-size: 5.25rem;
  --cnvs-i-fontsize: 3.5rem;
}

.iconlist {
  --cnvs-iconlist-size: 1rem;
  --cnvs-iconlist-color: var(--cnvs-themecolor);
  --cnvs-iconlist-margin: .5rem;
  --cnvs-iconlist-gutter: .75rem;
  --cnvs-iconlist-offset: 0;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.iconlist li {
  font-size: var(--cnvs-iconlist-size);
  flex-wrap: wrap;
  align-items: baseline;
  display: flex;
  position: relative;
}

.iconlist li + li {
  margin-top: var(--cnvs-iconlist-margin);
}

.iconlist li > * {
  flex: 1 0;
}

.iconlist > li i:first-child, .iconlist > li img:first-child {
  top: var(--cnvs-iconlist-offset);
  text-align: center;
  width: var(--cnvs-iconlist-size);
  height: var(--cnvs-iconlist-size);
  margin-right: var(--cnvs-iconlist-gutter);
  color: var(--cnvs-iconlist-color);
  flex: none;
  position: relative;
}

.iconlist ul {
  width: 100%;
  padding: var(--cnvs-iconlist-margin) 0 var(--cnvs-iconlist-margin) calc(var(--cnvs-iconlist-margin) * 4);
  flex: none;
  list-style-type: none;
}

.iconlist ul li {
  margin-top: var(--cnvs-iconlist-margin);
}

.iconlist.iconlist-lg {
  --cnvs-iconlist-size: 1.125rem;
  --cnvs-iconlist-margin: .75rem;
  --cnvs-iconlist-gutter: .75rem;
}

.iconlist.iconlist-xl {
  --cnvs-iconlist-size: 1.5rem;
  --cnvs-iconlist-margin: .75rem;
  --cnvs-iconlist-gutter: .875rem;
}

.social-icon {
  --cnvs-socialicon-size: 2.5rem;
  --cnvs-socialicon-gap: 5px;
  --cnvs-socialicon-fontsize: calc(var(--cnvs-socialicon-size) / 2.5);
  --cnvs-socialicon-border: 1px;
  --cnvs-socialicon-border-color: var(--cnvs-contrast-600);
  --cnvs-socialicon-lineheight: calc(var(--cnvs-socialicon-size)  - calc(var(--cnvs-socialicon-border) * 2));
  --cnvs-socialicon-rounded: .375rem;
  --cnvs-socialicon-color: var(--bs-contrast-900);
  float: left;
  margin: 0 var(--cnvs-socialicon-gap) var(--cnvs-socialicon-gap) 0;
  width: var(--cnvs-socialicon-size);
  height: var(--cnvs-socialicon-size);
  font-size: var(--cnvs-socialicon-fontsize);
  color: var(--cnvs-socialicon-color);
  text-shadow: none;
  border: var(--cnvs-socialicon-border) solid var(--cnvs-socialicon-border-color);
  border-radius: var(--cnvs-socialicon-rounded);
  display: block;
  overflow: hidden;
  line-height: var(--cnvs-socialicon-lineheight) !important;
}

[class*="-flex"] > .social-icon, [class*="row"] > .social-icon {
  float: none;
  margin-right: var(--cnvs-socialicon-gap-x);
  margin-bottom: var(--cnvs-socialicon-gap-y);
  flex: none;
}

.social-icon.si-large {
  --cnvs-socialicon-size: 3.5rem;
}

.social-icon.si-small {
  --cnvs-socialicon-size: 2rem;
  --cnvs-socialicon-fontsize: .875rem;
}

.social-icon i {
  line-height: inherit;
  transition: all .3s;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .social-icon i {
    transition: none;
  }
}

.social-icon i:last-child {
  color: #fff;
}

.social-icon:hover i:first-child {
  margin-top: calc(var(--cnvs-socialicon-lineheight) * -1);
}

.social-icon:hover {
  color: var(--cnvs-socialicon-color);
  border-color: #0000;
}

.social-icon.bg-dark, .social-icon.bg-dark:hover {
  color: var(--bs-gray-200);
}

.social-icon.bg-light {
  --cnvs-socialicon-color: var(--bs-gray-900);
  background-color: var(--bs-gray-200) !important;
}

.social-icon[class*=" bg-"] {
  border-color: #0000;
}

.social-icon.no-transition:hover i:first-child {
  margin-top: 0;
  display: none;
}

[class*="-flex"], [class*="row"] {
  --cnvs-socialicon-gap: 5px;
  --cnvs-socialicon-gap-x: var(--cnvs-socialicon-gap);
  --cnvs-socialicon-gap-y: 0px;
}

.si-sticky {
  z-index: 499;
  width: 36px;
  position: fixed;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.si-sticky.si-sticky-right {
  left: auto;
  right: 8px;
}

.bg-facebook, .h-bg-facebook:hover {
  background-color: rgba(var(--cnvs-color-facebook-rgb), 1) !important;
}

.bg-whatsapp, .h-bg-whatsapp:hover {
  background-color: rgba(var(--cnvs-color-whatsapp-rgb), 1) !important;
}

.bg-delicious, .h-bg-delicious:hover {
  background-color: rgba(var(--cnvs-color-delicious-rgb), 1) !important;
}

.bg-paypal, .h-bg-paypal:hover {
  background-color: rgba(var(--cnvs-color-paypal-rgb), 1) !important;
}

.bg-waze, .h-bg-waze:hover {
  background-color: rgba(var(--cnvs-color-waze-rgb), 1) !important;
}

.bg-android, .h-bg-android:hover {
  background-color: rgba(var(--cnvs-color-android-rgb), 1) !important;
}

.bg-googledrive, .h-bg-googledrive:hover {
  background-color: rgba(var(--cnvs-color-googledrive-rgb), 1) !important;
}

.bg-wikipedia, .h-bg-wikipedia:hover {
  background-color: rgba(var(--cnvs-color-wikipedia-rgb), 1) !important;
}

.bg-stumbleupon, .h-bg-stumbleupon:hover {
  background-color: rgba(var(--cnvs-color-stumbleupon-rgb), 1) !important;
}

.bg-foursquare, .h-bg-foursquare:hover {
  background-color: rgba(var(--cnvs-color-foursquare-rgb), 1) !important;
}

.bg-call, .h-bg-call:hover {
  background-color: rgba(var(--cnvs-color-call-rgb), 1) !important;
}

.bg-ninetyninedesigns, .h-bg-ninetyninedesigns:hover {
  background-color: rgba(var(--cnvs-color-ninetyninedesigns-rgb), 1) !important;
}

.bg-forrst, .h-bg-forrst:hover {
  background-color: rgba(var(--cnvs-color-forrst-rgb), 1) !important;
}

.bg-digg, .h-bg-digg:hover {
  background-color: rgba(var(--cnvs-color-digg-rgb), 1) !important;
}

.bg-spotify, .h-bg-spotify:hover {
  background-color: rgba(var(--cnvs-color-spotify-rgb), 1) !important;
}

.bg-reddit, .h-bg-reddit:hover {
  background-color: rgba(var(--cnvs-color-reddit-rgb), 1) !important;
}

.bg-blogger, .h-bg-blogger:hover {
  background-color: rgba(var(--cnvs-color-blogger-rgb), 1) !important;
}

.bg-cc, .h-bg-cc:hover {
  background-color: rgba(var(--cnvs-color-cc-rgb), 1) !important;
}

.bg-dribbble, .h-bg-dribbble:hover {
  background-color: rgba(var(--cnvs-color-dribbble-rgb), 1) !important;
}

.bg-evernote, .h-bg-evernote:hover {
  background-color: rgba(var(--cnvs-color-evernote-rgb), 1) !important;
}

.bg-flickr, .h-bg-flickr:hover {
  background-color: rgba(var(--cnvs-color-flickr-rgb), 1) !important;
}

.bg-google, .h-bg-google:hover {
  background-color: rgba(var(--cnvs-color-google-rgb), 1) !important;
}

.bg-salesforce, .h-bg-salesforce:hover {
  background-color: rgba(var(--cnvs-color-salesforce-rgb), 1) !important;
}

.bg-cloudflare, .h-bg-cloudflare:hover {
  background-color: rgba(var(--cnvs-color-cloudflare-rgb), 1) !important;
}

.bg-linkedin, .h-bg-linkedin:hover {
  background-color: rgba(var(--cnvs-color-linkedin-rgb), 1) !important;
}

.bg-vk, .h-bg-vk:hover {
  background-color: rgba(var(--cnvs-color-vk-rgb), 1) !important;
}

.bg-rss, .h-bg-rss:hover {
  background-color: rgba(var(--cnvs-color-rss-rgb), 1) !important;
}

.bg-skype, .h-bg-skype:hover {
  background-color: rgba(var(--cnvs-color-skype-rgb), 1) !important;
}

.bg-twitter, .h-bg-twitter:hover {
  background-color: rgba(var(--cnvs-color-twitter-rgb), 1) !important;
}

.bg-x-twitter, .h-bg-x-twitter:hover {
  background-color: rgba(var(--cnvs-color-x-twitter-rgb), 1) !important;
}

.bg-youtube, .h-bg-youtube:hover {
  background-color: rgba(var(--cnvs-color-youtube-rgb), 1) !important;
}

.bg-vimeo, .h-bg-vimeo:hover {
  background-color: rgba(var(--cnvs-color-vimeo-rgb), 1) !important;
}

.bg-slack, .h-bg-slack:hover {
  background-color: rgba(var(--cnvs-color-slack-rgb), 1) !important;
}

.bg-yahoo, .h-bg-yahoo:hover {
  background-color: rgba(var(--cnvs-color-yahoo-rgb), 1) !important;
}

.bg-email3, .h-bg-email3:hover {
  background-color: rgba(var(--cnvs-color-email3-rgb), 1) !important;
}

.bg-macstore, .h-bg-macstore:hover {
  background-color: rgba(var(--cnvs-color-macstore-rgb), 1) !important;
}

.bg-figma, .h-bg-figma:hover {
  background-color: rgba(var(--cnvs-color-figma-rgb), 1) !important;
}

.bg-podcast, .h-bg-podcast:hover {
  background-color: rgba(var(--cnvs-color-podcast-rgb), 1) !important;
}

.bg-meetup, .h-bg-meetup:hover {
  background-color: rgba(var(--cnvs-color-meetup-rgb), 1) !important;
}

.bg-dropbox, .h-bg-dropbox:hover {
  background-color: rgba(var(--cnvs-color-dropbox-rgb), 1) !important;
}

.bg-ebay, .h-bg-ebay:hover {
  background-color: rgba(var(--cnvs-color-ebay-rgb), 1) !important;
}

.bg-github, .h-bg-github:hover {
  background-color: rgba(var(--cnvs-color-github-rgb), 1) !important;
}

.bg-googleplay, .h-bg-googleplay:hover {
  background-color: rgba(var(--cnvs-color-googleplay-rgb), 1) !important;
}

.bg-itunes, .h-bg-itunes:hover {
  background-color: rgba(var(--cnvs-color-itunes-rgb), 1) !important;
}

.bg-periscope, .h-bg-periscope:hover {
  background-color: rgba(var(--cnvs-color-periscope-rgb), 1) !important;
}

.bg-meta, .h-bg-meta:hover {
  background-color: rgba(var(--cnvs-color-meta-rgb), 1) !important;
}

.bg-soundcloud, .h-bg-soundcloud:hover {
  background-color: rgba(var(--cnvs-color-soundcloud-rgb), 1) !important;
}

.bg-tumblr, .h-bg-tumblr:hover {
  background-color: rgba(var(--cnvs-color-tumblr-rgb), 1) !important;
}

.bg-wordpress, .h-bg-wordpress:hover {
  background-color: rgba(var(--cnvs-color-wordpress-rgb), 1) !important;
}

.bg-yelp, .h-bg-yelp:hover {
  background-color: rgba(var(--cnvs-color-yelp-rgb), 1) !important;
}

.bg-microsoft, .h-bg-microsoft:hover {
  background-color: rgba(var(--cnvs-color-microsoft-rgb), 1) !important;
}

.bg-trello, .h-bg-trello:hover {
  background-color: rgba(var(--cnvs-color-trello-rgb), 1) !important;
}

.bg-scribd, .h-bg-scribd:hover {
  background-color: rgba(var(--cnvs-color-scribd-rgb), 1) !important;
}

.bg-stripe, .h-bg-stripe:hover {
  background-color: rgba(var(--cnvs-color-stripe-rgb), 1) !important;
}

.bg-print, .h-bg-print:hover {
  background-color: rgba(var(--cnvs-color-print-rgb), 1) !important;
}

.bg-btc, .h-bg-btc:hover {
  background-color: rgba(var(--cnvs-color-btc-rgb), 1) !important;
}

.bg-weibo, .h-bg-weibo:hover {
  background-color: rgba(var(--cnvs-color-weibo-rgb), 1) !important;
}

.bg-acrobat, .h-bg-acrobat:hover {
  background-color: rgba(var(--cnvs-color-acrobat-rgb), 1) !important;
}

.bg-drupal, .h-bg-drupal:hover {
  background-color: rgba(var(--cnvs-color-drupal-rgb), 1) !important;
}

.bg-buffer, .h-bg-buffer:hover {
  background-color: rgba(var(--cnvs-color-buffer-rgb), 1) !important;
}

.bg-pocket, .h-bg-pocket:hover {
  background-color: rgba(var(--cnvs-color-pocket-rgb), 1) !important;
}

.bg-bitbucket, .h-bg-bitbucket:hover {
  background-color: rgba(var(--cnvs-color-bitbucket-rgb), 1) !important;
}

.bg-stackoverflow, .h-bg-stackoverflow:hover {
  background-color: rgba(var(--cnvs-color-stackoverflow-rgb), 1) !important;
}

.bg-hackernews, .h-bg-hackernews:hover {
  background-color: rgba(var(--cnvs-color-hackernews-rgb), 1) !important;
}

.bg-xing, .h-bg-xing:hover {
  background-color: rgba(var(--cnvs-color-xing-rgb), 1) !important;
}

.bg-instagram, .h-bg-instagram:hover {
  background-color: rgba(var(--cnvs-color-instagram-rgb), 1) !important;
}

.bg-quora, .h-bg-quora:hover {
  background-color: rgba(var(--cnvs-color-quora-rgb), 1) !important;
}

.bg-openid, .h-bg-openid:hover {
  background-color: rgba(var(--cnvs-color-openid-rgb), 1) !important;
}

.bg-steam, .h-bg-steam:hover {
  background-color: rgba(var(--cnvs-color-steam-rgb), 1) !important;
}

.bg-amazon, .h-bg-amazon:hover {
  background-color: rgba(var(--cnvs-color-amazon-rgb), 1) !important;
}

.bg-line, .h-bg-line:hover {
  background-color: rgba(var(--cnvs-color-line-rgb), 1) !important;
}

.bg-kickstarter, .h-bg-kickstarter:hover {
  background-color: rgba(var(--cnvs-color-kickstarter-rgb), 1) !important;
}

.bg-appstore, .h-bg-appstore:hover {
  background-color: rgba(var(--cnvs-color-appstore-rgb), 1) !important;
}

.bg-pinterest, .h-bg-pinterest:hover {
  background-color: rgba(var(--cnvs-color-pinterest-rgb), 1) !important;
}

.bg-fivehundredpx, .h-bg-fivehundredpx:hover {
  background-color: rgba(var(--cnvs-color-fivehundredpx-rgb), 1) !important;
}

.bg-tiktok, .h-bg-tiktok:hover {
  background-color: rgba(var(--cnvs-color-tiktok-rgb), 1) !important;
}

.bg-airbnb, .h-bg-airbnb:hover {
  background-color: rgba(var(--cnvs-color-airbnb-rgb), 1) !important;
}

.bg-gpay, .h-bg-gpay:hover {
  background-color: rgba(var(--cnvs-color-gpay-rgb), 1) !important;
}

.bg-unsplash, .h-bg-unsplash:hover {
  background-color: rgba(var(--cnvs-color-unsplash-rgb), 1) !important;
}

.bg-snapchat, .h-bg-snapchat:hover {
  background-color: rgba(var(--cnvs-color-snapchat-rgb), 1) !important;
}

.bg-discord, .h-bg-discord:hover {
  background-color: rgba(var(--cnvs-color-discord-rgb), 1) !important;
}

.bg-twitch, .h-bg-twitch:hover {
  background-color: rgba(var(--cnvs-color-twitch-rgb), 1) !important;
}

.border-facebook, .h-border-facebook:hover {
  border-color: rgba(var(--cnvs-color-facebook-rgb), 1) !important;
}

.border-whatsapp, .h-border-whatsapp:hover {
  border-color: rgba(var(--cnvs-color-whatsapp-rgb), 1) !important;
}

.border-delicious, .h-border-delicious:hover {
  border-color: rgba(var(--cnvs-color-delicious-rgb), 1) !important;
}

.border-paypal, .h-border-paypal:hover {
  border-color: rgba(var(--cnvs-color-paypal-rgb), 1) !important;
}

.border-waze, .h-border-waze:hover {
  border-color: rgba(var(--cnvs-color-waze-rgb), 1) !important;
}

.border-android, .h-border-android:hover {
  border-color: rgba(var(--cnvs-color-android-rgb), 1) !important;
}

.border-googledrive, .h-border-googledrive:hover {
  border-color: rgba(var(--cnvs-color-googledrive-rgb), 1) !important;
}

.border-wikipedia, .h-border-wikipedia:hover {
  border-color: rgba(var(--cnvs-color-wikipedia-rgb), 1) !important;
}

.border-stumbleupon, .h-border-stumbleupon:hover {
  border-color: rgba(var(--cnvs-color-stumbleupon-rgb), 1) !important;
}

.border-foursquare, .h-border-foursquare:hover {
  border-color: rgba(var(--cnvs-color-foursquare-rgb), 1) !important;
}

.border-call, .h-border-call:hover {
  border-color: rgba(var(--cnvs-color-call-rgb), 1) !important;
}

.border-ninetyninedesigns, .h-border-ninetyninedesigns:hover {
  border-color: rgba(var(--cnvs-color-ninetyninedesigns-rgb), 1) !important;
}

.border-forrst, .h-border-forrst:hover {
  border-color: rgba(var(--cnvs-color-forrst-rgb), 1) !important;
}

.border-digg, .h-border-digg:hover {
  border-color: rgba(var(--cnvs-color-digg-rgb), 1) !important;
}

.border-spotify, .h-border-spotify:hover {
  border-color: rgba(var(--cnvs-color-spotify-rgb), 1) !important;
}

.border-reddit, .h-border-reddit:hover {
  border-color: rgba(var(--cnvs-color-reddit-rgb), 1) !important;
}

.border-blogger, .h-border-blogger:hover {
  border-color: rgba(var(--cnvs-color-blogger-rgb), 1) !important;
}

.border-cc, .h-border-cc:hover {
  border-color: rgba(var(--cnvs-color-cc-rgb), 1) !important;
}

.border-dribbble, .h-border-dribbble:hover {
  border-color: rgba(var(--cnvs-color-dribbble-rgb), 1) !important;
}

.border-evernote, .h-border-evernote:hover {
  border-color: rgba(var(--cnvs-color-evernote-rgb), 1) !important;
}

.border-flickr, .h-border-flickr:hover {
  border-color: rgba(var(--cnvs-color-flickr-rgb), 1) !important;
}

.border-google, .h-border-google:hover {
  border-color: rgba(var(--cnvs-color-google-rgb), 1) !important;
}

.border-salesforce, .h-border-salesforce:hover {
  border-color: rgba(var(--cnvs-color-salesforce-rgb), 1) !important;
}

.border-cloudflare, .h-border-cloudflare:hover {
  border-color: rgba(var(--cnvs-color-cloudflare-rgb), 1) !important;
}

.border-linkedin, .h-border-linkedin:hover {
  border-color: rgba(var(--cnvs-color-linkedin-rgb), 1) !important;
}

.border-vk, .h-border-vk:hover {
  border-color: rgba(var(--cnvs-color-vk-rgb), 1) !important;
}

.border-rss, .h-border-rss:hover {
  border-color: rgba(var(--cnvs-color-rss-rgb), 1) !important;
}

.border-skype, .h-border-skype:hover {
  border-color: rgba(var(--cnvs-color-skype-rgb), 1) !important;
}

.border-twitter, .h-border-twitter:hover {
  border-color: rgba(var(--cnvs-color-twitter-rgb), 1) !important;
}

.border-x-twitter, .h-border-x-twitter:hover {
  border-color: rgba(var(--cnvs-color-x-twitter-rgb), 1) !important;
}

.border-youtube, .h-border-youtube:hover {
  border-color: rgba(var(--cnvs-color-youtube-rgb), 1) !important;
}

.border-vimeo, .h-border-vimeo:hover {
  border-color: rgba(var(--cnvs-color-vimeo-rgb), 1) !important;
}

.border-slack, .h-border-slack:hover {
  border-color: rgba(var(--cnvs-color-slack-rgb), 1) !important;
}

.border-yahoo, .h-border-yahoo:hover {
  border-color: rgba(var(--cnvs-color-yahoo-rgb), 1) !important;
}

.border-email3, .h-border-email3:hover {
  border-color: rgba(var(--cnvs-color-email3-rgb), 1) !important;
}

.border-macstore, .h-border-macstore:hover {
  border-color: rgba(var(--cnvs-color-macstore-rgb), 1) !important;
}

.border-figma, .h-border-figma:hover {
  border-color: rgba(var(--cnvs-color-figma-rgb), 1) !important;
}

.border-podcast, .h-border-podcast:hover {
  border-color: rgba(var(--cnvs-color-podcast-rgb), 1) !important;
}

.border-meetup, .h-border-meetup:hover {
  border-color: rgba(var(--cnvs-color-meetup-rgb), 1) !important;
}

.border-dropbox, .h-border-dropbox:hover {
  border-color: rgba(var(--cnvs-color-dropbox-rgb), 1) !important;
}

.border-ebay, .h-border-ebay:hover {
  border-color: rgba(var(--cnvs-color-ebay-rgb), 1) !important;
}

.border-github, .h-border-github:hover {
  border-color: rgba(var(--cnvs-color-github-rgb), 1) !important;
}

.border-googleplay, .h-border-googleplay:hover {
  border-color: rgba(var(--cnvs-color-googleplay-rgb), 1) !important;
}

.border-itunes, .h-border-itunes:hover {
  border-color: rgba(var(--cnvs-color-itunes-rgb), 1) !important;
}

.border-periscope, .h-border-periscope:hover {
  border-color: rgba(var(--cnvs-color-periscope-rgb), 1) !important;
}

.border-meta, .h-border-meta:hover {
  border-color: rgba(var(--cnvs-color-meta-rgb), 1) !important;
}

.border-soundcloud, .h-border-soundcloud:hover {
  border-color: rgba(var(--cnvs-color-soundcloud-rgb), 1) !important;
}

.border-tumblr, .h-border-tumblr:hover {
  border-color: rgba(var(--cnvs-color-tumblr-rgb), 1) !important;
}

.border-wordpress, .h-border-wordpress:hover {
  border-color: rgba(var(--cnvs-color-wordpress-rgb), 1) !important;
}

.border-yelp, .h-border-yelp:hover {
  border-color: rgba(var(--cnvs-color-yelp-rgb), 1) !important;
}

.border-microsoft, .h-border-microsoft:hover {
  border-color: rgba(var(--cnvs-color-microsoft-rgb), 1) !important;
}

.border-trello, .h-border-trello:hover {
  border-color: rgba(var(--cnvs-color-trello-rgb), 1) !important;
}

.border-scribd, .h-border-scribd:hover {
  border-color: rgba(var(--cnvs-color-scribd-rgb), 1) !important;
}

.border-stripe, .h-border-stripe:hover {
  border-color: rgba(var(--cnvs-color-stripe-rgb), 1) !important;
}

.border-print, .h-border-print:hover {
  border-color: rgba(var(--cnvs-color-print-rgb), 1) !important;
}

.border-btc, .h-border-btc:hover {
  border-color: rgba(var(--cnvs-color-btc-rgb), 1) !important;
}

.border-weibo, .h-border-weibo:hover {
  border-color: rgba(var(--cnvs-color-weibo-rgb), 1) !important;
}

.border-acrobat, .h-border-acrobat:hover {
  border-color: rgba(var(--cnvs-color-acrobat-rgb), 1) !important;
}

.border-drupal, .h-border-drupal:hover {
  border-color: rgba(var(--cnvs-color-drupal-rgb), 1) !important;
}

.border-buffer, .h-border-buffer:hover {
  border-color: rgba(var(--cnvs-color-buffer-rgb), 1) !important;
}

.border-pocket, .h-border-pocket:hover {
  border-color: rgba(var(--cnvs-color-pocket-rgb), 1) !important;
}

.border-bitbucket, .h-border-bitbucket:hover {
  border-color: rgba(var(--cnvs-color-bitbucket-rgb), 1) !important;
}

.border-stackoverflow, .h-border-stackoverflow:hover {
  border-color: rgba(var(--cnvs-color-stackoverflow-rgb), 1) !important;
}

.border-hackernews, .h-border-hackernews:hover {
  border-color: rgba(var(--cnvs-color-hackernews-rgb), 1) !important;
}

.border-xing, .h-border-xing:hover {
  border-color: rgba(var(--cnvs-color-xing-rgb), 1) !important;
}

.border-instagram, .h-border-instagram:hover {
  border-color: rgba(var(--cnvs-color-instagram-rgb), 1) !important;
}

.border-quora, .h-border-quora:hover {
  border-color: rgba(var(--cnvs-color-quora-rgb), 1) !important;
}

.border-openid, .h-border-openid:hover {
  border-color: rgba(var(--cnvs-color-openid-rgb), 1) !important;
}

.border-steam, .h-border-steam:hover {
  border-color: rgba(var(--cnvs-color-steam-rgb), 1) !important;
}

.border-amazon, .h-border-amazon:hover {
  border-color: rgba(var(--cnvs-color-amazon-rgb), 1) !important;
}

.border-line, .h-border-line:hover {
  border-color: rgba(var(--cnvs-color-line-rgb), 1) !important;
}

.border-kickstarter, .h-border-kickstarter:hover {
  border-color: rgba(var(--cnvs-color-kickstarter-rgb), 1) !important;
}

.border-appstore, .h-border-appstore:hover {
  border-color: rgba(var(--cnvs-color-appstore-rgb), 1) !important;
}

.border-pinterest, .h-border-pinterest:hover {
  border-color: rgba(var(--cnvs-color-pinterest-rgb), 1) !important;
}

.border-fivehundredpx, .h-border-fivehundredpx:hover {
  border-color: rgba(var(--cnvs-color-fivehundredpx-rgb), 1) !important;
}

.border-tiktok, .h-border-tiktok:hover {
  border-color: rgba(var(--cnvs-color-tiktok-rgb), 1) !important;
}

.border-airbnb, .h-border-airbnb:hover {
  border-color: rgba(var(--cnvs-color-airbnb-rgb), 1) !important;
}

.border-gpay, .h-border-gpay:hover {
  border-color: rgba(var(--cnvs-color-gpay-rgb), 1) !important;
}

.border-unsplash, .h-border-unsplash:hover {
  border-color: rgba(var(--cnvs-color-unsplash-rgb), 1) !important;
}

.border-snapchat, .h-border-snapchat:hover {
  border-color: rgba(var(--cnvs-color-snapchat-rgb), 1) !important;
}

.border-discord, .h-border-discord:hover {
  border-color: rgba(var(--cnvs-color-discord-rgb), 1) !important;
}

.border-twitch, .h-border-twitch:hover {
  border-color: rgba(var(--cnvs-color-twitch-rgb), 1) !important;
}

.color-facebook, .h-color-facebook:hover {
  color: rgba(var(--cnvs-color-facebook-rgb), 1) !important;
}

.color-whatsapp, .h-color-whatsapp:hover {
  color: rgba(var(--cnvs-color-whatsapp-rgb), 1) !important;
}

.color-delicious, .h-color-delicious:hover {
  color: rgba(var(--cnvs-color-delicious-rgb), 1) !important;
}

.color-paypal, .h-color-paypal:hover {
  color: rgba(var(--cnvs-color-paypal-rgb), 1) !important;
}

.color-waze, .h-color-waze:hover {
  color: rgba(var(--cnvs-color-waze-rgb), 1) !important;
}

.color-android, .h-color-android:hover {
  color: rgba(var(--cnvs-color-android-rgb), 1) !important;
}

.color-googledrive, .h-color-googledrive:hover {
  color: rgba(var(--cnvs-color-googledrive-rgb), 1) !important;
}

.color-wikipedia, .h-color-wikipedia:hover {
  color: rgba(var(--cnvs-color-wikipedia-rgb), 1) !important;
}

.color-stumbleupon, .h-color-stumbleupon:hover {
  color: rgba(var(--cnvs-color-stumbleupon-rgb), 1) !important;
}

.color-foursquare, .h-color-foursquare:hover {
  color: rgba(var(--cnvs-color-foursquare-rgb), 1) !important;
}

.color-call, .h-color-call:hover {
  color: rgba(var(--cnvs-color-call-rgb), 1) !important;
}

.color-ninetyninedesigns, .h-color-ninetyninedesigns:hover {
  color: rgba(var(--cnvs-color-ninetyninedesigns-rgb), 1) !important;
}

.color-forrst, .h-color-forrst:hover {
  color: rgba(var(--cnvs-color-forrst-rgb), 1) !important;
}

.color-digg, .h-color-digg:hover {
  color: rgba(var(--cnvs-color-digg-rgb), 1) !important;
}

.color-spotify, .h-color-spotify:hover {
  color: rgba(var(--cnvs-color-spotify-rgb), 1) !important;
}

.color-reddit, .h-color-reddit:hover {
  color: rgba(var(--cnvs-color-reddit-rgb), 1) !important;
}

.color-blogger, .h-color-blogger:hover {
  color: rgba(var(--cnvs-color-blogger-rgb), 1) !important;
}

.color-cc, .h-color-cc:hover {
  color: rgba(var(--cnvs-color-cc-rgb), 1) !important;
}

.color-dribbble, .h-color-dribbble:hover {
  color: rgba(var(--cnvs-color-dribbble-rgb), 1) !important;
}

.color-evernote, .h-color-evernote:hover {
  color: rgba(var(--cnvs-color-evernote-rgb), 1) !important;
}

.color-flickr, .h-color-flickr:hover {
  color: rgba(var(--cnvs-color-flickr-rgb), 1) !important;
}

.color-google, .h-color-google:hover {
  color: rgba(var(--cnvs-color-google-rgb), 1) !important;
}

.color-salesforce, .h-color-salesforce:hover {
  color: rgba(var(--cnvs-color-salesforce-rgb), 1) !important;
}

.color-cloudflare, .h-color-cloudflare:hover {
  color: rgba(var(--cnvs-color-cloudflare-rgb), 1) !important;
}

.color-linkedin, .h-color-linkedin:hover {
  color: rgba(var(--cnvs-color-linkedin-rgb), 1) !important;
}

.color-vk, .h-color-vk:hover {
  color: rgba(var(--cnvs-color-vk-rgb), 1) !important;
}

.color-rss, .h-color-rss:hover {
  color: rgba(var(--cnvs-color-rss-rgb), 1) !important;
}

.color-skype, .h-color-skype:hover {
  color: rgba(var(--cnvs-color-skype-rgb), 1) !important;
}

.color-twitter, .h-color-twitter:hover {
  color: rgba(var(--cnvs-color-twitter-rgb), 1) !important;
}

.color-x-twitter, .h-color-x-twitter:hover {
  color: rgba(var(--cnvs-color-x-twitter-rgb), 1) !important;
}

.color-youtube, .h-color-youtube:hover {
  color: rgba(var(--cnvs-color-youtube-rgb), 1) !important;
}

.color-vimeo, .h-color-vimeo:hover {
  color: rgba(var(--cnvs-color-vimeo-rgb), 1) !important;
}

.color-slack, .h-color-slack:hover {
  color: rgba(var(--cnvs-color-slack-rgb), 1) !important;
}

.color-yahoo, .h-color-yahoo:hover {
  color: rgba(var(--cnvs-color-yahoo-rgb), 1) !important;
}

.color-email3, .h-color-email3:hover {
  color: rgba(var(--cnvs-color-email3-rgb), 1) !important;
}

.color-macstore, .h-color-macstore:hover {
  color: rgba(var(--cnvs-color-macstore-rgb), 1) !important;
}

.color-figma, .h-color-figma:hover {
  color: rgba(var(--cnvs-color-figma-rgb), 1) !important;
}

.color-podcast, .h-color-podcast:hover {
  color: rgba(var(--cnvs-color-podcast-rgb), 1) !important;
}

.color-meetup, .h-color-meetup:hover {
  color: rgba(var(--cnvs-color-meetup-rgb), 1) !important;
}

.color-dropbox, .h-color-dropbox:hover {
  color: rgba(var(--cnvs-color-dropbox-rgb), 1) !important;
}

.color-ebay, .h-color-ebay:hover {
  color: rgba(var(--cnvs-color-ebay-rgb), 1) !important;
}

.color-github, .h-color-github:hover {
  color: rgba(var(--cnvs-color-github-rgb), 1) !important;
}

.color-googleplay, .h-color-googleplay:hover {
  color: rgba(var(--cnvs-color-googleplay-rgb), 1) !important;
}

.color-itunes, .h-color-itunes:hover {
  color: rgba(var(--cnvs-color-itunes-rgb), 1) !important;
}

.color-periscope, .h-color-periscope:hover {
  color: rgba(var(--cnvs-color-periscope-rgb), 1) !important;
}

.color-meta, .h-color-meta:hover {
  color: rgba(var(--cnvs-color-meta-rgb), 1) !important;
}

.color-soundcloud, .h-color-soundcloud:hover {
  color: rgba(var(--cnvs-color-soundcloud-rgb), 1) !important;
}

.color-tumblr, .h-color-tumblr:hover {
  color: rgba(var(--cnvs-color-tumblr-rgb), 1) !important;
}

.color-wordpress, .h-color-wordpress:hover {
  color: rgba(var(--cnvs-color-wordpress-rgb), 1) !important;
}

.color-yelp, .h-color-yelp:hover {
  color: rgba(var(--cnvs-color-yelp-rgb), 1) !important;
}

.color-microsoft, .h-color-microsoft:hover {
  color: rgba(var(--cnvs-color-microsoft-rgb), 1) !important;
}

.color-trello, .h-color-trello:hover {
  color: rgba(var(--cnvs-color-trello-rgb), 1) !important;
}

.color-scribd, .h-color-scribd:hover {
  color: rgba(var(--cnvs-color-scribd-rgb), 1) !important;
}

.color-stripe, .h-color-stripe:hover {
  color: rgba(var(--cnvs-color-stripe-rgb), 1) !important;
}

.color-print, .h-color-print:hover {
  color: rgba(var(--cnvs-color-print-rgb), 1) !important;
}

.color-btc, .h-color-btc:hover {
  color: rgba(var(--cnvs-color-btc-rgb), 1) !important;
}

.color-weibo, .h-color-weibo:hover {
  color: rgba(var(--cnvs-color-weibo-rgb), 1) !important;
}

.color-acrobat, .h-color-acrobat:hover {
  color: rgba(var(--cnvs-color-acrobat-rgb), 1) !important;
}

.color-drupal, .h-color-drupal:hover {
  color: rgba(var(--cnvs-color-drupal-rgb), 1) !important;
}

.color-buffer, .h-color-buffer:hover {
  color: rgba(var(--cnvs-color-buffer-rgb), 1) !important;
}

.color-pocket, .h-color-pocket:hover {
  color: rgba(var(--cnvs-color-pocket-rgb), 1) !important;
}

.color-bitbucket, .h-color-bitbucket:hover {
  color: rgba(var(--cnvs-color-bitbucket-rgb), 1) !important;
}

.color-stackoverflow, .h-color-stackoverflow:hover {
  color: rgba(var(--cnvs-color-stackoverflow-rgb), 1) !important;
}

.color-hackernews, .h-color-hackernews:hover {
  color: rgba(var(--cnvs-color-hackernews-rgb), 1) !important;
}

.color-xing, .h-color-xing:hover {
  color: rgba(var(--cnvs-color-xing-rgb), 1) !important;
}

.color-instagram, .h-color-instagram:hover {
  color: rgba(var(--cnvs-color-instagram-rgb), 1) !important;
}

.color-quora, .h-color-quora:hover {
  color: rgba(var(--cnvs-color-quora-rgb), 1) !important;
}

.color-openid, .h-color-openid:hover {
  color: rgba(var(--cnvs-color-openid-rgb), 1) !important;
}

.color-steam, .h-color-steam:hover {
  color: rgba(var(--cnvs-color-steam-rgb), 1) !important;
}

.color-amazon, .h-color-amazon:hover {
  color: rgba(var(--cnvs-color-amazon-rgb), 1) !important;
}

.color-line, .h-color-line:hover {
  color: rgba(var(--cnvs-color-line-rgb), 1) !important;
}

.color-kickstarter, .h-color-kickstarter:hover {
  color: rgba(var(--cnvs-color-kickstarter-rgb), 1) !important;
}

.color-appstore, .h-color-appstore:hover {
  color: rgba(var(--cnvs-color-appstore-rgb), 1) !important;
}

.color-pinterest, .h-color-pinterest:hover {
  color: rgba(var(--cnvs-color-pinterest-rgb), 1) !important;
}

.color-fivehundredpx, .h-color-fivehundredpx:hover {
  color: rgba(var(--cnvs-color-fivehundredpx-rgb), 1) !important;
}

.color-tiktok, .h-color-tiktok:hover {
  color: rgba(var(--cnvs-color-tiktok-rgb), 1) !important;
}

.color-airbnb, .h-color-airbnb:hover {
  color: rgba(var(--cnvs-color-airbnb-rgb), 1) !important;
}

.color-gpay, .h-color-gpay:hover {
  color: rgba(var(--cnvs-color-gpay-rgb), 1) !important;
}

.color-unsplash, .h-color-unsplash:hover {
  color: rgba(var(--cnvs-color-unsplash-rgb), 1) !important;
}

.color-snapchat, .h-color-snapchat:hover {
  color: rgba(var(--cnvs-color-snapchat-rgb), 1) !important;
}

.color-discord, .h-color-discord:hover {
  color: rgba(var(--cnvs-color-discord-rgb), 1) !important;
}

.color-twitch, .h-color-twitch:hover {
  color: rgba(var(--cnvs-color-twitch-rgb), 1) !important;
}

.toggle, .accordion {
  --cnvs-toggle-base-color: var(--cnvs-heading-color);
  --cnvs-toggle-font-size: 1rem;
  --cnvs-toggle-border-size: 1px;
  --cnvs-toggle-border-color: var(--cnvs-contrast-400);
  --cnvs-toggle-border-radius: 4px;
  --cnvs-toggle-bg-color: var(--cnvs-contrast-100);
  --cnvs-toggle-title-color: var(--cnvs-heading-color);
  --cnvs-toggle-content-padding: .75rem;
  --cnvs-toggle-bottom-margin: 1.5rem;
  margin-bottom: var(--cnvs-toggle-bottom-margin);
  display: block;
  position: relative;
}

.toggle-header, .accordion-header {
  cursor: pointer;
  color: var(--cnvs-toggle-title-color);
  font-size: var(--cnvs-toggle-font-size);
  flex-wrap: wrap;
  margin: 0 -5px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.toggle-icon, .accordion-icon {
  text-align: center;
  flex: none;
  width: auto;
  max-width: 100%;
  padding: 0 .375rem;
}

.toggle-icon i, .toggle-icon span {
  width: var(--cnvs-toggle-font-size);
}

.toggle-title, .accordion-title {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0 .25rem;
}

.toggle:not(.toggle-active) .toggle-open, .toggle-active .toggle-closed {
  display: none;
}

.toggle-active .toggle-open {
  display: inline-block;
}

.toggle-content, .accordion-content {
  padding: var(--cnvs-toggle-content-padding) 0;
  display: none;
  position: relative;
}

.toggle-bg .toggle-header {
  background-color: var(--cnvs-toggle-bg-color);
  padding: var(--cnvs-toggle-content-padding);
  border-radius: 2px;
  margin: 0;
}

.toggle-bg .toggle-content {
  padding: 1rem var(--cnvs-toggle-content-padding);
}

.toggle-border {
  border: var(--cnvs-toggle-border-size) solid var(--cnvs-contrast-400);
  border-radius: var(--cnvs-toggle-border-radius);
}

.toggle-border .toggle-header {
  padding: var(--cnvs-toggle-content-padding);
  margin: 0;
}

.toggle-border .toggle-content {
  padding: 0 1rem 1rem;
}

.faqs .toggle {
  border-bottom: 1px solid var(--cnvs-toggle-bg-color);
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.faqs .toggle-content {
  padding-bottom: 10px;
}

.accordion {
  --cnvs-accordion-border-size: 1px;
  --cnvs-accordion-border-color: var(--cnvs-contrast-300);
  --cnvs-toggle-content-padding: .75rem;
  --cnvs-accordion-content-padding: 1.5rem;
  margin-bottom: 20px;
}

.accordion-header {
  padding: var(--cnvs-toggle-content-padding) 0;
  border-top: var(--cnvs-accordion-border-size) dotted var(--cnvs-accordion-border-color);
  cursor: pointer;
}

.accordion-header:first-child {
  border-top: none;
}

.accordion:not([data-collapsible="true"]) .accordion-header.accordion-active {
  cursor: auto;
}

.accordion-header:not(.accordion-active) .accordion-open, .accordion-active .accordion-closed {
  display: none;
}

.accordion-active .accordion-open {
  display: inline-block;
}

.accordion-content {
  padding-top: 0;
  padding-bottom: var(--cnvs-toggle-content-padding);
}

.accordion-bg .accordion-header {
  background-color: var(--cnvs-toggle-bg-color);
  padding: var(--cnvs-toggle-content-padding);
  border-top: 0;
  border-radius: 2px;
  margin: 0 0 5px;
}

.accordion-bg .accordion-content {
  padding: var(--cnvs-toggle-content-padding) var(--cnvs-toggle-content-padding) var(--cnvs-toggle-content-padding);
}

.accordion-border {
  border: var(--cnvs-accordion-border-size) dotted var(--cnvs-accordion-border-color);
  border-radius: var(--cnvs-toggle-border-radius);
}

.accordion-border .accordion-header {
  border-color: var(--cnvs-contrast-300);
  padding: var(--cnvs-toggle-content-padding);
  margin: 0;
}

.accordion-border .accordion-content {
  padding: 0 1.125rem var(--cnvs-toggle-content-padding);
}

.accordion-lg {
  --cnvs-toggle-font-size: 1.25rem;
}

.tabs, .tab-content {
  --cnvs-tab-base-color: var(--cnvs-heading-color);
  --cnvs-tab-padding-width: 15px;
  --bs-nav-link-font-size: 1rem;
  --bs-nav-link-font-weight: 400;
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: rgba(var(--cnvs-contrast-rgb), .075);
  --bs-nav-tabs-link-hover-border-color: transparent;
  --cnvs-tabs-bg-color: var(--cnvs-contrast-bg-offset);
  --cnvs-tabs-active-bg-color: var(--cnvs-contrast-bg);
  --cnvs-tabs-active-color: var(--cnvs-themecolor);
  --cnvs-tab-container-padding: 20px;
  --cnvs-side-tab-width: 200px;
  --cnvs-sidenav-font-size: 1rem;
  --cnvs-sidenav-border: 1px;
  --cnvs-sidenav-radius: 4px;
  --cnvs-sidenav-icon-margin: 6px;
  --cnvs-sidenav-padding-height: 11px;
  --cnvs-sidenav-padding-width: 20px;
  --cnvs-sidenav-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-sidenav-font-color: var(--cnvs-heading-color);
  position: relative;
}

:not(.dark) .list-group-media .list-group-item-action p {
  opacity: .8;
  line-height: 1.6 !important;
}

[role="tablist"] a.i-plain.active {
  color: var(--cnvs-themecolor);
}

[role="tablist"] a.active {
  border-color: var(--cnvs-themecolor);
  background-color: var(--cnvs-themecolor);
  color: var(--cnvs-contrast-0);
}

.tab-bordered {
  position: relative;
}

.tab-bordered:before {
  content: "";
  border: 1px dashed var(--cnvs-contrast-300);
  z-index: 0;
  height: 1px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
}

.tab-bordered a {
  z-index: 2;
}

.tab-bordered a.i-bordered:not(.active):not(:hover) {
  background-color: var(--cnvs-tabs-active-bg-color) !important;
}

.tab-bordered.list-group-horizontal-sm:before {
  border: 1px dashed var(--cnvs-contrast-300);
  z-index: 0;
  width: 1px;
  height: 100%;
  inset: 15px auto 15px 26px;
}

.tab-sticky.sticky-sm-none {
  height: auto;
  position: relative;
  top: auto;
}

.tab-sticky {
  z-index: 1;
  height: 100%;
  position: sticky;
  top: 80px;
}

.canvas-tabs, .canvas-tabs.nav-tabs {
  --bs-nav-link-padding-x: 1.5rem;
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: rgba(var(--cnvs-contrast-rgb), .075);
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-link-color: var(--cnvs-heading-color);
  --bs-nav-link-hover-color: var(--cnvs-heading-color);
  padding: 0 var(--cnvs-tab-padding-width);
}

.canvas-tabs .nav-item:first-child .nav-link, .canvas-tabs.nav-tabs .nav-item:first-child .nav-link {
  border-left: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.canvas-tabs .nav-link, .canvas-tabs.nav-tabs .nav-link {
  background-color: var(--cnvs-tabs-bg-color);
  border: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  border-left: 0;
  min-height: 41px;
  transition: none;
}

.canvas-tabs .nav-link.active, .canvas-tabs .nav-tabs .nav-item.show .nav-link, .canvas-tabs.nav-tabs .nav-link.active, .canvas-tabs.nav-tabs .nav-tabs .nav-item.show .nav-link {
  background-color: var(--cnvs-tabs-active-bg-color);
  color: var(--cnvs-tabs-active-color);
  padding-bottom: calc(var(--bs-nav-link-padding-y)  + 1px);
  border-bottom: 0;
}

.canvas-tabs.tabs-bordered, .canvas-tabs.nav-tabs.tabs-bordered {
  padding: 0;
  margin-bottom: 0 !important;
}

.canvas-tabs.tabs-bordered + .tab-content, .canvas-tabs.nav-tabs.tabs-bordered + .tab-content {
  border: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  padding: var(--cnvs-tab-container-padding);
  border-top: 0;
}

.canvas-tabs.flex-column, .canvas-tabs.nav-tabs.flex-column {
  --bs-nav-link-padding-y: .75rem;
  padding: var(--cnvs-tab-padding-width) 0;
  border-bottom: 0;
  border-right: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.canvas-tabs.flex-column .nav-link, .canvas-tabs.nav-tabs.flex-column .nav-link {
  text-align: left;
  border-left: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  width: 100%;
  margin-bottom: -1px;
  margin-left: calc(var(--bs-nav-tabs-border-width) * 1);
}

.canvas-tabs.flex-column .nav-link:hover, .canvas-tabs.flex-column .nav-link:focus, .canvas-tabs.nav-tabs.flex-column .nav-link:hover, .canvas-tabs.nav-tabs.flex-column .nav-link:focus {
  isolation: auto;
}

.canvas-tabs.flex-column:not(.nav-pills) .nav-link.active, .canvas-tabs.nav-tabs.flex-column:not(.nav-pills) .nav-link.active {
  padding-bottom: var(--bs-nav-link-padding-y);
  border-right-color: #0000;
}

.canvas-tabs.flex-column:last-child .nav-link, .canvas-tabs.nav-tabs.flex-column:last-child .nav-link {
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.canvas-tabs.size-sm, .canvas-tabs.nav-tabs.size-sm {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-font-size: .9375rem;
}

.canvas-alt-tabs {
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-link-color: var(--cnvs-tab-base-color);
  --bs-nav-link-hover-color: var(--cnvs-tab-base-color);
  --bs-nav-link-padding-x: 1.5rem;
  --bs-nav-tabs-link-active-bg: var(--cnvs-contrast-bg);
  --bs-nav-tabs-link-active-color: var(--cnvs-themecolor);
  --bs-nav-tabs-link-active-border-color: var(--bs-nav-tabs-border-color) var(--bs-nav-tabs-border-color) var(--cnvs-contrast-bg);
  padding: 0 var(--cnvs-tab-padding-width);
}

.canvas-alt-tabs.tabs-tb .nav-link {
  transition: none;
}

.canvas-alt-tabs.tabs-tb .nav-link.active {
  border-top: calc(var(--bs-nav-tabs-border-width)  + 1px) solid var(--cnvs-themecolor);
  border-bottom: 0;
}

.canvas-alt-tabs.tabs-bb {
  --bs-nav-tabs-border-width: 0px;
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-link-color: var(--cnvs-tab-base-color);
  border-bottom: 1px solid var(--bs-nav-tabs-border-color);
  padding: 0;
}

.canvas-alt-tabs.tabs-bb .nav-link.active {
  border-bottom: 1px solid var(--cnvs-themecolor);
}

.canvas-alt-tabs .nav-link:not(.active):not(:hover), .canvas-alt-tabs .nav-link:not(.active):not(:focus) {
  border-color: #0000;
}

.canvas-alt-tabs2 {
  --bs-nav-pills-link-active-bg: var(--cnvs-themecolor);
  --bs-nav-pills-border-radius: 2px;
  --bs-nav-link-hover-color: var(--cnvs-tab-base-color);
  --bs-nav-link-padding-x: 1.5rem;
}

.canvas-alt-tabs2 .nav-link {
  background-color: var(--cnvs-contrast-200);
  --bs-nav-link-color: var(--cnvs-tab-base-color);
  box-shadow: inset 0 1px 2px #0000001a;
}

.canvas-alt-tabs2 .nav-item:not(:last-child) .nav-link {
  margin-right: 15px;
}

.canvas-alt-tabs2 .tabs-bordered {
  padding: 0;
  margin-bottom: 0 !important;
}

.canvas-alt-tabs2 .tabs-bordered + .tab-content {
  border: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  padding: var(--cnvs-tab-container-padding);
  border-top: 0;
}

.tabs-bordered .tabs, .tabs-bordered .tab-content {
  --bs-nav-tabs-border-color: var(--cnvs-contrast-300);
}

.tabs-bordered .flex-column {
  z-index: 1;
  padding: 0;
}

.tabs-bordered .tab-content {
  padding: var(--cnvs-sidenav-padding-width);
  border: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  height: 100%;
  margin-left: -1px;
}

.tabs-bordered .tab-content p {
  margin-bottom: 0;
}

@media (width >= 576px) {
  .canvas-br-side-tabs .nav-link {
    padding-left: 0;
    background-color: #0000 !important;
    border: 0 !important;
  }

  .canvas-br-side-tabs .nav-link.active {
    border-right: 2px solid var(--cnvs-themecolor) !important;
  }
}

@media (width <= 575.98px) {
  .tabs {
    border-bottom: 0;
    flex-direction: column;
    padding: 0;
  }

  .tabs li .nav-link {
    text-align: left;
    width: 100%;
    margin: 0 0 5px;
    border: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color) !important;
  }

  .tabs li:last-child .nav-link {
    margin-bottom: 0;
  }

  .tabs.canvas-alt-tabs {
    --bs-nav-tabs-link-active-color: var(--cnvs-themecolor);
  }
}

.faqlist li {
  margin-bottom: 5px;
  font-size: 1rem;
}

.faqlist li a {
  color: var(--cnvs-contrast-700);
}

.faqlist li a:hover {
  color: var(--cnvs-themecolor) !important;
}

.clients-grid {
  --cnvs-clients-grid-padding: 20px;
  --cnvs-clients-grid-border-size: 1px;
  --cnvs-clients-grid-border-style: dashed;
  --cnvs-clients-grid-border-color: rgba(var(--cnvs-contrast-rgb), .15);
  --cnvs-clients-img-opacity: .6;
  align-items: stretch;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.clients-grid > .col {
  padding: var(--cnvs-clients-grid-padding) 0;
  -webkit-backface-visibility: hidden;
  position: relative;
}

.clients-grid > .col a {
  width: 75%;
  opacity: var(--cnvs-clients-img-opacity);
  margin-left: auto;
  margin-right: auto;
  transition: all .5s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .clients-grid > .col a {
    transition: none;
  }
}

.clients-grid > .col a:hover {
  opacity: 1;
}

.clients-grid > .col img {
  width: 100%;
  display: block;
}

.clients-grid > .col:before, .clients-grid > .col:after {
  content: "";
  position: absolute;
}

.clients-grid > .col:before {
  height: 100%;
  top: 0;
  left: calc(-1 * var(--cnvs-clients-grid-border-size));
  border-left: var(--cnvs-clients-grid-border-size) var(--cnvs-clients-grid-border-style) var(--cnvs-clients-grid-border-color);
}

.clients-grid > .col:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: calc(-1 * var(--cnvs-clients-grid-border-size));
  border-bottom: var(--cnvs-clients-grid-border-size) var(--cnvs-clients-grid-border-style) var(--cnvs-clients-grid-border-color);
}

.testimonials-grid .col {
  padding: 2rem;
}

.testimonials-grid .col .testimonial {
  padding: 0;
  box-shadow: none !important;
  background-color: #0000 !important;
  border: 0 !important;
}

.quote-bubble {
  --cnvs-bubble-radius: .75rem;
  --cnvs-bubble-color: var(--cnvs-contrast-200);
  border-radius: var(--cnvs-bubble-radius);
  color: var(--cnvs-heading-color);
  background-color: var(--cnvs-bubble-color);
  padding: 1.25rem;
  font-size: 1rem;
  position: relative;
}

.quote-bubble:before {
  content: "";
  border-left: var(--cnvs-bubble-radius) solid var(--cnvs-bubble-color);
  border-right: var(--cnvs-bubble-radius) solid transparent;
  border-top: var(--cnvs-bubble-radius) solid var(--cnvs-bubble-color);
  border-bottom: var(--cnvs-bubble-radius) solid transparent;
  width: 0;
  height: 0;
  right: calc((var(--cnvs-bubble-radius)  + 1px) * -1);
  position: absolute;
  top: 0;
}

.quote-bubble.quote-bubble-left:before {
  border-left: var(--cnvs-bubble-radius) solid transparent;
  border-right: var(--cnvs-bubble-radius) solid var(--cnvs-bubble-color);
  left: calc((var(--cnvs-bubble-radius)  + 1px) * -1);
  right: auto;
}

.quote-bubble > p {
  margin-bottom: 0;
}

.quote-bubble.bg-primary {
  --cnvs-bubble-color: var(--bs-primary);
}

.quote-bubble.bg-danger {
  --cnvs-bubble-color: var(--bs-danger);
}

.quote-bubble.bg-success {
  --cnvs-bubble-color: var(--bs-success);
}

.quote-bubble.bg-warning {
  --cnvs-bubble-color: var(--bs-warning);
}

.quote-bubble.bg-info {
  --cnvs-bubble-color: var(--bs-info);
}

.quote-bubble.bg-dark {
  --cnvs-bubble-color: var(--bs-dark);
}

.testimonial {
  --cnvs-fslider-dots-size: .5rem;
  --cnvs-slider-pagination-gutters: calc(var(--cnvs-fslider-dots-size) * .5);
}

.testimonial .flex-control-nav {
  top: auto;
  bottom: .5rem;
  right: 0;
}

.testimonial .flex-control-nav li a {
  background-color: var(--cnvs-themecolor);
  opacity: .5;
  border: none;
}

.testimonial .flex-control-nav li:hover a {
  opacity: .75;
}

.testimonial .flex-control-nav li a.flex-active {
  opacity: 1;
}

.testimonial.testimonial-full .flex-control-nav {
  height: .5rem;
  margin-top: 20px;
  position: relative;
  inset: 0;
}

.testimonial.testimonial-full .flex-control-nav li {
  float: none;
  display: inline-block;
}

.team {
  --cnvs-team-base-color: var(--cnvs-heading-color);
  --cnvs-team-desc-align: center;
  --cnvs-team-desc-background: var(--cnvs-contrast-0);
  --cnvs-team-desc-padding: .75rem 0 2rem;
  --cnvs-team-title-font-size: 1.25rem;
  --cnvs-team-title-designation-font-size: 1rem;
  --cnvs-team-title-designation-font-color: var(--cnvs-contrast-600);
  --cnvs-team-title-designation-font-family: var(--cnvs-body-font);
  --cnvs-team-title-designation-font-weight: 400;
  --cnvs-team-title-designation-font-style: normal;
  --cnvs-team-title-designation-icon-width: 32px;
  --cnvs-team-title-designation-icon-size: 14px;
  --cnvs-team-overlay-color: #fff;
  --cnvs-team-overlay-opacity: .75;
  --cnvs-team-list-image-width: 250px;
  --cnvs-team-content-font-size: 1rem;
  --cnvs-team-content-font-color: var(--cnvs-contrast-600);
  position: relative;
}

.team-image {
  position: relative;
}

.team-image > a, .team-image img {
  width: 100%;
  display: block;
}

.team-desc {
  text-align: var(--cnvs-team-desc-align);
}

.team-desc-bg {
  background-color: var(--cnvs-team-desc-background);
  padding: var(--cnvs-team-desc-padding);
}

.team .si-share {
  text-align: left;
  margin-top: 30px;
}

.team .si-share .social-icon {
  font-size: var(--cnvs-team-title-designation-icon-size);
  width: var(--cnvs-team-title-designation-icon-width);
}

.team-title {
  padding-top: 20px;
  position: relative;
}

.team-title h4, .team-title .h4 {
  font-size: var(--cnvs-team-title-font-size);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-weight: 700;
  display: block;
}

.team-title span {
  color: var(--cnvs-team-title-designation-font-color);
  font-weight: var(--cnvs-team-title-designation-font-weight);
  font-family: var(--cnvs-team-title-designation-font-family);
  font-style: var(--cnvs-team-title-designation-font-style);
  font-size: var(--cnvs-team-title-designation-font-size);
  margin-top: 5px;
  display: block;
}

.team-content {
  color: var(--cnvs-team-content-font-color);
  margin-top: 1rem;
}

.team-content p:last-child {
  margin-bottom: 0;
}

.team.center .team-title:after {
  margin-left: -40px;
  left: 50%;
}

.team-list .team-desc {
  text-align: left;
}

.team-list .team-title {
  padding-top: 0;
}

.team .team-desc > .social-icon {
  margin-top: 20px;
}

.team .team-content ~ .social-icon {
  margin-top: 30px;
}

.team:not(.team-list) .social-icon {
  vertical-align: middle;
  float: none;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block !important;
}

.pricing, [class*="pricing-"] {
  --cnvs-pricing-base-color: var(--cnvs-heading-color);
  --cnvs-pricing-background: var(--cnvs-contrast-100);
  --cnvs-pricing-border-size: 1px;
  --cnvs-pricing-border-color: rgba(var(--cnvs-contrast-rgb), .075);
  --cnvs-pricing-border-radius: .25rem;
  --cnvs-pricing-title-padding: 1rem;
  --cnvs-pricing-title-bg: var(--cnvs-contrast-100);
  --cnvs-pricing-title-border: 1px solid rgba(var(--cnvs-contrast-rgb), .075);
  --cnvs-pricing-title-font-size: 1.5rem;
  --cnvs-pricing-title-font-weight: bold;
  --cnvs-pricing-title-font-spacing: 1px;
  --cnvs-pricing-title-font-transform: uppercase;
  --cnvs-pricing-title-font-color: var(--cnvs-heading-color);
  --cnvs-pricing-price-padding: 1.5rem;
  --cnvs-pricing-price-color: var(--cnvs-contrast-900);
  --cnvs-pricing-price-font-size: 4rem;
  --cnvs-pricing-price-bottom-border-width: 100px;
  --cnvs-pricing-price-bottom-border-size: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-pricing-price-currency-icon: 1.74rem;
  --cnvs-pricing-price-month: .99354rem;
  --cnvs-pricing-features-padding: 2rem;
  --cnvs-pricing-features-font-padding: .375rem;
  --cnvs-pricing-features-font-size: .875rem;
  --cnvs-pricing-popular-bg: var(--cnvs-contrast-100);
  --cnvs-pricing-popular-title-padding: 1.25rem;
  --cnvs-pricing-popular-font-color: var(--cnvs-themecolor);
  --cnvs-pricing-popular-title-font-size: 1.5rem;
  --cnvs-pricing-popular-pricing-font-size: 4.5rem;
  --cnvs-pricing-popular-box-shadow: 0 0 8px rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-pricing-minimal-bg: var(--cnvs-contrast-0);
  --cnvs-pricing-extended-padding: 30px;
}

.pricing {
  position: relative;
}

.pricing > [class^="col-"] {
  margin-top: 1.25rem;
}

.pricing-box {
  border: var(--cnvs-pricing-border-size) solid var(--cnvs-pricing-border-color);
  border-radius: var(--cnvs-pricing-border-radius);
  background-color: var(--cnvs-pricing-background);
  position: relative;
  overflow: hidden;
}

.pricing-title {
  padding: var(--cnvs-pricing-title-padding) 0;
  background-color: var(--cnvs-pricing-title-bg);
  border-bottom: var(--cnvs-pricing-title-border);
  letter-spacing: 1px;
}

.pricing-title h3, .pricing-title .h3 {
  font-size: var(--cnvs-pricing-title-font-size);
  font-weight: var(--cnvs-pricing-title-font-weight);
  color: var(--cnvs-pricing-title-font-color);
  margin: 0;
}

.pricing-title span {
  color: var(--cnvs-contrast-600);
  font-weight: 300;
  font-size: calc(var(--cnvs-pricing-title-font-size) * .7);
  text-transform: var(--cnvs-pricing-title-font-transform);
  margin: 3px 0;
  display: block;
}

.pricing-title.title-sm h3, .pricing-title.title-sm .h3 {
  font-size: calc(var(--cnvs-pricing-title-font-size) * .8333);
}

.pricing-title.title-sm span {
  font-size: calc(var(--cnvs-pricing-title-font-size) / 1.714);
}

.pricing-price {
  padding: var(--cnvs-pricing-price-padding) 0;
  color: var(--cnvs-pricing-price-color);
  font-size: var(--cnvs-pricing-price-font-size);
  line-height: 1;
  position: relative;
}

.pricing-price .price-unit {
  vertical-align: top;
  font-size: var(--cnvs-pricing-price-currency-icon);
  margin: 7px 3px 0 0;
  font-weight: normal;
  display: inline-block;
}

.pricing-price .price-tenure {
  vertical-align: baseline;
  font-size: var(--cnvs-pricing-price-month);
  letter-spacing: 1px;
  color: var(--cnvs-contrast-600);
  margin: 0 0 0 3px;
  font-weight: normal;
}

.pricing-features {
  border-top: var(--cnvs-pricing-border-size) solid var(--cnvs-pricing-border-color);
  border-bottom: var(--cnvs-pricing-border-size) solid var(--cnvs-pricing-border-color);
  background-color: var(--cnvs-contrast-100);
  padding: var(--cnvs-pricing-features-padding) 0;
}

.pricing-features ul {
  margin: 0;
  list-style: none;
}

.pricing-features li {
  padding: var(--cnvs-pricing-features-font-padding) 0;
}

.pricing-action {
  padding: calc(var(--cnvs-pricing-title-padding) * 1.25) 0;
}

.pricing-highlight {
  box-shadow: var(--cnvs-pricing-popular-box-shadow);
  z-index: 10;
  margin: calc(-1 * var(--cnvs-pricing-popular-title-padding)) calc(-1 * var(--cnvs-pricing-border-size));
}

.pricing-highlight .pricing-title, .pricing-highlight .pricing-features {
  background-color: var(--cnvs-pricing-background);
}

.pricing-highlight .pricing-price {
  font-size: var(--cnvs-pricing-popular-pricing-font-size);
}

.pricing-simple {
  border: 0;
}

.pricing-simple .pricing-title, .pricing-simple .pricing-features {
  background-color: #0000;
  border: 0;
}

.pricing-minimal .pricing-price {
  background-color: var(--cnvs-pricing-minimal-bg);
  border-bottom: var(--cnvs-pricing-border-size) solid var(--cnvs-pricing-border-color);
}

.pricing-minimal .pricing-price:after {
  display: none;
}

.pricing-extended {
  background-color: var(--cnvs-pricing-minimal-bg);
  text-align: left;
}

.pricing-extended .pricing-title {
  text-align: left;
  background-color: #0000;
  padding-top: 0;
}

.pricing-extended .pricing-features {
  background-color: #0000;
  border: 0;
}

.pricing-extended .pricing-action-area {
  border-left: var(--cnvs-pricing-title-border);
  background-color: var(--cnvs-pricing-title-bg);
  padding: var(--cnvs-pricing-extended-padding);
  text-align: center;
}

.pricing-extended .pricing-action-area .pricing-price, .pricing-extended .pricing-action-area .pricing-action {
  padding: 0;
}

.pricing-extended .pricing-action-area .pricing-price {
  padding-bottom: var(--cnvs-pricing-popular-title-padding);
}

.pricing-extended .pricing-action-area .pricing-price:after {
  display: none;
}

.pricing-extended .pricing-action-area .pricing-price span.price-tenure {
  text-transform: uppercase;
  margin: 10px 0 0;
  font-size: .875rem;
  font-weight: 300;
  display: block;
}

.pricing-extended .pricing-meta {
  color: var(--cnvs-contrast-600);
  font-weight: 300;
  font-size: calc(var(--cnvs-pricing-title-font-size) * .7);
  letter-spacing: var(--cnvs-pricing-title-font-spacing);
  text-transform: var(--cnvs-pricing-title-font-transform);
  padding-bottom: 10px;
}

.pricing.pricing-5 .pricing-box {
  float: left;
  width: 20%;
  margin-top: var(--cnvs-pricing-popular-title-padding);
}

.pricing.pricing-5 .pricing-box.best-price {
  margin-top: 0;
}

.pricing.pricing-5 .pricing-box:nth-child(5) {
  margin-left: calc(-1 * var(--cnvs-pricing-border-size));
}

.pricing.pricing-5 .pricing-title h3, .pricing.pricing-5 .pricing-title .h3, .pricing.pricing-5 .best-price .pricing-title h3, .pricing.pricing-5 .best-price .pricing-title .h3 {
  font-size: var(--cnvs-pricing-title-font-size);
}

.table-comparison, .table-comparison th {
  text-align: center;
}

.table-comparison th:first-child, .table-comparison td:first-child {
  text-align: left;
  font-weight: bold;
}

.counter {
  --cnvs-counter-font-size: 42px;
  --cnvs-counter-font-weight: 700;
  --cnvs-counter-font-family: var(--cnvs-primary-font);
  --cnvs-counter-caption-size: 1.125rem;
  --cnvs-counter-sm-font-size: 28px;
  --cnvs-counter-lg-font-size: 56px;
  --cnvs-counter-xl-font-size: 64px;
  --cnvs-counter-lined-size: 2px solid var(--cnvs-heading-color);
  --cnvs-counter-lined-width: 15%;
  font-size: var(--cnvs-counter-font-size);
  font-weight: var(--cnvs-counter-font-weight);
  font-family: var(--cnvs-counter-font-family);
}

.counter.counter-small {
  --cnvs-counter-font-size: var(--cnvs-counter-sm-font-size);
}

.counter.counter-large {
  --cnvs-counter-font-size: var(--cnvs-counter-lg-font-size);
}

.counter.counter-xlarge {
  --cnvs-counter-font-size: var(--cnvs-counter-xl-font-size);
  font-weight: 400;
}

.counter.counter-inherit {
  font-size: inherit;
  font-weight: inherit;
}

.counter + h5, .counter + .h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.widget .counter + h5, .widget .counter + .h5 {
  opacity: .7;
}

.counter.counter-lined + h5:before, .counter.counter-lined + .h5:before {
  content: "";
  width: var(--cnvs-counter-lined-width);
  border-top: var(--cnvs-counter-lined-size);
  opacity: .9;
  margin: 20px auto 25px;
  display: block;
  position: relative;
}

.counter.counter-small + h5, .counter.counter-small + .h5 {
  font-size: .875rem;
}

.counter.counter-large + h5, .counter.counter-large + .h5, .counter.counter-xlarge + h5, .counter.counter-xlarge + .h5 {
  font-size: 15px;
  font-weight: 300;
}

.rounded-skill {
  --cnvs-rounded-skills-font-size: 1.25rem;
  --cnvs-rounded-skills-font-color: var(--cnvs-heading-color);
  --cnvs-rounded-skills-icon-size: 42px;
  text-align: center;
  font-size: var(--cnvs-rounded-skills-font-size);
  color: var(--cnvs-rounded-skills-font-color);
  margin: 0 15px 15px;
  font-weight: bold;
  transition: opacity .4s;
  display: inline-block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .rounded-skill {
    transition: none;
  }
}

.rounded-skill + h5, .rounded-skill + .h5 {
  margin-bottom: 0;
}

.rounded-skill i {
  font-size: var(--cnvs-rounded-skills-icon-size);
}

.rounded-skill canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.news-carousel .rounded-skill {
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: 30px;
  right: 30px;
  margin: 0 !important;
}

.skill-progress {
  --cnvs-progress-height: 10px;
  --cnvs-progress-color: var(--cnvs-themecolor);
  --cnvs-progress-trackcolor: var(--cnvs-contrast-100);
  --cnvs-progress-rounded: .25rem;
  --cnvs-progress-speed: 1.1s;
}

.skill-progress + .skill-progress {
  margin-top: 1rem;
}

.skill-progress-bar {
  height: var(--cnvs-progress-height);
  background-color: var(--cnvs-progress-trackcolor);
  border-radius: var(--cnvs-progress-rounded);
  position: relative;
}

.skill-progress-percent {
  background-color: var(--cnvs-progress-color);
  border-radius: var(--cnvs-progress-rounded);
  width: 0;
  height: 100%;
  transition-property: width, height;
  transition-duration: var(--cnvs-progress-speed);
  transition-timing-function: ease;
  position: absolute;
  top: 0;
  left: 0;
}

.skill-progress-vertical {
  --cnvs-progress-width: 50px;
  --cnvs-progress-height: 300px;
}

.skill-progress-vertical .skill-progress-bar {
  width: var(--cnvs-progress-width);
  height: var(--cnvs-progress-height);
}

.skill-progress-vertical .skill-progress-percent {
  width: 100%;
  height: 0;
  top: auto;
  bottom: 0;
}

.skill-progress-title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.skill-progress-vertical .skill-progress-title {
  display: block;
}

.skill-progress-title h5, .skill-progress-title .h5 {
  margin-bottom: 0;
}

.owl-carousel {
  --cnvs-carousel-animation-transition: 1s;
  --cnvs-owl-video-play-icon-size: 64px;
  --cnvs-owl-video-play-icon: url("play.95b4bd90.png");
  --cnvs-owl-video-play-icon-hover: scale(1.3);
  --cnvs-owl-autoHeight-transition: .5s ease-in-out;
  --cnvs-owl-nav-hidden: true;
  --cnvs-owl-nav-border: 1px solid rgba(var(--cnvs-contrast-rgb), .2);
  --cnvs-owl-nav-background: var(--cnvs-body-bg);
  --cnvs-owl-nav-color: var(--cnvs-contrast-600);
  --cnvs-owl-nav-border-radius: 50%;
  --cnvs-owl-full-nav-size: 28px;
  --cnvs-owl-full-nav-border-radius: .25rem;
  --cnvs-owl-full-nav-bgcolor: rgba(var(--cnvs-contrast-rgb), .4);
  --cnvs-owl-full-nav-color: var(--cnvs-contrast-100);
  --cnvs-owl-full-nav-hover-bgcolor: var(--cnvs-themecolor);
  --cnvs-owl-full-nav-hover-color: var(--cnvs-body-bg);
  --cnvs-owl-dots-background: var(--cnvs-themecolor);
  --cnvs-owl-dots-opacity: .5;
  --cnvs-owl-nav-sizes: 36px;
  --cnvs-owl-dots-gutters: .25rem;
  --cnvs-owl-dots-sizes: .5rem;
  --cnvs-owl-nav-bg: var(--cnvs-themecolor);
  --cnvs-owl-nav-margin: calc(var(--cnvs-owl-nav-sizes) / -2);
  --cnvs-owl-nav-with-dots: calc(var(--cnvs-owl-nav-margin)  - calc(calc(30px + var(--cnvs-owl-dots-sizes)) / 2));
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .owl-height {
    transition: none;
  }
}

.owl-carousel {
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  touch-action: manipulation;
  direction: ltr;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  -webkit-animation-duration: var(--cnvs-carousel-animation-transition);
  animation-duration: var(--cnvs-carousel-animation-transition);
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  background: center no-repeat;
  min-height: 100px;
  display: block;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item img {
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  display: block;
}

.slider-element .owl-carousel .owl-item img {
  -webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot, .owl-carousel .owl-dots button {
  cursor: pointer;
  cursor: hand;
  user-select: none;
  border: 0;
  padding: 0;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.mega-menu-content .owl-carousel {
  opacity: 1;
}

.owl-carousel.owl-refresh .owl-item {
  display: none;
}

.owl-carousel.owl-drag .owl-item {
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-item .owl-lazy {
    transition: none;
  }
}

.owl-carousel .owl-video-wrapper {
  background: #111;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  height: var(--cnvs-owl-video-play-icon-size);
  width: var(--cnvs-owl-video-play-icon-size);
  margin-left: calc(var(--cnvs-owl-video-play-icon-size) * -.5);
  margin-top: calc(var(--cnvs-owl-video-play-icon-size) * -.5);
  background: var(--cnvs-owl-video-play-icon) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-video-play-icon {
    transition: none;
  }
}

.owl-carousel .owl-video-play-icon:hover {
  transition: var(--cnvs-owl-video-play-icon-hover);
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-video-play-icon:hover {
    transition: none;
  }
}

.owl-carousel .owl-video-play-icon .owl-video-play-icon, .owl-carousel .owl-video-play-icon .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-play-icon .owl-video-tn {
  opacity: 0;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-video-play-icon .owl-video-tn {
    transition: none;
  }
}

.owl-carousel .owl-video-play-icon .owl-video-frame {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-dots, .owl-carousel .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
}

.owl-carousel .owl-nav [class*="owl-"] {
  zoom: 1;
  border: var(--cnvs-owl-nav-border);
  color: var(--cnvs-owl-nav-color);
  background-color: var(--cnvs-owl-nav-background);
  border-radius: var(--cnvs-owl-nav-border-radius);
  opacity: 0;
  top: 50%;
  left: calc(var(--cnvs-owl-nav-sizes) * -1);
  transition: all .3s;
  position: absolute;
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-nav [class*="owl-"] {
    transition: none;
  }
}

.slider-element .owl-nav [class*="owl-"], .owl-carousel-full .owl-nav [class*="owl-"] {
  height: calc(var(--cnvs-owl-full-nav-sizes) * 2.143);
  line-height: calc(var(--cnvs-owl-full-nav-sizes) * 2.143);
  color: var(--cnvs-owl-full-nav-color);
  background-color: var(--cnvs-owl-full-nav-bgcolor);
  font-size: var(--cnvs-owl-full-nav-sizes);
  border-radius: 0 var(--cnvs-owl-full-nav-border-radius) var(--cnvs-owl-full-nav-border-radius) 0;
  border: none;
  margin-top: -30px;
  left: 0 !important;
}

.slider-element .owl-nav .owl-next, .owl-carousel-full .owl-nav .owl-next {
  border-radius: var(--cnvs-owl-full-nav-border-radius) 0 0 var(--cnvs-owl-full-nav-border-radius);
  left: auto !important;
  right: 0 !important;
}

.owl-carousel:hover .owl-nav [class*="owl-"] {
  opacity: 1;
  left: calc(-.5 * var(--cnvs-owl-nav-sizes));
}

.owl-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: calc(-.5 * var(--cnvs-owl-nav-sizes));
}

.owl-carousel .owl-nav [class*="owl-"]:hover {
  text-decoration: none;
  background-color: var(--cnvs-owl-full-nav-hover-bgcolor) !important;
  color: var(--cnvs-owl-full-nav-hover-color) !important;
}

.owl-carousel .owl-nav .disabled {
  display: none !important;
}

.owl-carousel .owl-dots .owl-dot {
  zoom: 1;
  opacity: var(--cnvs-owl-dots-opacity);
  width: var(--cnvs-owl-dots-sizes);
  height: var(--cnvs-owl-dots-sizes);
  background-color: var(--cnvs-owl-dots-background);
  border-radius: 50%;
  margin-top: 30px;
  transition: all .3s;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-dots .owl-dot {
    transition: none;
  }
}

.owl-carousel .owl-dots .owl-dot.active, .owl-carousel .owl-dots .owl-dot:hover {
  opacity: 1;
}

.owl-carousel[class*="owl-nav-pos-"], .owl-carousel[class*="owl-dots-pos-"] .owl-carousel[class*="owl-img-pos-"] {
  flex-direction: column;
  display: flex;
}

.owl-carousel.owl-nav-pos-1 .owl-nav, .owl-carousel.owl-dots-pos-1 .owl-dots, .owl-carousel.owl-content-pos-1 .owl-stage-outer {
  order: 1;
}

.owl-carousel.owl-nav-pos-2 .owl-nav, .owl-carousel.owl-dots-pos-2 .owl-dots, .owl-carousel.owl-content-pos-2 .owl-stage-outer {
  order: 2;
  margin: 20px 0;
}

.owl-carousel.owl-nav-pos-3 .owl-nav, .owl-carousel.owl-dots-pos-3 .owl-dots, .owl-carousel.owl-content-pos-3 .owl-stage-outer {
  order: 3;
}

.owl-carousel.owl-dots-pos-2 .owl-dots .owl-dot, .owl-carousel.owl-dots-pos-3 .owl-dots .owl-dot {
  margin-top: 0;
}

.owl-carousel.owl-nav-pos-left .owl-nav, .owl-carousel.owl-nav-pos-right .owl-nav, .owl-carousel.owl-dots-pos-left .owl-dots, .owl-carousel.owl-dots-pos-right .owl-dots {
  justify-content: center;
  display: flex;
}

.owl-carousel.owl-dots-pos-left .owl-dots, .owl-carousel.owl-nav-pos-left .owl-nav {
  justify-content: flex-start;
}

.owl-carousel.owl-dots-pos-right .owl-dots, .owl-carousel.owl-nav-pos-right .owl-nav {
  justify-content: flex-end;
}

.owl-carousel.with-carousel-dots.owl-dots-pos-top .owl-nav [class*="owl-"] {
  margin-top: 0;
}

.owl-carousel.owl-dots-size-sm {
  --cnvs-owl-dots-sizes: 4px;
  --cnvs-owl-dots-gutters: 2px;
}

.owl-carousel.owl-dots-size-lg {
  --cnvs-owl-dots-sizes: 12px;
  --cnvs-owl-dots-gutters: 5px;
}

.owl-carousel.owl-dots-size-xl {
  --cnvs-owl-dots-sizes: 16px;
  --cnvs-owl-dots-gutters: 6px;
}

.owl-carousel .owl-dots .owl-dot {
  width: var(--cnvs-owl-dots-sizes);
  height: var(--cnvs-owl-dots-sizes);
  background-color: var(--cnvs-owl-nav-bg);
  margin-left: var(--cnvs-owl-dots-gutters);
  margin-right: var(--cnvs-owl-dots-gutters);
  transition: all .3s;
}

.owl-carousel.owl-nav-hover-fixed[class*="owl-nav-pos-"] .owl-nav .disabled {
  pointer-events: none;
  opacity: .4 !important;
  display: inline-block !important;
}

.owl-carousel.owl-dots-border .owl-dots .owl-dot:not(.active) {
  opacity: 1;
  border: 1px solid var(--cnvs-owl-nav-bg);
  background-color: #0000;
}

.owl-carousel.owl-dots-border .owl-dots .owl-dot.active {
  border-color: #0000;
}

.owl-carousel.owl-dots-square .owl-dots .owl-dot {
  --cnvs-owl-dots-sizes: 10px;
  border-radius: 0;
}

.owl-carousel.owl-dots-rounded .owl-dots .owl-dot {
  --cnvs-owl-dots-sizes: 10px;
  border-radius: 2px;
}

.owl-carousel.owl-dots-dashed .owl-dots .owl-dot {
  --cnvs-owl-dots-sizes: 16px;
  opacity: .4;
  border-radius: 4px;
  height: 4px;
}

.owl-carousel.owl-dots-dashed .owl-dots .owl-dot.active {
  --cnvs-owl-dots-sizes: 32px;
  opacity: 1;
}

.owl-carousel.owl-dots-number .owl-dots {
  --cnvs-owl-dots-sizes: 28px;
  counter-reset: dots;
  --cnvs-owl-nav-bg: #fff;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot {
  opacity: .3;
  width: auto;
  position: relative;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot.active {
  opacity: 1;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot:before {
  counter-increment: dots;
  content: counter(dots, decimal-leading-zero);
  display: inline-block;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot span {
  background-color: #666;
  width: 0;
  height: 2px;
  margin-left: 5px;
  transition: width .4s ease-in-out;
  display: inline-block;
  position: relative;
  top: -5px;
}

.owl-carousel.owl-dots-number .owl-dots .owl-dot.active span {
  width: 30px;
}

.owl-carousel .owl-nav [class*="owl-"] {
  left: calc(-1 * var(--cnvs-owl-nav-sizes));
  width: var(--cnvs-owl-nav-sizes);
  height: var(--cnvs-owl-nav-sizes);
  line-height: calc(-1 * var(--cnvs-owl-nav-sizes)  - 4px);
  font-size: calc(var(--cnvs-owl-nav-sizes) / 2);
  margin-top: var(--cnvs-owl-nav-margin);
}

@media (width <= 767.98px) {
  .owl-carousel .owl-nav [class*="owl-"] {
    left: var(--cnvs-owl-nav-sizes);
  }
}

.owl-carousel.with-carousel-dots .owl-nav [class*="owl-"] {
  margin-top: var(--cnvs-owl-nav-with-dots);
}

.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: calc(-1 * var(--cnvs-owl-nav-sizes));
}

@media (width <= 767.98px) {
  .owl-carousel .owl-nav .owl-next {
    right: var(--cnvs-owl-nav-sizes);
  }
}

.slider-element .owl-nav .owl-next, .owl-carousel-full .owl-nav .owl-next {
  left: auto !important;
  right: 0 !important;
}

.owl-carousel.owl-nav-hover-fixed .owl-nav [class*="owl-"], .owl-carousel:hover .owl-nav [class*="owl-"] {
  opacity: 1;
  left: calc(-1 * var(--cnvs-owl-nav-sizes) / 2);
}

@media (width <= 767.98px) {
  .owl-carousel.owl-nav-hover-fixed .owl-nav [class*="owl-"], .owl-carousel:hover .owl-nav [class*="owl-"] {
    left: calc(var(--cnvs-owl-nav-sizes) / 4);
  }
}

.owl-carousel.owl-nav-hover-fixed .owl-nav .owl-next, .owl-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: calc(-1 * var(--cnvs-owl-nav-sizes) / 2);
}

@media (width <= 767.98px) {
  .owl-carousel.owl-nav-hover-fixed .owl-nav .owl-next, .owl-carousel:hover .owl-nav .owl-next {
    right: calc(var(--cnvs-owl-nav-sizes) / 4);
  }
}

.owl-carousel .owl-nav [class*="owl-"]:hover {
  background-color: var(--cnvs-owl-nav-bg) !important;
}

.slider-element .owl-nav [class*="owl-"], .owl-carousel-full .owl-nav [class*="owl-"] {
  height: calc(var(--cnvs-owl-nav-sizes) * 1.66667);
  line-height: calc(var(--cnvs-owl-nav-sizes) * 1.66667);
  font-size: calc(var(--cnvs-owl-nav-sizes) * 1.66667 / 2);
  margin-top: -30px;
}

.owl-carousel-full .with-carousel-dots .owl-nav [class*="owl-"] {
  margin-top: calc(var(--cnvs-owl-nav-sizes) * 1.66667 / -2 - (30px + var(--cnvs-owl-dots-sizes)) / 2);
}

.owl-carousel.owl-nav-hover-fixed[class*="owl-nav-pos-"] .owl-nav [class*="owl-"] {
  margin: 0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
}

.owl-carousel.owl-nav-hover-fixed[class*="owl-nav-pos-"] .owl-nav .owl-prev {
  margin-right: 5px;
}

.owl-carousel.owl-nav-hover-fixed[class*="owl-nav-pos-"] .owl-nav .owl-next {
  margin-left: 5px;
}

.owl-carousel.owl-nav-text .owl-nav [class*="owl-"] {
  border-radius: 3px;
  width: auto;
  height: auto;
  padding: 4px 8px;
  font-size: .925rem;
  line-height: 1.5;
}

.owl-carousel.owl-nav-text .owl-nav [class*="owl-"] i {
  position: relative;
  top: 1px;
}

.flip-card {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.flip-card-front, .flip-card-back {
  backface-visibility: hidden;
  color: #fff;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  width: 100%;
  height: 280px;
  font-size: 1.5rem;
  transition: transform .7s cubic-bezier(.4, .2, .2, 1);
}

@media (prefers-reduced-motion: reduce) {
  .flip-card-front, .flip-card-back {
    transition: none;
  }
}

.flip-card-front.no-after:after, .flip-card-back.no-after:after {
  content: none;
}

.flip-card-front:after, .flip-card-back:after {
  z-index: 1;
  content: "";
  opacity: .6;
  backface-visibility: hidden;
  background-color: #000;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.flip-card-front:hover .flip-card-front, .flip-card-front:hover .flip-card-back, .flip-card-back:hover .flip-card-front, .flip-card-back:hover .flip-card-back {
  transition: transform .7s cubic-bezier(.4, .2, .2, 1);
}

@media (prefers-reduced-motion: reduce) {
  .flip-card-front:hover .flip-card-front, .flip-card-front:hover .flip-card-back, .flip-card-back:hover .flip-card-front, .flip-card-back:hover .flip-card-back {
    transition: none;
  }
}

.flip-card-back {
  background-color: #666;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.flip-card-inner {
  box-sizing: border-box;
  width: 100%;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  outline: 1px solid #0000;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)translateZ(60px)scale(.94);
}

.flip-card-back {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.top-to-bottom .flip-card-back {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(180deg);
}

.flip-card-front, .flip-card:hover .flip-card-back {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateY(0);
}

.flip-card:hover .flip-card-front {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
}

.top-to-bottom .flip-card-front, .top-to-bottom:hover .flip-card-back {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(0);
}

.top-to-bottom:hover .flip-card-front {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(-180deg);
}

.flip-card-inner span {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.flip-card-inner p {
  color: #ffffffb3;
  margin-bottom: 0;
  font-size: 1rem;
  position: relative;
}

.titular-title {
  --cnvs-titular-title-font-size-h1: 3.25rem;
  --cnvs-titular-title-font-size-h2: 2.75rem;
  --cnvs-titular-title-font-size-h3: 2rem;
  --cnvs-titular-title-font-size-subtitle: 1rem;
  letter-spacing: -1px;
  font-weight: 500;
}

h1.titular-title, .titular-title.h1 {
  font-size: var(--cnvs-titular-title-font-size-h1);
}

h2.titular-title, .titular-title.h2 {
  font-size: var(--cnvs-titular-title-font-size-h2);
}

h3.titular-title, .titular-title.h3 {
  font-size: var(--cnvs-titular-title-font-size-h3);
}

.titular-sub-title {
  letter-spacing: 4px;
  font-weight: 600;
  font-size: var(--cnvs-titular-title-font-size-subtitle);
  text-transform: uppercase;
  margin: 0;
}

.titular-title + .titular-sub-title {
  margin-top: -25px;
  margin-bottom: 30px;
}

.title-block {
  --cnvs-title-block-padding: 20px;
  --cnvs-title-block-color: var(--cnvs-heading-color);
  --cnvs-title-block-border: 7px;
  --cnvs-title-block-border-color: var(--cnvs-themecolor);
  --cnvs-title-block-subtitle-color: var(--cnvs-contrast-700);
  padding: 2px 0 3px var(--cnvs-title-block-padding);
  border-left: var(--cnvs-title-block-border) solid var(--cnvs-title-block-border-color);
  margin-bottom: 30px;
}

.title-block-right {
  padding: 2px var(--cnvs-title-block-padding) 3px 0;
  border-left: 0;
  border-right: var(--cnvs-title-block-border) solid var(--cnvs-title-block-border-color);
  text-align: right;
}

.title-block h1, .title-block .h1, .title-block h2, .title-block .h2, .title-block h3, .title-block .h3, .title-block h4, .title-block .h4 {
  margin-bottom: 0;
}

.title-block > span {
  color: var(--cnvs-title-block-subtitle-color);
  margin-top: 4px;
  font-weight: 300;
  display: block;
}

.title-block h1 + span, .title-block .h1 + span, .title-block h2 + span, .title-block .h2 + span {
  font-size: 1.25rem;
}

.title-block h3 + span, .title-block .h3 + span {
  font-size: 1.125rem;
}

.title-block h4 + span, .title-block .h4 + span {
  font-size: .9375rem;
}

.heading-block {
  --cnvs-heading-block-font-size-h1: 2.5rem;
  --cnvs-heading-block-font-size-h2: 2.125rem;
  --cnvs-heading-block-font-size-h3: 1.875rem;
  --cnvs-heading-block-font-size-h4: 1.375rem;
  --cnvs-heading-block-font-weight: 600;
  --cnvs-heading-block-font-spacing: 0;
  --cnvs-heading-block-font-color: var(--cnvs-heading-color);
  --cnvs-heading-block-font-transform: none;
  --cnvs-heading-block-center-container: 700px;
  --cnvs-title-block-border-size: 40px;
  --cnvs-heading-block-border: 2px solid var(--cnvs-heading-color);
  --cnvs-heading-block-span-color: var(--cnvs-contrast-600);
  margin-bottom: 50px;
}

.heading-block h1, .heading-block .h1, .heading-block h2, .heading-block .h2, .heading-block h3, .heading-block .h3, .heading-block h4, .heading-block .h4 {
  font-weight: var(--cnvs-heading-block-font-weight);
  text-transform: var(--cnvs-heading-block-font-transform);
  letter-spacing: var(--cnvs-heading-block-font-spacing);
  color: var(--cnvs-heading-block-font-color);
  margin-bottom: 0;
}

.heading-block h1, .heading-block .h1 {
  font-size: var(--cnvs-heading-block-font-size-h1);
}

.heading-block h2, .heading-block .h2 {
  font-size: var(--cnvs-heading-block-font-size-h2);
}

.heading-block h3, .heading-block .h3 {
  font-size: var(--cnvs-heading-block-font-size-h3);
}

.heading-block h4, .heading-block .h4 {
  font-size: var(--cnvs-heading-block-font-size-h4);
}

.heading-block > span:not(.before-heading) {
  color: var(--cnvs-heading-block-span-color);
  margin-top: .5rem;
  font-weight: 300;
  display: block;
}

.heading-block.center > span, .heading-block.text-center > span, .center .heading-block > span, .text-center .heading-block > span {
  max-width: var(--cnvs-heading-block-center-container);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .text-md-start .heading-block > span {
    max-width: none !important;
  }
}

.heading-block h1 + span, .heading-block .h1 + span {
  font-size: calc(var(--cnvs-heading-block-font-size-h1) / 1.5);
}

.heading-block h2 + span, .heading-block .h2 + span, .heading-block h3 + span, .heading-block .h3 + span, .heading-block h4 + span, .heading-block .h4 + span {
  font-size: calc(var(--cnvs-heading-block-font-size-h2) / 1.75);
}

.heading-block:after {
  content: "";
  width: var(--cnvs-title-block-border-size);
  border-top: var(--cnvs-heading-block-border);
  margin-top: 30px;
  display: block;
}

.center .heading-block:after, .text-center .heading-block:after, .heading-block.center:after, .heading-block.text-center:after {
  margin: 30px auto 0;
}

@media (width >= 768px) {
  .text-md-start .heading-block:after {
    margin-left: 0 !important;
  }
}

.text-end .heading-block, .heading-block.text-end, .heading-block.title-right {
  direction: rtl;
}

.heading-block.border-0:after, .heading-block.border-bottom-0:after {
  display: none;
}

.heading-block.border-color:after {
  border-color: var(--cnvs-themecolor);
}

.emphasis-title {
  --cnvs-emphasis-title-font-size: 64px;
  margin: 0 0 50px;
}

.emphasis-title h1, .emphasis-title .h1, .emphasis-title h2, .emphasis-title .h2 {
  text-transform: uppercase;
  color: var(--cnvs-contrast-900);
  text-transform: none;
  font-weight: 400;
  font-size: var(--cnvs-emphasis-title-font-size);
  letter-spacing: -2px;
}

.emphasis-title h1 strong, .emphasis-title .h1 strong, .emphasis-title h2 strong, .emphasis-title .h2 strong {
  font-weight: 600;
}

.fancy-title {
  --cnvs-fancy-title-bg: #fff;
  --cnvs-fancy-title-padding: .75rem;
  --cnvs-fancy-title-border-size: 1px;
  --cnvs-fancy-title-border-color: rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-fancy-title-dotted-border: images / icons / dotted. png;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
  position: relative;
}

.fancy-title h1, .fancy-title .h1, .fancy-title h2, .fancy-title .h2, .fancy-title h3, .fancy-title .h3, .fancy-title h4, .fancy-title .h4, .fancy-title h5, .fancy-title .h5, .fancy-title h6, .fancy-title .h6 {
  margin-bottom: 0;
  position: relative;
}

.fancy-title:before, .fancy-title:after {
  content: "";
  border-top: calc(var(--cnvs-fancy-title-border-size) * 3) double rgba(var(--cnvs-contrast-rgb), .1);
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  height: 0;
}

.fancy-title:before {
  margin-right: var(--cnvs-fancy-title-padding);
  display: none;
}

.fancy-title:after {
  margin-left: var(--cnvs-fancy-title-padding);
}

.title-border:before, .title-border:after {
  border-top-width: var(--cnvs-fancy-title-border-size);
  border-top-style: solid;
}

.title-border-color:before, .title-border-color:after {
  border-top: var(--cnvs-fancy-title-border-size) solid var(--cnvs-themecolor);
}

.title-center:before, .title-right:before {
  display: block;
}

.title-right:after, .title-bottom-border:before, .title-bottom-border:after {
  display: none;
}

.title-bottom-border h1, .title-bottom-border .h1, .title-bottom-border h2, .title-bottom-border .h2, .title-bottom-border h3, .title-bottom-border .h3, .title-bottom-border h4, .title-bottom-border .h4, .title-bottom-border h5, .title-bottom-border .h5, .title-bottom-border h6, .title-bottom-border .h6 {
  width: 100%;
  padding: 0 0 var(--cnvs-fancy-title-padding);
  border-bottom: calc(var(--cnvs-fancy-title-border-size) * 2) solid var(--cnvs-themecolor);
}

:root {
  --cnvs-before-heading-font-size: 1rem;
  --cnvs-before-heading-font-weight: 400;
  --cnvs-before-heading-font-family: var(--cnvs-secondary-font);
  --cnvs-before-heading-ls: 0px;
  --cnvs-before-heading-tt: none;
  --cnvs-before-heading-fst: normal;
  --cnvs-before-heading-color: var(--cnvs-contrast-600);
  --cnvs-before-heading-margin-bottom: .25rem;
}

.before-heading {
  margin: 0 0 var(--cnvs-before-heading-margin-bottom);
  font-size: var(--cnvs-before-heading-font-size);
  font-weight: var(--cnvs-before-heading-font-weight);
  font-family: var(--cnvs-before-heading-font-family);
  letter-spacing: var(--cnvs-before-heading-ls);
  text-transform: var(--cnvs-before-heading-tt);
  color: var(--cnvs-before-heading-color);
  font-style: normal;
  display: block;
}

.divider {
  --cnvs-divider-margin: 3rem;
  --cnvs-divider-gap: .5rem;
  --cnvs-divider-color: var(--cnvs-contrast-200);
  --cnvs-divider-hover-color: var(--cnvs-contrast-600);
  --cnvs-divider-size: 18px;
  --cnvs-divider-width: 100%;
  --cnvs-divider-border-width: 1px;
  --cnvs-divider-border-style: solid;
  --cnvs-divider-border-color: var(--cnvs-divider-color);
  --cnvs-divider-bg-color: var(--cnvs-contrast-200);
  --cnvs-divider-rounded-color: var(--cnvs-contrast-600);
  --cnvs-divider-icon-border-width: var(--cnvs-divider-border-width);
  margin: var(--cnvs-divider-margin) auto;
  width: var(--cnvs-divider-width);
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.divider.divider-rounded, .divider.divider-border {
  --cnvs-divider-icon-border-width: 0px;
  --cnvs-divider-rounded-size: calc(var(--cnvs-divider-size) * 1.66667);
  --cnvs-divider-line-height: calc(var(--cnvs-divider-rounded-size)  - calc(var(--cnvs-divider-icon-border-width) * 2));
  --cnvs-divider-color: var(--cnvs-contrast-400);
  --cnvs-divider-border-color: var(--cnvs-contrast-200);
  --cnvs-divider-gap: 0;
}

.divider.divider-border {
  --cnvs-divider-icon-border-width: 1px;
  --cnvs-divider-bg-color: transparent;
}

.divider.divider-margin-lg {
  --cnvs-divider-margin: 5rem;
}

.divider:after, .divider:before {
  content: "";
  border-bottom: var(--cnvs-divider-border-width) var(--cnvs-divider-border-style) var(--cnvs-divider-border-color);
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.divider:before {
  margin-right: var(--cnvs-divider-gap);
  display: none;
}

.divider:after {
  margin-left: var(--cnvs-divider-gap);
}

.divider i, .divider a, .divider-text {
  width: var(--cnvs-divider-size);
  text-align: center;
  max-width: 100%;
  color: var(--cnvs-divider-color);
  flex: none;
  line-height: 1;
  position: relative;
  font-size: calc(var(--cnvs-divider-size) * .75) !important;
}

.divider a, .divider-text {
  width: auto;
  color: inherit;
  transition: all .3s;
}

.divider-text {
  --cnvs-divider-size: calc(var(--cnvs-divider-size) * .6);
}

.divider a:hover {
  color: var(--cnvs-divider-hover-color);
}

.divider.divider-right:before {
  display: block;
}

.divider.divider-right:after {
  display: none;
}

.divider.divider-center:before {
  display: block;
}

.divider.divider-rounded i, .divider.divider-border i {
  width: var(--cnvs-divider-rounded-size);
  height: var(--cnvs-divider-rounded-size);
  line-height: var(--cnvs-divider-line-height);
  background-color: var(--cnvs-divider-bg-color);
  border: var(--cnvs-divider-icon-border-width) var(--cnvs-divider-border-style) var(--cnvs-divider-border-color);
  border-radius: 50%;
}

.divider.divider-rounded i {
  color: var(--cnvs-divider-rounded-color);
}

.divider.divider-line {
  --cnvs-divider-gap: 0;
}

.divider.divider-thick {
  --cnvs-divider-border-width: 3px;
  --cnvs-divider-icon-border-width: 3px;
}

.divider.divider-sm {
  --cnvs-divider-width: 55%;
}

.divider.divider-xs {
  --cnvs-divider-width: 35%;
}

#infscr-loading, #portfolio-ajax-loader {
  --cnvs-ajax-loader-size: 48px;
  z-index: 20;
  width: var(--cnvs-ajax-loader-size);
  height: var(--cnvs-ajax-loader-size);
  margin: calc(-1 * var(--cnvs-ajax-loader-size)) 0 0 calc(-1 * var(--cnvs-ajax-loader-size));
  line-height: var(--cnvs-ajax-loader-size);
  color: #fff;
  text-align: center;
  background-color: #000000b3;
  border-radius: 3px;
  font-size: 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
}

#infscr-loading img, #portfolio-ajax-loader img {
  width: var(--cnvs-ajax-loader-size);
  height: var(--cnvs-ajax-loader-size);
  margin: 12px;
  display: none;
}

#portfolio-ajax-loader {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  background-color: #ffffffb3;
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  transition: all .3s;
  top: 0;
  left: 0;
}

#portfolio-ajax-loader img {
  display: block;
}

#portfolio-ajax-loader.loader-overlay-display {
  opacity: 1;
  z-index: 9999;
}

.portfolio-ajax-trigger > i:first-child, .portfolio-ajax-loading .portfolio-ajax-trigger > i:nth-child(2) {
  display: block;
}

.portfolio-ajax-loading .portfolio-ajax-trigger > i:first-child, .portfolio-ajax-trigger > i:nth-child(2) {
  display: none;
}

.page-load-status {
  padding: 30px 0;
  display: none;
  position: relative;
}

.page-load-status .css3-spinner-ball-pulse-sync > div {
  background-color: var(--cnvs-contrast-900);
}

blockquote {
  font-size: var(--cnvs-font-size-h4);
  border-left: 5px solid var(--cnvs-contrast-200);
  margin: 0 0 20px;
  padding: 10px 20px;
}

blockquote.float-start {
  max-width: 300px;
  margin: 5px 20px 10px 0;
  padding-right: 0;
}

blockquote.float-end {
  max-width: 300px;
  margin: 5px 0 10px 20px;
  padding-left: 0;
}

.blockquote-reverse {
  border-right: 5px solid var(--cnvs-contrast-200);
  text-align: right;
  border-left: 0;
  padding-left: 0;
  padding-right: 15px;
}

.blockquote-reverse.quote:before {
  content: "";
  font-family: bootstrap-icons;
  line-height: inherit;
  place-self: center;
  display: inline-flex;
  left: auto;
  right: 0;
}

.blockquote-footer {
  font-family: var(--cnvs-secondary-font);
  font-size: 85%;
}

.blockquote-icon > * {
  z-index: 1;
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  top: -3px;
  left: -1.125rem;
}

.blockquote-icon > :nth-child(2) {
  opacity: .4;
  z-index: 0;
  margin-left: .5rem;
}

.quote {
  --cnvs-quote-icon-size: 48px;
  position: relative;
  border: none !important;
}

.quote p {
  position: relative;
}

.quote:before {
  content: "";
  font-family: bootstrap-icons;
  line-height: inherit;
  width: var(--cnvs-quote-icon-size);
  height: var(--cnvs-quote-icon-size);
  line-height: var(--cnvs-quote-icon-size);
  font-size: calc(var(--cnvs-quote-icon-size)  - 6px);
  color: var(--cnvs-contrast-200);
  place-self: center;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.cascading-images, .cascading-images-inner, .cascading-image {
  --cnvs-cascading-padding: 20%;
  position: relative;
}

.cascading-image {
  padding: var(--cnvs-cascading-padding);
}

.cascading-image:not(:first-child) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cascading-image > * {
  position: relative;
}

.cascading-image-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cascading-image-bg > * {
  width: 100%;
  height: 100%;
}

.hover-3d {
  --cnvs-hover-3d-duration: .2s;
  --cnvs-hover-3d-easing: linear;
  transition: all var(--cnvs-hover-3d-duration) var(--cnvs-hover-3d-easing);
  display: block;
}

.text-splitter.scroll-detect span {
  opacity: calc(.1 + 1 * var(--cnvs-scroll-percent));
  transition: all .3s;
  transform: rotateX(20deg);
}

.text-splitter.scroll-detect.scroll-detect-inview span {
  transition-delay: calc(40ms * var(--cnvs-split-index));
  transform: rotateX(0);
}

.dropcap {
  float: left;
  text-transform: uppercase;
  margin: 0 5px 0 0;
  font-size: 42px;
  line-height: 1;
}

.highlight {
  color: #fff;
  background-color: #444;
  border-radius: 2px;
  padding: 2px 5px;
}

.text-rotater .t-rotate {
  display: none;
}

.text-rotater .t-rotate.morphext, .text-rotater .t-rotate.plugin-typed-init {
  display: inherit;
}

.typed-cursor {
  font-weight: 400;
}

.text-rotater > .t-rotate > .animated {
  display: inline-block;
}

.underliner {
  --cnvs-underliner-size: 30%;
  --cnvs-underliner-color: var(--cnvs-themecolor-rgb);
  --cnvs-underliner-opacity: .5;
  --cnvs-underliner-speed: .3s;
  background-image: linear-gradient(to bottom, transparent calc(100% - var(--cnvs-underliner-size)), rgba(var(--cnvs-underliner-color), var(--cnvs-underliner-opacity)) calc(100% - var(--cnvs-underliner-size)));
  transition: background-size var(--cnvs-underliner-speed) cubic-bezier(.14, .15, .13, .99);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  position: relative;
  opacity: 1 !important;
}

.underliner:hover, .underliner.is-in-viewport {
  background-size: 100% 100%;
}

.bg-overlay, .bg-overlay-bg, .bg-overlay-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-overlay {
  z-index: 5;
}

.bg-overlay [data-hover-animate]:not(.animated) {
  opacity: 0;
}

.bg-overlay-content {
  z-index: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.bg-overlay-bg {
  --cnvs-bg-overlay-bg-opacity: .75;
  --cnvs-bg-overlay-bg: rgba(var(--cnvs-invert-contrast-rgb), var(--cnvs-bg-overlay-bg-opacity));
  background-color: var(--cnvs-bg-overlay-bg);
}

.text-overlay-mask {
  opacity: .85;
  background: -o-linear-gradient(top, #0000 0%, #000000d9 100%);
  background: -ms-linear-gradient(top, #0000 0%, #000000d9 100%);
  height: auto;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#a6000000", GradientType= 0);
  background: -moz-linear-gradient(top, #0000 0%, #000000d9 100%);
  background: -o-linear-gradient(top, #0000 0%, #000000d9 100%);
  background: linear-gradient(#0000 0%, #000000d9 100%);
  padding: 40px 15px 15px;
  top: auto;
  bottom: 0;
}

.overlay-trigger-icon {
  --cnvs-bg-overlay-icon-size: 40px;
  --cnvs-bg-overlay-icon-gap: .25rem;
  --cnvs-bg-overlay-icon-font-size: 1.125rem;
  --cnvs-bg-overlay-icon-rounded: 50%;
  width: var(--cnvs-bg-overlay-icon-size);
  height: var(--cnvs-bg-overlay-icon-size);
  line-height: var(--cnvs-bg-overlay-icon-size);
  margin: 0 var(--cnvs-bg-overlay-icon-gap);
  font-size: var(--cnvs-bg-overlay-icon-font-size);
  text-align: center;
  border-radius: var(--cnvs-bg-overlay-icon-rounded);
  -webkit-backface-visibility: hidden;
  transition: all .3s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .overlay-trigger-icon {
    transition: none;
  }
}

.overlay-trigger-icon .uil-play {
  position: relative;
  left: 1px;
}

.overlay-trigger-icon:not([class*="text-white"]):hover {
  color: var(--cnvs-themecolor) !important;
}

.overlay-trigger-icon.size-sm {
  --cnvs-bg-overlay-icon-size: 40px;
  --cnvs-bg-overlay-icon-gap: .5rem;
  --cnvs-bg-overlay-icon-font-size: .875rem;
}

.overlay-trigger-icon.size-lg {
  --cnvs-bg-overlay-icon-size: 64px;
  --cnvs-bg-overlay-icon-gap: .5rem;
  --cnvs-bg-overlay-icon-font-size: 1.5rem;
}

.bg-overlay-mask {
  --cnvs-bg-overlay-mask-color: #000;
  --cnvs-bg-overlay-mask-opacity: .55;
  position: relative;
}

.bg-overlay-mask:before {
  content: "";
  background-color: var(--cnvs-bg-overlay-mask-color);
  width: 100%;
  height: 100%;
  opacity: var(--cnvs-bg-overlay-mask-opacity);
  position: absolute;
  top: 0;
  left: 0;
}

.gmap {
  width: 100%;
  height: 450px;
  position: relative;
}

.gmap img {
  max-width: none !important;
}

.gm-style .gm-style-iw h3 span, .gm-style .gm-style-iw .h3 span {
  font-size: inherit;
  font-family: inherit;
}

#map-overlay {
  padding: 100px 0;
  position: relative;
}

#map-overlay .gmap {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#content .cse .gsc-control-cse, #content .gsc-control-cse, #content .gsc-above-wrapper-area, #content .gsc-adBlock, #content .gsc-thumbnail-inside, #content .gsc-url-top, #content .gsc-table-result, #content .gsc-webResult, #content .gsc-result {
  padding: 0 !important;
}

#content .gsc-selected-option-container {
  width: auto !important;
}

#content .gsc-result-info {
  padding-left: 0 !important;
}

#content .gsc-above-wrapper-area-container, #content .gsc-table-result {
  margin-bottom: 10px;
}

#content .gcsc-branding {
  display: none;
}

#content .gsc-results, #content .gsc-webResult {
  width: 100% !important;
}

#content .gs-no-results-result .gs-snippet, #content .gs-error-result .gs-snippet {
  margin: 0 !important;
}

.top-advert {
  border-left: 1px solid var(--cnvs-contrast-200);
  border-right: 1px solid var(--cnvs-contrast-200);
  padding: 5px;
}

.top-advert a, .top-advert img {
  display: block;
}

.bnews-title {
  float: left;
  text-transform: uppercase;
  margin-top: 2px;
  padding-top: .3em;
  display: block;
}

.bnews-slider {
  float: left;
  width: 970px;
  min-height: 0;
  margin-left: 20px;
}

#gotoTop {
  --cnvs-gotoTop-size: 2.5rem;
  --cnvs-gotoTop-bg: #0000004d;
  --cnvs-gotoTop-icon-size: 1.5rem;
  --cnvs-gotoTop-icon-color: #fff;
  --cnvs-gotoTop-position-boxed-right: 30px;
  --cnvs-gotoTop-position-boxed-bottom: 90px;
  --cnvs-gotoTop-position-botom: 30px;
  --cnvs-gotoTop-border-radius: 2px;
  --cnvs-gotoTop-hover-color: var(--cnvs-themecolor);
  z-index: -999;
  width: var(--cnvs-gotoTop-size);
  height: var(--cnvs-gotoTop-size);
  line-height: var(--cnvs-gotoTop-size);
  background-color: var(--cnvs-gotoTop-bg);
  font-size: var(--cnvs-gotoTop-icon-size);
  text-align: center;
  color: var(--cnvs-gotoTop-icon-color);
  top: auto;
  left: auto;
  right: var(--cnvs-gotoTop-position-boxed-right);
  bottom: var(--cnvs-gotoTop-position-boxed-bottom);
  cursor: pointer;
  border-radius: var(--cnvs-gotoTop-border-radius);
  opacity: 0;
  transition: background-color .2s linear, opacity .4s;
  position: fixed;
}

@media (prefers-reduced-motion: reduce) {
  #gotoTop {
    transition: none;
  }
}

.gototop-active #gotoTop {
  z-index: 599;
  opacity: 1;
}

.stretched #gotoTop {
  right: var(--cnvs-gotoTop-position-boxed-right);
  bottom: var(--cnvs-gotoTop-position-boxed-bottom);
}

#gotoTop:hover {
  background-color: var(--cnvs-gotoTop-hover-color);
}

.read-more-wrap {
  transition: height .5s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .read-more-wrap {
    transition: none;
  }
}

.read-more-mask {
  z-index: 9;
  background-image: linear-gradient(#fff0, #fff);
  width: 100%;
  height: 35%;
  min-height: 60px;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
}

.dark .read-more-mask {
  background-image: linear-gradient(rgba(var(--bs-dark-rgb), 0), rgb(var(--bs-dark-rgb)));
}

.read-more-trigger {
  transition: all .5s;
}

@media (prefers-reduced-motion: reduce) {
  .read-more-trigger {
    transition: none;
  }
}

.read-more-trigger i {
  margin-left: 3px;
  position: relative;
  top: 1px;
}

.read-more-wrap .read-more-trigger {
  z-index: 10;
  margin: 0;
  display: block;
  position: absolute;
  top: auto;
  bottom: 5px;
  left: 4px;
}

.read-more-wrap .read-more-trigger-center {
  left: 50%;
  transform: translateX(-50%);
}

.read-more-wrap .read-more-trigger-right {
  left: auto;
  right: 5px;
}

.gdpr-settings {
  --cnvs-gdpr-settings-offset: 1rem;
  --cnvs-gdpr-settings-width: calc(100vw - calc(var(--cnvs-gdpr-settings-offset) * 2));
  --cnvs-gdpr-settings-height: 60vh;
  --cnvs-gdpr-settings-spacing: 3rem;
  max-width: var(--cnvs-gdpr-settings-width);
  max-height: var(--cnvs-gdpr-settings-height);
  margin-bottom: var(--cnvs-gdpr-settings-offset);
  top: auto;
  bottom: 0;
  left: var(--cnvs-gdpr-settings-offset);
  right: var(--cnvs-gdpr-settings-offset);
  opacity: 0;
  padding: var(--cnvs-gdpr-settings-spacing);
  z-index: 999;
  background-color: var(--cnvs-contrast-100);
  border-radius: .25rem;
  transition: all .3s;
  position: fixed;
  overflow-y: auto;
}

@media (prefers-reduced-motion: reduce) {
  .gdpr-settings {
    transition: none;
  }
}

.gdpr-settings-sm {
  --cnvs-gdpr-settings-width: 30rem;
  margin-right: var(--cnvs-gdpr-settings-offset);
}

.gdpr-settings-sm.gdpr-settings-right {
  left: auto;
  right: var(--cnvs-gdpr-settings-offset);
  margin-right: 0;
  margin-left: var(--cnvs-gdpr-settings-offset);
}

@media (width >= 768px) {
  .gdpr-settings {
    max-height: none;
    overflow: auto;
  }
}

.gdpr-container {
  height: auto;
  position: relative;
}

.gdpr-container:not(.gdpr-content-active) {
  min-height: 250px;
}

.gdpr-container.gdpr-content-active {
  min-height: 0 !important;
}

.gdpr-container.gdpr-content-active .gdpr-blocked-message {
  display: none;
}

.gdpr-blocked-message {
  z-index: 1;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.gdpr-blocked-message a {
  text-underline-offset: 2px;
  transition: all var(--cnvs-transitions);
}

.gdpr-blocked-message a:hover {
  text-underline-offset: 4px;
}

.error404 {
  color: #ddd;
  letter-spacing: 4px;
  font-size: 18vw;
  font-weight: 700;
  line-height: 1;
  display: block;
}

.error404-wrap .container {
  z-index: 7;
}

.error404-wrap .error404 {
  opacity: .2;
  text-shadow: 1px 1px 5px #0006;
  margin-bottom: 40px;
  font-size: 19vw;
  font-weight: bold;
  line-height: .9;
  color: #fff !important;
}

.error404-wrap .heading-block h4, .error404-wrap .heading-block .h4 {
  margin-bottom: 8px;
  font-weight: 300;
}

.error404-wrap .heading-block span {
  font-size: 1.125rem;
}

.error404-wrap form {
  max-width: 500px;
}

.landing-wide-form {
  background: #0000004d;
  border-radius: 3px;
  padding: 30px;
}

.landing-form-overlay {
  z-index: 10;
  background-color: #0009;
  border-radius: 3px 3px 0 0;
  position: absolute;
  inset: auto 0 -154px auto;
}

.landing-video {
  z-index: 1;
  width: 560px;
  height: 315px;
  margin: 22px 0 0 95px;
  overflow: hidden;
}

.landing-promo h3, .landing-promo .h3 {
  font-size: 1.75rem;
}

.landing-promo > .container > span {
  font-size: 1.125rem;
}

.nav-tree {
  margin-bottom: 1.5rem;
  position: relative;
}

.nav-tree ul {
  margin-bottom: 0;
  list-style: none;
}

.nav-tree ul ul {
  display: none;
}

.nav-tree ul ul a {
  padding: 6px 0 6px 20px;
  font-family: Inter, sans-serif;
}

.nav-tree ul ul ul a {
  padding-left: 40px;
}

.nav-tree ul ul ul ul a {
  padding-left: 60px;
}

.nav-tree ul ul ul ul ul a {
  padding-left: 80px;
}

.nav-tree > ul {
  margin: -8px 0;
}

.nav-tree li {
  position: relative;
}

.nav-tree li a {
  color: var(--cnvs-heading-color);
  font-size: 1rem;
  font-family: var(--cnvs-primary-font);
  padding: 8px 0;
  transition: transform;
  display: block;
}

.nav-tree li li a {
  font-size: calc(1rem - 2px);
}

.nav-tree li i {
  text-align: center;
  width: 16px;
}

.nav-tree li i:not(.sub-menu-indicator) {
  margin-right: .5rem;
  position: relative;
}

.nav-tree li i.sub-menu-indicator {
  width: auto;
  margin-left: .5rem;
  margin-right: 0;
  font-size: .85rem;
  transition: transform .3s;
}

.nav-tree li:hover > a, .nav-tree li.current > a, .nav-tree li.active > a {
  color: var(--cnvs-themecolor) !important;
}

.nav-tree li.active > a .sub-menu-indicator {
  transform: rotate(90deg);
}

.nav-tree li.current > ul {
  display: block;
}

.wedding-head {
  text-shadow: 1px 1px 1px #00000026;
  font-size: 80px;
  line-height: 1;
  font-family: var(--cnvs-primary-font);
  position: relative;
}

.wedding-head .first-name, .wedding-head .last-name, .wedding-head .and {
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 15px;
  font-weight: bold;
  display: inline-block;
}

.wedding-head .last-name {
  text-align: left;
  margin: 0 0 0 15px;
}

.wedding-head .first-name span, .wedding-head .last-name span {
  text-transform: none;
  margin-top: 10px;
  font-family: Playfair Display, serif;
  font-size: 56px;
  font-style: italic;
  font-weight: 400;
  display: block;
}

.wedding-head .and {
  margin: 0;
  font-family: Times New Roman, serif;
  font-size: 200px;
}

.switch-toggle {
  visibility: hidden;
  margin-left: -9999px;
  position: absolute;
}

.switch-toggle + label {
  cursor: pointer;
  user-select: none;
  outline: none;
  display: block;
  position: relative;
}

.switch {
  --cnvs-switch-width: 60px;
  --cnvs-switch-spacing: 1px;
  --cnvs-switch-bg: var(--cnvs-contrast-300);
  --cnvs-switch-active-bg: var(--cnvs-themecolor);
}

.switch-toggle + label {
  padding: calc(var(--cnvs-switch-spacing) * 2);
  width: var(--cnvs-switch-width);
  height: calc(var(--cnvs-switch-width) * .5);
  background-color: var(--cnvs-switch-bg);
  border-radius: 512px;
}

.switch-toggle + label:before, .switch-toggle + label:after {
  top: var(--cnvs-switch-spacing);
  left: var(--cnvs-switch-spacing);
  bottom: var(--cnvs-switch-spacing);
  content: "";
  border-radius: 512px;
  display: block;
  position: absolute;
}

.switch-toggle-round + label:before {
  right: var(--cnvs-switch-spacing);
  background-color: var(--cnvs-contrast-200);
  transition: background .4s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-toggle-round + label:before {
    transition: none;
  }
}

.switch-toggle-round + label:after {
  width: calc(var(--cnvs-switch-width) * .5 - var(--cnvs-switch-spacing) * 2);
  background-color: var(--cnvs-body-bg);
  transition: margin .4s;
  box-shadow: 0 2px 5px #0000004d;
}

@media (prefers-reduced-motion: reduce) {
  .switch-toggle-round + label:after {
    transition: none;
  }
}

.switch-toggle-round:checked + label:before {
  background-color: var(--cnvs-switch-active-bg);
}

.switch-toggle-round:checked + label:after {
  margin-left: calc(var(--cnvs-switch-width) * .5);
}

.switch-toggle-round.switch-rounded-mini + label {
  --cnvs-switch-width: 32px;
}

.switch-toggle-round.switch-rounded-large + label {
  --cnvs-switch-width: 90px;
}

.switch-toggle-round.switch-rounded-xlarge + label {
  --cnvs-switch-width: 120px;
}

.switch-toggle-flat {
  transition: background .4s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-toggle-flat {
    transition: none;
  }
}

.switch-toggle-flat + label:before {
  top: calc(var(--cnvs-switch-spacing) * 2);
  left: calc(var(--cnvs-switch-spacing) * 2);
  bottom: calc(var(--cnvs-switch-spacing) * 2);
  right: calc(var(--cnvs-switch-spacing) * 2);
  background-color: #fff;
  transition: background .4s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-toggle-flat + label:before {
    transition: none;
  }
}

.switch-toggle-flat + label:after {
  top: calc(var(--cnvs-switch-spacing) * 4);
  left: calc(var(--cnvs-switch-spacing) * 4);
  bottom: calc(var(--cnvs-switch-spacing) * 4);
  width: calc(var(--cnvs-switch-width) * .5 - var(--cnvs-switch-spacing) * 8);
  background-color: var(--cnvs-switch-bg);
  transition: margin .4s, background .4s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-toggle-flat + label:after {
    transition: none;
  }
}

.switch-toggle-flat:checked + label {
  background-color: var(--cnvs-switch-active-bg);
}

.switch-toggle-flat:checked + label:after {
  margin-left: calc(var(--cnvs-switch-width) * .5);
  background-color: var(--cnvs-switch-active-bg);
}

.switch-toggle-flat.switch-flat-mini + label {
  --cnvs-switch-width: 32px;
  --cnvs-switch-spacing: .5px;
}

.switch-toggle-flat.switch-flat-large + label {
  --cnvs-switch-width: 90px;
}

.switch-toggle-flat.switch-flat-xlarge + label {
  --cnvs-switch-width: 120px;
}

.show-error-msg .switch + label.error {
  display: block !important;
}

.switch input[type="checkbox"].error + label {
  border: 1px solid var(--bs-danger);
}

.switch input[type="checkbox"].error + label:before {
  background-color: rgba(var(--bs-danger-rgb), .1);
}

#driver-highlighted-element-stage {
  opacity: 0;
}

.driver-popover-tip:before, .driver-popover-tip :after {
  content: "";
  margin-left: calc(-1 * var(--cnvs-driver-popover-tip-size) * .5);
  width: var(--cnvs-driver-popover-tip-size);
  height: var(--cnvs-driver-popover-tip-size);
  background-color: var(--cnvs-driver-popover-tip-bg);
  border-radius: 50%;
  position: absolute;
  top: -20px;
  left: 0;
}

.driver-popover-tip.top:before, .driver-popover-tip.top:after {
  margin-left: calc(-1 * var(--cnvs-driver-popover-tip-size) * .5);
  top: -20px;
  left: 0;
}

.driver-popover-tip.bottom:before, .driver-popover-tip.bottom:after {
  top: 5px;
  bottom: auto;
}

.driver-popover-tip.left:before, .driver-popover-tip.left:after {
  top: calc(-1 * var(--cnvs-driver-popover-tip-size) * .5);
  left: calc(-1 * var(--cnvs-driver-popover-tip-size));
}

.driver-popover-tip.right:before, .driver-popover-tip.right:after {
  top: calc(-1 * var(--cnvs-driver-popover-tip-size) * .5);
  left: auto;
  right: calc(-1 * var(--cnvs-driver-popover-tip-size));
}

.driver-popover-tip:before {
  animation: 3s ease-out infinite pulsate;
}

@keyframes pulsate {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: .3;
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@media (prefers-reduced-motion: reduce) {
  .driver-popover-tip:before {
    animation: none;
  }
}

div#driver-popover-item {
  --cnvs-driver-popover-tip-size: 14px;
  --cnvs-driver-popover-tip-bg: #fff;
  padding: 25px;
}

div#driver-popover-item .driver-popover-title {
  margin-bottom: 10px;
  font-size: 1.35rem;
}

div#driver-popover-item .driver-popover-description {
  margin-bottom: 25px;
  font-size: .925rem;
  line-height: 1.5;
  display: block;
}

div#driver-popover-item .driver-popover-footer button {
  background-color: var(--bs-light);
  padding: 7px 13px;
  font-size: .75rem;
}

@media (width <= 768px) {
  div#driver-popover-item {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
  }

  .driver-popover-tip {
    display: none;
  }
}

.cnvs-cursor {
  --cnvs-cursor-size: .75rem;
  --cnvs-cursor-bg: var(--cnvs-themecolor);
  --cnvs-cursor-opacity: .15;
  --cnvs-cursor-border-opacity: 1;
  --cnvs-cursor-action-size: 4rem;
  --cnvs-cursor-action-bg: var(--cnvs-themecolor);
  --cnvs-cursor-action-opacity: .35;
  pointer-events: none;
  z-index: 999999;
  transition: transform 50ms;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.cnvs-cursor-follower, .cnvs-cursor-dot {
  width: var(--cnvs-cursor-size);
  height: var(--cnvs-cursor-size);
  background: var(--cnvs-cursor-bg);
  opacity: var(--cnvs-cursor-opacity);
  transform-origin: center;
  border-radius: 50%;
  transition: all .25s;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.cnvs-cursor-dot {
  --cnvs-cursor-size: .25rem;
  border: 0;
}

.cnvs-cursor.cnvs-cursor-action {
  --cnvs-cursor-size: var(--cnvs-cursor-action-size);
  --cnvs-cursor-bg: var(--cnvs-cursor-action-bg);
  --cnvs-cursor-opacity: var(--cnvs-cursor-action-opacity);
}

.cnvs-cursor.cnvs-cursor-action .cnvs-cursor-follower {
  border-color: #0000;
}

.cnvs-cursor.cnvs-cursor-border {
  --cnvs-cursor-opacity: var(--cnvs-cursor-border-opacity);
}

.cnvs-cursor.cnvs-cursor-border .cnvs-cursor-follower {
  border: 1px solid var(--cnvs-cursor-bg);
  opacity: var(--cnvs-cursor-opacity);
  background: none;
}

.cnvs-cursor.cnvs-cursor-border .cnvs-cursor-dot {
  background: var(--cnvs-cursor-bg);
  opacity: var(--cnvs-cursor-opacity);
}

.cnvs-cursor.cnvs-cursor-disabled {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.grid-border {
  --cnvs-grid-border-width: 1px;
  --cnvs-grid-border-color: var(--cnvs-contrast-900);
  --cnvs-grid-border-color-dark: #fff;
  --cnvs-grid-border-opacity: .07;
  overflow: hidden;
}

.grid-border [class^="col-"]:before, .grid-border [class^="col-"]:after {
  content: "";
  border-style: solid;
  border-width: 0;
  border-color: var(--cnvs-grid-border-color);
  opacity: var(--cnvs-grid-border-opacity);
  position: absolute;
}

.grid-border [class^="col-"]:before {
  height: 100%;
  top: 0;
  left: calc(-1 * var(--cnvs-grid-border-width));
  border-left-width: var(--cnvs-grid-border-width);
}

.grid-border [class^="col-"]:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: calc(-1 * var(--cnvs-grid-border-width));
  border-bottom-width: var(--cnvs-grid-border-width);
}

.media-wrap:not(.media-is-playing) .media-trigger-volume, .media-is-playing .media-trigger-play, .media-wrap:not(.media-is-playing) .media-trigger-pause, .media-is-muted .media-trigger-mute, .media-wrap:not(.media-is-muted) .media-trigger-unmute {
  display: none !important;
}

.app-menu {
  --cnvs-app-menu-height: 70px;
  --cnvs-app-menu-icon-size: 1.25rem;
  --cnvs-app-menu-color: var(--cnvs-contrast-1000);
  --cnvs-app-menu-bg-color: var(--cnvs-contrast-100);
  --cnvs-app-menu-item-active-bg: var(--cnvs-contrast-rgb);
  background: var(--cnvs-app-menu-bg-color);
  border-top: 1px solid rgba(var(--cnvs-app-menu-item-active-bg), .1);
  z-index: 999;
  width: 100%;
  height: auto;
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
}

.app-menu-container {
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  display: flex;
}

.app-menu-item {
  flex: 1 0;
  position: relative;
}

.app-menu-item:hover > .app-menu-link, .app-menu-item.current > .app-menu-link {
  background-color: rgba(var(--cnvs-app-menu-item-active-bg), .1);
}

.app-menu.app-menu-style-2 .app-menu-item:hover > .app-menu-link, .app-menu.app-menu-style-2 .app-menu-item.current > .app-menu-link {
  --cnvs-app-menu-item-active-bg: var(--cnvs-themecolor);
  background-color: var(--cnvs-contrast-0);
  position: relative;
}

.app-menu.app-menu-style-2 .app-menu-item:hover > .app-menu-link:before, .app-menu.app-menu-style-2 .app-menu-item.current > .app-menu-link:before {
  content: "";
  background-color: var(--cnvs-app-menu-item-active-bg);
  width: 100%;
  height: .25rem;
  position: absolute;
  top: -1px;
  left: 0;
}

.app-menu-link {
  --cnvs-app-menu-link-spacing: calc(var(--cnvs-app-menu-height)  - var(--cnvs-app-menu-icon-size));
  width: 100%;
  padding: calc(var(--cnvs-app-menu-link-spacing) * .5 - .3125rem) 1rem calc(var(--cnvs-app-menu-link-spacing) * .5 + .3125rem);
  text-align: center;
  color: var(--cnvs-app-menu-color);
  transition: all .2s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .app-menu-link {
    transition: none;
  }
}

.app-menu-link > i {
  line-height: 1;
  font-size: var(--cnvs-app-menu-icon-size);
  height: var(--cnvs-app-menu-icon-size);
  display: block;
}

.app-menu-link > span {
  margin-top: .375rem;
  font-size: .875rem;
  display: block;
}

.app-menu-item:hover > .app-menu-link {
  background-color: rgba(var(--cnvs-app-menu-item-active-bg), .05);
}

.circle-stack {
  --cnvs-circle-stack-size: 5rem;
  --cnvs-circle-stack-inset-ratio: .2;
  --cnvs-circle-stack-inset: calc(var(--cnvs-circle-stack-size) * var(--cnvs-circle-stack-inset-ratio));
  --cnvs-circle-stack-border-color: var(--cnvs-body-bg);
  --cnvs-circle-stack-border-size: .25rem;
  --cnvs-circle-stack-border-radius: 50%;
  --cnvs-circle-stack-fontsize: calc(var(--cnvs-circle-stack-size) * .35);
  --cnvs-circle-stack-color: var(--cnvs-contrast-900);
  --cnvs-circle-stack-bgcolor: var(--cnvs-contrast-200);
}

.circle-stack > * {
  width: var(--cnvs-circle-stack-size);
  height: var(--cnvs-circle-stack-size);
  border: var(--cnvs-circle-stack-border-size) solid var(--cnvs-circle-stack-border-color);
  object-fit: cover;
  object-position: center;
  border-radius: var(--cnvs-circle-stack-border-radius);
  color: var(--cnvs-circle-stack-color);
  font-size: var(--cnvs-circle-stack-fontsize);
  text-align: center;
  background-color: var(--cnvs-circle-stack-bgcolor);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.circle-stack > :not(:first-child) {
  margin-left: calc(-1 * var(--cnvs-circle-stack-inset));
}

.circle-stack.flex-row-reverse > :not(:first-child) {
  margin: 0 calc(-1 * var(--cnvs-circle-stack-inset)) 0 0;
}

.circle-stack.flex-column > :not(:first-child), .circle-stack.flex-column.flex-coumn-reverse > :not(:first-child) {
  margin: calc(-1 * var(--cnvs-circle-stack-inset)) 0 0 0;
}

@keyframes tickerAnim {
  0% {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes tickerAnimReverse {
  0% {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.ticker-wrap {
  --cnvs-ticker-duration: 50s;
  --cnvs-ticker-gap: 2rem;
  box-sizing: content-box;
  width: 100%;
  padding-left: 100%;
  position: relative;
  overflow: hidden;
}

.ticker-wrap.ticker-reverse {
  padding-left: 0;
  padding-right: 100%;
}

.ticker-wrap .ticker {
  white-space: nowrap;
  box-sizing: content-box;
  animation: var(--cnvs-ticker-duration) linear infinite;
  padding-right: 100%;
  animation-name: tickerAnim;
  display: inline-block;
}

.ticker-wrap .ticker .ticker-item {
  padding: 0 var(--cnvs-ticker-gap);
  font-size: 1.5rem;
  display: inline-block;
}

.ticker-wrap .ticker .ticker-item[href]:hover {
  text-decoration: underline !important;
}

.ticker-wrap.ticker-reverse .ticker {
  padding-left: 100%;
  padding-right: 0;
  animation-name: tickerAnimReverse;
}

.ticker-wrap.pause-on-hover .ticker:hover {
  animation-play-state: paused;
}

:root, .not-dark {
  --cnvs-form-processor-bg: #fff;
  --cnvs-input-btn-input-color: var(--bs-body-color);
  --cnvs-input-btn-input-bg: var(--bs-body-bg);
  --cnvs-input-btn-border-color: var(--bs-border-color);
  --cnvs-input-label-mb: .5rem;
  --cnvs-form-label-font-weight: 500;
  --cnvs-form-label-error: var(--bs-danger);
}

form {
  --cnvs-form-processor-bg: #fff;
  --cnvs-form-processor-bg-op: .7;
  --cnvs-form-group-margin: 1.25rem;
}

form .form-group {
  margin-bottom: var(--cnvs-form-group-margin);
}

.form-control {
  --cnvs-input-btn-padding-y: .375rem;
  --cnvs-input-btn-padding-x: .75rem;
  --cnvs-input-btn-font-size: 1rem;
  --cnvs-input-font-family: ;
  --cnvs-input-btn-font-weight: 400;
  --cnvs-input-btn-line-height: 1.5;
  --cnvs-input-btn-input-color: var(--bs-body-color);
  --cnvs-input-btn-input-bg: var(--bs-body-bg);
  --cnvs-form-control-radius: var(--bs-border-radius);
  --cnvs-input-btn-border-width: var(--bs-border-width);
  --cnvs-input-btn-border-color: var(--bs-border-color);
  --cnvs-input-btn-padding-y-sm: .25rem;
  --cnvs-input-btn-padding-x-sm: .5rem;
  --cnvs-input-btn-font-size-sm: .875rem;
  --cnvs-input-btn-padding-y-lg: .5rem;
  --cnvs-input-btn-padding-x-lg: 1rem;
  --cnvs-input-btn-font-size-lg: 1.25rem;
  --cnvs-input-focus-color: var(--bs-body-color);
  --cnvs-input-focus-bg: var(--bs-body-bg);
  --cnvs-input-focus-border-color: #86b7fe;
  --cnvs-input-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  padding: var(--cnvs-input-btn-padding-y) var(--cnvs-input-btn-padding-x);
  font-size: var(--cnvs-input-btn-font-size);
  font-family: var(--cnvs-input-font-family);
  font-weight: var(--cnvs-input-btn-font-weight);
  line-height: var(--cnvs-input-btn-line-height);
  color: var(--cnvs-input-btn-input-color);
  background-color: var(--cnvs-input-btn-input-bg);
  border: var(--cnvs-input-btn-border-width) solid var(--cnvs-input-btn-border-color);
  border-radius: var(--cnvs-form-control-radius);
}

.form-control.error {
  border-color: var(--cnvs-form-label-error);
}

.form-control-sm {
  --cnvs-input-btn-padding-y: var(--cnvs-input-btn-padding-y-sm);
  --cnvs-input-btn-padding-x: var(--cnvs-input-btn-padding-x-sm);
  --cnvs-input-btn-font-size: var(--cnvs-input-btn-font-size-sm);
}

.form-control-lg {
  --cnvs-input-btn-padding-y: var(--cnvs-input-btn-padding-y-lg);
  --cnvs-input-btn-padding-x: var(--cnvs-input-btn-padding-x-lg);
  --cnvs-input-btn-font-size: var(--cnvs-input-btn-font-size-lg);
}

.form-control:focus {
  color: var(--cnvs-input-focus-color);
  background-color: var(--cnvs-input-focus-bg);
  border-color: var(--cnvs-input-focus-border-color);
  box-shadow: var(--cnvs-input-focus-box-shadow);
}

.form-control.border-form-control {
  --cnvs-input-btn-padding-x: 0;
  --cnvs-form-control-radius: 0;
  --cnvs-input-focus-box-shadow: none;
  border-top: #0000;
  border-left: #0000;
  border-right: #0000;
  border-bottom-width: var(--cnvs-input-btn-border-width);
  background-color: #0000;
}

label {
  margin-bottom: var(--cnvs-input-label-mb);
  font-weight: var(--cnvs-form-label-font-weight);
}

label.label-muted {
  color: #0d6efd40;
  margin-right: 5px;
  font-weight: normal;
}

label.error {
  color: var(--cnvs-form-label-error);
  margin-top: 5px;
  font-weight: 400;
  display: none !important;
}

.show-error-msg + label.error, input[type="checkbox"]:not(:checked) + label.error {
  display: block !important;
}

[data-condition]:not(.condition-fulfilled) {
  display: none !important;
}

.contact-form-overlay {
  background-color: var(--cnvs-form-background);
  z-index: 10;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 10px #00000026;
}

.preloader, .form-process, .preloader2 {
  background: center center no-repeat var(--cnvs-input-btn-input-bg);
  width: 100%;
  height: 100%;
  display: block;
}

.preloader2 {
  background-color: #0000;
}

.form-process {
  z-index: 3;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.form-process:before {
  content: "";
  background-color: var(--cnvs-form-processor-bg);
  width: 100%;
  height: 100%;
  opacity: var(--cnvs-form-processor-bg-op);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

#footer {
  --cnvs-footer-top-border: 5px solid rgba(var(--cnvs-contrast-rgb), .15);
  --cnvs-copyrights-link-color: var(--cnvs-contrast-500);
  background-color: var(--cnvs-footer-bg);
  border-top: var(--cnvs-footer-top-border);
  position: relative;
}

@media (width >= 992px) {
  .sticky-footer #slider:not(.slider-parallax-invisible), .sticky-footer #page-submenu, .sticky-footer #page-title, .sticky-footer #content {
    z-index: 2;
  }

  .sticky-footer #footer {
    position: sticky;
    top: auto;
    bottom: 0;
    left: 0;
  }
}

#footer .footer-widgets-wrap {
  padding: var(--cnvs-content-padding) 0;
  position: relative;
}

#copyrights {
  padding: calc(var(--cnvs-content-padding) * .5) 0;
  background-color: var(--cnvs-contrast-300);
  font-size: var(--cnvs-copyrights-font-size);
  line-height: var(--cnvs-line-height-content);
}

#copyrights i.footer-icon {
  font-size: var(--cnvs-copyrights-font-size);
  width: var(--cnvs-copyrights-font-size);
  text-align: center;
  margin-right: 3px;
  position: relative;
  top: 1px;
}

#copyrights .text-end a:last-child {
  margin-right: 0;
}

.copyright-links {
  color: var(--cnvs-copyrights-link-color);
  margin-top: .5rem;
}

.copyright-links a {
  color: var(--cnvs-copyrights-link-color);
  border-bottom: 1px dotted var(--cnvs-copyrights-link-color);
  margin: 0 .25rem;
  display: inline-block;
}

.copyright-links a:hover {
  opacity: .91;
}

.copyright-links a:first-child {
  margin-left: 0;
}

.copyrights-menu {
  margin-bottom: 10px;
}

.copyrights-menu a {
  font-size: var(--cnvs-copyrights-font-size);
  margin: 0 10px;
  border-bottom: 0 !important;
}

.copyrights-menu a:first-child {
  margin-left: 0;
}

.footer-logo {
  margin-bottom: 30px;
  display: block;
}

:root, .not-dark {
  --cnvs-widget-border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
}

.widget {
  --cnvs-widget-border: 1px solid rgba(var(--cnvs-contrast-rgb), .1);
  --cnvs-widget-margin: 3rem;
  --cnvs-widget-title-margin: 1.5rem;
  --cnvs-widget-title-font-size: .9325rem;
  --cnvs-widget-title-font-weight: 600;
  --cnvs-widget-title-letter-spacing: 1px;
  --cnvs-widget-title-text-transform: uppercase;
  margin-top: var(--cnvs-widget-margin);
  position: relative;
}

.widget p {
  line-height: 1.7;
}

.sidebar-widgets-wrap .widget {
  padding-top: var(--cnvs-widget-margin);
  border-top: var(--cnvs-widget-border);
}

.sidebar-widgets-wrap .widget:first-child {
  border-top: 0;
  padding-top: 0;
}

.sidebar-widgets-wrap .widget > h4, .sidebar-widgets-wrap .widget > .h4 {
  letter-spacing: calc(var(--cnvs-widget-title-letter-spacing)  + 1px);
}

.widget:first-child {
  margin-top: 0;
}

.widget > h4, .widget > .h4 {
  margin-bottom: var(--cnvs-widget-title-margin);
  font-size: var(--cnvs-widget-title-font-size);
  font-weight: var(--cnvs-widget-title-font-weight);
  letter-spacing: var(--cnvs-widget-title-letter-spacing);
  text-transform: var(--cnvs-widget-title-text-transform);
}

.widget_nav_menu ul, .widget_nav_menu li, .widget_links ul, .widget_links li, .widget_meta ul, .widget_meta li, .widget_archive ul, .widget_archive li, .widget_recent_comments ul, .widget_recent_comments li, .widget_recent_entries ul, .widget_recent_entries li, .widget_categories ul, .widget_categories li, .widget_pages ul, .widget_pages li, .widget_rss ul, .widget_rss li {
  margin: 0;
  list-style: none;
}

.widget_nav_menu li, .widget_links li, .widget_meta li, .widget_archive li, .widget_recent_comments li, .widget_recent_entries li, .widget_categories li, .widget_pages li, .widget_rss li {
  padding: .25rem 0;
  font-size: 1rem;
  display: flex;
}

.widget_nav_menu li a, .widget_links li a, .widget_meta li a, .widget_archive li a, .widget_recent_comments li a, .widget_recent_entries li a, .widget_categories li a, .widget_pages li a, .widget_rss li a {
  color: var(--cnvs-heading-color);
  padding: 0 .25rem 0 .75rem;
  display: inline-block;
  border: none !important;
}

.widget_nav_menu li a:hover, .widget_links li a:hover, .widget_meta li a:hover, .widget_archive li a:hover, .widget_recent_comments li a:hover, .widget_recent_entries li a:hover, .widget_categories li a:hover, .widget_pages li a:hover, .widget_rss li a:hover {
  color: var(--cnvs-themecolor);
}

.widget_nav_menu:not(.widget-li-noicon) li:before, .widget_links:not(.widget-li-noicon) li:before, .widget_meta:not(.widget-li-noicon) li:before, .widget_archive:not(.widget-li-noicon) li:before, .widget_recent_comments:not(.widget-li-noicon) li:before, .widget_recent_entries:not(.widget-li-noicon) li:before, .widget_categories:not(.widget-li-noicon) li:before, .widget_pages:not(.widget-li-noicon) li:before, .widget_rss:not(.widget-li-noicon) li:before {
  content: "";
  font-family: bootstrap-icons;
  font-size: .75rem;
  line-height: inherit;
  place-self: center;
  display: inline-flex;
  position: relative;
  left: 0;
}

.widget_nav_menu.widget-li-noicon li a, .widget_links.widget-li-noicon li a, .widget_meta.widget-li-noicon li a, .widget_archive.widget-li-noicon li a, .widget_recent_comments.widget-li-noicon li a, .widget_recent_entries.widget-li-noicon li a, .widget_categories.widget-li-noicon li a, .widget_pages.widget-li-noicon li a, .widget_rss.widget-li-noicon li a {
  padding-left: 0;
}

.widget_nav_menu > ul, .widget_links > ul, .widget_meta > ul, .widget_archive > ul, .widget_recent_comments > ul, .widget_recent_entries > ul, .widget_categories > ul, .widget_pages > ul, .widget_rss > ul {
  margin-top: -4px !important;
}

.widget_nav_menu > ul > li:first-child, .widget_links > ul > li:first-child, .widget_meta > ul > li:first-child, .widget_archive > ul > li:first-child, .widget_recent_comments > ul > li:first-child, .widget_recent_entries > ul > li:first-child, .widget_categories > ul > li:first-child, .widget_pages > ul > li:first-child, .widget_rss > ul > li:first-child {
  border-top: 0 !important;
}

.widget_nav_menu ul ul, .widget_links ul ul, .widget_meta ul ul, .widget_archive ul ul, .widget_recent_comments ul ul, .widget_recent_entries ul ul, .widget_categories ul ul, .widget_pages ul ul, .widget_rss ul ul {
  margin-left: 1rem;
}

.widget_recent_comments li:before {
  content: "";
}

.widget .testimonial.no-image .testi-image {
  display: none;
}

.widget .testimonial.twitter-scroll .testi-image {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.widget .testimonial.twitter-scroll .testi-image a, .widget .testimonial.twitter-scroll .testi-image img, .widget .testimonial.twitter-scroll .testi-image i {
  width: 28px;
  height: 28px;
}

.widget .testimonial.twitter-scroll .testi-image i {
  background-color: var(--cnvs-contrast-100);
  color: var(--cnvs-contrast-300);
  font-size: .875rem;
  line-height: 28px;
}

.widget .testimonial:not(.twitter-scroll) .testi-image, .widget .testimonial:not(.twitter-scroll) .testi-image a, .widget .testimonial:not(.twitter-scroll) .testi-image img {
  width: 42px;
  height: 42px;
}

.widget .testimonial p {
  font-size: .875rem;
}

@media (width >= 768px) and (width <= 991.98px) {
  .sidebar-widgets-wrap {
    display: -ms-flex;
    flex-wrap: wrap;
    margin: 0 -20px -40px;
    display: flex;
    position: relative;
  }

  .sidebar-widgets-wrap .widget {
    border-top: 0;
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 0 40px;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.quick-contact-widget.form-widget .form-control, .quick-contact-widget.form-widget .input-group, .quick-contact-widget.form-widget .sm-form-control {
  margin-bottom: 10px;
}

.quick-contact-widget.form-widget .form-control {
  margin-bottom: 0;
}

.quick-contact-widget.form-widget form, #template-contactform {
  position: relative;
}

.subscribe-widget h5, .subscribe-widget .h5 {
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.5;
}

.widget-twitter-feed .twitter-feed {
  --cnvs-iconlist-size: 1.5rem;
  --cnvs-iconlist-offset: .375rem;
  --cnvs-iconlist-gutter: 1rem;
}

.widget-twitter-feed .twitter-feed li:not(:first-child) {
  margin-top: 1.125rem;
}

.widget-twitter-feed .twitter-feed li div {
  font-size: 1rem;
}

.widget-twitter-feed .twitter-feed small, .widget-twitter-feed .twitter-feed .small {
  margin-top: .25rem;
  display: block;
}

.widget-twitter-feed .twitter-feed small a:not(:hover), .widget-twitter-feed .twitter-feed .small a:not(:hover) {
  color: var(--cnvs-contrast-600);
}

.widget-filter-links ul {
  margin-bottom: 0;
  list-style: none;
}

.widget-filter-links .widget-filter-reset {
  font-size: 12px;
  line-height: 22px;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

.widget-filter-links .widget-filter-reset a {
  color: red !important;
}

.widget-filter-links .widget-filter-reset.active-filter {
  display: none;
}

.widget-filter-links:not(:first-child) .widget-filter-reset {
  top: 50px;
}

.widget-filter-links li {
  font-size: 15px;
  line-height: 24px;
  position: relative;
}

.widget-filter-links li:not(:first-child) {
  margin-top: 10px;
}

.widget-filter-links li a {
  color: var(--cnvs-heading-color);
  font-family: var(--cnvs-primary-font);
  font-weight: 500;
  display: block;
}

.widget-filter-links li span {
  text-align: center;
  width: 22px;
  height: 22px;
  color: var(--cnvs-contrast-600);
  background-color: var(--cnvs-contrast-200);
  border-radius: 50%;
  margin-left: .25rem;
  font-size: 11px;
  line-height: 22px;
}

.widget-filter-links li span:not(.d-inline-block) {
  position: absolute;
  top: 1px;
  left: auto;
  right: 0;
}

.widget-filter-links li:hover a {
  color: var(--cnvs-contrast-600);
}

.widget-filter-links li.active-filter a {
  font-weight: 700;
}

.widget-filter-links li.active-filter span {
  text-shadow: 1px 1px 1px #0000001a;
  color: #fff !important;
  background-color: var(--cnvs-themecolor) !important;
}

.tagcloud {
  flex-wrap: wrap;
  margin-bottom: -2px;
  display: flex;
}

.tagcloud a {
  border: 1px solid var(--cnvs-contrast-300);
  color: var(--cnvs-contrast-700);
  border-radius: 2px;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 3px 6px;
  transition: all .3s ease-in-out;
  font-size: 13px !important;
}

@media (prefers-reduced-motion: reduce) {
  .tagcloud a {
    transition: none;
  }
}

.tagcloud a:hover {
  border-color: var(--cnvs-themecolor) !important;
  color: var(--cnvs-themecolor) !important;
}

:root, .not-dark {
  --cnvs-pagination-circle-size: 35px;
  --cnvs-pagination-margin: 5px;
  --bs-pagination-hover-color: var(--cnvs-contrast-0);
}

.pagination {
  --bs-pagination-color: var(--cnvs-themecolor);
  --bs-pagination-hover-bg: var(--cnvs-themecolor);
  --bs-pagination-hover-color: var(--cnvs-contrast-0);
  --bs-pagination-hover-border-color: var(--bs-pagination-hover-bg);
  --bs-pagination-active-bg: var(--bs-pagination-hover-bg);
  --bs-pagination-active-border-color: var(--bs-pagination-hover-border-color);
}

.pagination.pagination-circle .page-item .page-link {
  --bs-pagination-padding-x: 0;
  --bs-pagination-padding-y: 0;
  --bs-pagination-border-radius: 50%;
  width: var(--cnvs-pagination-circle-size);
  height: var(--cnvs-pagination-circle-size);
  line-height: calc(var(--cnvs-pagination-circle-size)  - var(--bs-pagination-border-width) * 2);
  text-align: center;
}

.pagination.pagination-circle.pagination-lg {
  --cnvs-pagination-circle-size: 56px;
}

.pagination.pagination-circle.pagination-sm {
  --cnvs-pagination-circle-size: 26px;
}

.pagination:not(.pagination-circle):not(.pagination-rounded) .page-item:not(:first-child):not(:last-child) {
  --bs-pagination-border-radius: 0;
}

.pagination:not(.pagination-circle):not(.pagination-rounded) .page-item:first-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination:not(.pagination-circle):not(.pagination-rounded) .page-item:last-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination.pagination-rounded {
  --bs-pagination-border-radius: 4px;
}

.pagination.pagination-circle .page-item:not(:first-child) .page-link, .pagination.pagination-rounded .page-item:not(:first-child) .page-link {
  margin-left: var(--cnvs-pagination-margin);
}

.pagination.pagination-circle.pagination-lg {
  --cnvs-pagination-margin: 8px;
}

.pagination.pagination-circle.pagination-sm {
  --cnvs-pagination-margin: 3px;
}

.pagination.pagination-3d .page-item .page-link {
  border-bottom: 3px solid rgba(var(--cnvs-contrast-rgb), .15) !important;
}

.pagination.pagination-transparent .page-item .page-link {
  --bs-pagination-border-width: 0px;
}

.pagination.pagination-inside-transparent .page-item .page-link:not(:hover) {
  --bs-pagination-color: var(--cnvs-contrast-900);
}

.pagination.pagination-inside-transparent .page-item:not(:first-child):not(:nth-child(2)):not(:last-child) .page-link {
  border-left: 0;
}

.pagination.pagination-inside-transparent .page-item.active .page-link, .pagination.pagination-inside-transparent .page-link:hover, .pagination.pagination-inside-transparent .page-link:focus {
  --bs-pagination-border-color: transparent !important;
}

.pagination.pagination-inside-transparent.pagination-button .page-item:not(:first-child):not(:last-child) .page-link {
  --bs-pagination-border-width: 0;
}

.pagination.pagination-pill .page-item:first-child .page-link {
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
}

.pagination.pagination-pill .page-item:last-child .page-link {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}

.pagination.pagination-primary {
  --bs-pagination-bg: var(--bs-primary);
  --bs-pagination-border-color: var(--bs-primary);
  --bs-pagination-color: #fff;
}

.pagination.pagination-secondary {
  --bs-pagination-bg: var(--bs-secondary);
  --bs-pagination-border-color: var(--bs-secondary);
  --bs-pagination-color: #fff;
}

.pagination.pagination-success {
  --bs-pagination-bg: var(--bs-success);
  --bs-pagination-border-color: var(--bs-success);
  --bs-pagination-color: #fff;
}

.pagination.pagination-info {
  --bs-pagination-bg: var(--bs-info);
  --bs-pagination-border-color: var(--bs-info);
  --bs-pagination-color: #fff;
}

.pagination.pagination-warning {
  --bs-pagination-bg: var(--bs-warning);
  --bs-pagination-border-color: var(--bs-warning);
  --bs-pagination-color: #fff;
}

.pagination.pagination-danger {
  --bs-pagination-bg: var(--bs-danger);
  --bs-pagination-border-color: var(--bs-danger);
  --bs-pagination-color: #fff;
}

.pagination.pagination-light {
  --bs-pagination-bg: var(--bs-light);
  --bs-pagination-border-color: var(--bs-light);
  --bs-pagination-color: #fff;
}

.pagination.pagination-dark {
  --bs-pagination-bg: var(--bs-dark);
  --bs-pagination-border-color: var(--bs-dark);
  --bs-pagination-color: #fff;
}

.pagination.pagination-light {
  --bs-pagination-color: #222;
}

.table .success {
  color: var(--bs-success);
}

.table .info {
  color: var(--bs-info);
}

.table .warning {
  color: var(--bs-warning);
}

.table .danger {
  color: var(--bs-danger);
}

ul:not(.pagination) .page-link, ul:not(.pagination) .page-link:hover, ul:not(.pagination) .page-link:focus {
  color: var(--cnvs-themecolor);
}

.boot-tabs {
  margin-bottom: 20px !important;
}

.carousel-control .fa-chevron-left, .carousel-control .fa-chevron-right {
  z-index: 5;
  display: inline-block;
  position: absolute;
  top: 47.5%;
}

.carousel-control .fa-chevron-left {
  left: 50%;
}

.carousel-control .fa-chevron-right {
  right: 50%;
}

.responsive-utilities th small, .responsive-utilities th .small {
  color: var(--cnvs-contrast-500);
  font-weight: 400;
  display: block;
}

.show-grid [class^="col-"] {
  border: 1px solid rgba(var(--cnvs-contrast-rgb), .15);
  background-color: rgba(var(--cnvs-contrast-rgb), .1);
  padding-top: 10px;
  padding-bottom: 10px;
}

.img-circle {
  border-radius: 50% !important;
}

ul:not(.pagination) .page-item.active .page-link, ul:not(.pagination) .page-link:hover, ul:not(.pagination) .page-link:focus {
  color: var(--cnvs-contrast-0) !important;
  background-color: var(--cnvs-themecolor) !important;
  border-color: var(--cnvs-themecolor) !important;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  background-color: var(--cnvs-themecolor);
}

label.radio, label.checkbox {
  padding-left: 20px;
}

.input-group-lg > .input-group-addon {
  line-height: 1;
}

.shape-divider {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
  overflow: hidden;
}

.shape-divider[data-position="bottom"] {
  top: auto;
  bottom: -1px;
}

.shape-divider[data-front="true"] {
  z-index: 4;
}

.shape-divider svg {
  opacity: 0;
  width: calc(100% + 1.3px);
  height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.shape-divider[data-position="bottom"], .shape-divider[data-flip-vertical="true"] {
  transform: rotate(180deg);
}

.shape-divider[data-position="bottom"][data-flip-vertical="true"] {
  transform: rotate(0);
}

.shape-divider[data-flip="true"] svg {
  transform: translateX(-50%)rotateY(180deg);
}

.shape-divider-fill {
  fill: var(--cnvs-body-bg);
  transform-origin: center;
  transform: rotateY(0);
}

.mb_YTVPlayer {
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
  animation-timing-function: linear;
  display: block;
  transform: translate3d(0, 0, 0);
}

.mb_YTVPlayer:focus {
  outline: 0;
}

.mb_YTVPlayer.fullscreen {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  display: block !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.mb_YTVPlayer.fullscreen .mbYTP_wrapper {
  opacity: 1 !important;
}

.mbYTP_wrapper iframe {
  max-width: 4000px !important;
}

.inline_YTPlayer {
  vertical-align: top;
  background: #00000080;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 5px #000000b3;
}

.inline_YTPlayer img {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  transform: none !important;
}

.datepicker td, .datepicker th {
  padding: 5px;
}

.rating-xl {
  font-size: 3rem !important;
}

.rating-lg {
  font-size: 2.5rem !important;
}

.rating-md {
  font-size: 2rem !important;
}

.rating-sm {
  font-size: 1.75rem !important;
}

.rating-xs {
  font-size: 1.25rem !important;
}

.rating-container .star {
  margin: 0 2px !important;
}

.rating-container .filled-stars {
  -webkit-text-stroke: 0px !important;
  text-shadow: none !important;
}

@keyframes fadeInDownSmall {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownSmall {
  animation-name: fadeInDownSmall;
}

@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftSmall {
  animation-name: fadeInLeftSmall;
}

@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    transform: translate3d(50%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightSmall {
  animation-name: fadeInRightSmall;
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
}

@keyframes fadeOutDownSmall {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
}

.fadeOutDownSmall {
  animation-name: fadeOutDownSmall;
}

@keyframes fadeOutLeftSmall {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
}

.fadeOutLeftSmall {
  animation-name: fadeOutLeftSmall;
}

@keyframes fadeOutRightSmall {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(50%, 0, 0);
  }
}

.fadeOutRightSmall {
  animation-name: fadeOutRightSmall;
}

@keyframes fadeOutUpSmall {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
}

.fadeOutUpSmall {
  animation-name: fadeOutUpSmall;
}

:root, .not-dark {
  --cnvs-loader-color: var(--cnvs-contrast-300);
}

.page-transition #wrapper:not(.animated) {
  opacity: 0;
  position: relative;
}

.page-transition #wrapper.animated {
  animation-fill-mode: both;
}

.page-transition-wrap, .css3-spinner {
  z-index: 1000;
  text-align: center;
  background-color: var(--cnvs-body-bg);
  -webkit-perspective: 1000px;
  width: 100%;
  height: 100%;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  left: 0;
}

.css3-spinner {
  --cnvs-loader-color: var(--cnvs-contrast-300);
  z-index: auto;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.css3-spinner > div {
  --cnvs-loader-width: auto;
  --cnvs-loader-height: var(--cnvs-loader-width);
  --cnvs-loader-gutters: 0;
  --cnvs-loader-radius: 100%;
  width: var(--cnvs-loader-width);
  height: var(--cnvs-loader-height);
  margin: 0 var(--cnvs-loader-gutters);
  background-color: var(--cnvs-loader-color);
  border-radius: var(--cnvs-loader-radius);
  animation: 1.4s ease-in-out infinite both bouncedelay;
  display: inline-block;
}

.css3-spinner > div > div {
  width: var(--cnvs-loader-width);
  height: var(--cnvs-loader-height);
  margin: 0 var(--cnvs-loader-gutters);
  border-radius: var(--cnvs-loader-radius);
}

.css3-spinner .css3-spinner-bounce1, .css3-spinner .css3-spinner-bounce2, .css3-spinner .css3-spinner-bounce3 {
  --cnvs-loader-width: 18px;
  --cnvs-loader-gutters: 3px;
}

.css3-spinner .css3-spinner-bounce1 {
  animation-delay: -.32s;
}

.css3-spinner .css3-spinner-bounce2 {
  animation-delay: -.16s;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.css3-spinner > .css3-spinner-flipper {
  --cnvs-loader-width: 32px;
  --cnvs-loader-radius: 0;
  animation: 1.2s ease-in-out infinite rotateplane;
}

@keyframes rotateplane {
  0% {
    transform: perspective(120px)rotateX(0)rotateY(0);
  }

  50% {
    transform: perspective(120px)rotateX(-180.1deg)rotateY(0);
  }

  100% {
    transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
  }
}

.css3-spinner > .css3-spinner-double-bounce1, .css3-spinner > .css3-spinner-double-bounce2 {
  --cnvs-loader-width: 40px;
  --cnvs-loader-radius: 50%;
  opacity: .6;
  animation: 2s ease-in-out infinite cssspinnerbounce;
  position: absolute;
}

.css3-spinner > .css3-spinner-double-bounce2 {
  animation-delay: -1s;
}

@keyframes cssspinnerbounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.css3-spinner > .css3-spinner-rect1, .css3-spinner > .css3-spinner-rect2, .css3-spinner > .css3-spinner-rect3, .css3-spinner > .css3-spinner-rect4, .css3-spinner > .css3-spinner-rect5 {
  --cnvs-loader-width: 6px;
  --cnvs-loader-height: 30px;
  --cnvs-loader-gutters: 1px;
  --cnvs-loader-radius: 0;
  animation: 1.2s ease-in-out infinite stretchdelay;
}

.css3-spinner > .css3-spinner-rect2 {
  animation-delay: -1.1s;
}

.css3-spinner > .css3-spinner-rect3 {
  animation-delay: -1s;
}

.css3-spinner > .css3-spinner-rect4 {
  animation-delay: -.9s;
}

.css3-spinner > .css3-spinner-rect5 {
  animation-delay: -.8s;
}

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(.4);
  }

  20% {
    transform: scaleY(1);
  }
}

.css3-spinner > .css3-spinner-cube1, .css3-spinner > .css3-spinner-cube2 {
  --cnvs-loader-width: 16px;
  --cnvs-loader-radius: 0;
  animation: 1.8s ease-in-out infinite cubemove;
}

.css3-spinner > .css3-spinner-cube2 {
  animation-delay: -.9s;
}

@keyframes cubemove {
  25% {
    transform: translateX(42px)rotate(-90deg)scale(.5);
  }

  50% {
    transform: translateX(42px)translateY(42px)rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px)translateY(42px)rotate(-180deg);
  }

  75% {
    transform: translateX(0)translateY(42px)rotate(-270deg)scale(.5);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.css3-spinner > .css3-spinner-scaler {
  --cnvs-loader-width: 40px;
  animation: 1s ease-in-out infinite scaleout;
}

@keyframes scaleout {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.css3-spinner > .css3-spinner-grid-pulse {
  --cnvs-loader-width: 48px;
  flex-wrap: wrap;
  animation: none;
  display: flex;
  background-color: #0000 !important;
}

.css3-spinner-grid-pulse > div {
  --cnvs-loader-width: 12px;
  --cnvs-loader-height: 12px;
  --cnvs-loader-gutters: 2px;
  background-color: var(--cnvs-loader-color);
  max-width: calc(var(--cnvs-loader-width) * 4);
  animation-name: ball-grid-pulse;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  display: inline-block;
}

.css3-spinner-grid-pulse > div:first-child {
  animation-duration: 1.3s;
  animation-delay: .73s;
}

.css3-spinner-grid-pulse > div:nth-child(2) {
  animation-duration: 1.3s;
  animation-delay: .32s;
}

.css3-spinner-grid-pulse > div:nth-child(3) {
  animation-duration: .88s;
  animation-delay: .71s;
}

.css3-spinner-grid-pulse > div:nth-child(4) {
  animation-duration: 1.06s;
  animation-delay: .62s;
}

.css3-spinner-grid-pulse > div:nth-child(5) {
  animation-duration: .62s;
  animation-delay: .31s;
}

.css3-spinner-grid-pulse > div:nth-child(6) {
  animation-duration: 1.48s;
  animation-delay: -.14s;
}

.css3-spinner-grid-pulse > div:nth-child(7) {
  animation-duration: 1.47s;
  animation-delay: -.1s;
}

.css3-spinner-grid-pulse > div:nth-child(8) {
  animation-duration: 1.49s;
  animation-delay: .4s;
}

.css3-spinner-grid-pulse > div:nth-child(9) {
  animation-duration: .7s;
  animation-delay: .73s;
}

@keyframes ball-grid-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: .7;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.css3-spinner > .css3-spinner-clip-rotate {
  --cnvs-loader-width: 33px;
  animation: none;
  background-color: #0000 !important;
}

.css3-spinner-clip-rotate > div {
  border: 2px solid var(--cnvs-loader-color);
  border-radius: 100%;
  animation: 1s linear infinite rotate;
  background: none !important;
  border-bottom-color: #0000 !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(180deg)scale(.6);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

.css3-spinner > .css3-spinner-ball-rotate {
  --cnvs-loader-width: 12px;
  --cnvs-loader-gutters: 10px;
  width: var(--cnvs-loader-width);
  height: var(--cnvs-loader-width);
  margin: 0;
  animation: 1s cubic-bezier(.7, -.13, .22, .86) infinite ballrotate;
  background-color: #0000 !important;
}

.css3-spinner-ball-rotate > div {
  background-color: var(--cnvs-loader-color);
  border-radius: 100%;
  position: relative;
  margin: 0 !important;
}

.css3-spinner-ball-rotate > div:first-child, .css3-spinner-ball-rotate > div:nth-child(3) {
  width: var(--cnvs-loader-width);
  height: var(--cnvs-loader-width);
  content: "";
  opacity: .7;
  position: absolute;
}

.css3-spinner-ball-rotate > div:first-child {
  top: 0;
  left: calc(-1 * var(--cnvs-loader-width)  - var(--cnvs-loader-gutters));
}

.css3-spinner-ball-rotate > div:nth-child(3) {
  top: 0;
  left: calc(var(--cnvs-loader-width)  + var(--cnvs-loader-gutters));
}

@keyframes ballrotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.css3-spinner > .css3-spinner-zig-zag {
  --cnvs-loader-width: 12px;
  animation: none;
  transform: translate(0);
  background-color: #0000 !important;
}

.css3-spinner-zig-zag > div {
  background-color: var(--cnvs-loader-color);
  animation-fill-mode: both;
  position: absolute;
}

.css3-spinner-zig-zag > div:first-child {
  animation: 1.5s linear infinite ball-zig-deflect;
}

.css3-spinner-zig-zag > div:last-child {
  animation: 1.5s linear infinite ball-zag-deflect;
}

@keyframes ball-zig-deflect {
  17% {
    transform: translate(-15px, -30px);
  }

  34% {
    transform: translate(15px, -30px);
  }

  50% {
    transform: translate(0);
  }

  67% {
    transform: translate(15px, -30px);
  }

  84% {
    transform: translate(-15px, -30px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-zag-deflect {
  17% {
    transform: translate(15px, 30px);
  }

  34% {
    transform: translate(-15px, 30px);
  }

  50% {
    transform: translate(0);
  }

  67% {
    transform: translate(-15px, 30px);
  }

  84% {
    transform: translate(15px, 30px);
  }

  100% {
    transform: translate(0);
  }
}

.css3-spinner > .css3-spinner-ball-scale-multiple {
  --cnvs-loader-width: 60px;
  animation: none;
  background-color: #0000 !important;
}

.css3-spinner-ball-scale-multiple > div {
  background-color: var(--cnvs-loader-color);
  left: calc(50% - var(--cnvs-loader-width) / 2);
  top: calc(50% - var(--cnvs-loader-height) / 2);
  opacity: 0;
  animation: 1.25s linear infinite ball-scale-multiple;
  position: absolute;
}

.css3-spinner-ball-scale-multiple > div:nth-child(2) {
  animation-delay: -.4s;
}

.css3-spinner-ball-scale-multiple > div:nth-child(3) {
  animation-delay: -.2s;
}

@keyframes ball-scale-multiple {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  5% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.css3-spinner > .css3-spinner-triangle-path {
  --cnvs-loader-width: 10px;
  width: auto;
  height: auto;
  animation: none;
  transform: translate(-29.994px, -37.5094px);
  background-color: #0000 !important;
}

.css3-spinner-triangle-path > div {
  background-color: var(--cnvs-loader-color);
  animation-fill-mode: both;
  position: absolute;
}

.css3-spinner-triangle-path > div:first-child {
  animation: 2s ease-in-out infinite ball-triangle-path-1;
}

.css3-spinner-triangle-path > div:nth-child(2) {
  animation: 2s ease-in-out infinite ball-triangle-path-2;
}

.css3-spinner-triangle-path > div:nth-child(3) {
  animation: 2s ease-in-out infinite ball-triangle-path-3;
}

.css3-spinner-triangle-path > div:first-of-type {
  top: 50px;
}

.css3-spinner-triangle-path > div:nth-of-type(2) {
  left: 25px;
}

.css3-spinner-triangle-path > div:nth-of-type(3) {
  top: 50px;
  left: 50px;
}

@keyframes ball-triangle-path-1 {
  33% {
    transform: translate(25px, -50px);
  }

  66% {
    transform: translate(50px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-triangle-path-2 {
  33% {
    transform: translate(25px, 50px);
  }

  66% {
    transform: translate(-25px, 50px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-triangle-path-3 {
  33% {
    transform: translate(-50px);
  }

  66% {
    transform: translate(-25px, -50px);
  }

  100% {
    transform: translate(0);
  }
}

.css3-spinner > .css3-spinner-ball-pulse-sync {
  --cnvs-loader-width: 14px;
  --cnvs-loader-gutters: 3px;
  width: auto;
  height: auto;
  margin: 0;
  animation: none;
  background-color: #0000 !important;
}

.css3-spinner-ball-pulse-sync > div {
  background-color: var(--cnvs-loader-color);
  animation-fill-mode: both;
  display: inline-block;
}

.css3-spinner-ball-pulse-sync > div:nth-child(0) {
  animation: .7s ease-in-out -.21s infinite ball-pulse-sync;
}

.css3-spinner-ball-pulse-sync > div:first-child {
  animation: .7s ease-in-out -.14s infinite ball-pulse-sync;
}

.css3-spinner-ball-pulse-sync > div:nth-child(2) {
  animation: .7s ease-in-out -.07s infinite ball-pulse-sync;
}

.css3-spinner-ball-pulse-sync > div:nth-child(3) {
  animation: .7s ease-in-out infinite ball-pulse-sync;
}

@keyframes ball-pulse-sync {
  33% {
    opacity: .85;
    transform: translateY(10px);
  }

  66% {
    opacity: .7;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.css3-spinner > .css3-spinner-scale-ripple {
  --cnvs-loader-width: 50px;
  width: auto;
  height: auto;
  animation: none;
  background-color: #0000 !important;
}

.css3-spinner-scale-ripple > div {
  left: calc(50% - var(--cnvs-loader-width) / 2);
  top: calc(50% - var(--cnvs-loader-height) / 2);
  border: 2px solid var(--cnvs-loader-color);
  animation: 1.4s cubic-bezier(.21, .53, .56, .8) infinite ball-scale-ripple-multiple;
  position: absolute;
}

.css3-spinner-scale-ripple > div:nth-child(0) {
  animation-delay: -.8s;
}

.css3-spinner-scale-ripple > div:first-child {
  animation-delay: -.6s;
}

.css3-spinner-scale-ripple > div:nth-child(2) {
  animation-delay: -.4s;
}

.css3-spinner-scale-ripple > div:nth-child(3) {
  animation-delay: -.2s;
}

@keyframes ball-scale-ripple-multiple {
  0% {
    opacity: 1;
    transform: scale(.1);
  }

  70% {
    opacity: .7;
    transform: scale(1);
  }

  100% {
    opacity: 0;
  }
}

:root {
  --cnvs-mfp-overlay-color: #0b0b0b;
  --cnvs-mfp-overlay-opacity: .9;
  --cnvs-mfp-shadow: 0 0 8px #0009;
  --cnvs-mfp-popup-padding-left: 8px;
  --cnvs-mfp-popup-padding-left-mobile: 6px;
  --cnvs-mfp-z-index-base: 1040;
  --cnvs-mfp-controls-opacity: .65;
  --cnvs-mfp-controls-color: #fff;
  --cnvs-mfp-inner-close-icon-color: #333;
  --cnvs-mfp-controls-text-color: #ccc;
  --cnvs-mfp-controls-text-color-hover: #fff;
  --cnvs-mfp-preloader-size: 2rem;
  --cnvs-mfp-close-btn-size: 4rem;
  --cnvs-mfp-close-btn-font-size: 1.5rem;
  --cnvs-mfp-arrow-size: 4rem;
  --cnvs-mfp-title-padding: 1rem;
  --cnvs-mfp-title-border-radius: .25rem;
  --cnvs-mfp-link-color: #ccc;
  --cnvs-mfp-iframe-padding-top: 40px;
  --cnvs-mfp-iframe-background: #000;
  --cnvs-mfp-iframe-max-width: 900px;
  --cnvs-mfp-iframe-ratio: 9 / 16;
  --cnvs-mfp-image-background: #444;
  --cnvs-mfp-caption-title-color: #f3f3f3;
  --cnvs-mfp-caption-subtitle-color: #bdbdbd;
}

.mfp-bg {
  width: 100%;
  height: 100%;
  z-index: calc(var(--cnvs-mfp-z-index-base)  + 2);
  background: var(--cnvs-mfp-overlay-color);
  opacity: var(--cnvs-mfp-overlay-opacity);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  width: 100%;
  height: 100%;
  z-index: calc(var(--cnvs-mfp-z-index-base)  + 3);
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0 var(--cnvs-mfp-popup-padding-left);
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: calc(var(--cnvs-mfp-z-index-base)  + 5);
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  cursor: auto;
  width: 100%;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: var(--cnvs-mfp-controls-text-color);
  z-index: calc(var(--cnvs-mfp-z-index-base)  + 4);
  width: var(--cnvs-mfp-preloader-size);
  height: var(--cnvs-mfp-preloader-size);
  font-size: var(--cnvs-mfp-preloader-size);
  text-align: center;
  font-family: bootstrap-icons;
  line-height: 1;
  line-height: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mfp-preloader a {
  color: var(--cnvs-mfp-controls-text-color);
}

.mfp-preloader a:hover {
  color: var(--cnvs-mfp-controls-text-color-hover);
}

.mfp-container:not(.mfp-s-error) .mfp-preloader:before {
  content: "";
  width: 100%;
  height: 100%;
  line-height: 1;
  animation: 2s linear infinite spin;
  display: block;
}

.mfp-container.mfp-s-error .mfp-preloader {
  width: auto;
  height: auto;
  font-family: inherit;
  font-size: 1.5rem;
  line-height: 1.5;
  animation: none;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: calc(var(--cnvs-mfp-z-index-base)  + 6);
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  width: var(--cnvs-mfp-close-btn-size);
  height: var(--cnvs-mfp-close-btn-size);
  line-height: var(--cnvs-mfp-close-btn-size);
  text-align: center;
  opacity: var(--cnvs-mfp-controls-opacity);
  color: var(--cnvs-mfp-controls-color);
  font-style: normal;
  font-size: var(--cnvs-mfp-close-btn-font-size);
  padding: 0 0 18px 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: var(--cnvs-mfp-inner-close-icon-color);
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: var(--cnvs-mfp-controls-color);
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  white-space: nowrap;
  color: var(--cnvs-mfp-controls-text-color);
  background: #00000080;
  border-radius: .25rem;
  padding: .5rem 1rem;
  font-size: .75rem;
  line-height: 18px;
  position: absolute;
  top: auto;
  bottom: 25px;
  right: 20px;
}

.mfp-counter:empty {
  display: none;
}

.mfp-arrow {
  opacity: var(--cnvs-mfp-controls-opacity);
  width: var(--cnvs-mfp-arrow-size);
  height: var(--cnvs-mfp-arrow-size);
  font-family: bootstrap-icons;
  line-height: inherit;
  -webkit-tap-highlight-color: transparent;
  font-size: calc(var(--cnvs-mfp-arrow-size) / 2);
  color: var(--cnvs-mfp-link-color);
  margin: 0;
  padding: 0;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.mfp-arrow:hover {
  opacity: 1;
  transform: translateY(-50%)scale(1.2);
}

@media (width <= 991.98px) {
  .mfp-arrow {
    background-color: #000000bf !important;
  }

  .mfp-arrow:hover {
    transform: translateY(-50%);
    background-color: #000000d9 !important;
  }
}

.mfp-arrow-left:before, .mfp-arrow-right:before {
  place-self: center;
  display: inline-flex;
}

.mfp-arrow-right:before {
  content: "";
}

.mfp-arrow-right {
  left: auto;
  right: 0;
}

.mfp-arrow-left {
  left: 0;
  right: auto;
}

.mfp-arrow-left:before {
  content: "";
}

.mfp-iframe-holder {
  padding-top: var(--cnvs-mfp-iframe-padding-top);
  padding-bottom: var(--cnvs-mfp-iframe-padding-top);
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: var(--cnvs-mfp-iframe-max-width);
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: calc(var(--cnvs-mfp-iframe-ratio) * 100%);
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  width: 100%;
  height: 100%;
  box-shadow: var(--cnvs-mfp-shadow);
  background: var(--cnvs-mfp-iframe-background);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

img.mfp-img {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  z-index: -1;
  width: auto;
  height: auto;
  box-shadow: var(--cnvs-mfp-shadow);
  background: var(--cnvs-mfp-image-background);
  display: block;
  position: absolute;
  inset: 0;
}

.mfp-figure small, .mfp-figure .small {
  color: var(--cnvs-mfp-caption-subtitle-color);
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure small:empty, .mfp-figure .small:empty {
  display: none;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  cursor: auto;
  width: 100%;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: center;
  width: auto;
  color: var(--cnvs-mfp-caption-title-color);
  word-wrap: break-word;
  padding: var(--cnvs-mfp-title-padding);
  backdrop-filter: blur(3em);
  border-radius: var(--cnvs-mfp-title-border-radius);
  background: #273c4433;
  line-height: 1.65;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.mfp-title:empty {
  display: none;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (width <= 800px) and (orientation: landscape), screen and (height <= 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small, .mfp-img-mobile .mfp-figure .small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    text-align: center;
    background: #0009;
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (width <= 900px) {
  .mfp-arrow {
    transform: translateY(-50%)scale(.75) !important;
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: var(--cnvs-mfp-popup-padding-left-mobile);
    padding-right: var(--cnvs-mfp-popup-padding-left-mobile);
  }
}

:root {
  --cnvs-animate-duration: 1s;
  --cnvs-animate-delay: 1s;
  --cnvs-animate-repeat: 1;
}

.not-animated, [data-animate]:not(.animated) {
  opacity: 0;
}

.animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--cnvs-animate-duration);
  animation-duration: var(--cnvs-animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  -webkit-animation-iteration-count: calc(var(--cnvs-animate-repeat) * 1);
  animation-iteration-count: calc(var(--cnvs-animate-repeat) * 1);
}

.animated.repeat-2 {
  -webkit-animation-iteration-count: calc(var(--cnvs-animate-repeat) * 2);
  animation-iteration-count: calc(var(--cnvs-animate-repeat) * 2);
}

.animated.repeat-3 {
  -webkit-animation-iteration-count: calc(var(--cnvs-animate-repeat) * 3);
  animation-iteration-count: calc(var(--cnvs-animate-repeat) * 3);
}

.animated.delay-1s {
  -webkit-animation-delay: calc(var(--cnvs-animate-delay) * 1);
  animation-delay: calc(var(--cnvs-animate-delay) * 1);
}

.animated.delay-2s {
  -webkit-animation-delay: calc(var(--cnvs-animate-delay) * 2);
  animation-delay: calc(var(--cnvs-animate-delay) * 2);
}

.animated.delay-3s {
  -webkit-animation-delay: calc(var(--cnvs-animate-delay) * 3);
  animation-delay: calc(var(--cnvs-animate-delay) * 3);
}

.animated.delay-4s {
  -webkit-animation-delay: calc(var(--cnvs-animate-delay) * 4);
  animation-delay: calc(var(--cnvs-animate-delay) * 4);
}

.animated.delay-5s {
  -webkit-animation-delay: calc(var(--cnvs-animate-delay) * 5);
  animation-delay: calc(var(--cnvs-animate-delay) * 5);
}

.animated.faster {
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) / 2);
  animation-duration: calc(var(--cnvs-animate-duration) / 2);
}

.animated.fast {
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * .8);
  animation-duration: calc(var(--cnvs-animate-duration) * .8);
}

.animated.slow {
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * 2);
  animation-duration: calc(var(--cnvs-animate-duration) * 2);
}

.animated.slower {
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * 3);
  animation-duration: calc(var(--cnvs-animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * 1.3);
  animation-duration: calc(var(--cnvs-animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-duration: calc(var(--cnvs-animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--cnvs-animate-duration) * 2);
  animation-duration: calc(var(--cnvs-animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

.dark {
  --cnvs-contrast-bg: #212529;
  --cnvs-contrast-bg-offset: #343a40;
  --cnvs-contrast-0: #000;
  --cnvs-contrast-100: #212529;
  --cnvs-contrast-200: #343a40;
  --cnvs-contrast-300: #495057;
  --cnvs-contrast-400: #6c757d;
  --cnvs-contrast-500: #adb5bd;
  --cnvs-contrast-600: #ced4da;
  --cnvs-contrast-700: #dee2e6;
  --cnvs-contrast-800: #e9ecef;
  --cnvs-contrast-900: #f8f9fa;
  --cnvs-contrast-1000: #fff;
  --bs-link-color: var(--cnvs-contrast-900);
  --cnvs-invert-contrast-0: #fff;
  --cnvs-invert-contrast-100: #f8f9fa;
  --cnvs-invert-contrast-200: #e9ecef;
  --cnvs-invert-contrast-300: #dee2e6;
  --cnvs-invert-contrast-400: #ced4da;
  --cnvs-invert-contrast-500: #adb5bd;
  --cnvs-invert-contrast-600: #6c757d;
  --cnvs-invert-contrast-700: #495057;
  --cnvs-invert-contrast-800: #343a40;
  --cnvs-invert-contrast-900: #212529;
  --cnvs-invert-contrast-1000: #000;
  --cnvs-contrast-rgb: 255, 255, 255;
  --cnvs-invert-contrast-rgb: 0, 0, 0;
  --cnvs-heading-color: var(--cnvs-contrast-900);
  --cnvs-topbar-bg: var(--cnvs-contrast-bg-offset);
  --cnvs-header-bg: var(--cnvs-contrast-bg);
  --cnvs-header-sticky-bg: var(--cnvs-contrast-bg);
  --cnvs-side-header-bg: var(--cnvs-contrast-bg);
  --cnvs-header-floating-bg: var(--cnvs-contrast-bg);
  --cnvs-primary-menu-submenu-bg: var(--cnvs-contrast-bg);
  --cnvs-section-bg: var(--cnvs-contrast-bg-offset);
  --cnvs-footer-bg: var(--cnvs-contrast-bg-offset);
  color: var(--cnvs-contrast-800);
}

body.dark {
  --cnvs-body-bg: var(--cnvs-contrast-bg);
}

.dark#footer .footer-widgets-wrap, .dark #footer .footer-widgets-wrap {
  color: var(--cnvs-contrast-800);
}

.dark#footer .footer-widgets-wrap a, .dark #footer .footer-widgets-wrap a {
  color: #ffffffbf;
}

.dark#footer .footer-widgets-wrap a:hover, .dark #footer .footer-widgets-wrap a:hover {
  color: var(--cnvs-contrast-1000);
}

.dark #copyrights, #copyrights.dark {
  color: #fff6;
  background-color: #0003;
}
/*# sourceMappingURL=index.afd45d7b.css.map */
