/* ----------------------------------------------------------------

	shop.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/

:root,
.not-dark {
	--#{$cnvs-prefix}cart-quantity-bg: #{$quantity-box-bg};
	--#{$cnvs-prefix}cart-quantity-color: var(--#{$cnvs-prefix}contrast-800);
	--#{$cnvs-prefix}cart-quantity-color-hover: var(--#{$cnvs-prefix}cart-quantity-color);
	--#{$cnvs-prefix}cart-quantity-border-color: #{$line-color};
	--#{$cnvs-prefix}cart-quantity-bg-hover: var(--#{$cnvs-prefix}contrast-300);
}

.shop {
	position: relative;
}

.product {
	position: relative;
	.product-image {
		position: relative;
		overflow: hidden;
		border-radius: 0.25rem;
		> a,
		.slide a,
		img {
			display: block;
			width: 100%;
		}

		> a {
			@include transition(all 0.5s ease);
		}
	}
}

.product-image {
	> a:nth-of-type(2) {
		opacity: 0;
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
	}

	&:hover > a:nth-of-type(2) {
		opacity: 1;
		z-index: 2;
	}
}

.product-desc {
	padding: $shop-desc-padding 0;
}

.product-title {
	margin-bottom: 0.5rem;
}

.product-title h3 {
	margin: 0;
	font-size: $shop-title-font-size;
	font-weight: 600;
}

.product-title h3 a,
.single-product .product-title h2 a {
	color: $shop-title-color;
}

.product-title h3 a:hover,
.single-product .product-title h2 a:hover {
	color: var(--#{$cnvs-prefix}themecolor);
}

.product-price {
	font-size: $shop-product-price;
	font-weight: $shop-product-price-font-weight;
	color: $shop-product-price-color;
	margin-bottom: 0.25rem;
}

.product-price del {
	font-weight: 400;
	font-size: 90%;
	color: $shop-product-price-del;
}

.product-price ins {
	text-decoration: none;
	color: var(--#{$cnvs-prefix}themecolor);
}

.product-image > .badge,
.sale-flash {
	position: absolute;
	top: 0.75rem;
	left: 0.75rem;
	z-index: 5;
}

/* Shop - Single
-----------------------------------------------------------------*/

.single-product .product {
	width: 100%;
	margin: 0;
}

.shop-quick-view-ajax,
.portfolio-ajax-modal {
	position: relative;
	background-color: var(--#{$cnvs-prefix}contrast-bg);
	width: auto;
	max-width: $shop-quick-view;
	margin: 0 auto;
}
.portfolio-ajax-modal {
	max-width: 1000px;
}

.single-product .product-image,
.single-product .product-image img {
	height: auto;
}

.single-product .product-title h2 {
	font-size: $shop-single-title-size;
	margin-bottom: 8px;
}

.single-product .product-price {
	font-size: $shop-single-price-size;
	color: var(--#{$cnvs-prefix}themecolor);
	margin-bottom: 0;
}

.single-product .product-desc .line {
	margin: 20px 0;
}

.quantity {
	--#{$cnvs-prefix}cart-quantity-width: #{$quantity-box-width};
	--#{$cnvs-prefix}cart-quantity-height: #{$quantity-box-height};
	--#{$cnvs-prefix}cart-quantity-font-size: #{$quantity-box-font-size};
	--#{$cnvs-prefix}cart-quantity-border: #{$quantity-border};
	--#{$cnvs-prefix}cart-quantity-bg: #{$quantity-box-bg};
	--#{$cnvs-prefix}cart-quantity-color: var(--#{$cnvs-prefix}contrast-800);
	--#{$cnvs-prefix}cart-quantity-color-hover: var(--#{$cnvs-prefix}cart-quantity-color);
	--#{$cnvs-prefix}cart-quantity-border-color: #{$line-color};
	--#{$cnvs-prefix}cart-quantity-bg-hover: var(--#{$cnvs-prefix}contrast-300);
	display: inline-flex;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;
	margin-right: 30px;
}

.quantity input[type="number"]::-webkit-inner-spin-button,
.quantity input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.quantity .qty {
	width: var(--#{$cnvs-prefix}cart-quantity-width);
	height: var(--#{$cnvs-prefix}cart-quantity-height);
	line-height: var(--#{$cnvs-prefix}cart-quantity-height);
	border: 0;
	border-left: var(--#{$cnvs-prefix}cart-quantity-border) solid var(--#{$cnvs-prefix}cart-quantity-border-color);
	border-right: var(--#{$cnvs-prefix}cart-quantity-border) solid var(--#{$cnvs-prefix}cart-quantity-border-color);
	background-color: var(--#{$cnvs-prefix}cart-quantity-bg);
	text-align: center;
	margin-bottom: 0;
	font-size: var(--#{$cnvs-prefix}cart-quantity-font-size);
	color: var(--#{$cnvs-prefix}cart-quantity-color);
}

.quantity .plus,
.quantity .minus {
	display: block;
	cursor: pointer;
	border: 0px transparent;
	padding: 0;
	width: calc(var(--#{$cnvs-prefix}cart-quantity-width) - calc(var(--#{$cnvs-prefix}cart-quantity-border) * 4 ));
	height: var(--#{$cnvs-prefix}cart-quantity-height);
	line-height: var(--#{$cnvs-prefix}cart-quantity-height);
	text-align: center;
	background-color: var(--#{$cnvs-prefix}cart-quantity-bg);
	color: var(--#{$cnvs-prefix}cart-quantity-color);
	font-size: var(--#{$cnvs-prefix}cart-quantity-font-size);
	font-weight: bold;
	@include transition(background-color 0.2s linear);
}

.quantity .plus:hover,
.quantity .minus:hover {
	background-color: var(--#{$cnvs-prefix}cart-quantity-bg-hover);
	color: var(--#{$cnvs-prefix}cart-quantity-color-hover);
}

.quantity .qty:focus,
.quantity .plus:focus,
.quantity .minus:focus {
	box-shadow: none !important;
	outline: 0 !important;
}

.product-meta {
	font-size: 90%;
	color: var(--#{$cnvs-prefix}contrast-500);
}

.product-meta > .card-body > span {
	display: inline-block;
	margin: 0 5px;
}

.single-product .fbox-plain.fbox-sm .fbox-icon {
	width: 2.25rem;
}

.single-product .fbox-plain.fbox-sm .fbox-icon i {
	font-size: 1.125rem;
	line-height: 1.5;
}

// Quantity Styles
.cart-border .quantity {
	--#{$cnvs-prefix}cart-quantity-bg: transparent;
	--#{$cnvs-prefix}cart-quantity-border-color: rgba(var(--#{$cnvs-prefix}contrast-rgb), .15);
	--#{$cnvs-prefix}cart-quantity-bg-hover: var(--#{$cnvs-prefix}contrast-200);
	border: var(--#{$cnvs-prefix}cart-quantity-border) solid var(--#{$cnvs-prefix}cart-quantity-border-color);
}

.cart-border-2 .quantity {
	--#{$cnvs-prefix}cart-quantity-height: 46px;
	--#{$cnvs-prefix}cart-quantity-bg-hover: transparent;
	--#{$cnvs-prefix}cart-quantity-color-hover: var(--#{$prefix}danger);
}

.cart-border.cart-border-2 .qty {
	--#{$cnvs-prefix}cart-quantity-width: 25px;
}

.cart-bg-circle .quantity {
	--#{$cnvs-prefix}cart-quantity-border: 0px;
	--#{$cnvs-prefix}cart-quantity-width: 43px;
	--#{$cnvs-prefix}cart-quantity-height: 43px;
	transition: none;
}

// .dark .quantity {
// 	--#{$cnvs-prefix}cart-quantity-bg: rgba(0, 0, 0, 0.2);
// 	--#{$cnvs-prefix}cart-quantity-color-hover: var(--#{$cnvs-prefix}cart-quantity-color);
// 	--#{$cnvs-prefix}cart-quantity-border-color: rgba($white, 0.5);
// 	--#{$cnvs-prefix}cart-quantity-bg-hover: rgba($black, 0.3);
// }

/* ----------------------------------------------------------------
	Cart
-----------------------------------------------------------------*/

.cart .remove {
	font-size: 0.875rem;
	color: #ff0000;
}

.cart .remove:hover {
	color: #000;
}

.cart th {
	padding: $cart-padding !important;
	font-size: $cart-heading-font-size;
	color: $cart-heading-font-color;
}

.cart td {
	padding: $cart-padding !important;
	vertical-align: middle !important;
	border-color: rgba(var(--#{$cnvs-prefix}contrast-rgb), .1) !important;
}

.cart-product-thumbnail a {
	display: block;
	width: $cart-thumbnail-size;
}

.cart-product-thumbnail img {
	display: block;
	width: $cart-thumbnail-size - 4;
	height: $cart-thumbnail-size - 4;
	border: 2px solid rgba(var(--#{$cnvs-prefix}contrast-rgb), .2);
}

.cart-product-thumbnail img:hover {
	border-color: var(--#{$cnvs-prefix}themecolor);
}

.cart-product-name,
.product-name {
	a {
		font-weight: 600;
		&:not(:hover) {
			color: $shop-title-color;
		}
	}
}

.cart-product-price,
.cart-product-quantity,
.cart-product-subtotal {
	text-align: center !important;
}

.cart-product-quantity .quantity {
	margin: 0;
}

@include media-breakpoint-down(sm) {
	.cart:not(.cart-totals) {
		thead th {
			display: none;
		}
		tbody td {
			display: block;
			width: 100%;
			border: 0;
			padding: 0 !important;
			text-align: center;
		}

		.cart_item {
			display: block;
			position: relative;
			border: 1px solid rgba(var(--#{$cnvs-prefix}contrast-rgb), .1);
			margin-bottom: 1.5rem;
			padding: 1.5rem;
			.cart-product-remove {
				display: block;
				position: absolute;
				top: 1.25rem;
				left: auto;
				right: 1.25rem;
				width: 1.25rem;
				height: 1.25rem;
			}
		}

		.cart-product {
			&-thumbnail,
			&-name,
			&-quantity {
				margin-bottom: 1rem;
			}
		}

		.cart_item {
			.cart-product-thumbnail a {
				display: inline-block;
			}
			.cart-product-price {
				display: none;
			}

			.cart-product-name a {
				font-size: 1rem;
			}

			.cart-product-subtotal {
				font-size: 1.25rem;
				color: var(--#{$cnvs-prefix}themecolor);
			}
		}
	}
	.quantity .qty {
		width: 42px;
	}

	.quantity .qty,
	.quantity .plus,
	.quantity .minus {
		font-size: $cart-heading-font-size;
	}
}

td.actions {
	padding: 20px 15px !important;
	vertical-align: top !important;
	background-color: var(--#{$cnvs-prefix}contrast-100);
}
