/* ----------------------------------------------------------------

	widgets.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/

:root,
.not-dark {
	--#{$cnvs-prefix}widget-border 			  :          #{$widget-border};
}

.widget {
	--#{$cnvs-prefix}widget-border 			  :          #{$widget-border};
	--#{$cnvs-prefix}widget-margin 			  :          #{$widget-margin};
	--#{$cnvs-prefix}widget-title-margin         :          #{$widget-title-margin};
	--#{$cnvs-prefix}widget-title-font-size      :          #{$widget-title-font-size};
	--#{$cnvs-prefix}widget-title-font-weight    :          #{$widget-title-font-weight};
	--#{$cnvs-prefix}widget-title-letter-spacing :          #{$widget-title-letter-spacing};
	--#{$cnvs-prefix}widget-title-text-transform :          #{$widget-title-text-transform};

	position: relative;
	margin-top: var(--#{$cnvs-prefix}widget-margin);

	p {
		line-height: 1.7;
	}

	.sidebar-widgets-wrap & {
		padding-top: var(--#{$cnvs-prefix}widget-margin);
		border-top: var(--#{$cnvs-prefix}widget-border);
		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
		> h4 {
			letter-spacing: calc(var(--#{$cnvs-prefix}widget-title-letter-spacing) + 1px);
		}
	}

	&:first-child {
		margin-top: 0;
	}

	> h4 {
		margin-bottom: var(--#{$cnvs-prefix}widget-title-margin);
		font-size: var(--#{$cnvs-prefix}widget-title-font-size);
		font-weight: var(--#{$cnvs-prefix}widget-title-font-weight);
		letter-spacing: var(--#{$cnvs-prefix}widget-title-letter-spacing);
		text-transform: var(--#{$cnvs-prefix}widget-title-text-transform);
	}

	/* Widget - Links
	-----------------------------------------------------------------*/
	&_nav_menu,
	&_links,
	&_meta,
	&_archive,
	&_recent_comments,
	&_recent_entries,
	&_categories,
	&_pages,
	&_rss {
		ul,
		li {
			list-style: none;
			margin: 0;
		}
		li {
			display: flex;
			padding: 0.25rem 0;
			font-size: 1rem;
			a {
				display: inline-block;
				padding: 0 0.25rem 0 0.75rem;
				border: none !important;
				color: var(--#{$cnvs-prefix}heading-color);
				&:hover {
					color: var(--#{$cnvs-prefix}themecolor);
				}
			}
		}
		&:not(.widget-li-noicon) li::before {
			content: "\F231";
			font-family: "bootstrap-icons";
			display: inline-flex;
			align-self: center;
			justify-self: center;
			position: relative;
			font-size: 0.75rem;
			line-height: inherit;
			left: 0;
		}
		&.widget-li-noicon li a {
			padding-left: 0;
		}
		> ul {
			margin-top: -4px !important;
			> li:first-child {
				border-top: 0 !important;
			}
		}
		ul ul {
			margin-left: 1rem;
		}
	}
	&_recent_comments li::before {
		content: "\F249";
	}

	/* Widget - Testimonial & Twitter
	-----------------------------------------------------------------*/

	.testimonial {
		&.no-image .testi-image {
			display: none;
		}

		&.twitter-scroll {
			.testi-image {
				margin-right: 10px;
				width: 28px;
				height: 28px;
				a,
				img,
				i {
					width: 28px;
					height: 28px;
				}
				i {
					background-color: var(--#{$cnvs-prefix}contrast-100);
					line-height: 28px;
					font-size: 0.875rem;
					color: var(--#{$cnvs-prefix}contrast-300);
				}
			}
		}

		&:not(.twitter-scroll) .testi-image,
		&:not(.twitter-scroll) .testi-image a,
		&:not(.twitter-scroll) .testi-image img {
			width: 42px;
			height: 42px;
		}

		p {
			font-size: 0.875rem;
		}
	}
}

@include media-breakpoint-only(md) {
	.sidebar-widgets-wrap {
		position: relative;
		display: -ms-flex;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -20px -40px -20px;
		.widget {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
			margin: 0 0 40px 0;
			padding-top: 0;
			padding-left: 20px;
			padding-right: 20px;
			border-top: 0;
		}
	}
}

/* Widget - Quick Contact Form
-----------------------------------------------------------------*/

.quick-contact-widget {
	&.form-widget {
		.form-control,
		.input-group,
		.sm-form-control {
			margin-bottom: 10px;
		}

		.form-control {
			margin-bottom: 0;
		}
	}
}

.quick-contact-widget.form-widget form,
#template-contactform {
	position: relative;
}

/* Newsletter Widget
-----------------------------------------------------------------*/

.subscribe-widget h5 {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.5;
}

/* Twitter Feed Widget
-----------------------------------------------------------------*/

.widget-twitter-feed {
	.twitter-feed {
		--#{$cnvs-prefix}iconlist-size: 1.5rem;
		--#{$cnvs-prefix}iconlist-offset: 0.375rem;
		--#{$cnvs-prefix}iconlist-gutter: 1rem;
		li:not(:first-child) {
			margin-top: 1.125rem;
		}

		li {
			div {
				font-size: 1rem;
			}
		}

		small {
			display: block;
			margin-top: 0.25rem;
			a:not(:hover) {
				color: var(--#{$cnvs-prefix}contrast-600);
			}
		}
	}
}

/* Widget Filter Links
-----------------------------------------------------------------*/

.widget-filter-links {
	ul {
		list-style: none;
		margin-bottom: 0;
	}
	.widget-filter-reset {
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
		font-size: 12px;
		line-height: 22px;
		a {
			color: red !important;
		}
		&.active-filter {
			display: none;
		}
	}

	&:not(:first-child) .widget-filter-reset {
		top: 50px;
	}

	li {
		position: relative;
		font-size: 15px;
		line-height: 24px;
		&:not(:first-child) {
			margin-top: 10px;
		}
		a {
			display: block;
			color: var(--#{$cnvs-prefix}heading-color);
			font-family: var(--#{$cnvs-prefix}primary-font);
			font-weight: 500;
		}

		span {
			&:not(.d-inline-block) {
				position: absolute;
				top: 1px;
				left: auto;
				right: 0;
			}
			margin-left: 0.25rem;
			font-size: 11px;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			color: var(--#{$cnvs-prefix}contrast-600);
			background-color: var(--#{$cnvs-prefix}contrast-200);
			border-radius: 50%;
		}
		&:hover a {
			color: var(--#{$cnvs-prefix}contrast-600);
		}
		&.active-filter a {
			font-weight: 700;
		}
		&.active-filter span {
			color: #fff !important;
			background-color: var(
				--#{$cnvs-prefix}themecolor
			) !important;
			text-shadow: 1px 1px 1px rgba(black, 0.1);
		}
	}
}

/* Tag Cloud
-----------------------------------------------------------------*/

.tagcloud {
	margin-bottom: -2px;
	display: flex;
	flex-wrap: wrap;
	a {
		margin-right: 4px;
		margin-bottom: 4px;
		padding: 3px 6px;
		border: 1px solid var(--#{$cnvs-prefix}contrast-300);
		font-size: 13px !important;
		color: var(--#{$cnvs-prefix}contrast-700);
		border-radius: 2px;
		@include transition(all 0.3s ease-in-out);

		&:hover {
			border-color: var(--#{$cnvs-prefix}themecolor) !important;
			color: var(--#{$cnvs-prefix}themecolor) !important;
		}
	}
}
